import React, { FC } from 'react'
import { getGatsbyImageData } from 'gatsby-source-sanity'
import { options } from '../../contexts/SanityClient'
import VideoCta from '../VideoCta'
// import { IVideoCta } from '../../interfaces/videoCta.interface'
import { IGatsbyImageData } from 'gatsby-plugin-image'

export interface IVideoCta {
  videoUrl?: string
  placeholderImage?: {
    asset:
      | ({
          _id?: string
          gatsbyImageData?: IGatsbyImageData | null
        } & IGatsbyImageData)
      | null
  }
  text?: string
}

const VideoCtaBlock: FC<IVideoCta> = ({ videoUrl, placeholderImage, text }) => {
  let imageProps: IGatsbyImageData | null = null

  if (placeholderImage && placeholderImage.asset) {
    imageProps = getGatsbyImageData(placeholderImage.asset._id, { fit: 'fillmax' }, options)
  }

  const props: IVideoCta = {
    videoUrl,
    text,
    placeholderImage: {
      asset: imageProps,
    },
  }

  return <VideoCta videoCta={props} />
}
export default VideoCtaBlock
