import React, { FC } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { LogoContainer, FooterLogoContainer } from './PartnerLogos.styled'
import { useLocation } from '@gatsbyjs/reach-router'
import { Maybe, SanityPartner } from '../../generated/api'

export interface LogoProps {
  id?: SanityPartner['id']
  href?: SanityPartner['websiteUrl']
  img?: Maybe<{
    asset?: Maybe<{ gatsbyImageData: any }>
  }>
  alt?: SanityPartner['name']
}

interface PartnerLogosProps {
  logos: LogoProps[]
  isFooter: boolean
}

const PartnerLogos: FC<PartnerLogosProps> = ({ logos, isFooter = true }) => {
  const location = useLocation()
  return isFooter ? (
    <FooterLogoContainer logoCount={logos.length}>
      {logos.map((_logo) => {
        // set defaults
        const logo = {
          ..._logo,
          alt: _logo.alt || 'Partner logo',
          href: _logo.href || '',
        }

        return (
          logo?.img?.asset?.gatsbyImageData && (
            <div key={logo.id}>
              {/*"href" logic is an accessibility requirement*/}
              <a href={logo.href === '/' ? location.origin : logo.href} aria-label={logo.href}>
                <GatsbyImage image={logo?.img?.asset?.gatsbyImageData} alt={logo.alt} />
              </a>
            </div>
          )
        )
      })}
    </FooterLogoContainer>
  ) : (
    <LogoContainer logoCount={logos.length}>
      {logos.map((_logo) => {
        // set defaults
        const logo = {
          ..._logo,
          alt: _logo.alt || 'Partner logo',
          href: _logo.href || '',
        }
        return (
          <div key={logo.id}>
            <a href={logo.href === '/' ? location.origin : logo.href} aria-label={logo.alt}>
              <GatsbyImage image={logo?.img?.asset?.gatsbyImageData} alt={logo.alt} />
            </a>
          </div>
        )
      })}
    </LogoContainer>
  )
}

export default PartnerLogos
