import * as React from 'react'
import { animated } from 'react-spring'
import styled from 'styled-components'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const StyledSuggestion = styled.div`
  min-height: 80px;
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: black;
  font-size: 12px;
  font-weight: normal;
  cursor: pointer;
  text-align: left;
  padding: 5px 10px;
  border-bottom: 1px solid #e0e0e0;

  &:hover {
    background-color: #e0e0e0;
  }
`

const StyledText = styled.span`
  font-family: ${(props) => props.theme.fonts.primaryFont};
  font-size: 14px;
  color: #044567;
`

const AutoComplete = ({ data, handleClick, forwardRef, setSearchValue, searchValue }) => {
  const { t } = useTranslation()
  return (
    <animated.div
      ref={forwardRef}
      tabIndex={0}
      style={{
        overflowY: 'auto',
        position: 'absolute',
        height: 'auto',
        maxHeight: '400px',
        width: '100%',
        backgroundColor: 'white',
        zIndex: 10000,
        overflow: 'scroll',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #d5d5d5',
        borderRadius: 5,
        marginTop: 5,
      }}
    >
      {!data.length && searchValue.length > 0 && (
        <StyledSuggestion style={{ justifyContent: 'center', alignItems: 'center' }}>
          <StyledText>{t('components.autocomplete.noResult')}</StyledText>
        </StyledSuggestion>
      )}
      {data.map((el) => {
        const searchedValue = `${el.properties.name || el.properties.district || ''}${
          el.properties.postcode ? `, ${el.properties.postcode}` : ''
        }${el.properties.city || el.properties.country ? `, ${el.properties.city || el.properties.country}` : ''}`
        return (
          <StyledSuggestion
            onClick={() => {
              handleClick(el)
              setSearchValue(searchedValue)
            }}
            key={`${el.properties?.name}${el.geometry.coordinates[0]}`}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                fontFamily: 'co-text',
              }}
            >
              <StyledText style={{ fontWeight: 'bold' }}>
                {el.properties.name ? el.properties.name : el.properties.district}
              </StyledText>
              <StyledText>{el.properties.city}</StyledText>
              <div style={{ display: 'flex' }}>
                {el.properties.postcode && (
                  <StyledText style={{ marginRight: 5 }}>{`${el.properties.postcode},`}</StyledText>
                )}
                <StyledText>{el.properties.country}</StyledText>
              </div>
            </div>
          </StyledSuggestion>
        )
      })}
    </animated.div>
  )
}

export default AutoComplete
