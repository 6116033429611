import { object, string, ref } from 'yup'

export const getChangePasswordSchema = (t2) => {
  const t = (...args: any) => t2(...args) || undefined
  return object({
    currentPassword: string()
      .max(50, t('errors.formik.password.max'))
      .required(t('manageAccount.passwordChange.errors.currentPassword')),
    password: string()
      .min(8, t('manageAccount.passwordChange.errors.tooShortPassword'))
      .max(50, t('errors.formik.password.max'))
      .required(t('manageAccount.passwordChange.errors.password'))
      .matches(/^(?=.*[a-z])/, t('manageAccount.passwordChange.errors.smallLetter'))
      .matches(/^(?=.*[A-Z])/, t('manageAccount.passwordChange.errors.bigLetter'))
      .matches(/^(?=.*[0-9])/, t('manageAccount.passwordChange.errors.numberRequired')),
    confirmPassword: string()
      .oneOf([ref('password'), null], t('manageAccount.passwordChange.errors.equal'))
      .required(t('manageAccount.passwordChange.errors.confirmPassword')),
  })
}
