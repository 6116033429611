import React, { FC } from 'react'
import Logo from '../Logo/Logo'
import { StyledHeaderLanding } from './HeaderLanding.styled'
import usePageContext from '../../hooks/usePageContext'
import { useLocation } from '@gatsbyjs/reach-router'

const HeaderLanding: FC = () => {
  const { headerBigLogoShown, headerLogoShown } = usePageContext()
  const { pathname } = useLocation()

  return (
    <StyledHeaderLanding home={pathname === '/'} register={pathname === '/signup'} centered={!!headerBigLogoShown}>
      {headerLogoShown || (headerBigLogoShown && <Logo linkTo="/landing" />)}
    </StyledHeaderLanding>
  )
}

export default HeaderLanding
