import { object, string } from 'yup'

export const getProfileDetailsSchema = (t2) => {
  const t = (...args: any) => t2(...args) || undefined
  return object({
    postCode: string().max(20, t('errors.formik.postCode.max')).nullable().required(t('validation.req')),
    gender: string().nullable().required(t('validation.req')),
    // preferredLanguage: Yup.string()
    //   .oneOf(
    //     SUPPORTED_LANGUAGES.map(({ localeCode }) => localeCode),
    //     t('player.errors.preferredLanguage'),
    //   )
    //   .required(t('player.errors.preferredLanguage')),
  })
}

export interface ProfileDetailsProps {
  postCode: string
  gender: string
  submit: null | string
}
