import styled from 'styled-components'

export const Wrapper = styled.div`
  h2 {
    font-size: 18px;
    font-family: ${(props) => props.theme.fonts.primaryFont};
    color: ${(props) => props.theme.colors.body};
    margin: 5px auto;
  }
  p {
    font-size: 18px;
    font-family: ${(props) => props.theme.fonts.primaryFont};
    color: ${(props) => props.theme.colors.body};
    margin: 5px auto;
    &.sub {
      color: ${(props) => props.theme.colors.midDarkGrey};
      font-size: 1.1rem;
    }
  }
`
