import React, { FC, useEffect } from 'react'
import { FRESHDESK_WIDGET_ENGLISH_ID, FRESHDESK_WIDGET_WELSH_ID } from '../../utils/constants'
import { HelpWidget } from '../../generated/api'
import { Script } from 'gatsby'

interface Props {
  helpWidget: HelpWidget
}

const FreshdeskWidget: FC<Props> = ({ helpWidget }) => {
  useEffect(() => {
    if (window.location.search.includes('mobile-freshdesk')) {
      setTimeout(() => {
        // @ts-ignore
        window.FreshworksWidget('open')
      }, 500)
    }
  }, [])

  useEffect(() => {
    let widgetId = FRESHDESK_WIDGET_ENGLISH_ID
    switch (helpWidget) {
      case HelpWidget.Standard:
      case HelpWidget.StandardAndMobile:
        widgetId = FRESHDESK_WIDGET_ENGLISH_ID
        break
      case HelpWidget.StandardAndWelsh:
        widgetId = FRESHDESK_WIDGET_WELSH_ID
        break
    }
    // @ts-ignore
    window.fwSettings = {
      widget_id: widgetId,
    }
  }, [helpWidget])

  const InjectFreshdeskScript = () => {
    let widgetId = FRESHDESK_WIDGET_ENGLISH_ID
    switch (helpWidget) {
      case HelpWidget.Standard:
      case HelpWidget.StandardAndMobile:
        widgetId = FRESHDESK_WIDGET_ENGLISH_ID
        break
      case HelpWidget.StandardAndWelsh:
        widgetId = FRESHDESK_WIDGET_WELSH_ID
        break
    }
    return (
      <>
        <Script type="text/javascript">
          {`
          window.fwSettings = {
            widget_id: ${widgetId},
          };
          !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()`}
        </Script>
        <Script type="text/javascript" src={`https://euc-widget.freshworks.com/widgets/${widgetId}.js`} async defer />
      </>
    )
  }

  return InjectFreshdeskScript()
}

export default FreshdeskWidget
