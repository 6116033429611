import SanityClient from '@sanity/client'

export const options = {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID || '',
  dataset: process.env.GATSBY_SANITY_DATASET || '',
  apiVersion: '2021-03-25', // use a UTC date string
  useCdn: false,
}

const sanity = new SanityClient(options)

export default sanity
