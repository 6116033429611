import * as React from 'react'
import { StyledCheckbox } from './Checkbox.styled'
import { Error } from '../FormElements/FormElements.styled'

interface CheckboxProps {
  id?: string
  name?: string
  text: string | React.ReactElement
  smallFont?: boolean
  required?: boolean
  value?: boolean
  isStyled?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  error?: string
  className?: string
  customFill?: string
  onBlur?: (e: any) => void
  testID?: string
}

const Checkbox: React.FC<CheckboxProps> = ({
  id,
  name,
  text,
  smallFont,
  required,
  value,
  onChange,
  isStyled = true,
  customFill,
  error,
  className,
  onBlur,
  testID,
}) => {
  const Container = isStyled
    ? (props) => <StyledCheckbox customFill={customFill} smallFont={smallFont} isError={!!error} {...props} />
    : (props) => <>{props.children}</>

  return (
    <Container>
      <input
        className={className}
        type="checkbox"
        aria-label={id}
        id={id}
        name={name}
        required={required}
        checked={value}
        onChange={onChange}
        onBlur={onBlur}
        tabIndex={0}
        data-testid={testID}
      />
      <label className={className} htmlFor={id}>
        {text}
      </label>
      {error && (
        <Error className="checkboxError" data-testid={testID ? `${testID}-error` : null}>
          {error}
        </Error>
      )}
    </Container>
  )
}

export default Checkbox
