import React, { FC } from 'react'
import CallToActionPanel, { ContextProps } from '../CallToAction/CallToActionPanel'
import { Links } from '../ContextLink'

interface CTAButtonCardProps {
  title: string
  link: {
    internalLink?: Links
    externalUrl?: string
  }
  detailText: string
  context: ContextProps
  icon: string
}

const CTAButtonCard: FC<CTAButtonCardProps> = ({ title, link, context, detailText, icon }) => {
  return (
    <CallToActionPanel
      isButton
      linkTo={link.externalUrl || link.internalLink}
      {...{ title, text: detailText, link, context, icon }}
    />
  )
}

export default CTAButtonCard
