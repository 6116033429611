import React, { FC } from 'react'
import { graphql } from 'gatsby'
import Wrapper from '../../components/Wrapper/Wrapper'
import { Heading } from '../../components/Heading/Heading.styled'
import SEO from '../../components/seo'
import RichText from '../../components/RichText'
import { IPageProps } from '../../contexts/PageContext'
import useGame from '../../contexts/GameContext/useGame'

const HowToPlayPage: FC<IPageProps<Queries.HowToPlayPageQuery>> = ({ data }) => {
  const { game } = useGame()
  const { title, _rawBody: body } = data?.sanityGame?.howToPlay || {}

  return (
    <>
      <SEO title={title || 'How to Play'} />
      <Wrapper width="850px" textAlign="left">
        <Heading contentPage>{title}</Heading>
        <RichText blocks={body} context={{ game }} />
      </Wrapper>
    </>
  )
}

export default HowToPlayPage

export const query = graphql`
  query HowToPlayPage($gameSlug: String, $language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
    sanityGame(slug: { eq: $gameSlug }, i18n_lang: { eq: $language }) {
      howToPlay {
        id
        title
        _rawBody(resolveReferences: { maxDepth: 15 })
      }
    }
  }
`
