import React, { FC } from 'react'
import { graphql } from 'gatsby'

import SEO from '../../../components/seo'
import Wrapper from '../../../components/Wrapper/Wrapper'
import { Heading } from '../../../components/Heading/Heading.styled'
import PartnerLogos from '../../../components/PartnerLogos/PartnerLogos'
import RichText from '../../../components/RichText'
import useGame from '../../../contexts/GameContext/useGame'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { IPageProps } from '../../../contexts/PageContext'

const PartnersPage: FC<IPageProps<Queries.PartnersPageQuery>> = ({ data }) => {
  const { game } = useGame()
  const { t } = useTranslation()
  const { title = t('game.partnersPage.seoTitle'), _rawIntroduction: introduction } =
    data.sanityGame?.partnersPage || {}
  const partnerLogos =
    game?.content?.partners?.map((partner) => ({
      id: partner?.id,
      img: partner?.logo,
      alt: partner?.name,
      href: partner?.websiteUrl,
    })) || []

  return (
    <>
      <SEO title={`${title} | ${game?.info.name}`} />
      <Wrapper width="800px" veryTall>
        <Heading medium>{title}</Heading>
        <RichText blocks={introduction} context={{ game }} />
        {game?.content.partners && game?.content.partners?.length > 0 && (
          <PartnerLogos isFooter={false} logos={partnerLogos} />
        )}
      </Wrapper>
    </>
  )
}
export default PartnersPage
export const query = graphql`
  query PartnersPage($gameSlug: String, $language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
    sanityGame(slug: { eq: $gameSlug }, i18n_lang: { eq: $language }) {
      ...gameDetails
    }
  }
`
