import React, { FC } from 'react'
import { graphql } from 'gatsby'
import Wrapper from '../../../../components/Wrapper/Wrapper'
import Button from '../../../../components/Button/Button'
import SEO from '../../../../components/seo'
import { Error, FormItem } from '../../../../components/FormElements/FormElements.styled'
import { HeadingWrapper } from '../../../../components/HeadingWrapper/HeadingWrapper.styled'
import { Heading } from '../../../../components/Heading/Heading.styled'
import { SubHeading } from '../../../../components/SubHeading/SubHeading.styled'
import { ErrorMessage, Formik } from 'formik'
import SelectDropdown from '../../../../components/SelectDropdown/SelectDropdown'
import GameConsentLines from '../../../../components/GameConsentLines/GameConsentLines'
import useJoinGame from '../../../../hooks/useJoinGame/useJoinGame'
import useNavigate from '../../../../hooks/useNavigate'
import RichText from '../../../../components/RichText'
import { isBefore, subDays } from 'date-fns'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { GameStatus } from '../../../../generated/api'
import usePageContext from '../../../../hooks/usePageContext'
import { IPageProps } from '../../../../contexts/PageContext'
import { getGameDetailsSchema } from './validation'
import JoinGameMessage from '../../../../components/JoinGameMessage'

const GameDetails: FC<IPageProps<Queries.GameDetailsQuery>> = ({ data }, context) => {
  const join = useJoinGame()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const gameDetailsSchema = getGameDetailsSchema(t)
  const { allGames } = usePageContext()

  const games = allGames
    ? allGames
        .filter((g) => [GameStatus.Anticipation, GameStatus.Live].includes(g.info.status))
        .filter((game) => {
          const isGameLocked =
            game.info.restrictAtDays && game.info.restrictAtDays > 0
              ? isBefore(subDays(new Date(game.info.endsAt), game.info.restrictAtDays), new Date())
              : false

          return !isGameLocked
        })
        .map((game) => ({
          id: game.id,
          name: game.info.name,
        }))
    : []

  const gameConsentText = (gameName: string): string | null => {
    const gameProperties = data?.allSanityGame?.edges?.find(({ node }) => node.name === gameName)?.node
    if (gameProperties?.gamePartnerConsentText) {
      return gameProperties?.gamePartnerConsentText as unknown as string
    } else {
      return null
    }
  }

  const getPlainText = (richText) => {
    const children = richText?.[0].children
    const text = children?.map((content) => content?.text)

    return text ? text.join('') : ''
  }

  return (
    <>
      <SEO title={t('joinGame.title')} />
      <HeadingWrapper>
        <Heading data-testid="page-title">{t('joinGame.title')}</Heading>
        <SubHeading as="h2" secondaryForm veryTall data-testid="page-subtitle">
          {t('joinGame.subTitle')}
        </SubHeading>
      </HeadingWrapper>
      <JoinGameMessage />
      <Wrapper width="400px" textAlign="left">
        <Formik
          enableReinitialize
          initialValues={{
            gameId: '',
            gameName: '',
            consent: false,
            submit: null,
          }}
          validationSchema={gameDetailsSchema}
          onSubmit={async (values, { setErrors }) => {
            try {
              if (!values.gameId) return navigate(`/signup/profile-details`, { replace: true })
              await join({
                gameId: values.gameId,
                consentText: values?.consent ? getPlainText(gameConsentText(values.gameName)) : '',
              })
              await navigate(`/signup/add-card`, { replace: true })
            } catch (e) {
              if (e?.errors?.[0].message.includes('Can not join the game. Leave current game before.')) {
                setErrors({ submit: t('errors.api.joinGame.haveGame') || undefined })
              } else {
                setErrors({ submit: t('errors.joinGame') || undefined })
              }
            }
          }}
        >
          {({ values, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => {
            return (
              <form onSubmit={handleSubmit} noValidate>
                <fieldset>
                  <legend>{t('joinGame.select.title')}</legend>
                  <FormItem>
                    <SelectDropdown
                      id={'gameId'}
                      ariaLabel="select-game"
                      options={games.map((game) => ({
                        value: game.id,
                        display:
                          game.id === 'GME#3e952ad5-943d-4c94-8b91-b8629b9350cd'
                            ? game.name + ' (North Ayrshire, Argyll and Bute)'
                            : game.name,
                      }))}
                      placeholder={t('joinGame.select.placeholder') || ''}
                      value={values.gameId}
                      onBlur={handleBlur}
                      onChange={(event) => {
                        const value = event.target.value === t('joinGame.select.placeholder') ? '' : event.target.value
                        setFieldValue('gameId', value)
                        setFieldValue('gameName', games.find((game) => game.id === value)?.name)
                        if (!value) {
                          setFieldValue('tagType', undefined)
                        }
                      }}
                      secondary={true}
                      testID="game-select"
                    />
                  </FormItem>
                  {getPlainText(gameConsentText(values.gameName)) && (
                    <GameConsentLines
                      name="consent"
                      text={<RichText blocks={gameConsentText(values.gameName)} context={context} />}
                      onChange={(e) => {
                        const value = e.target.checked
                        setFieldValue('consent', value)
                      }}
                      value={!!values.consent}
                    />
                  )}
                </fieldset>
                <ErrorMessage name="gameId" component={(props) => <Error className="checkboxError" {...props} />} />
                <ErrorMessage name="consent" component={(props) => <Error className="checkboxError" {...props} />} />
                <ErrorMessage name="submit" component={(props) => <Error className="checkboxError" {...props} />} />
                <Button
                  type="submit"
                  isSubmitting={isSubmitting}
                  disabled={isSubmitting || !values.gameId}
                  text={t('joinGame.buttonText')}
                  secondary
                  testID="join-game-button"
                />
              </form>
            )
          }}
        </Formik>
      </Wrapper>
    </>
  )
}

export const query = graphql`
  query GameDetails($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
    allSanityGame(
      filter: { status: { in: ["live", "anticipation"] }, i18n_lang: { eq: $language } }
      sort: { name: ASC }
    ) {
      edges {
        node {
          name
          slug
          gamePartnerConsentText: _rawGamePartnerConsentText
          ...gameDetails
        }
      }
    }
  }
`

export default GameDetails
