import React, { FC, useState } from 'react'
import { graphql } from 'gatsby'
import Modal from '../../../components/Modal/Modal'
import { modalBig } from '../../../components/Modal/Modal.styled'
import SEO from '../../../components/seo'
import Button from '../../../components/Button/Button'
import Spacer from '../../../components/Spacer'
import Accordion from '../../../components/Accordion/Accordion'
import Wrapper from '../../../components/Wrapper/Wrapper'
import { Heading } from '../../../components/Heading/Heading.styled'
import TeamLeaderboard from '../../../containers/Leaderboards/Team/TeamLeaderboard'
import { HowItWorks, ScrollableArea } from './components/leaderboards.styled'
import { FinishedLeaderboard } from './components/finishedLeaderboards'
import useAuth from '../../../contexts/AuthContext/useAuth'
import { StyledTab, StyledTabList, StyledTabPanel, StyledTabs } from '../../../components/Tabs/Tabs.styled'
import YouLeaderboard from '../../../containers/Leaderboards/You/YouLeaderboard'
import RosetteIcon from '../../../assets/icons/RosetteIcon'
import FollowLeaderboard from '../../../containers/Leaderboards/FollowLeaderboard'
import FamilyMemberSelect from '../../../components/FamilymemberSelect/index'
import UpdateMessage from '../../../components/UpdateMessage'
import { RichTextProps } from '../../../components/RichText'
import useGame from '../../../contexts/GameContext/useGame'
import { useLocation } from '@gatsbyjs/reach-router'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { IPageProps } from '../../../contexts/PageContext'
import { CountdownView } from './components/countdownView'

const Leaderboard: FC<IPageProps<Queries.LeaderboardQuery>> = ({ data }) => {
  const { user, family } = useAuth()
  const { game } = useGame()
  const { t } = useTranslation()

  const globalSettings = data.sanityGlobalSettings as
    | { endOfGameLeaderboardsHiddenText?: RichTextProps | undefined }
    | undefined
  const sanityGame = data.sanityGame
  const [introductionModalOpen, setIntroductionModalOpen] = useState(false)

  const { search } = useLocation()
  let winners = ''

  if (typeof window !== 'undefined') {
    winners = new URLSearchParams(search).get('sw') || ''
  }

  const leaderboardSettings = sanityGame?.leaderboardSettings
  const howItWorksContent = leaderboardSettings?.howItWorksContent

  const finished = new Date(game?.info.endsAt || '') < new Date() || winners === 'den-uni-show'
  const beforeStart = new Date(game?.info.startsAt || '') > new Date()

  const hasFamily = family?.length > 1

  const showWinners = data.sanityGame?.endOfGameShowWinners || winners === 'den-uni-show'

  return (
    <>
      <SEO title={t('game.leaderboards.seoTitle', { gameName: game?.info.name })} />
      <Wrapper noMobileMargins width="610px">
        {beforeStart && <CountdownView />}
        {finished && (
          <>
            <Heading marginBottom="20px">{t('game.leaderboards.titleFinal')}</Heading>
            <FinishedLeaderboard globalSettings={globalSettings} showWinners={showWinners} />
          </>
        )}
        {!beforeStart && !finished && (
          <>
            {hasFamily && (
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <FamilyMemberSelect />
              </div>
            )}
            <Heading marginBottom="20px">{t('game.leaderboards.title')}</Heading>
            {Array.isArray(howItWorksContent) && howItWorksContent.length > 0 && (
              <>
                <Button
                  iconComponent={<RosetteIcon width={20} height={31} />}
                  width="295px"
                  onClick={() => setIntroductionModalOpen(true)}
                  text={t('game.leaderboards.howItWorksButton')}
                  tertiary
                  border="1px solid #E4E4E4"
                  showArrow
                />
                <Modal
                  modalOpen={introductionModalOpen}
                  modalType={modalBig}
                  closeModal={() => setIntroductionModalOpen(false)}
                  ariaLabel="How it works + prizes"
                  customStyle={{
                    height: 'auto',
                    maxHeight: '90%',
                  }}
                >
                  <HowItWorks>
                    <Heading as="h2">
                      <RosetteIcon width={37} height={58} /> {t('game.leaderboards.howItWorksModalTitle')}
                    </Heading>

                    <ScrollableArea>
                      <Accordion context={{ game }} items={howItWorksContent} useText expandFirst />
                    </ScrollableArea>
                  </HowItWorks>
                </Modal>
              </>
            )}
            <Spacer space="29px" />
            {game?.content?.showMessage ? <UpdateMessage /> : null}
            <Spacer space="29px" />
            {!game?.content?.hideLeaderboards ? (
              user?.info.gameId === game?.id ? (
                <StyledTabs>
                  <StyledTabList>
                    <StyledTab>{t('game.leaderboards.team')}</StyledTab>
                    <StyledTab>{t('game.leaderboards.you')}</StyledTab>
                    <StyledTab>{t('game.leaderboards.follow')}</StyledTab>
                  </StyledTabList>
                  <StyledTabPanel>
                    <TeamLeaderboard />
                  </StyledTabPanel>

                  <StyledTabPanel>
                    <YouLeaderboard />
                  </StyledTabPanel>

                  <StyledTabPanel>
                    <FollowLeaderboard />
                  </StyledTabPanel>
                </StyledTabs>
              ) : (
                <TeamLeaderboard />
              )
            ) : null}
          </>
        )}
      </Wrapper>
    </>
  )
}

export default Leaderboard

export const query = graphql`
  query Leaderboard($gameSlug: String, $language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
    sanityGame(slug: { eq: $gameSlug }, i18n_lang: { eq: $language }) {
      ...gameDetails
    }

    sanityGlobalSettings(_id: { regex: "/(drafts.|)globalSettings/" }, i18n_lang: { eq: $language }) {
      endOfGameLeaderboardsHiddenText: _rawEndOfGameLeaderboardsHiddenText
    }
  }
`
