import { object, string, bool } from 'yup'

export const getSignupSchema = (t2) => {
  const t = (...args: any) => t2(...args) || undefined
  return object({
    email: string()
      .email(t('validation.email.notValid'))
      .max(50, t('errors.formik.email.max'))
      .required(t('validation.email.req')),
    password: string()
      // @ts-ignore
      .password()
      .matches(/^\S*$/, t('validation.password.noWhitespace'))
      .minNumbers(1, t('errors.formik.password.number'))
      .minUppercase(1, t('errors.formik.password.uppercase'))
      .minLowercase(1, t('errors.formik.password.lowercase'))
      .minSymbols(0)
      .min(8, t('validation.password.minChar'))
      .max(50, t('errors.formik.password.max'))
      .required(t('validation.password.req')),
    confirmPassword: string().min(7).max(50).required(t('validation.password.req')),
    marketingOptIn: bool(),
    termsConditions: bool().oneOf([true], t('validation.req')).required(t('validation.req')),
  })
}
