import React, { FC } from 'react'
import { useSpring, animated } from 'react-spring'
import numeral from 'numeral'

interface AnimateNumberProps {
  number: number
  format: string
  countMax?: number
}

const AnimateNumber: FC<AnimateNumberProps> = ({ number, format = '0', countMax = 30 }) => {
  const startNumber = number > countMax ? number - countMax : 0

  const springProps = useSpring({
    val: number,
    from: { val: startNumber },
  })

  return (
    <strong>
      <animated.span>{springProps.val.to((val: number) => numeral(val).format(format))}</animated.span>
    </strong>
  )
}

export default AnimateNumber
