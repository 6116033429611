import React, { FC } from 'react'
import styled from 'styled-components'
import { prop } from 'styled-tools'

const SpacerWrapper = styled.div`
  margin-bottom: ${prop('space', '20px')};
`

interface SpacerProps {
  space?: string
}

const Spacer: FC<SpacerProps> = ({ space }) => {
  return <SpacerWrapper space={space} />
}

export default Spacer
