import React from 'react'
import styled from 'styled-components'
import Cross from '../../../assets/images/svg/cross.svg'

const ButtonWrapper = styled.button`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  width: 30px;
  height: 30px;
  padding: 7px;
  margin-left: 15px;
  background-color: transparent;
  &:hover {
    background-color: #fff;
  }
  &:active,
  &:focus {
    background-color: transparent;
  }
`

// const Dots = styled.div`
//   font-family: ${(props) => props.theme.fonts.primaryFont};
//   background-color: ${(props) => props.theme.colors.primary};
//   width: 5px;
//   height: 5px;
//   border-radius: 50%;
// `

const OptionsButton = (props: React.HTMLProps<HTMLButtonElement>) => {
  return (
    <ButtonWrapper {...props}>
      <Cross />
    </ButtonWrapper>
  )
}

export default OptionsButton
