import React from 'react'
import { CardWrapper, StyledCardBody } from './new-events.styled'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { StyledCardDate, StyledCardTitle } from '../../../../components/Card/Card.styled'
import { BodyEllipsis, TitleEllipsis } from '../../../../utils/ellipsis'
import Button from '../../../../components/Button/Button'
import RichText from '../../../../components/RichText'
import format from 'date-fns/format'
import { isToday, isTomorrow } from 'date-fns'
import useNavigate from '../../../../hooks/useNavigate'
import { Post } from '../../../../components/CardGrid/CardGrid'
import { zonedTimeToUtc } from 'date-fns-tz'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { ContextProps } from '../../../../components/CallToAction/CallToActionPanel'
import useGame from '../../../../contexts/GameContext/useGame'
import { GameStatus } from '../../../../generated/api'

interface Props {
  post: Post
  context: unknown
}

const Card = ({ post, context }: Props) => {
  const { game } = useGame()
  const { image, alt, title, description, startDate, endDate, startTime, endTime, eventSlug } = post
  const navigate = useNavigate()
  const { t } = useTranslation()

  const isImage = image?.asset?.gatsbyImageData && getImage(image.asset?.gatsbyImageData)

  return (
    <CardWrapper>
      <div style={{ height: 500, width: '100%' }}>
        {image?.asset?.gatsbyImageData && isImage && (
          <GatsbyImage
            style={{ width: '100%', height: 190, overflow: 'hidden' }}
            image={getImage(image.asset?.gatsbyImageData) as IGatsbyImageData}
            alt={alt || title || ''}
            data-testid="event-image"
          />
        )}
        <div
          style={{
            padding: 20,
            height: 310,
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', height: '20%' }}>
            <div data-testid="event-title">{title && <StyledCardTitle>{TitleEllipsis(title)}</StyledCardTitle>}</div>
            <div data-testid="event-time">
              <StyledCardDate datetime={startDate && new Date(startDate)}>
                {getDateDisplay({ startDate, startTime, endDate, endTime })}
              </StyledCardDate>
            </div>
          </div>
          <div
            style={{ display: 'flex', height: '55%', marginTop: 5, marginBottom: 5, textOverflow: 'ellipsis' }}
            data-testid="event-description"
          >
            {description && (
              <StyledCardBody>
                {BodyEllipsis(<RichText blocks={description} context={context as ContextProps} />)}
              </StyledCardBody>
            )}
          </div>
          {eventSlug && game && game.info.status !== GameStatus.Finished && (
            <div style={{ display: 'flex', height: '20%', justifyContent: 'center' }}>
              <Button
                // @ts-ignore
                onClick={() => navigate(`/events/${eventSlug}`)}
                style={{ width: '80%' }}
                text={t('game.events.cardButtonText')}
                tertiary
              />
            </div>
          )}
        </div>
      </div>
    </CardWrapper>
  )
}

export default Card

const getStartDateDisplay = (startDate, startTime): string => {
  if (!startDate) return ''

  const today: boolean = isToday(new Date(startDate))
  const tomorrow: boolean = isTomorrow(new Date(startDate))

  let dateString = `${startDate}`
  let formatString = ''
  let prefix = ''

  if (today) {
    prefix = 'Today '
  } else if (tomorrow) {
    prefix = 'Tomorrow'
  }

  if (!today && !tomorrow) {
    formatString = 'EEE d MMM'
  }

  if (startTime) {
    dateString += `T${startTime}`
    formatString += " h:mmaaaaa'm'"
  }

  const newDate = zonedTimeToUtc(dateString, 'Europe/London').toISOString()

  return `${prefix}${formatString && format(new Date(newDate), formatString)}`
}

const getEndDateDisplay = (endDate, endTime, startDate): string => {
  if (!endDate) return ''
  let dateString = ''
  let formatString = ''
  if (endDate && endDate !== startDate) {
    formatString = 'EEE d MMM'
    dateString = `${endDate}`
  } else {
    dateString = `${startDate}`
  }

  if (endTime) {
    if (endDate && endDate !== startDate) {
      formatString += " h:mmaaaaa'm'"
    } else {
      formatString = "h:mmaaaaa'm'"
    }

    dateString += `T${endTime}`
  }

  if (!dateString || !formatString) {
    return ''
  }

  const newDate = zonedTimeToUtc(dateString, 'Europe/London').toISOString()

  return format(new Date(newDate), formatString)
}

export const getDateDisplay = ({ startDate, startTime, endDate, endTime }): string => {
  let displayString = getStartDateDisplay(startDate, startTime)
  const endDateDisplay = getEndDateDisplay(endDate, endTime, startDate)
  if (endDateDisplay) {
    displayString += ` - ${endDateDisplay}`
  }
  return displayString
}
