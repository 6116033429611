import styled from 'styled-components'
import { withProp } from 'styled-tools'

export const StyledCheckbox = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  margin: 0 1.5rem 0 0;
  min-height: ${(props) => props.theme.radioCheckboxWidth};

  .checkboxError {
    flex-basis: 100%;
  }
  input[type='checkbox'] {
    z-index: 1;
    cursor: pointer;
    &:checked {
      + label::before {
        background: ${withProp(['customFill', 'theme'], (customFill, theme) => customFill || theme.colors.primary)};
      }
    }

    ${(props) =>
      props.smallFont &&
      `
        + label {
            font-size: 1.3rem;
            &::before {
                margin-top: 0;
            }
            &::after {
                margin-top: 0;
            }
        }`}
  }

  ${(props) =>
    props.isError &&
    `
        input[type="checkbox"] + label::before, input[type="radio"] + label::before {
            border-color: ${props.theme.colors.error};

        }
    `}
`
