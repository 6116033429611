import { useEffect, useRef, useState } from 'react'

export const useAutoComplete = (initialIsVisible) => {
  const [isAutoCompleteComponentVisible, setIsAutoCompleteComponentVisible] = useState(initialIsVisible)
  const ref = useRef<HTMLInputElement>(null)

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsAutoCompleteComponentVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  return {
    ref,
    isAutoCompleteComponentVisible,
    setIsAutoCompleteComponentVisible,
  }
}
