import styled from 'styled-components'
import device from '../../styles/device'

export const HeadingWrapper = styled.div`
  margin: 0 auto 3rem;
  height: 100%;
  max-width: 600px;
  text-align: center;
  h1 {
    margin: 2rem;
  }
  h2 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  p {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  @media ${device.tabletLandscape} {
    h1 {
      margin: 3rem 2rem;
    }
  }
  ${(props) =>
    props.tall &&
    `
      margin-top: 4rem;
      margin-bottom: 4rem;
      
      @media ${device.mobLandscape} {
      margin-top: 6rem;
      margin-bottom: 6rem;
      }
      
      @media ${device.tabletLandscape} {
         margin-top: 8rem;
         margin-bottom: 8rem;
  }`}

  ${(props) =>
    props.veryTall &&
    `
        margin-bottom: 4rem;

        @media ${device.mobLandscape} {
            margin-bottom: 6rem;
        }

        @media ${device.tabletLandscape} {
            margin-bottom: 8rem;
        }
    `}
`
