import React, { FC, PropsWithChildren } from 'react'
import HeaderLanding from '../../components/HeaderLanding/HeaderLanding'
import FooterWithBackground from '../../components/Footers/FooterWithBackground/FooterWithBackground'
import FooterSimple from '../../components/Footers/FooterSimple/FooterSimple'
import CookieConsent from '../../components/CookieConsent/CookieConsent'
import { BackgroundContainer } from '../../components/BackgroundContainer/BackgroundContainer.styled'
import GlobalStyle from '../../styles/global'
import SEO from '../../components/seo'
import Header from '../../components/Header/Header'
import Message from '../../components/Message'
import { MainContainer, OuterContainer } from './Layout.styled'
import usePageContext from '../../hooks/usePageContext'
import useRedirect from './useRedirect'
import { useLocation } from '@gatsbyjs/reach-router'
import { graphql } from 'gatsby'
import FreshdeskWidget from '../../components/FreshdeskWidget'
import { HelpWidget } from '../../generated/api'
import useGame from '../GameContext/useGame'

const LayoutContent: FC<PropsWithChildren> = ({ children }) => {
  useRedirect()
  const { pathname } = useLocation()
  const { headerBigLogoShown, positiveMargin, footerShown, footerWithBackground, isLanding, hideConsent } =
    usePageContext()
  const { game } = useGame()

  const gameSlug = game?.info.slug

  if (typeof window !== 'undefined') {
    const feedbackDiv = document?.querySelector('div[data-appzi-dom^="1"]')
    const iframeBody = feedbackDiv?.querySelector('iframe')?.contentWindow?.document?.querySelector('body')
    const divWithoutRole = iframeBody?.querySelectorAll('div')[0]

    divWithoutRole?.setAttribute('role', 'main')
    divWithoutRole?.setAttribute('aria-hidden', 'true')
  }

  const inSignup = !!pathname.match('/signup/+.')

  return (
    <>
      <SEO title="Beat the street" />
      <OuterContainer id="layout-outer-container">
        <Header />
        {headerBigLogoShown && <HeaderLanding />}
        <MainContainer id="layout-main-container" positiveMargin={positiveMargin}>
          <GlobalStyle />
          {!inSignup && false && <Message />}
          {children}
        </MainContainer>
        {footerShown &&
          (footerWithBackground ? (
            <FooterWithBackground isGamePage={!!gameSlug} />
          ) : (
            <FooterSimple isGamePage={!!gameSlug} />
          ))}
        {!hideConsent && <CookieConsent />}
        <FreshdeskWidget helpWidget={game?.info.helpWidget || HelpWidget.Standard} />
        {isLanding && <BackgroundContainer isLanding={isLanding} />}
      </OuterContainer>
    </>
  )
}

export const query = graphql`
  query ($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
  }
`

export default LayoutContent
