import React from 'react'
import Wrapper from '../Wrapper/Wrapper'
import { CTAButton } from '../PrimaryActionButton/PrimaryActionButton.styled'
import useAuth from '../../contexts/AuthContext/useAuth'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import useGameRestricted from '../../hooks/useGameRestricted'

const CompleteGameSetup = () => {
  const { isAuthenticated, family, masterUser, user } = useAuth()
  const { isGameRestricted } = useGameRestricted()
  const { t } = useTranslation()

  const linkedAccount = user?.id !== masterUser?.id && user?.role.isChild === false

  if (!isAuthenticated || linkedAccount) return null
  if (isGameRestricted) return null

  const hasFamily = family?.length > 1
  const isParent = masterUser?.role.parentId === masterUser?.id

  if (!!masterUser?.tags.length && (!isParent || !hasFamily)) return null

  const kids = family?.filter((member) => member.role.isChild)

  const isEveryFamilyMemberFullySetup = kids?.every((member) => member?.tags?.length)

  if (isEveryFamilyMemberFullySetup && masterUser?.tags?.length) return null

  return (
    <Wrapper>
      <CTAButton to={'/game-setup'}>{t('game.dashboard.completeSetup') || ''}</CTAButton>
    </Wrapper>
  )
}

export default CompleteGameSetup
