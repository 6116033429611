import styled from 'styled-components'
import { withProp } from 'styled-tools'
import device from '../../styles/device'
import Button from '../Button/Button'

export const StyledCard = styled.div`
  text-align: center;
  > div {
    max-width: 342px;
    padding: 0;
    border-radius: 6px;
    overflow: hidden;
    background: ${(props) => props.theme.colors.white};
    text-align: left;
    margin: 0 auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;

    &:hover {
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
      margin: -3px auto 3px;
    }
  }
  margin-bottom: 20px;
  padding-bottom: 10px;
  text-align: left;
  box-sizing: border-box;
  padding: 0;

  width: 100%;
  @media ${device.tabletPortrait} {
    width: ${withProp('gridSize', (gridSize) => `${gridSize !== 1 ? '40%' : '100%'}`)};
    ${withProp('gridSize', (gridSize) => {
      return (
        gridSize !== 1 &&
        `
                &:nth-child(2n + 1) {
                    padding-right: 10px;
                    padding-left: 0px;
                }
                &:nth-child(2n + 2) {
                    padding-left: 10px;
                }
            `
      )
    })};
  }

  @media ${device.tabletLandscape} {
    width: ${withProp('gridSize', (gridSize) => `${gridSize !== 1 ? `${Math.round(100 / gridSize)}%` : '100%'}`)};
    ${withProp('gridSize', (gridSize) => {
      if (gridSize === 3) {
        return `
                    &:nth-child(3n + 1) {
                        padding-right: 10px;
                        padding-left: 0px;
                    }
                    &:nth-child(3n+ 2) {
                        padding: 0 10px;
                    }
                    &:nth-child(3n + 3) {
                        padding-left: 10px;
                    }
            `
      }
      if (gridSize === 2) {
        return `
                    &:nth-child(2n + 1) {
                        padding-right: 10px;
                    }
                    &:nth-child(2n + 2) {
                        padding-left: 10px;
                    }
            `
      }
      return ''
    })};
  }
  .gatsby-image-wrapper {
    height: 179px;
    overflow: hidden;
  }
`

export const StyledCardContainer = styled.div`
  padding: 2.2rem;
`
export const StyledCardTitle = styled.div`
  font-family: ${(props) => props.theme.fonts.secondaryFont};
  color: ${(props) => props.theme.colors.body};
  font-size: 1.9rem;
  margin-bottom: 0.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  white-space: nowrap;
`
export const StyledCardDate = styled.time`
  color: ${(props) => props.theme.colors.orange};
  font-size: ${(props) => props.theme.fonts.xs};
`
export const StyledCardBody = styled.div`
  width: 100%;
  color: ${(props) => props.theme.colors.darkGrey};
  font-size: ${(props) => props.theme.fonts.xs};
  padding-bottom: 1.3rem;
  overflow: hidden;
`

export const StyledButton = styled(Button)`
  width: 100%;
  border-radius: 5px;
  font-weight: 700;
  border: 1px solid ${(props) => props.theme.colors.brightBlue};
  color: ${(props) => props.theme.colors.secondary};
  font-family: ${(props) => props.theme.fonts.primaryFont};
  font-size: ${(props) => props.theme.fonts.ms};
  ${(props) => props.theme.hover} {
    border: 1px solid ${(props) => props.theme.colors.brightBlue};
  }
`

export const SizeText = styled('p')`
  color: ${(props) => props.theme.colors.midGrey};
  font-family: ${(props) => props.theme.fonts.primaryFont};
  font-size: ${(props) => props.theme.fonts.xxs};
  text-align: center;
`
