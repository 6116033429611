import { GameStatus, useGetActiveGameQuery } from '../generated/api'
import { isAfter, subDays } from 'date-fns'
import useAuth from '../contexts/AuthContext/useAuth'

const useGameRestricted = () => {
  const { masterUser: player } = useAuth()

  const { data, isLoading } = useGetActiveGameQuery(
    {
      id: player?.info.gameId,
    },
    {
      enabled: Boolean(player?.info.gameId),
    },
  )

  const game = data?.getGame

  const restrictedDay = subDays(new Date(game?.info.endsAt || ''), game?.info.restrictAtDays || 0)
  const isRestricted = isAfter(new Date(), restrictedDay) && game?.info.status === GameStatus.Live

  return {
    isGameRestricted: isRestricted,
    isLoading: player?.info.gameId ? isLoading : false,
    game,
  }
}

export default useGameRestricted
