import { LeaderboardPointType, Maybe, useGetLeaderboardPlayersQuery } from '../../../generated/api'
import useAuth from '../../../contexts/AuthContext/useAuth'

const useYouLeaderboards = (type: LeaderboardPointType, teamId?: Maybe<string>) => {
  const { user } = useAuth()

  const { data: res, ...rest } = useGetLeaderboardPlayersQuery(
    {
      input: {
        gameId: user?.info.gameId as string,
        teamId: teamId,
        playerId: user?.id as string,
        type,
      },
    },
    {
      enabled: !!(user?.info.gameId && user.id),
    },
  )

  return {
    ...rest,
    data: res?.getPlayerLeaderboards.data.filter((e) => e.name) || [],
    rank: res?.getPlayerLeaderboards.rank || '-',
  }
}

export default useYouLeaderboards
