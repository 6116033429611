import React from 'react'
import CallToActionPanel from './CallToActionPanel'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export function BeatBoxMapCTA({ context }) {
  const { t } = useTranslation()
  const { scheme } = context
  const title =
    scheme && scheme.name
      ? t('components.CTA.beatBoxMap.titleWithName', { schemeName: scheme.name })
      : t('components.CTA.beatBoxMap.title')
  return (
    <CallToActionPanel
      context={context}
      title={title}
      style="dark"
      link={{ linkText: t('components.CTA.beatBoxMap.linkText'), internalLink: '/map', internalLinkToggle: true }}
      icon="handbag_woman"
    />
  )
}

export default CallToActionPanel
