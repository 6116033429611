import React, { FC } from 'react'
import Wrapper from '../../../components/Wrapper/Wrapper'
import SEO from '../../../components/seo'
import { HeadingWrapper } from '../../../components/HeadingWrapper/HeadingWrapper.styled'
import { Heading } from '../../../components/Heading/Heading.styled'
import { SubHeading } from '../../../components/SubHeading/SubHeading.styled'
import { CustomLink } from '../../../components/Link/Link.styled'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { IPageProps } from '../../../contexts/PageContext'

const UnderAge: FC<IPageProps<Queries.UnderAgeQuery>> = () => {
  const { t } = useTranslation()
  return (
    <>
      <SEO title={t('signUp.selectAge.seoTitle')} />
      <HeadingWrapper>
        <Heading data-testid="page-title">Sign up</Heading>
        <SubHeading as="h5" secondaryForm veryTall data-testid="page-subtitle">
          {t('signUp.selectAge.subTitle')}
        </SubHeading>
      </HeadingWrapper>
      <Wrapper width="400px" textAlign="center">
        <p data-testid="ask-parent-text">{t('signUp.underAge.text')}</p>
      </Wrapper>
      <Wrapper>
        <CustomLink
          style={{ marginTop: 50, marginBottom: 20, fontSize: '1.8rem' }}
          to="/signup/"
          data-testid="continue-as-parent-button"
        >
          {t('signUp.underAge.linkText')}
        </CustomLink>
      </Wrapper>
    </>
  )
}
export default UnderAge
export const query = graphql`
  query UnderAge($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
  }
`
