import React, { FC } from 'react'
import { graphql } from 'gatsby'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Formik, ErrorMessage } from 'formik'
import * as Yup from 'yup'
// eslint-disable-next-line @typescript-eslint/no-var-requires
require('yup-password')(Yup) // extend yup

import Wrapper from '../../components/Wrapper/Wrapper'
import Input from '../../components/Input/Input'
import Button from '../../components/Button/Button'
import { Error, FormItem } from '../../components/FormElements/FormElements.styled'
import { HeadingWrapper } from '../../components/HeadingWrapper/HeadingWrapper.styled'
import { Heading } from '../../components/Heading/Heading.styled'
import RecaptureTerms from '../../components/RecaptureTerms/RecaptureTerms'
import useAuth from '../../contexts/AuthContext/useAuth'
import RichText from '../../components/RichText'
import useNavigate from '../../hooks/useNavigate'
import { Circles } from 'react-loader-spinner'
import useGame from '../../contexts/GameContext/useGame'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import SEO from '../../components/seo'
import { IPageProps } from '../../contexts/PageContext'
import { useManagePlayers } from '../signup/hooks/useManagePlayers'
import { getNewsletterSignupSchema } from './validation'
import { useNewsletterSignupMutation } from '../../generated/api'

const NewsletterSignup: FC<IPageProps<Queries.NewsletterSignupQuery>> = ({ data }) => {
  const title = data?.sanityHomepage?.newsletter?.title || ''
  const body = data?.sanityHomepage?.newsletter?.body || ''
  const navigate = useNavigate()
  const { game } = useGame()
  const { t } = useTranslation()
  const { onUpdatePlayer } = useManagePlayers()
  const newsletterSignupSchema = getNewsletterSignupSchema(t)

  const { user, isInitialized } = useAuth()

  const { mutateAsync: signupNews } = useNewsletterSignupMutation()

  return (
    <>
      <GoogleReCaptchaProvider language="en-GB" reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_KEY || ''}>
        <>
          <SEO title={t('newsletter.seoTitle')} />
          <HeadingWrapper>
            <Heading secondary small>
              {title}
            </Heading>
            <RichText blocks={body} context={{ game }} />
          </HeadingWrapper>
          <Wrapper width="400px">
            {isInitialized ? (
              <>
                <Formik
                  initialValues={{
                    email: user?.profile.email || '',
                    submit: null,
                  }}
                  validationSchema={newsletterSignupSchema}
                  onSubmit={async (values, { setErrors }) => {
                    try {
                      if (!user && game?.id) {
                        await signupNews({
                          input: {
                            email: values.email,
                            gameId: game?.id,
                          },
                        })
                      } else if (user?.id) {
                        await onUpdatePlayer({
                          id: user?.id,
                          profile: {
                            marketingEmailsText: '',
                            allowMarketingEmails: true,
                          },
                        })
                      }
                      await navigate('/newsletter/success')
                    } catch (error) {
                      console.log('e ==', error.message)
                      if (error?.message.includes('There is an User connected to this email address')) {
                        setErrors({ submit: t('errors.api.newsletter.userExist') || undefined })
                      } else {
                        setErrors({ submit: t('errors.generic') || undefined })
                      }
                    }
                  }}
                >
                  {({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting, touched }) => {
                    return (
                      <form onSubmit={handleSubmit} noValidate>
                        {!user && (
                          <FormItem>
                            <Input
                              type="email"
                              id="email"
                              text={t('common.email') || ''}
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.email ? errors.email : undefined}
                              autoComplete="email"
                              required
                            />
                          </FormItem>
                        )}
                        <ErrorMessage
                          name="submit"
                          component={(props) => <Error className="checkboxError" {...props} />}
                        />
                        <Button
                          id="submit-button"
                          type="submit"
                          isSubmitting={isSubmitting}
                          disabled={isSubmitting}
                          text={t('newsletter.buttonText')}
                          secondary
                        />
                      </form>
                    )
                  }}
                </Formik>
                <RecaptureTerms />
              </>
            ) : (
              <Circles />
            )}
          </Wrapper>
        </>
      </GoogleReCaptchaProvider>
    </>
  )
}

export const query = graphql`
  query NewsletterSignup($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
    sanityRegistrationSettings(_id: { regex: "/(drafts.|)registrationSettings/" }, i18n_lang: { eq: $language }) {
      contactConsentText
    }
    sanityHomepage(_id: { regex: "/(drafts.|)homepage/" }, i18n_lang: { eq: $language }) {
      newsletter {
        id
        title
        body: _rawBody
      }
    }
  }
`

export default NewsletterSignup
