import React, { FC } from 'react'
import RichText, { RichTextProps } from '../../../../components/RichText'
import useGame from '../../../../contexts/GameContext/useGame'
import { StyledTab, StyledTabList, StyledTabPanel, StyledTabs } from '../../../../components/Tabs/Tabs.styled'
import TeamLeaderboard from '../../../../containers/Leaderboards/Team/TeamLeaderboard'
import YouLeaderboard from '../../../../containers/Leaderboards/You/YouLeaderboard'
import useAuth from '../../../../contexts/AuthContext/useAuth'
import FollowLeaderboard from '../../../../containers/Leaderboards/FollowLeaderboard/index'
import { useTranslation } from 'gatsby-plugin-react-i18next'

interface FinishedLeaderboardProps {
  globalSettings?: {
    endOfGameLeaderboardsHiddenText?: RichTextProps
  }
  showWinners?: boolean
}

export const FinishedLeaderboard: FC<FinishedLeaderboardProps> = ({ globalSettings, showWinners }) => {
  const { game } = useGame()
  const { user } = useAuth()
  const { t } = useTranslation()

  if (!showWinners) {
    return (
      <>
        <RichText blocks={globalSettings?.endOfGameLeaderboardsHiddenText} context={{ game }} />
      </>
    )
  }

  return (
    <>
      <div style={{ marginTop: 50 }}>
        {user?.info.gameId === game?.id ? (
          <StyledTabs>
            <StyledTabList>
              <StyledTab>{t('game.leaderboards.team')}</StyledTab>
              <StyledTab>{t('game.leaderboards.you')}</StyledTab>
              <StyledTab>{t('game.leaderboards.follow')}</StyledTab>
            </StyledTabList>
            <StyledTabPanel>
              <TeamLeaderboard />
            </StyledTabPanel>

            <StyledTabPanel>
              <YouLeaderboard />
            </StyledTabPanel>

            <StyledTabPanel>
              <FollowLeaderboard />
            </StyledTabPanel>
          </StyledTabs>
        ) : (
          <TeamLeaderboard />
        )}
      </div>
    </>
  )
}
