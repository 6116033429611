import React, { FC } from 'react'
import { serializers } from './serializers'
import { PortableText, PortableTextProps } from '@portabletext/react'
import { ContextProps } from '../CallToAction/CallToActionPanel'

export interface RichTextProps extends Omit<PortableTextProps, 'value'> {
  context?: ContextProps
  blocks?: any
}

export const RichText: FC<RichTextProps> = ({ context = {}, ...props }) => (
  <PortableText value={props.blocks} {...Object.assign({ components: serializers(context) }, props)} />
)

export default RichText
