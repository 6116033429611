import React, { FC } from 'react'
import useWindowSize from '../../../hooks/useWindowSize'
import PartnerLogos from '../../PartnerLogos/PartnerLogos'
import FooterMobileImage from '../../../assets/images/svg/footer-mobile.svg'
import FooterDesktopImage from '../../../assets/images/svg/footer-desktop.svg'
import {
  Container,
  ImageContainer,
  FooterWrapper,
  FooterText,
  FooterTextContainer,
  FooterLinks,
  FooterLink,
  StyledHelpText,
} from './FooterWithBackground.styled'
import theme from '../../../styles/theme'
import useGame from '../../../contexts/GameContext/useGame'
import usePageContext from '../../../hooks/usePageContext'
import { useLocation } from '@gatsbyjs/reach-router'
import ContextLink from '../../ContextLink'
import { useTranslation } from 'gatsby-plugin-react-i18next'
// import ChangeLanguage from '../../ChangeLanguage'
import { SanityPartner } from 'src/generated/api'

interface Props {
  isGamePage?: boolean
}

const Footer: FC<Props> = () => {
  const { width } = useWindowSize()
  const footerImage = width > 1023 ? <FooterDesktopImage /> : <FooterMobileImage />
  const { game } = useGame()
  const { pathname } = useLocation()
  const { showPartnersLogos, pageList, sanityData } = usePageContext()
  const { t } = useTranslation()
  const partnersLimit = game?.content?.numberOfPartnersInFooter || undefined

  const partners: SanityPartner[] =
    (game && partnersLimit !== undefined && game.content?.partners) ||
    game?.content?.footerPartners ||
    sanityData?.sanityHomepage?.partners ||
    []

  const partnerLogos =
    (showPartnersLogos === true &&
      partners
        ?.slice(0, partnersLimit)
        .filter((e) => e)
        .map((v) => {
          return {
            id: v.id,
            img: v.logo,
            alt: v.name,
            href: v.websiteUrl,
          }
        })) ||
    []

  const handleOpenHelpDesk = () => {
    if (typeof window !== 'undefined') {
      // @ts-ignore
      window.FreshworksWidget('open')
    }
  }

  return (
    <Container home={pathname === '/'} id="footer-container">
      <ImageContainer>{footerImage}</ImageContainer>
      <FooterWrapper id="footer-wrapper">
        <FooterTextContainer>
          <FooterText>
            <p style={{ color: theme.colors.darkGreyAlt }}>
              &copy;
              {` ${t('components.footer.intelligentHealth', {
                date: new Date().getFullYear(),
              })}`}
            </p>
            <FooterLinks>
              {pageList?.some((x) => x.includes('/about-us')) && (
                <FooterLink>
                  <ContextLink to="/about-us" style={{ color: theme.colors.midDarkBlue }}>
                    {t('components.footer.aboutUs')}
                  </ContextLink>
                </FooterLink>
              )}
              {pageList?.some((x) => x.includes('/terms-and-conditions')) && (
                <FooterLink>
                  <ContextLink to="/terms-and-conditions" style={{ color: theme.colors.midDarkBlue }}>
                    {t('components.footer.tAndC')}
                  </ContextLink>
                </FooterLink>
              )}
              {pageList?.some((x) => x.includes('/privacy')) && (
                <FooterLink>
                  <ContextLink to="/privacy" style={{ color: theme.colors.midDarkBlue }}>
                    {t('components.footer.privacy')}
                  </ContextLink>
                </FooterLink>
              )}
              {pageList?.some((x) => x.includes('/accessibility')) && (
                <FooterLink>
                  <ContextLink to="/accessibility" style={{ color: theme.colors.midDarkBlue }}>
                    {t('components.footer.accessibility')}
                  </ContextLink>
                </FooterLink>
              )}
              {pageList?.some((x) => x.includes('/cookies')) && (
                <FooterLink>
                  <ContextLink to="/cookies" style={{ color: theme.colors.midDarkBlue }}>
                    {t('components.footer.cookies')}
                  </ContextLink>
                </FooterLink>
              )}
              <FooterLink>
                <StyledHelpText onClick={handleOpenHelpDesk}>{t('components.footer.help')}</StyledHelpText>
              </FooterLink>

              {/*<ChangeLanguage isGamePage={isGamePage} />*/}
            </FooterLinks>
          </FooterText>
        </FooterTextContainer>
        {partnerLogos.length > 0 && <PartnerLogos logos={partnerLogos} isFooter />}
      </FooterWrapper>
    </Container>
  )
}

export default Footer
