import React, { FC } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import RichText from '../components/RichText'
import { ContextProps } from '../components/CallToAction/CallToActionPanel'

interface PrivacyPolicyProps {
  context?: ContextProps
}

const PrivacyPolicy: FC<PrivacyPolicyProps> = ({ context }) => {
  const {
    sanityHomepage: { privacyPolicy },
  } = useStaticQuery(graphql`
    query ($language: String) {
      sanityHomepage(_id: { regex: "/(drafts.|)homepage/" }, i18n_lang: { eq: $language }) {
        privacyPolicy {
          id
          body: _rawBody
        }
      }
    }
  `)

  return <>{privacyPolicy && <RichText blocks={privacyPolicy.body} context={context} />}</>
}

export default PrivacyPolicy
