import React, { FC } from 'react'
import useAuth from '../../contexts/AuthContext/useAuth'
import useGame from '../../contexts/GameContext/useGame'
import { PlayerStats } from './PlayerStats'
import { DetailedGameStats } from './DetailedGameStats'
import { useSurvey } from '../../hooks/useSurvey/useSurvey'
import { StatsWrapper } from './GameStats.styled'
import useWindowSize from '../../hooks/useWindowSize'
import { useLocation } from '@gatsbyjs/reach-router'
import { GameStatus } from '../../generated/api'

export interface GameStatsProps {
  onWhite?: boolean
  carousel?: boolean
  carouselColumns?: number
  desktopColumns?: number
}

export interface StatsProps {
  carousel?: boolean
  pageWidth?: string | number
  carouselColumns?: number
}

const GameStats: FC<GameStatsProps> = ({
  onWhite = false,
  carousel = false,
  carouselColumns = 2,
  desktopColumns = 3,
}) => {
  const { width } = useWindowSize()
  const { user, isAuthenticated } = useAuth()
  const { game } = useGame()
  const { closingSurveyEnabled, tellUsAboutYouSurveyEnabled } = useSurvey()
  const { pathname } = useLocation()

  const totalDesktopColumns = () => {
    if (isAuthenticated && pathname?.includes('/dashboard')) {
      if (game?.info.status == GameStatus.Sustain || game?.info.status === GameStatus.Finished) {
        return desktopColumns
      } else {
        return closingSurveyEnabled || tellUsAboutYouSurveyEnabled ? desktopColumns + 1 : desktopColumns
      }
    }
    return desktopColumns
  }

  return (
    <StatsWrapper onWhite={onWhite} carouselColumns={carouselColumns} desktopColumns={totalDesktopColumns()}>
      {isAuthenticated && user?.info.gameId === game?.id && pathname?.includes('/dashboard') ? (
        <PlayerStats
          {...{
            carousel,
            carouselColumns,
            pageWidth: width,
          }}
        />
      ) : (
        <DetailedGameStats
          {...{
            carousel,
            carouselColumns,
            pageWidth: width,
          }}
        />
      )}
    </StatsWrapper>
  )
}

export default GameStats
