import React, { FC } from 'react'
import { graphql } from 'gatsby'
import SEO from '../../../components/seo'
import Loading from '../../../components/Loading'
import Spacer from '../../../components/Spacer'
import BackgroundArc from '../../../components/BackgroundArc/BackgroundArc'
import useGame from '../../../contexts/GameContext/useGame'
import useConfirmEmail from '../../../hooks/useConfirmEmail'
import StartedLanding from './Started'
import AnticipationPage from './Anticipation'
import { IPageProps } from '../../../contexts/PageContext'

const LandingPage: FC<IPageProps<Queries.LandingQuery>> = () => {
  const { game } = useGame()
  useConfirmEmail()

  return (
    <>
      <SEO title={`${game?.info.name || ''}`} />
      <BackgroundArc />
      {!game ? (
        <>
          <Loading />
          <Spacer space="60rem" />
        </>
      ) : game.info.status === 'anticipation' ? (
        <AnticipationPage />
      ) : (
        <StartedLanding />
      )}
    </>
  )
}

export const query = graphql`
  query Landing($gameSlug: String, $language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
    sanityGame(slug: { eq: $gameSlug }, i18n_lang: { eq: $language }) {
      ...gameDetails
    }

    sanityGlobalSettings(_id: { regex: "/(drafts.|)globalSettings/" }, i18n_lang: { eq: $language }) {
      endOfGameIntroductionText: _rawEndOfGameIntroductionText
      endOfGameWinnersText: _rawEndOfGameWinnersText
    }
  }
`

export default LandingPage
