export enum UiReducerActionTypes {
  SET_MESSAGE = 'SET_MESSAGE',
}

interface UiState {
  message?: {
    [key: string]: unknown
  }
}

const initial: UiState = {
  message: {},
}

const uiReducer = (state = initial, action) => {
  switch (action.type) {
    case UiReducerActionTypes.SET_MESSAGE:
      return {
        ...state,
        message: { ...action?.message },
      }

    default:
      return state
  }
}

export default uiReducer
