import { Action, InitializeAction, LoginAction, RefetchAction, RegisterAction, SetUserAction, State } from './type'

export const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user, masterUser, family } = action.payload

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user: user,
      masterUser,
      family,
    }
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user, family } = action.payload

    return {
      ...state,
      isAuthenticated: true,
      user: user,
      masterUser: user,
      family,
    }
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: undefined,
    masterUser: undefined,
    family: [],
  }),
  REGISTER: (state: State, action: RegisterAction): State => {
    const { user } = action.payload
    return {
      ...state,
      isAuthenticated: true,
      user: user,
      masterUser: user,
      family: [],
    }
  },
  REFETCH: (state: State, action: RefetchAction): State => {
    const { user, family, masterUser } = action.payload

    return {
      ...state,
      user: user || state.user,
      masterUser: masterUser || state.masterUser,
      family: family || [],
    }
  },
  SET_USER: (state: State, action: SetUserAction): State => {
    const { user } = action.payload
    return {
      ...state,
      user: user || state.user,
    }
  },
  VERIFY_CODE: (state: State): State => ({ ...state }),
  RESEND_CODE: (state: State): State => ({ ...state }),
  PASSWORD_RECOVERY: (state: State): State => ({ ...state }),
  PASSWORD_RESET: (state: State): State => ({ ...state }),
  COMPLETE_NEW_PASSWORD: (state: State): State => ({ ...state }),
}

export const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state
