import React from 'react'
import Wrapper from '../../../../components/Wrapper/Wrapper'
import styled from 'styled-components'
import { Heading } from '../../../../components/Heading/Heading.styled'
import Logo from '../../../../components/Logo/Logo'
import Countdown from '../../../../components/Countdown/Countdown'
import Spacer from '../../../../components/Spacer'
import PlayTheGame from '../../../../components/PlayTheGame/PlayTheGame'
import PrimaryActionButton from '../../../../components/PrimaryActionButton/PrimaryActionButton'
import device from '../../../../styles/device'
import CallToActionPanel from '../../../../components/CallToAction'
import useGame from '../../../../contexts/GameContext/useGame'
import useAuth from '../../../../contexts/AuthContext/useAuth'
import SocialBlock from '../../../../components/SocialBlock'
import BlockWrapper from '../../../../components/BlockWrapper/BlockWrapper'
import ContextLink from '../../../../components/ContextLink'
import { useSurvey } from '../../../../hooks/useSurvey/useSurvey'
import GameStats from '../../../../containers/GameStats'
import { isBefore } from 'date-fns'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { TagTypes } from '../../../../generated/api'
import HeadingWrapper from '../components/HeadingWraper'
import Introduction from '../components/Introduction'

const AnticipationHeading = styled(Heading)`
  margin-top: -20px;
  @media ${device.tabletLandscape} {
    margin-top: -35px;
  }
`

function AnticipationPage() {
  const { game } = useGame()
  const { user, isAuthenticated } = useAuth()
  const { surveyLink, tellUsAboutYouSurveyEnabled } = useSurvey()
  const { t } = useTranslation()

  const isTeamStarted = isBefore(new Date(), new Date(game?.info.teamsStartsAt || ''))

  const hasPhysicalTag = user?.tags?.some((tag) => [TagTypes.Card, TagTypes.Fob].includes(tag.tagType))

  return (
    <Wrapper>
      {!isTeamStarted ? (
        <>
          <HeadingWrapper>
            <Heading>{t('game.landing.title.teamStarted')}</Heading>
          </HeadingWrapper>
          <GameStats carousel carouselColumns={2} {...{ game }} />
          <Spacer space="5rem" />
        </>
      ) : (
        <>
          <AnticipationHeading landing medium marginBottom="0px">
            {t('game.landing.title.anticipation', { gameName: game?.info.name })}
          </AnticipationHeading>
          <Wrapper style={{ minHeight: '220px' }}>
            <Logo animated />
          </Wrapper>
        </>
      )}
      {game && <PrimaryActionButton game={game} />}
      <Countdown to={new Date(game?.info.startsAt || '')} title={t('game.landing.countDownTitle') || ''} />
      <PlayTheGame gameStartDate={game?.info.startsAt || ''} gameEndDate={game?.info.endsAt || ''} />

      {!hasPhysicalTag && isAuthenticated && (
        <>
          <CallToActionPanel
            isButton
            icon="hand"
            title={t('components.CTA.card.title') || ''}
            text={t('components.CTA.card.text') || ''}
            linkTo={'/game-setup'}
          />
          <BlockWrapper>
            <ContextLink to="/faqs#q-where-can-i-pick-up-a-card">{t('game.landing.cardPickUp')}</ContextLink>
          </BlockWrapper>
        </>
      )}

      {/*<CTAs game={game} user={user} />*/}

      {isAuthenticated && tellUsAboutYouSurveyEnabled && (
        <CallToActionPanel
          title={t('components.CTA.telUsAboutYou.title', { userFirstName: user?.profile.firstName }) || ''}
          text={t('components.CTA.telUsAboutYou.text') || ''}
          style="white"
          link={{
            linkText: t('components.CTA.telUsAboutYou.linkText') || '',
            externalUrl: surveyLink,
            externalUrlToggle: true,
          }}
          target="_self"
          icon="young_lady"
        />
      )}

      <Introduction game={game} />
      <SocialBlock game={game} />
      <Spacer />
    </Wrapper>
  )
}

export default AnticipationPage
