import styled from 'styled-components'
import device from '../../styles/device'
import ContextLink from '../ContextLink'

export const MenuContainer = styled.div`
  display: flex;
  max-height: 48px;

  @media ${device.mobLandscape} {
    max-height: 58px;
  }

  @media ${device.tabletLandscape} {
    max-height: 75px;
  }
`

export const MenuButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  font-family: ${(props) => props.theme.fonts.primaryFont};
  color: ${(props) => (props.isNavOpen ? props.theme.colors.white : props.theme.colors.body)};
  padding: 0.2rem;
  margin: 0 1.5rem;
  max-width: 120px;

  @media ${device.tabletLandscape} {
    padding: 0.4rem;
  }

  ${(props) => props.theme.hover} {
    background-color: transparent;
  }

  svg {
    padding: 0.3rem ${(props) => props.marker};
    height: 24px;
    width: 24px;
    margin-bottom: 0.2rem;
    transition: fill ${(props) => props.theme.transitions.transition};

    @media ${device.mobLandscape} {
      height: 30px;
      width: 30px;
      margin-bottom: 0.7rem;
    }
    @media ${device.tabletLandscape} {
      height: 40px;
      width: 40px;
      margin-bottom: 1.2rem;
    }

    path {
      fill: ${(props) => (props.isNavOpen ? props.theme.colors.white : props.theme.colors.primary)};
    }
    circle {
      fill: ${(props) => (props.isNavOpen ? props.theme.colors.white : props.theme.colors.primary)};
      stroke: ${(props) => (props.isNavOpen ? props.theme.colors.body : props.theme.colors.white)};
    }
  }

  p,
  span {
    display: block;
    margin: 0;
    max-width: 60px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 11px;

    @media ${device.tabletLandscape} {
      max-width: 100%;
      font-size: 15px;
    }
  }
`
export const MenuLink = styled(ContextLink)`
  font-size: 11px;
  @media ${device.tabletLandscape} {
    font-size: 15px;
  }
`
