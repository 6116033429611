import { object, string } from 'yup'
export const getResetPasswordSchema = (t2) => {
  const t = (...args: any) => t2(...args) || undefined
  return object({
    email: string().email(t('errors.formik.email.valid')).required(t('forgotPassword.reset.errors.emailRequired')),
    code: string().required(t('forgotPassword.reset.errors.codeRequired')),
    newPassword: string()
      // @ts-ignore
      .password()
      .minNumbers(1, t('errors.formik.password.number'))
      .minUppercase(1, t('errors.formik.password.uppercase'))
      .minLowercase(1, t('errors.formik.password.lowercase'))
      .minSymbols(0)
      .min(7, t('forgotPassword.reset.errors.minCharacters'))
      .max(50, t('errors.formik.password.max'))
      .required(t('forgotPassword.reset.errors.passwordRequired')),
    confirmPassword: string()
      .min(7, t('forgotPassword.reset.errors.minCharacters'))
      .max(50, t('errors.formik.password.max'))
      .required(t('forgotPassword.reset.errors.passwordRequired')),
  })
}
