import { ContentWrapper, SectionWrapper, TextWrapper, WalkImageWrapper, WalkTextWrapper } from '../get-started.styled'
import SVGImage from '../../../components/DynamicImg/SVGImage'
import { Heading } from '../../../components/Heading/Heading.styled'
import Wrapper from '../../../components/Wrapper/Wrapper'
import React from 'react'

const WalkSection = () => {
  return (
    <ContentWrapper>
      <Wrapper>
        <SectionWrapper>
          <WalkImageWrapper style={{ width: '100%' }}>
            <SVGImage
              filename="get-starteg-girl-with-dog"
              alt={'girl with dog'}
              svgProperties={{
                width: '100%',
                height: '100%',
              }}
            />
          </WalkImageWrapper>

          <TextWrapper style={{ width: '100%' }}>
            <WalkTextWrapper>
              <Heading as="h2" style={{ marginLeft: 0 }} textAlign="left">
                Over 1.6 million players across 116 towns and cities have taken part so far
              </Heading>
              <Heading as="h3" style={{ marginLeft: 0 }} textAlign="left">
                Real change in numbers
              </Heading>
              <p>
                Walk, cycle or wheel. Played outdoors in the community, Beat the Street is a free, fun, and safe way to
                get you moving. That can be walking, cycling to school or work every day, or simply exploring your local
                area with friends and family.
              </p>
            </WalkTextWrapper>
          </TextWrapper>
        </SectionWrapper>
      </Wrapper>
    </ContentWrapper>
  )
}

export default WalkSection
