import getISODay from 'date-fns/getISODay'
import getHours from 'date-fns/getHours'
import useAuth from '../../contexts/AuthContext/useAuth'
import { useGetRoutesQuery } from '../../generated/api'

export const useGetScore = () => {
  const { user } = useAuth()

  const points = user?.points?.all || 0

  const { data: routesRes, isLoading } = useGetRoutesQuery(
    {
      playerId: user?.id,
    },
    {
      enabled: !!user?.id,
    },
  )

  const routes = routesRes?.getPlayer.routes || []

  const selectedSwipes = routes?.filter((route) => {
    const hour = getHours(new Date(route.swipeTimestamp))
    const day = getISODay(new Date(route.swipeTimestamp))

    // Monday to Friday - 7AM - 9AM and 3PM - 6PM
    if (day < 6) {
      if ((hour >= 7 && hour < 9) || (hour >= 15 && hour < 18)) {
        return route
      }
    }
  })

  const sumUpPoints: number = selectedSwipes.reduce((a, b) => a + (b.points || 0), 0)

  return {
    points,
    pointsDuringSpecifiedTime: sumUpPoints,
    routes,
    isLoading,
  }
}
