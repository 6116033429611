import Modal from '../../../components/Modal/Modal'
import { wideSmallModal } from '../../../components/Modal/Modal.styled'
import { Text } from '../../../components/Message/Message.styled'
import Button from '../../../components/Button/Button'
import theme from '../../../styles/theme'
import Wrapper from '../../../components/Wrapper/Wrapper'
import React, { useState } from 'react'
import useAuth from '../../../contexts/AuthContext/useAuth'
import { useManagePlayers } from '../../signup/hooks/useManagePlayers'
import { toast } from 'react-toastify'
import Section from '../../../components/Section'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const LeaveFamilySection = () => {
  const { masterUser, setUser, refetch } = useAuth()
  const { onRemoveFromFamily } = useManagePlayers()
  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [error, setError] = useState(false)
  const { t } = useTranslation()

  const toastMessageSuccess = () =>
    toast.success(t('manageAccount.yourFamilySection.toastMessage'), {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })

  return (
    <>
      <Section />
      <Modal
        modalOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        heading={t('manageAccount.yourFamilySection.modal.title') || ''}
        modalType={wideSmallModal}
        children={
          <div style={{ paddingRight: '1.5rem', paddingLeft: '1.5rem' }}>
            <Text style={{ marginBottom: '25px', fontSize: 16, fontWeight: 'normal' }}>
              {t('manageAccount.yourFamilySection.modal.text')}
            </Text>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                text={t('common.cancel')}
                onClick={() => setIsModalOpen(false)}
                tertiary
                style={{ marginRight: '15px', width: '90px' }}
              />
              <Button
                text={t('common.remove')}
                secondary
                isSubmitting={isLoading}
                style={{
                  backgroundColor: theme.colors.error,
                  borderColor: theme.colors.error,
                  width: '100px',
                }}
                onClick={async () => {
                  setIsLoading(true)
                  if (masterUser?.id && masterUser.role.parentId) {
                    try {
                      await onRemoveFromFamily({ parentId: masterUser?.role.parentId, playerId: masterUser?.id })
                      toastMessageSuccess()
                      await setUser(masterUser.id)
                      await refetch()
                      setError(false)
                    } catch (e) {
                      console.log('err:', e)
                      setError(true)
                    } finally {
                      setIsLoading(false)
                      setIsModalOpen(false)
                    }
                  }
                }}
              />
            </div>
          </div>
        }
      />
      <Wrapper>
        <h2>{t('manageAccount.yourFamilySection.title')}</h2>
      </Wrapper>
      <Wrapper veryTall marginTop="2rem" width="400px" textAlign="left">
        <Button
          text={t('manageAccount.yourFamilySection.button')}
          tertiary
          style={{
            color: theme.colors.error,
            borderColor: theme.colors.error,
          }}
          onClick={() => setIsModalOpen(true)}
        />
        {error && (
          <Text style={{ color: theme.colors.error, textAlign: 'center', fontSize: 16, marginTop: '30px' }}>
            {t('manageAccount.yourFamilySection.error')}
          </Text>
        )}
      </Wrapper>
    </>
  )
}

export default LeaveFamilySection
