import React from 'react'
import MoreHorizontalIcon from '../../assets/icons/MoreHorizontal'
import { StyledMenu, StyledMenuList, StyledMenuButton } from './Menu.styled'

interface MenuProps {
  children: React.ReactNode
  handleToggleMenu: () => void
  isMenuOpen: boolean
}

const Menu = ({ children, handleToggleMenu, isMenuOpen }: MenuProps) => {
  return (
    <>
      <StyledMenu tabIndex={0} role={'presentation'}>
        <StyledMenuButton onClick={handleToggleMenu}>
          <MoreHorizontalIcon width={23} height={30} />
        </StyledMenuButton>
      </StyledMenu>
      {isMenuOpen && <StyledMenuList>{children}</StyledMenuList>}
    </>
  )
}

export default Menu
