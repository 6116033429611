import Wrapper from '../../components/Wrapper/Wrapper'
import device from '../../styles/device'
import { withProp } from 'styled-tools'
import styled from 'styled-components'

export const TABLETWIDTH = '768'

export const StatsWrapper = styled(Wrapper).attrs((props) => ({
  borderColor: props.onWhite ? '#eaeaea' : '#ffffff',
}))`
  max-width: 900px;
  margin-top: 0;
  margin-bottom: 20px;
  @media ${device.tabletPortrait} {
    margin-bottom: 3rem;
  }
  @media ${device.tabletLandscape} {
    margin-bottom: 4.5rem;
  }
  @media ${device.laptopNarrow} {
    margin-bottom: 6rem;
  }
  @media ${device.desktop} {
    margin-bottom: 6rem;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    li {
      list-style: none;
      width: ${withProp('carouselColumns', (carouselColumns) =>
        carouselColumns ? Math.round(100 / carouselColumns) : '50',
      )}%;
      min-height: 155px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px 0 20px;
      svg,
      svg image {
        width: 100%;
      }
    }
    li::before {
      content: none;
    }
    li:last-child {
      &:first-child {
        width: 100%;
        border: none;
      }
    }
    li:first-child {
      border-right: 1px dashed ${(props) => props.borderColor};
      padding: 0 20px 0 5px;
    }

    ${({ carouselColumns }) =>
      carouselColumns === 1 &&
      `
     li:first-child {
                border-right: none;
            }
        `}

    @media ${device.tabletPortrait} {
      li:not(:last-child) {
        border-right: 1px dashed ${(props) => props.borderColor};
      }
      li {
        width: ${withProp('desktopColumns', (desktopColumns) => Math.round(100 / desktopColumns))}%;

        padding: 0 20px;
      }
    }
  }
`

export const Unit = styled.div`
  width: 100%;
  font-size: 1.6rem;
  color: ${(props) => props.theme.colors.darkGrey};
  svg {
    display: block;
    margin: 0 auto 5px;
  }
  strong {
    letter-spacing: -2px;
    font-size: 4rem;
    line-height: 4.5rem;
    margin-bottom: 1.5rem;
    display: block;
    color: ${(props) => props.theme.colors.primary};

    @media ${device.mobPortrait} {
      font-size: 5rem;
      line-height: 5rem;
    }
    @media ${device.tabletPortrait} {
      font-size: 5.5rem;
      line-height: 5.5rem;
    }
  }
  p.intro {
    font-size: 2.2rem;
    letter-spacing: -1px;
    margin-bottom: 0;
    color: ${(props) => props.theme.colors.primary};
  }
  h4 {
    font: normal 1.6rem 'co-text';
    line-height: 1.8rem;
    color: ${(props) => props.theme.colors.darkGrey};
    a {
      color: ${(props) => props.theme.colors.midDarkBlue};
      font-size: 1.6rem;
      text-decoration: none;
      font-weight: normal;
    }
  }

  .mobileStat {
    @media ${device.mobLandscape} {
      display: none;
    }
  }

  .desktopStat {
    display: none;
    @media ${device.mobLandscape} {
      display: block;
    }
  }
`

export const Points = styled(Unit)`
  > strong {
    color: ${(props) => props.theme.colors.secondary};
  }
`

export const Players = styled(Unit)`
  > strong {
    color: ${(props) => props.theme.colors.primary};
  }
`

export const Miles = styled(Unit)`
  > strong {
    color: ${(props) => props.theme.colors.body};
  }
`

export const Map = styled(Unit)`
  display: flex;
  align-items: center;
  justify-items: center;
  flex-flow: column;
  .mapLink {
    display: block;
    svg {
      width: 70%;
      @media ${device.tabletPortrait} {
        width: 100%;
        height: 120px;
      }
    }
  }
`
