import { useEffect } from 'react'
import { useDispatch } from '../redux/store'
import { setMessage } from '../redux/actions/ui'
import useGame from '../contexts/GameContext/useGame'
import useAuth from '../contexts/AuthContext/useAuth'
import { useSelector } from '../redux/store'

const useConfirmEmail = () => {
  // we don't display massage now, if on again check if should use activeGame instead of game
  const { game } = useGame()
  const { user, isAuthenticated } = useAuth()
  const dispatch = useDispatch()
  const message = useSelector((state) => state.ui.message)

  useEffect(() => {
    if (message?.type !== 'success') {
      if (isAuthenticated && !user?.profile.isVerified && user?.profile.isRegistered && game) {
        dispatch(
          setMessage({
            type: 'warning',
            show: true,
            closable: true,
            predefined: 'confirmEmail',
            page: 'landing',
          }),
        )
      }
    }
  }, [user, game, isAuthenticated])
}

export default useConfirmEmail
