import React from 'react'
import styled from 'styled-components'
import useNavigate from '../../../hooks/useNavigate'
import Tooltip from '../../../components/Tooltip/Tooltip'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const BackgroundContainer = styled.div`
  display: flex;
  background-color: transparent;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;
  overflow: visible;
`

const ButtonContainer = styled.button`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  align-items: center;
  border-radius: 100px;
  border: 2px dotted #959494;
  padding: 10px;

  &:hover {
    background-color: #f2f8f2;
  }

  &:focus {
    background-color: transparent;
  }
`
const PlusIcon = styled.p`
  font-family: 'Co Text', serif;
  font-size: 36px;
  color: #6e6e6e;
  margin: 0;
`

const AddFamilyMemberButton = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <BackgroundContainer>
      <Tooltip text={t('components.addFamilyMember.tooltip') || ''}>
        <ButtonContainer
          onClick={() => navigate('/signup/family/select-age')}
          aria-label={t('aria.label.addFamilyMember')}
        >
          <PlusIcon>+</PlusIcon>
        </ButtonContainer>
      </Tooltip>
    </BackgroundContainer>
  )
}

export default AddFamilyMemberButton
