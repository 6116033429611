import React, { FC } from 'react'
import { StaticQuery, graphql } from 'gatsby'

interface SVGImageProps {
  className?: string
  filename?: string
  alt?: string
  svgProperties?: {
    width: string | number
    height: string | number
  }
}

const SVGImage: FC<SVGImageProps> = ({ className, filename, alt, svgProperties }) => {
  const { width, height } = svgProperties || {}
  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile {
            edges {
              node {
                relativePath
                name
                publicURL
              }
            }
          }
        }
      `}
      render={(data) => {
        const img = data.images.edges.find((n) => {
          return n.node.relativePath.includes(filename)
        })
        if (!img) {
          return <></>
        }

        if (!img.node.publicURL) {
          return (
            <svg className={className} width={width} height={height}>
              <img alt={alt} width={width} height={height} />
            </svg>
          )
        }

        return (
          <svg className={className} width={width} height={height}>
            <image xlinkHref={img.node.publicURL} width={width} height={height} />
          </svg>
        )
      }}
    />
  )
}

export default SVGImage
