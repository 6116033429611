import React, { FC } from 'react'
import { StyledCardGrid } from './CardGrid.styled'
import Card from '../Card/Card'
import { ImageDataLike } from 'gatsby-plugin-image'
import { ContextProps } from '../CallToAction/CallToActionPanel'
import { Maybe } from '../../generated/api'

export type Block = Record<string, any>
export interface Post {
  id: string
  title: string | null
  eventSlug?: string
  description: Maybe<Block>
  startDate?: Date | string
  startTime?: Date | string
  endDate?: Date | string
  endTime?: Date | string
  externalUrl?: string
  file?: Maybe<{
    asset?: Maybe<{
      url?: Maybe<string>
      size?: Maybe<number>
      extension?: Maybe<string>
    }>
  }>
  alt?: Maybe<string>
  image: Maybe<{
    asset?: Maybe<{
      gatsbyImageData?: ImageDataLike
      id?: string
    }>
  }>
}

interface CardGridProps {
  context: ContextProps
  posts?: Post[]
}

const CardGrid: FC<CardGridProps> = ({ context, posts }) => (
  <StyledCardGrid>
    {posts?.map((post: Post) => {
      return (
        <Card
          context={context}
          gridSize={Math.min(3, posts.length)}
          key={post.id}
          title={post.title || ''}
          image={post?.image?.asset?.gatsbyImageData}
          body={post.description}
          startDate={post.startDate}
          startTime={post.startTime}
          endDate={post.endDate}
          endTime={post.endTime}
          externalUrl={post.externalUrl}
          file={post.file && post.file.asset}
          alt={post.alt || ''}
        />
      )
    })}
  </StyledCardGrid>
)

export default CardGrid
