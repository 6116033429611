import { prop } from 'styled-tools'
import styled from 'styled-components'

export const AnchorLinks = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  padding: 30px 0;
  margin: 30px 0;
  border: 1px dashed ${(props) => props.theme.colors.grey};
  border-width: 1px 0;
  li {
    background: url(${prop('background')}) 0 center no-repeat;
    padding: 0px 0 4px 22px;
    margin: 0 0 2px 0;
    a {
      font-size: ${(props) => props.theme.fonts.ms};
    }

    &::before {
      display: none;
    }
  }
`
