import Button from '../../../components/Button/Button'
import Wrapper from '../../../components/Wrapper/Wrapper'
import React, { useState } from 'react'
import useGame from '../../../contexts/GameContext/useGame'
import theme from '../../../styles/theme'
import { useLeaveGameMutation } from '../../../generated/api'
import { Text } from '../../../components/Message/Message.styled'
import Modal from '../../../components/Modal/Modal'
import { verySmallModal } from '../../../components/Modal/Modal.styled'
import useNavigate from '../../../hooks/useNavigate'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const ChangeGame = () => {
  const { activeGame: game } = useGame()
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { mutateAsync: leave } = useLeaveGameMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['GetPlayerById'] }),
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const navigate = useNavigate()

  const onLeveGame = async () => {
    try {
      setIsLoading(true)
      await leave({})

      await navigate('/join-game')
    } catch (e) {
      console.log('err', e)
      setError(true)
    } finally {
      setIsModalOpen(false)
    }
  }

  return (
    <div id={'your-game'}>
      <Modal
        modalOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        heading={t('manageAccount.gameSection.game.modal.title') || ''}
        modalType={verySmallModal}
        children={
          <div style={{ paddingRight: '1.5rem', paddingLeft: '1.5rem' }}>
            <Text style={{ marginBottom: '25px', fontSize: 16, fontWeight: 'normal' }}>
              {t('manageAccount.gameSection.game.modal.text')}
            </Text>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                text={t('manageAccount.gameSection.game.modal.buttonReject')}
                onClick={() => setIsModalOpen(false)}
                tertiary
                style={{ marginRight: '15px', width: '80px' }}
              />
              <Button
                text={t('manageAccount.gameSection.game.modal.buttonConfirm')}
                secondary
                isSubmitting={isLoading}
                style={{
                  backgroundColor: theme.colors.error,
                  borderColor: theme.colors.error,
                  width: '100px',
                }}
                onClick={onLeveGame}
              />
            </div>
          </div>
        }
      />
      <Wrapper>
        {error && (
          <Text style={{ color: theme.colors.error, textAlign: 'center', fontSize: 16 }}>{t('errors.generic')}</Text>
        )}
      </Wrapper>
      {game?.id ? (
        <Wrapper veryTall marginTop="2rem" width="400px" textAlign="left">
          <Text style={{ textAlign: 'center', fontSize: 17, fontWeight: 'normal', marginBottom: '20px' }}>
            {t('manageAccount.gameSection.game.text')}
          </Text>
          <Button
            text={t('manageAccount.gameSection.game.buttonChange')}
            tertiary
            style={{
              color: theme.colors.error,
              borderColor: theme.colors.error,
            }}
            onClick={() => setIsModalOpen(true)}
          />
        </Wrapper>
      ) : (
        <Wrapper veryTall marginTop="2rem" width="400px" textAlign="left">
          <Button text={t('manageAccount.gameSection.game.buttonJoin')} onClick={() => navigate('/join-game')} />
        </Wrapper>
      )}
    </div>
  )
}

export default ChangeGame
