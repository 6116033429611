import React, { FC } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const RCWrapper = styled.p`
  color: ${(props) => props.theme.colors.midGrey};
  margin-top: 2rem;
  line-height: 1.2rem;
  small,
  a {
    font-size: 1rem;
  }
`

const RecaptureTerms: FC = () => {
  const { t } = useTranslation()
  return (
    <RCWrapper data-testid="recaptcha-terms">
      <small>
        {t('components.reCaptcha.text', { recaptcha: 'reCAPTCHA' })}{' '}
        <a
          href="https://policies.google.com/privacy"
          aria-label="https://policies.google.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
          data-testid="recapcha-terms-privacy-policy"
        >
          {t('components.reCaptcha.privacy')}
        </a>{' '}
        {t('components.reCaptcha.and')}{' '}
        <a
          href="https://policies.google.com/terms"
          aria-label="https://policies.google.com/terms"
          target="_blank"
          rel="noopener noreferrer"
          data-testid="recapcha-terms-service-terms"
        >
          {t('components.reCaptcha.Terms')}
        </a>{' '}
        {t('components.reCaptcha.apply')}
      </small>
    </RCWrapper>
  )
}

export default RecaptureTerms
