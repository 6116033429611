import round from 'lodash/round'
import { LngLatBoundsLike } from 'mapbox-gl'

const formatBoundingBox = (boundingBox: string): LngLatBoundsLike => {
  const coordinates = boundingBox.split(',')

  return [
    [round(Number(coordinates[0]), 6), round(Number(coordinates[1]), 6)],
    [round(Number(coordinates[2]), 6), round(Number(coordinates[3]), 6)],
  ]
}
export default formatBoundingBox
