import * as React from 'react'
import Award from '../../../components/Award/Award'
import styled from 'styled-components'
import { FlexWrapper } from '../Award.styles'
import { AwardType } from '../Award.interface'
import theme from '../../../styles/theme'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const Title = styled.h1`
  text-align: center;
  font-size: 22px;
`

const Subtitle = styled.p`
  text-align: center;
  font-size: 18px;
  color: ${(props) => props.theme.colors.body};
`

const Description = styled('span')`
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.body};
  margin-bottom: 10px;
`

const Motivator = styled('p')`
  text-align: center;
  font-size: 15px;
  color: #007cbd;
  margin: 2rem 0 2rem;
`

const Content = ({ award, swipePoints, userPoints }: { award: any; userPoints?: number; swipePoints?: number }) => {
  const { t } = useTranslation()

  const score = award.type === AwardType.points ? userPoints : swipePoints
  const isAwardLocked = score === undefined || score < award.range

  return (
    <FlexWrapper
      style={{
        height: '100%',
        justifyContent: 'space-evenly',
        padding: 30,
        paddingBottom: 0,
      }}
    >
      <FlexWrapper>
        {isAwardLocked ? (
          <Title>{t('game.awards.locked')}</Title>
        ) : (
          <>
            <Title style={{ margin: 0 }}>{t('game.awards.modalTitle')}</Title>
            <Subtitle>{t('game.awards.unlocked')}</Subtitle>
          </>
        )}
      </FlexWrapper>
      <Award
        icon={false}
        customStyle={{
          height: '22vh',
          width: '22vh',
          maxHeight: '220px',
          maxWidth: '220px',
        }}
        award={award}
      />
      <FlexWrapper>
        <h2
          style={{
            fontSize: 28,
            textAlign: 'center',
            marginBottom: 0,
          }}
        >
          {award.name}
        </h2>
        <Description>{award.description}</Description>
      </FlexWrapper>
      {isAwardLocked && (
        <>
          <div
            style={{
              marginTop: 10,
              height: 1,
              width: '100%',
              borderBottom: `1px dashed ${theme.colors.midGrey}`,
              paddingBottom: 0,
              paddingTop: 0,
              opacity: 0.5,
            }}
          />
          <Motivator>{award.motivator}</Motivator>
        </>
      )}
    </FlexWrapper>
  )
}

export default Content
