import React, { FC, useEffect, useState } from 'react'
import Toggle from '../../../components/Toggle/Toggle'
import SelectDropdown from '../../../components/SelectDropdown/SelectDropdown'
import Wrapper from '../../../components/Wrapper/Wrapper'
import Loading from '../../../components/Loading'
import Tooltip from '../../../components/Tooltip/Tooltip'
import LeaderboardTable from '../../../components/LeaderboardTable/LeaderboardTable'
import { IndexType, LeaderboardProps, LeaderboardType } from '../interfaces'
import useAuth from '../../../contexts/AuthContext/useAuth'
import { PaginationButton, ToggleWrap } from '../Leaderboard.styled'
import Button from '../../../components/Button/Button'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import getLimitRowsIndexes from '../utils/getLimitRowsIndexes'
import useTeamLeaderboards from './useTeamLeaderboards'
import { LeaderboardPointType, LeaderboardTeamPointType } from '../../../generated/api'

const TeamLeaderboard: FC<LeaderboardProps> = ({ mini, limitRows = false, pageSize = 10 }) => {
  const { user } = useAuth()
  const { t } = useTranslation()
  const [pointType, setPointType] = useState<LeaderboardTeamPointType>(LeaderboardTeamPointType.Average)
  const [selectedLeaderboardId, setSelectedLeaderboardId] = useState<string | undefined>()
  const { data, leaderboards, isLoading } = useTeamLeaderboards({
    leaderboardId: selectedLeaderboardId,
    pointType,
    type: LeaderboardPointType.Swipe,
  })
  const [topIndex, setTopIndex] = useState(0)
  const [bottomIndex, setBottomIndex] = useState(pageSize)

  const userTeamIndex = data?.findIndex((team) => user?.info.teamId && team?.id === user?.info.teamId)
  const { topIndex: limitTopIndex, bottomIndex: limitBottomIndex } = getLimitRowsIndexes(userTeamIndex, data.length)

  const setOnTeamPosition = () => {
    const index = userTeamIndex > -1 ? userTeamIndex : 0
    const base = Math.floor(index / pageSize) * pageSize

    setTopIndex(base)

    if (base && base === userTeamIndex) {
      setTopIndex(base - 1)
    }

    setBottomIndex(base + (pageSize - 1))
  }

  useEffect(() => {
    if (userTeamIndex && userTeamIndex >= pageSize) {
      setOnTeamPosition()
    }
  }, [pageSize, userTeamIndex])

  const pointsHelpText = t('game.leaderboards.typeTooltip')

  const incrementIndex = (index?: IndexType) => {
    if (index === IndexType.top) {
      const newTopIndex = topIndex - pageSize < 0 ? 0 : topIndex - pageSize
      return setTopIndex(newTopIndex)
    }
    const newBottomIndex = bottomIndex + pageSize > data.length ? data.length : bottomIndex + pageSize
    return setBottomIndex(newBottomIndex)
  }

  const leaderboardTypeOptions = leaderboards?.map((leaderboard) => {
    return { value: leaderboard.id, display: leaderboard.name }
  })

  if (isLoading) return <Loading />
  return (
    <>
      {!mini && (
        <>
          <Wrapper>
            <SelectDropdown
              ariaLabel="Change team type"
              value={selectedLeaderboardId}
              onChange={(ev) => setSelectedLeaderboardId(ev.target.value === t('common.all') ? '' : ev.target.value)}
              options={[t('common.all'), ...leaderboardTypeOptions]}
              secondary={true}
            />
          </Wrapper>

          <ToggleWrap>
            <Toggle
              value={pointType}
              onChange={(v: LeaderboardTeamPointType) => setPointType(v)}
              left={{
                value: LeaderboardTeamPointType.Average,
                text: t(`game.leaderboards.pointsType.${LeaderboardTeamPointType.Average}`) || '',
              }}
              right={{
                value: LeaderboardTeamPointType.All,
                text: t(`game.leaderboards.pointsType.${LeaderboardTeamPointType.All}`) || '',
              }}
            />
            {pointsHelpText && <Tooltip overlayPosition="left" text={pointsHelpText} />}
          </ToggleWrap>
        </>
      )}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {!mini && (
          <>
            {topIndex !== 0 && (
              <Button
                style={{ fontSize: '1.7rem' }}
                link
                onClick={() => {
                  setTopIndex(0)
                  setBottomIndex(10)
                }}
                text={t(`game.leaderboards.jumpToTop`)}
              />
            )}
            {userTeamIndex !== -1 && (
              <Button
                style={{ fontSize: '1.7rem' }}
                link
                onClick={() => setOnTeamPosition()}
                text={t(`game.leaderboards.yourPosition`, {
                  number: `(${userTeamIndex + 1}) ${String.fromCodePoint(8595)}`,
                })}
              />
            )}
          </>
        )}
      </div>
      <LeaderboardTable
        pointsType={pointType}
        mini={true}
        data={data}
        type={LeaderboardType.team}
        yourPosition={userTeamIndex}
        total={data.length}
        topIndex={limitRows ? limitTopIndex : topIndex}
        bottomIndex={limitRows ? limitBottomIndex : bottomIndex}
      />
      {bottomIndex < data.length && !mini && !isLoading && (
        <PaginationButton
          text={t(`game.leaderboards.paginationNext`, {
            number: Math.min(pageSize, data.length - bottomIndex),
          })}
          onClick={() => incrementIndex(IndexType.bottom)}
        />
      )}
    </>
  )
}

export default TeamLeaderboard
