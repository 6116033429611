import React from 'react'
import { PostCodeInput } from '../../../generated/api'

export const validatePostcode = async (
  postCode: string,
  setPostCodeData: React.Dispatch<React.SetStateAction<PostCodeInput | undefined>>,
): Promise<{ statusCode: number; error?: string }> => {
  const res = await fetch(`https://api.postcodes.io/postcodes/${postCode}`)
  const data = await res.json()
  if (data.status === 200) {
    const postCodeData = data.result
    setPostCodeData({
      postcode: postCodeData.postcode,
      country: postCodeData.country,
      latitude: postCodeData.latitude,
      longitude: postCodeData.longitude,
      codes: {
        admin_district: postCodeData.codes.admin_district,
        admin_county: postCodeData.codes.admin_county,
        admin_ward: postCodeData.codes.admin_ward,
        parish: postCodeData.codes.parish,
        parliamentary_constituency: postCodeData.codes.parliamentary_constituency,
        ccg: postCodeData.codes.ccg,
        ccg_id: postCodeData.codes.ccg_id,
        ced: postCodeData.codes.ced,
        nuts: postCodeData.codes.nuts,
        lsoa: postCodeData.codes.lsoa,
        msoa: postCodeData.codes.msoa,
        lau2: postCodeData.codes.lau2,
        pfa: postCodeData.codes.pfa,
      },
    })
  }

  return {
    statusCode: data?.status,
    error: data?.error,
  }
}
