import { State } from './type'

const USER_KEY = 'btsUser'
const PARENT_KEY = 'btsParent'

type User = State['user']

class LocalCache {
  getUser(): User | undefined {
    if (typeof window === 'undefined') return undefined
    const userString = localStorage.getItem(USER_KEY)
    try {
      if (!userString) return undefined
      return JSON.parse(userString)
    } catch {
      return undefined
    }
  }

  getParent(): User | undefined {
    if (typeof window === 'undefined') return undefined
    const parentString = localStorage.getItem(PARENT_KEY)
    try {
      if (!parentString) return undefined
      return JSON.parse(parentString)
    } catch {
      return undefined
    }
  }

  setUser(user: User) {
    if (typeof window === 'undefined') return undefined
    localStorage.setItem(USER_KEY, JSON.stringify(user))
  }
  setParent(parent: User) {
    if (typeof window === 'undefined') return undefined
    localStorage.setItem(PARENT_KEY, JSON.stringify(parent))
  }

  cleanCache() {
    if (typeof window === 'undefined') return undefined
    localStorage.removeItem(USER_KEY)
    localStorage.removeItem(PARENT_KEY)
  }
}

const localCache = new LocalCache()

export default localCache
