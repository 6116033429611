import React, { FC, useMemo } from 'react'
import Countdown from '../../../../components/Countdown/Countdown'
import Wrapper from '../../../../components/Wrapper/Wrapper'
import Spacer from '../../../../components/Spacer'
import PlayTheGame from '../../../../components/PlayTheGame/PlayTheGame'
import PrimaryActionButton from '../../../../components/PrimaryActionButton/PrimaryActionButton'
import useGame from '../../../../contexts/GameContext/useGame'
import { isAfter, isBefore, subDays } from 'date-fns'
//import { GlobalSettings } from '../../../../interfaces/globalSettings.interface'
import useAuth from '../../../../contexts/AuthContext/useAuth'
import SocialBlock from '../../../../components/SocialBlock'
import GameStats from '../../../../containers/GameStats'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import FinishedGameContent from './components/FinishedGameContent'
import CTAs from '../components/CTAs'
import Introduction from '../components/Introduction'
import { GameStatus } from '../../../../generated/api'
import { Heading } from '../../../../components/Heading/Heading.styled'
import HeadingWrapper from '../components/HeadingWraper'

const StartedLandingPage: FC = () => {
  const { game } = useGame()
  const { user } = useAuth()
  const { t } = useTranslation()

  const isFinished = game?.info.status === GameStatus.Sustain || game?.info.status === GameStatus.Finished

  const headingText = useMemo(() => {
    if (!game) return ''
    if (isFinished) {
      return t('game.landing.title.finished')
    }

    const restrictedDay = subDays(new Date(game.info.endsAt), game.info.restrictAtDays || 0)
    if (isAfter(new Date(), restrictedDay)) {
      //restricted
      return t('game.landing.title.lastWeek')
    }
    return t('game.landing.title.live')
  }, [game])

  if (!game) return null

  return (
    <>
      <HeadingWrapper>
        <Heading>{headingText}</Heading>
      </HeadingWrapper>
      <GameStats carousel carouselColumns={2} {...{ game }} />

      <Spacer space="5rem" />
      <Wrapper>
        <PrimaryActionButton game={game} />
      </Wrapper>
      {isBefore(new Date(), new Date(game?.info.startsAt)) && <Countdown to={new Date(game.info.startsAt)} />}
      {isFinished ? (
        <FinishedGameContent />
      ) : (
        <PlayTheGame gameStartDate={game.info.startsAt} gameEndDate={game.info.endsAt} />
      )}

      {!isFinished && <CTAs game={game} user={user} />}
      {!isFinished && <Introduction game={game} />}
      <SocialBlock game={game} />
      <Spacer space="5rem" />
    </>
  )
}

export default StartedLandingPage
