export const iconProperties = {
  beatbox: {
    top: '33px',
    left: '10px',
    height: '111px',
    width: '78px',
  },
  girl_wellies: {
    top: '53px',
    left: '10px',
    height: '111px',
    width: '70px',
  },
  hand: {
    top: '13px',
    left: '0px',
    height: '82px',
    width: '68px',
  },
  handbag_woman: {
    top: '0px',
    left: '0px',
    height: '200px',
    width: '78px',
  },
  letterbox_path: {
    top: '-25px',
    left: '-35px',
    height: '200px',
    width: '192px',
  },
  schoolchildren: {
    top: '20px',
    left: '-15px',
    height: '160px',
    width: '94px',
  },
  wheelchair: {
    top: '20px',
    left: '-5px',
    height: '157px',
    width: '110px',
  },
  young_lady: {
    top: '25px',
    left: '13px',
    height: '157px',
    width: '65px',
  },
  virus_character: {
    top: '20px',
    left: '-48px',
    height: '140px',
    width: '140px',
  },
  app_boy: {
    top: '20px',
    left: '-10px',
    height: '170px',
    width: '100px',
  },
  tree: {
    top: '28px',
    left: '-32px',
    height: '200px',
    width: '120px',
  },
}

export const themes = {
  dark: {
    bg: '#044567',
    textColor: '#fff',
    border: 'none',
  },
  light: {
    bg: '#96D6DE',
    textColor: '#044567',
    border: 'none',
  },
  white: {
    bg: '#fff',
    textColor: '#044567',
    border: '1px solid #979797',
  },
}
