import styled from 'styled-components'
import theme from '../../../../styles/theme'
import device from '../../../../styles/device'

export const StyledCardGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media ${device.tabletLandscape} {
    justify-content: flex-start;
  }
`

export const CardWrapper = styled.div`
  height: 500px;
  width: 350px;

  border-radius: 10px;

  padding: 0;
  overflow: hidden;
  background: ${theme.colors.white};
  text-align: left;
  margin: 10px 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    margin-top: -5px;
  }
`

export const StyledCardBody = styled.div`
  width: 100%;
  color: ${(props) => props.theme.colors.darkGrey};
  font-size: ${(props) => props.theme.fonts.xs};
  padding-bottom: 1.3rem;
  overflow: hidden;
  max-height: 150px;
  text-overflow: ellipsis;
`
