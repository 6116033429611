import React, { FC } from 'react'
import styled from 'styled-components'
import device from '../../styles/device'

const Wrapper = styled.div`
  > div > * {
    margin-bottom: 30px;
  }
  @media ${device.tabletPortrait} {
    text-align: center;
    > div {
      width: 690px;
      margin: 0 auto;
      text-align: left;
      display: flex;
      flex-wrap: wrap;
    }
  }
`

interface Props {
  children: React.ReactNode
}
const CTAGrid: FC<Props> = ({ children }) => {
  return (
    <Wrapper>
      <div>{children}</div>
    </Wrapper>
  )
}

export default CTAGrid
