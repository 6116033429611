import * as React from 'react'
import { useState } from 'react'
import { ThreeDots } from 'react-loader-spinner'
import { StyledInput } from './Input.styled'
import { Error } from '../FormElements/FormElements.styled'
import SVGImage from '../DynamicImg/SVGImage'
import { onSpaceOrEnterPressed } from '../../utils/events'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Maybe } from '../../generated/api'

interface InputProps {
  id: string
  type: string
  text?: string
  icon?: React.ReactFragment
  button?: boolean
  required?: boolean
  disabled?: boolean
  hasError?: boolean
  isSubmitting?: boolean
  hasPassToggle?: boolean
  tooltip?: React.ReactElement
  value?: Maybe<string | number>
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: React.ChangeEventHandler<HTMLInputElement>
  error?: string
  maxlength?: number
  pattern?: string
  placeholder?: string
  autoComplete?: string
  className?: string
  forwardedRef?: React.RefObject<HTMLInputElement>
  onInvalid?: React.ChangeEventHandler<HTMLInputElement>
  style?: any
  inputProps?: any
}

const Input: React.FC<InputProps> = ({
  id,
  type,
  text,
  icon,
  button,
  required,
  disabled,
  tooltip,
  value,
  onChange,
  onBlur,
  onInvalid,
  error,
  autoComplete,
  placeholder,
  pattern,
  isSubmitting,
  hasError,
  hasPassToggle,
  maxlength,
  forwardedRef,
  style,
  inputProps,
}) => {
  const { t } = useTranslation()
  const [passwordShown, setPasswordShown] = useState(false)

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown)
  }

  return (
    <StyledInput
      withIcon={icon}
      isButton={button}
      isSubmitting={isSubmitting}
      isError={typeof hasError === 'boolean' ? hasError : !!error}
      passwordShown={passwordShown}
    >
      <input
        style={style}
        type={passwordShown ? 'text' : type}
        name={id}
        id={id}
        disabled={disabled}
        required={required}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onInvalid={onInvalid}
        placeholder={placeholder}
        pattern={pattern}
        maxLength={maxlength}
        autoComplete={autoComplete}
        ref={forwardedRef}
        {...inputProps}
      />
      {text && (
        <label htmlFor={id}>
          {text}
          {tooltip}
        </label>
      )}
      {isSubmitting && <ThreeDots color="#ffffff" height={15} width={50} />}
      {hasPassToggle && (
        <span
          // TODO
          // aria-label={t('aria.label.showPassword')}
          className="passVisible"
          onClick={togglePasswordVisibility}
          onKeyDown={(e) => onSpaceOrEnterPressed(e, togglePasswordVisibility)}
          role="button"
          tabIndex={0}
          data-testid="show-password-toggle"
        >
          <SVGImage
            className="showPass"
            alt={t('aria.alt.showPassword') || ''}
            filename="password-show.svg"
            svgProperties={{ width: '15px', height: '15px' }}
          />
          <SVGImage
            className="hidePass"
            alt={t('aria.alt.hidePassword') || ''}
            filename="password-hide"
            svgProperties={{ width: '15px', height: '15px' }}
          />
        </span>
      )}
      {icon}
      {error && (
        <Error data-testid={inputProps?.['data-testid'] ? `${inputProps?.['data-testid']}-error` : null}>{error}</Error>
      )}
    </StyledInput>
  )
}

export default Input
