import React, { FC } from 'react'

interface ShallowArcProps {
  backgroundColor: string
}

const ShallowArc: FC<ShallowArcProps> = ({ backgroundColor }) => {
  return (
    <>
      <svg
        width="1440px"
        height="242px"
        viewBox="0 0 1440 242"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        preserveAspectRatio="none"
      >
        <title>Combined Shape</title>
        <desc>Created with Sketch.</desc>
        <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="dashboard-desktop" transform="translate(0.000000, -1033.000000)" fill={backgroundColor}>
            <path
              d="M-13,1275 L-13.0197104,1192.89166 C-39.5904224,1180.6858 -54,1167.60512 -54,1154 C-54,1087.17355 293.650892,1033 722.5,1033 C1151.34911,1033 1499,1087.17355 1499,1154 C1499,1168.59756 1482.4115,1182.59138 1452.01176,1195.54867 L1452,1275 L722.5,1275 L722.5,1275 L-13,1275 Z"
              id="Combined-Shape"
            />
          </g>
        </g>
      </svg>
    </>
  )
}

export default ShallowArc
