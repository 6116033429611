import styled from 'styled-components'
import { prop, withProp } from 'styled-tools'

export const StyledButton = styled.button`
  width: 100%;
  max-width: ${prop('width', '400px')};
  border-radius: 6px;
  font-family: ${(props) => props.theme.fonts.secondaryFont};
  font-size: ${(props) => props.theme.fonts.m};
  user-select: none;
  svg {
    margin-right: 12px;
  }

  ${(props) =>
    props.iconOnly &&
    `
        svg {
            margin: 0;
        }
        padding: 3px;
        `};

  ${(props) =>
    props.iconOnly &&
    `
        &:focus {
            outline: none;
            border: 1px solid ${props.theme.colors.secondary};
        }
    `}

  ${(props) =>
    props.secondary &&
    `
        background-color: ${props.theme.colors.secondary};

        ${props.theme.hover} {
            background-color: ${props.theme.colors.body};
        }
        &:hover:disabled {
            outline: none;
            background-color: ${props.theme.colors.secondary}; 
        }
        &:focus {
            outline: none;
        }
        &:focus-visible {
            outline: 2px solid ${props.theme.colors.orange};
        }    
        &:active {
            outline: none;
        }

    `};

  ${(props) =>
    props.tertiary &&
    `
        border-radius: 6px;
        padding: 0.6rem;
        cursor: pointer;
        background-color: ${props.theme.colors.white};
        color: ${props.theme.colors.secondary};
        border: 1px solid  ${props.theme.colors.secondary};
        transition: all 0.1s cubic-bezier(.25,.8,.25,1);

        ${props.theme.hover} {
            transition: ${props.theme.transitions.transition};
            color: ${props.theme.colors.body};
            box-shadow:  0px 0px 10px 0px rgba(0,0,0,0.08);
            background-color: ${props.theme.colors.white};
        }
        &:focus {
            outline: none;
            box-shadow: 0 1px 3px rgba(0,0,0,0.04), 0 1px 3px rgba(0,0,0,0.04);
        }
        &:focus-visible {
            outline: 2px solid ${props.theme.colors.orange};
        }
        &:active {
            outline: none;
            box-shadow: 0 1px 3px rgba(0,0,0,0.04), 0 1px 3px rgba(0,0,0,0.04);
        }

    `};

  ${(props) =>
    props.showArrow &&
    `
        svg.arrow {
            margin: 5px 0 5px 20px;
        }
        padding-right: 0;
        `};

  ${(props) =>
    props.link &&
    `
        width: auto;
        max-width: none;
        border: none;
        padding: 5px 0;
        cursor: pointer;
        background:none;
        color: ${props.theme.colors.secondary};
        font-family: ${props.theme.fonts.primaryFont};
        font-size: ${props.theme.fonts.s};
        ${props.theme.hover} {
            color: ${props.theme.colors.secondary};
            text-decoration: underline;
            border: none;
            background-color: ${props.theme.colors.white};
        }
    `};

  ${(props) =>
    props.smallFont &&
    `
        font-size: ${props.theme.fonts.mxs};

    `};

  ${withProp('border', (border) => (border ? `border:${border}` : ''))};
  ${(props) =>
    props.isSubmitting &&
    `
        position:relative;

            color: ${props.theme.colors.transparent};

            transition: none;
            ${props.theme.hover} {
                color: ${props.theme.colors.transparent};
            }

    `}
  > .loader {
    width: 100%;
    position: absolute;
    top: 13px;
  }
`
