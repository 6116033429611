import React, { FC } from 'react'
import PinSVG from '../../assets/icons/Pin'
import { PinWrapper } from './Pin.styled'

interface PinProps {
  children?: React.ReactNode
  fill?: string
  width?: string
  height?: string
  paddingTop?: string
  className?: string
}

const Pin: FC<PinProps> = ({ width = '42px', height = '61px', paddingTop = '8px', className, children }) => (
  <PinWrapper className={className} options={{ width, height, paddingTop }}>
    <PinSVG width={width} height={height} />
    <span>{children}</span>
  </PinWrapper>
)

export default Pin
