import React from 'react'
import YouLeaderboard from './You/YouLeaderboard'
import Loading from '../../components/Loading'
import { StyledTabList, StyledTab, StyledTabPanel } from '../../components/Tabs/Tabs.styled'
import TeamLeaderboard from './Team/TeamLeaderboard'
import useGame from '../../contexts/GameContext/useGame'
import useAuth from '../../contexts/AuthContext/useAuth'
import { FixedHeightStyledTabs } from './Leaderboard.styled'
import FollowLeaderboard from './FollowLeaderboard'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const InlineLeaderboards = () => {
  const { game } = useGame()
  const { user } = useAuth()
  const { t } = useTranslation()

  if (!game) return <Loading />

  return user?.info.gameId === game.id ? (
    <FixedHeightStyledTabs>
      <StyledTabList>
        <StyledTab>{t('game.leaderboards.team')}</StyledTab>
        <StyledTab>{t('game.leaderboards.you')}</StyledTab>
        <StyledTab>{t('game.leaderboards.follow')}</StyledTab>
      </StyledTabList>
      <StyledTabPanel>
        <TeamLeaderboard mini limitRows />
      </StyledTabPanel>

      <StyledTabPanel>
        <YouLeaderboard mini limitRows />
      </StyledTabPanel>

      <StyledTabPanel>
        <FollowLeaderboard mini />
      </StyledTabPanel>
    </FixedHeightStyledTabs>
  ) : (
    <TeamLeaderboard mini />
  )
}

export default InlineLeaderboards
