import React, { FC, useEffect } from 'react'
import SEO from '../../components/seo'
import { HeadingWrapper } from '../../components/HeadingWrapper/HeadingWrapper.styled'
import { Heading } from '../../components/Heading/Heading.styled'
import LinkButton from '../../components/LinkButton/LinkButton'
import Spacer from '../../components/Spacer'
import useAuth from '../../contexts/AuthContext/useAuth'
import isEmpty from 'lodash/isEmpty'
import Loading from '../../components/Loading'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import useGame from '../../contexts/GameContext/useGame'
import { SurveyTypes, useCreateSurveyMutation, useFindAllPlayerSurveysQuery } from '../../generated/api'
import { useLocation } from '@gatsbyjs/reach-router'
import { IPageProps } from '../../contexts/PageContext'

const CompletedSurvey: FC<IPageProps<Queries.CompletedSurveyQuery>> = () => {
  const { user } = useAuth()
  const { activeGame } = useGame()
  const { t } = useTranslation()
  const location = useLocation()

  const parameters = new URLSearchParams(location?.search)
  const surveyType: string | null = parameters?.get('type')
  const gameId = parameters?.get('gameId')
  const playerId = parameters?.get('playerId')

  const { data: surveyRes, status } = useFindAllPlayerSurveysQuery(
    { playerId: playerId, gameId: gameId },
    { enabled: Boolean(playerId) && Boolean(gameId) },
  )

  const surveys = surveyRes?.findPlayerSurveys

  const { mutateAsync: create } = useCreateSurveyMutation()

  useEffect(() => {
    ;(async () => {
      if (
        (surveyType === SurveyTypes.Closing ||
          surveyType === SurveyTypes.TellUsAboutYou ||
          surveyType === SurveyTypes.SixMonths ||
          surveyType === SurveyTypes.OneYear) &&
        !!playerId &&
        !!gameId
      ) {
        if (status === 'success' && isEmpty(surveys?.find((survey) => survey?.surveyType === surveyType))) {
          try {
            await create({
              input: {
                surveyType,
                playerId,
                gameId,
              },
            })
          } catch (e) {
            console.log(e)
          }
        }
      }
    })()
  }, [status])

  if (status === 'loading') return <Loading />

  return (
    <>
      <SEO title={t('completedSurvey.seoTitle')} />
      <HeadingWrapper veryTall>
        <Spacer space="10rem" />
        <Heading>
          {!!surveyType && !!gameId && !!playerId && status !== 'error'
            ? t('completedSurvey.titleSuccess')
            : t('completedSurvey.titleError')}
        </Heading>
        <LinkButton replace to={activeGame && user?.info.gameId ? `/${activeGame.info.slug}/` : '/'}>
          {t('common.continue') || ''}
        </LinkButton>
      </HeadingWrapper>
    </>
  )
}
export default CompletedSurvey
export const query = graphql`
  query CompletedSurvey($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
  }
`
