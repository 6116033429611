import React, { FC, useEffect } from 'react'
import Wrapper from '../../../../components/Wrapper/Wrapper'
import Button from '../../../../components/Button/Button'
import SEO from '../../../../components/seo'
import { Error, FormItem } from '../../../../components/FormElements/FormElements.styled'
import { HeadingWrapper } from '../../../../components/HeadingWrapper/HeadingWrapper.styled'
import { Heading } from '../../../../components/Heading/Heading.styled'
import { SubHeading } from '../../../../components/SubHeading/SubHeading.styled'
import { ErrorMessage, Formik } from 'formik'
import SelectDropdown from '../../../../components/SelectDropdown/SelectDropdown'
import ContextLink from '../../../../components/ContextLink'
import useNavigate from '../../../../hooks/useNavigate'
import useChild from '../../../../hooks/useChild/useChild'
import Tooltip from '../../../../components/Tooltip/Tooltip'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useManagePlayers } from '../../hooks/useManagePlayers'
import { TeamStatus, useFindAllTeamsQuery } from '../../../../generated/api'
import { IPageProps } from '../../../../contexts/PageContext'
import useGameRestricted from '../../../../hooks/useGameRestricted'
import Loading from '../../../../components/Loading'
import RestrictedPage from '../../../../components/RestrictedPage'

const FamilyJoinTeam: FC<IPageProps<Queries.FamilyJoinTeamQuery>> = () => {
  const { child } = useChild()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { onJoinTeam } = useManagePlayers()
  const { isGameRestricted, isLoading, game } = useGameRestricted()

  useEffect(() => {
    if (!child && !isGameRestricted && !isLoading) {
      navigate(`/`)
    }
  }, [isGameRestricted, isLoading])

  const { data: teamsRes } = useFindAllTeamsQuery(
    { input: { gameId: child?.info.gameId || '' } },
    { enabled: Boolean(child?.info.gameId) },
  )

  if (isLoading) {
    return <Loading />
  }

  if (isGameRestricted) {
    return (
      <RestrictedPage
        title={t('joinTeam.title')}
        text={t('joinTeam.restrictedPage', { days: game?.info.restrictAtDays })}
      />
    )
  }

  const teams = teamsRes?.findTeams

  const approvedTeams = teams
    ?.map((team) => {
      if (team?.status !== TeamStatus.Approved) return
      return {
        value: team.id,
        display: team.name,
      }
    })
    ?.filter((team) => team)

  const childTeam = (child?.id && teams?.find((team) => team.id === child?.info.teamId)) || null

  return (
    <>
      <SEO title={t('signUp.title')} />
      <HeadingWrapper>
        <Heading>{t('joinTeam.title')}</Heading>
        <SubHeading as="h2" secondaryForm veryTall>
          {child?.info.teamId
            ? t('joinTeam.subTitle.haveTeam', { teamName: childTeam?.name || 'Team' })
            : t('joinTeam.subTitle.noTeam')}
        </SubHeading>
      </HeadingWrapper>
      <Wrapper width="400px" textAlign="left">
        {child?.info.teamId ? (
          <ContextLink to="/signup/family/success">
            <Button type="submit" text={t('common.continue')} secondary />
          </ContextLink>
        ) : (
          <Formik
            enableReinitialize
            initialValues={{
              teamId: '',
              submit: null,
            }}
            onSubmit={async (values, { setErrors }) => {
              try {
                if (values.teamId && child?.id && values.teamId !== t('joinTeam.input.placeholder')) {
                  await onJoinTeam({
                    playerId: child?.id,
                    teamId: values.teamId,
                  })
                }
                await navigate(`/signup/family/success`, { replace: true })
              } catch (e) {
                setErrors({ submit: t('errors.joinTeam') || undefined })
              }
            }}
          >
            {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
              return (
                <form onSubmit={handleSubmit} noValidate>
                  <fieldset>
                    <legend>
                      {t('joinTeam.input.label')} <Tooltip text={t('joinTeam.input.tooltip') || ''} />
                    </legend>
                    <FormItem>
                      <SelectDropdown
                        id={'teamId'}
                        options={approvedTeams}
                        placeholder={t('joinTeam.input.placeholder') || ''}
                        value={values.teamId}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        secondary
                      />
                    </FormItem>
                  </fieldset>

                  <ErrorMessage name="teamId" component={(props) => <Error className="checkboxError" {...props} />} />
                  <ErrorMessage name="submit" component={(props) => <Error className="checkboxError" {...props} />} />
                  <Button
                    type="submit"
                    isSubmitting={isSubmitting}
                    disabled={isSubmitting}
                    text={t('common.continue')}
                    secondary
                  />
                </form>
              )
            }}
          </Formik>
        )}
      </Wrapper>
    </>
  )
}

export const query = graphql`
  query FamilyJoinTeam($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
  }
`

export default FamilyJoinTeam
