import React, { FC } from 'react'
import Modal from '../../../components/Modal/Modal'
import { modalSmall } from '../../../components/Modal/Modal.styled'
import { Wrapper } from './AddFollowPlayerModal'
import Button from '../../../components/Button/Button'
import theme from '../../../styles/theme'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useDeleteFollowMutation, useFindFollowedQuery } from '../../../generated/api'
import useAuth from '../../../contexts/AuthContext/useAuth'
import { useQueryClient } from '@tanstack/react-query'

interface OptionsModalProps {
  setSelectedPlayerModal: (value?: unknown) => void
  selectedPlayerModal?: string
  children?: string
}

const OptionsModal: FC<OptionsModalProps> = ({ selectedPlayerModal, setSelectedPlayerModal }) => {
  const { user } = useAuth()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { data: followingRes } = useFindFollowedQuery(
    {
      input: {
        gameId: user?.info.gameId as string,
        playerId: user?.id as string,
      },
    },
    {
      enabled: !!(user?.info.gameId && user?.id),
    },
  )

  const followed = followingRes?.findFollowed || []

  const followId = followed.find((follow) => follow === selectedPlayerModal)

  const { mutateAsync: deleteFollowMutation } = useDeleteFollowMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['GetLeaderboardFollow'] }),
  })

  const onUnfollow = async () => {
    try {
      if (followId && user?.id && user?.info.gameId) {
        await deleteFollowMutation({ input: { gameId: user?.info.gameId, followId, playerId: user?.id } })
      }
    } catch (e) {
      console.log('Error unfollow', e)
    }
    await setSelectedPlayerModal(undefined)
  }

  return (
    <Modal
      modalOpen={true}
      closeModal={() => setSelectedPlayerModal(undefined)}
      ariaLabel={t('game.leaderboards.unfollowModal.unfollow') || ''}
      modalType={modalSmall}
      overlayClassName="is-narrow is-tap-list"
      heading={t('game.leaderboards.unfollowModal.unfollow') || ''}
      customStyle={{ height: 'auto', minHeight: 'auto' }}
    >
      <Wrapper style={{ paddingBottom: '2rem' }}>
        <p>{t('game.leaderboards.unfollowModal.text')}</p>

        <Button
          text={t('game.leaderboards.unfollowModal.unfollow')}
          style={{ marginBottom: 16 }}
          onClick={onUnfollow}
        />
        <Button
          text={t('common.cancel')}
          onClick={() => setSelectedPlayerModal(undefined)}
          style={{
            background: 'transparent',
            border: `2px solid ${theme.colors.primary}`,
            color: theme.colors.primary,
          }}
        />
      </Wrapper>
    </Modal>
  )
}

export default OptionsModal
