import React, { createContext, FC, PropsWithChildren, useState } from 'react'
import { User } from './AuthContext/type'
import { useGetChildQuery } from '../generated/api'

export type ChildContextType = {
  setChildId: React.Dispatch<React.SetStateAction<string>>
  child?: User
  childId?: string
  isLoading: boolean
}

const initState = {
  setChildId: () => ({}),
  isLoading: false,
}

const ChildContext = createContext<ChildContextType>(initState)

//todo
export const ChildProvider: FC<PropsWithChildren> = ({ children }) => {
  const [childId, setChildId] = useState<string | undefined>()

  const { data: childRes, isLoading } = useGetChildQuery({ id: childId || '' }, { enabled: Boolean(childId) })

  return (
    <ChildContext.Provider value={{ isLoading: isLoading, setChildId, child: childRes?.getPlayer, childId }}>
      {children}
    </ChildContext.Provider>
  )
}

export default ChildContext
