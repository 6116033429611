import React, { FC, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { prop } from 'styled-tools'
import lottie, { AnimationConfigWithData, AnimationConfigWithPath } from 'lottie-web/build/player/lottie_light'

const Wrapper = styled.div`
  overflow: visible;
  text-align: center;
  > * {
    width: ${prop('width', '70%')};
    height: ${prop('height', 'auto')};
    margin: -86px auto 0;
  }
`

interface LottieAnimationProps {
  animationData?: any
  loop?: boolean
  autoplay?: boolean
  segments?: boolean
  width?: number | string
  height?: number | string
}

const LottieAnimation: FC<LottieAnimationProps> = ({
  animationData,
  loop = true,
  autoplay = true,
  segments = false,
  width,
  height,
}) => {
  const targetRef = useRef<any>()

  useEffect(() => {
    const options = {
      animationData: animationData.default,
      container: targetRef.current,
      renderer: 'svg',
      loop,
      autoplay,
      segments,
    } as AnimationConfigWithPath | AnimationConfigWithData
    const anim = lottie.loadAnimation(options)
    return () => {
      anim.destroy()
    }
  }, [])

  return (
    <Wrapper {...{ width, height }}>
      <div ref={targetRef} />
    </Wrapper>
  )
}

export default LottieAnimation
