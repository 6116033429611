import React from 'react'
import { StyledMenuItem } from './Menu.styled'

interface MenuItemProps {
  label: string
  icon?: JSX.Element
  onClick?: () => void
}

const MenuItem = ({ label, icon, onClick }: MenuItemProps) => {
  return (
    <StyledMenuItem onMouseDown={onClick}>
      {icon && <span style={{ marginRight: '1rem' }}>{icon}</span>}
      <span style={{ fontSize: 14 }}>{label}</span>
    </StyledMenuItem>
  )
}

export default MenuItem
