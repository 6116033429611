import styled from 'styled-components'
import { darken } from 'polished'
import device from '../../styles/device'

// need to keep this format for custom modal styles
export const modalVideo = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '0',
    borderRadius: '0.4rem',
    padding: '0',
    width: '90%',
    height: '90%',
    overflow: 'visible',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.50)',
    zIndex: '21',
    position: 'fixed',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
  },
}

export const modalSmall = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '0',
    boxShadow: 'rgba(0, 0, 0, 0.22) 0px 25.6px 57.6px 0px, rgba(0, 0, 0, 0.18) 0px 4.8px 14.4px 0px',
    background: 'white',
    borderRadius: '0.8rem',
    padding: '2rem',
    width: '90%',
    minWidth: '260px',
    maxWidth: '500px',
    height: '90%',
    minHeight: '530px',
    overflow: 'hidden',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.40)',
    zIndex: '21',
    position: 'fixed',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
  },
}

export const verySmallModal = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '0',
    boxShadow: 'rgba(0, 0, 0, 0.22) 0px 25.6px 57.6px 0px, rgba(0, 0, 0, 0.18) 0px 4.8px 14.4px 0px',
    background: 'white',
    borderRadius: '0.8rem',
    padding: '2rem',
    width: '90%',
    minWidth: '260px',
    maxWidth: '400px',
    minHeight: '100px',
    overflow: 'hidden',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.40)',
    zIndex: '21',
    position: 'fixed',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
  },
}

export const wideSmallModal = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '0',
    boxShadow: 'rgba(0, 0, 0, 0.22) 0px 25.6px 57.6px 0px, rgba(0, 0, 0, 0.18) 0px 4.8px 14.4px 0px',
    background: 'white',
    borderRadius: '0.8rem',
    padding: '2rem',
    minWidth: '260px',
    maxWidth: '600px',
    minHeight: '100px',
    overflow: 'hidden',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.40)',
    zIndex: '21',
    position: 'fixed',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
  },
}
export const modalBig = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '0',
    'box-shadow': 'rgba(0, 0, 0, 0.15) 0px 2px 6px 0px',
    background: 'white',
    borderRadius: '0.4rem',
    padding: '2rem 0 1rem 0',
    width: '90%',
    maxWidth: '850px',
    height: '90%',
    overflow: 'hidden',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.60)',
    zIndex: '21',
    position: 'fixed',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
  },
}

export const modalBigScroll = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '0',
    'box-shadow': 'rgba(0, 0, 0, 0.15) 0px 2px 6px 0px',
    background: 'white',
    borderRadius: '0.4rem',
    padding: '2rem 0 1rem 0',
    width: '90%',
    maxWidth: '850px',
    height: '90%',
    overflow: 'scroll',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.60)',
    zIndex: '21',
    position: 'fixed',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
  },
}

export const CloseButton = styled.button`
  margin: 0;
  background-color: white;
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  z-index: ${(props) => props.theme.zIndex.modalCloseButton};
  padding: 0.6rem;
  margin-top: 12px;
  margin-right: 10px;
  .is-video & {
    margin: 0;
    top: -1rem;
    right: 0;
    padding: 0.4rem;
  }

  ${(props) => props.theme.hover} {
    background-color: ${(props) => darken(0.1, props.theme.colors.white)};
  }

  svg {
    width: 14px;
    height: 14px;
    vertical-align: middle;

    path {
      fill: ${(props) => props.theme.colors.body};
    }
    .is-video & {
      padding: 1px;
      width: 12px;
      height: 12px;

      @media ${device.mobLandscape} {
        padding: 4px;
        width: 20px;
        height: 20px;
      }
    }
  }
`

export const ModalHeading = styled.h2`
  padding: 1rem 1.5rem;
  margin-top: 2rem;

  .is-tap-list & {
    padding: 2rem 1.5rem 1rem 2.4rem;
    font-size: 2.6rem;
    @media ${device.mobLandscape} {
      font-size: 3rem;
    }
  }
`
