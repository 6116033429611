import React from 'react'
import Profile from '../../assets/icons/Profile'
import styled from 'styled-components'
import theme from '../../styles/theme'

const BackgroundContainer = styled.div`
  width: 60px;
  height: 60px;
  background-color: #f2f8f2;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  position: relative;
  ${({ isActive }) => (isActive ? `border: 3px solid ${theme.colors.primary}` : '')}
`

interface Props {
  isActive: boolean
}

const FamilyMember = ({ isActive }: Props) => {
  return (
    <BackgroundContainer isActive={isActive}>
      <Profile width={35} height={35} isActive={isActive} />
    </BackgroundContainer>
  )
}

export default FamilyMember
