import Wrapper from '../../../components/Wrapper/Wrapper'
import {
  ButtonsWraper,
  ContentWrapper,
  CTAButtonExtra,
  GetHelpButton,
  GreyWrapper,
  SectionWrapper,
  TertiaryButton,
  TextWrapper,
} from '../get-started.styled'
import { Heading } from '../../../components/Heading/Heading.styled'
import React from 'react'

const ReadyToPlaySection = ({ handleOpenHelpDesk, scroll, link }) => {
  return (
    <GreyWrapper>
      <ContentWrapper>
        <Wrapper>
          <SectionWrapper style={{ flexDirection: 'column' }}>
            <TextWrapper>
              <Heading as="h2" style={{ marginBottom: '1rem' }} textAlign="center">
                Ready to play?
              </Heading>
            </TextWrapper>
            <ButtonsWraper style={{ justifyContent: 'center' }}>
              <CTAButtonExtra to={link}>Play Now</CTAButtonExtra>
              <TertiaryButton onClick={scroll}>How to play</TertiaryButton>
            </ButtonsWraper>
            <TextWrapper>
              <p className="big">
                Need help? <GetHelpButton onClick={handleOpenHelpDesk}>Get help</GetHelpButton>
              </p>
            </TextWrapper>
          </SectionWrapper>
        </Wrapper>
      </ContentWrapper>
    </GreyWrapper>
  )
}

export default ReadyToPlaySection
