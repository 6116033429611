import { RefObject, useEffect } from 'react'
import maplibregl from 'maplibre-gl'
import { MapRef } from 'react-map-gl'

interface Props {
  mapRef: RefObject<MapRef>
  isMapLoaded: boolean
}

const useMapControl = ({ mapRef, isMapLoaded }: Props) => {
  useEffect(() => {
    if (!isMapLoaded) return

    mapRef.current?.addControl(new maplibregl.ScaleControl({ unit: 'imperial' }) as any, 'bottom-right')
    mapRef.current?.addControl(new maplibregl.NavigationControl({}) as any, 'bottom-right')
    mapRef.current?.addControl(
      new maplibregl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
      }) as any,
      'bottom-right',
    )
  }, [mapRef, isMapLoaded])
}

export default useMapControl
