import React, { FC } from 'react'
import styled from 'styled-components'
import LinkButton from '../LinkButton/LinkButton'

const Wrapper = styled.div`
  h2,
  p {
    font-size: 18px;
    font-family: ${(props) => props.theme.fonts.primaryFont};
    color: ${(props) => props.theme.colors.body};
    margin: 5px auto;
    display: inline;
  }
  p {
    color: ${(props) => props.theme.colors.secondary};
    margin: 5px auto 22px;
  }
  a {
    display: block;
    margin-top: 22px;
  }
`

interface AnchorProps {
  target: string
  rel: string
}

interface TextWithCTAProps {
  title: string
  text: string
  linkUrl: string
  external: boolean
  buttonText: string | JSX.Element | JSX.Element[]
  openInNewWindow: boolean
}

const TextWithCTA: FC<TextWithCTAProps> = ({ title, text, linkUrl, external, buttonText, openInNewWindow = true }) => {
  const anchorProps = {} as AnchorProps
  if (openInNewWindow) {
    anchorProps.target = '_blank'
    anchorProps.rel = 'noopener noreferrer'
  } else {
    anchorProps.target = '_self'
  }

  return (
    <Wrapper>
      <h2>{title}</h2>
      <p>{text}</p>
      <LinkButton to={linkUrl} $secondary external={external} {...anchorProps}>
        {buttonText}
      </LinkButton>
    </Wrapper>
  )
}

export default TextWithCTA
