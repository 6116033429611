import styled from 'styled-components'
import { darken } from 'polished'
import device from '../../../styles/device'
import Modal from 'react-modal'

export const modalSmall = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.70)',
    zIndex: '21',
    position: 'fixed',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
  },
}

export const CloseButton = styled.button`
  margin: 0;
  background-color: ${(props) => props.theme.colors.white};
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  z-index: ${(props) => props.theme.zIndex.modalCloseButton};
  padding: 0.6rem;
  margin-top: 12px;
  margin-right: 10px;

  & :hover,
  :focus,
  :active {
    background-color: ${(props) => props.theme.colors.white};
  }
  .is-video & {
    margin: 0;
    top: -1rem;
    right: 0;
    padding: 0.4rem;
  }

  ${(props) => props.theme.hover} {
    background-color: ${(props) => darken(0.1, props.theme.colors.white)};
  }

  svg {
    width: 14px;
    height: 14px;
    vertical-align: middle;

    path {
      fill: ${(props) => props.theme.colors.body};
    }
    .is-video & {
      padding: 1px;
      width: 12px;
      height: 12px;

      @media ${device.mobLandscape} {
        padding: 4px;
        width: 20px;
        height: 20px;
      }
    }
  }
`

export const ModalHeading = styled.h2`
  padding: 1rem 1.5rem;
  margin-top: 2rem;

  .is-tap-list & {
    padding: 2rem 1.5rem 1rem 2.4rem;
    font-size: 2.6rem;
    @media ${device.mobLandscape} {
      font-size: 3rem;
    }
  }
`

export const StyledModal = styled(Modal)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
  border: 0;
  box-shadow:
    rgba(0, 0, 0, 0.22) 0px 25.6px 57.6px 0px,
    rgba(0, 0, 0, 0.18) 0px 4.8px 14.4px 0px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 0.8rem;
  padding: 2rem;
  height: 80%;
  width: 90%;
  max-width: 400px;
  overflow: hidden;

  @media ${device.tabletPortrait} {
    height: 80%;
    max-height: 550px;
    max-width: 500px;
  }
`
