import device from '../../../../styles/device'
import { prop } from 'styled-tools'
import styled from 'styled-components'

export const EventsHeading = styled.h1`
  text-align: center;
  font-size: 30px;
  margin: -20px 30px 40px 30px;
  @media ${device.tabletPortrait} {
    font-size: 50px;
    margin-bottom: 50px;
  }
`

export const EventsWrapper = styled('div')`
  background-color: ${prop('backgroundColor')};
  display: flex;
  justify-content: center;
  > div {
    max-width: 1200px;
    min-height: 550px;
    margin: -120px auto 0;
    padding-bottom: 50px;

    @media ${device.tabletPortrait} {
      margin-top: -50px;
      transform: translateY(-120px);
      padding-bottom: 0px;
    }
  }
  h2 {
    font-size: 2.2rem;
  }
  position: relative;
  margin: 150px auto;
  @media ${device.mobLandscape} {
    margin: 150px auto;
  }
  @media ${device.tabletPortrait} {
    margin: 200px auto;
  }

  > svg {
    position: absolute;
    z-index: -1;
    top: -50px;
    left: -13%;
    width: 126%;
    @media ${device.tabletPortrait} {
      top: -80px;
      left: 0;
      width: 100%;
    }
  }
`

export const FilterWrapper = styled('div')`
  margin: 2rem auto 0;
  margin-top: 2rem;

  @media ${device.tabletPortrait} {
    margin-top: 4rem;
    button {
      font-size: 15px;
    }
  }
`
