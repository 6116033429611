import { graphql, useStaticQuery } from 'gatsby'

const useMapIcons = () => {
  const mapIconsQuery = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "png/map-icons" }, extension: { eq: "png" } }) {
        edges {
          node {
            id
            name
            publicURL
          }
        }
      }
    }
  `)

  const icons = mapIconsQuery.allFile.edges.map(({ node }) => node) || []

  // This function should only be passed to "onLoad" event.
  const registerIconsOnLoad = (map: mapboxgl.MapStyleDataEvent) => {
    for (const icon of icons) {
      map.target.loadImage(icon.publicURL, (error, image) => {
        if (!image) return
        map.target.addImage(icon.name, image, { sdf: false, pixelRatio: 2 })
      })
    }
  }

  return {
    mapIcons: icons,
    registerIconsOnLoad,
  }
}

export default useMapIcons
