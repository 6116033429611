import React, { FC, useState } from 'react'
import Modal from '../Modal/Modal'
import { modalBig } from '../Modal/Modal.styled'
import TermsAndConditions from '../../containers/TermsAndConditions'
import PrivacyPolicy from '../../containers/PrivacyPolicy'
import { ContextProps } from '../CallToAction/CallToActionPanel'
import { Links } from '../ContextLink'

interface OverlayLinkProps {
  link: Links | string
  children: JSX.Element | JSX.Element[]
  context: ContextProps
}

const OverlayLink: FC<OverlayLinkProps> = ({ link, children, context }) => {
  const [modalOpen, setModalOpen] = useState(false)
  return (
    <>
      <Modal
        modalOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
        modalType={modalBig}
        customStyle={{
          overflowY: 'scroll',
          padding: '30px 50px 20px 40px',
        }}
      >
        {link === '/terms-and-conditions' ? (
          <TermsAndConditions context={{ ...context, openLinksInNewTab: true }} />
        ) : (
          <></>
        )}
        {link === '/privacy' ? <PrivacyPolicy context={{ ...context, openLinksInNewTab: true }} /> : <></>}
      </Modal>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={link}
        onClick={(event) => {
          // This is so a checkbox is not getting checked if this
          // link is inside a label, e.g. on the signup page.
          event.preventDefault()
          setModalOpen(true)
        }}
      >
        {children}
      </a>
    </>
  )
}

export default OverlayLink
