import React, { FC } from 'react'
import { format } from 'date-fns'
import styled from 'styled-components'
import device from '../../styles/device'
import { Heading } from '../Heading/Heading.styled'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const PlayTheGameWrapper = styled.div`
  text-align: center;
  h2 {
    margin-bottom: 2px;
    font-size: 25px;
  }
  h3 {
    font-size: 20px;
  }
  @media ${device.tabletPortrait} {
    h2,
    h3 {
      display: inline-block;
      font-size: 30px;
    }
  }
`

interface PlayTheGameProps {
  gameStartDate: Date | string
  gameEndDate: Date | string
}

const PlayTheGame: FC<PlayTheGameProps> = ({ gameStartDate, gameEndDate }) => {
  const { t } = useTranslation()
  const startDate = typeof gameStartDate === 'string' ? new Date(gameStartDate) : gameStartDate
  const endDate = typeof gameEndDate === 'string' ? new Date(gameEndDate) : gameEndDate
  const isSameYear: boolean = startDate.getFullYear() === endDate.getFullYear()
  const startDateDisplay = format(startDate, `d MMM ${isSameYear === false ? 'yyyy' : ''}`)
  const endDateDisplay = format(endDate, 'd MMM yyyy')
  return (
    <PlayTheGameWrapper>
      <Heading as="h2">{t('components.playTheGame.title')}</Heading>
      <Heading as="h3" secondary>
        {startDateDisplay} - {endDateDisplay}
      </Heading>
    </PlayTheGameWrapper>
  )
}

export default PlayTheGame
