import { number, object, string } from 'yup'
import * as Yup from 'yup'
import { TagTypes } from '../../../../generated/api'

export const getConnectCardSchema = (t) => {
  const t2 = (...args: any) => t(...args) || undefined
  return object({
    tagNumber: number()
      .required(t('gameSetup.connectCard.errors.noCardNumber'))
      .min(1, t('gameSetup.connectCard.errors.wrongCardNumber'))
      .max(99999999, t('gameSetup.connectCard.errors.wrongCardNumber')),
    tagType: string().required(t('gameSetup.connectCard.errors.tagType')),
    code: string()
      .when('tagType', {
        is: TagTypes.Card,
        then: Yup.string().required(t('gameSetup.connectCard.errors.code')),
      })
      .nullable(),
  })
}
