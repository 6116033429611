import React, { FC } from 'react'
import { graphql } from 'gatsby'
import 'mapbox-gl/src/css/mapbox-gl.css'
import useWindowSize from '../../../hooks/useWindowSize'
import Wrapper from '../../../components/Wrapper/Wrapper'
import Loading from '../../../components/Loading'
import { Heading } from '../../../components/Heading/Heading.styled'
import SEO from '../../../components/seo'
import Mapbox from './Mapbox/Mapbox'
import Spacer from '../../../components/Spacer'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import useGame from '../../../contexts/GameContext/useGame'
import MapProvider from './Mapbox/Context'
import { useFindLocationsQuery } from '../../../generated/api'
import { IPageProps } from 'src/contexts/PageContext'

const { GATSBY_SHOW_TEMPORARY_MAP } = process.env

const MapPage: FC<IPageProps<Queries.MapPageQuery>> = ({ data: sanityData }) => {
  const { game } = useGame()
  const { isError, isLoading, isFetched, data } = useFindLocationsQuery(
    {
      gameId: game?.id,
    },
    {
      enabled: !!game?.id,
    },
  )
  const { t } = useTranslation()
  const { height } = useWindowSize()

  if (GATSBY_SHOW_TEMPORARY_MAP === 'true') {
    return (
      <>
        <SEO title={t('game.map.seoTitle', { gameName: game?.info.name })} />
        <h1 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          {t('game.map.title', { gameName: game?.info.name })}
        </h1>
        <Spacer space="5rem" />
        <iframe
          title="Google Maps"
          src="https://www.google.com/maps/d/u/2/embed?mid=1Xeta8xqckEASOxHXGLMv0UKlBhMrg9Da"
          width="100%"
          style={{
            height: (height * 2) / 3,
          }}
        />
      </>
    )
  }

  if (isLoading) {
    return (
      <>
        <SEO title={t('game.map.seoTitle', { gameName: game?.info.name })} />
        <h1 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
          {t('game.map.title', { gameName: game?.info.name })}
        </h1>
        <Wrapper width="500px">
          <Loading />
        </Wrapper>
      </>
    )
  }

  if (!data?.findLocations.length && isFetched) {
    return (
      <>
        <SEO title={t('game.map.seoTitle', { gameName: game?.info.name })} />
        <Wrapper
          width="500px"
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}
        >
          <Heading tertiary>{t('game.map.underConstruction', { gameName: game?.info.name })}</Heading>
        </Wrapper>
      </>
    )
  }

  if (isError) {
    return (
      <>
        <SEO title={t('game.map.seoTitle', { gameName: game?.info.name })} />
        <Wrapper
          width="500px"
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}
        >
          <Heading tertiary>{t('game.map.error')}</Heading>
        </Wrapper>
      </>
    )
  }

  return (
    <>
      <SEO title={t('game.map.seoTitle', { gameName: game?.info.name })} />
      <h1 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
        {t('game.map.title', { gameName: game?.info.name })}
      </h1>
      <Wrapper>
        <MapProvider>
          <Mapbox reportEmail={sanityData?.sanityGame?.emailAddress} ctaData={sanityData?.sanityGame?.ctaForMap} />
        </MapProvider>
      </Wrapper>
    </>
  )
}

export const query = graphql`
  query MapPage($gameSlug: String, $language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
    sanityGame(slug: { eq: $gameSlug }, i18n_lang: { eq: $language }) {
      ...gameDetails
      slug
      ctaForMap {
        ...cta
      }
    }
  }
`

export default MapPage
