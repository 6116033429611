import React from 'react'
import { StyledWrapper } from './StyledWrapper'
import theme from '../../styles/theme'

interface Props {
  id: string
  name?: string
  text?: string
  icon?: React.ReactFragment
  button?: boolean
  required?: boolean
  disabled?: boolean
  hasError?: boolean
  value: string | number
  rows?: number
  cols?: number
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: React.ChangeEventHandler<HTMLInputElement>
  error?: string
  maxlength?: number
  characterCounter?: JSX.Element
  placeholder?: string
  autoComplete?: string
  className?: string
  onInvalid?: React.ChangeEventHandler<HTMLInputElement>
  style?: any
  textAreaProps?: any
}

const TextArea = ({
  id,
  name,
  style,
  text,
  textAreaProps,
  disabled,
  required,
  value,
  onBlur,
  onInvalid,
  onChange,
  maxlength,
  placeholder,
  autoComplete,
  characterCounter,
  error,
  rows,
}: Props) => {
  return (
    <StyledWrapper>
      <textarea
        id={id}
        name={name}
        rows={rows}
        style={error ? { borderColor: theme.colors.error } : style}
        disabled={disabled}
        required={required}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onInvalid={onInvalid}
        placeholder={placeholder}
        maxLength={maxlength}
        autoComplete={autoComplete}
        {...textAreaProps}
      />
      <div style={{ position: 'absolute', right: 0 }}>{characterCounter}</div>
      {error && <p style={{ color: theme.colors.error, margin: 0 }}>{error}</p>}
      {text && <label htmlFor={id}>{text}</label>}
    </StyledWrapper>
  )
}

export default TextArea
