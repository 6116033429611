import styled from 'styled-components'
import { ifProp, prop } from 'styled-tools'
import device from '../../styles/device'

export const Girl = styled.div`
  position: absolute;
  top: 350px;
  left: 50px;
  height: 275px;
  width: 97px;
  background-size: 100% 100%;
  background-image: url(${prop('filename')});
  display: none;
  @media ${device.tabletLandscape} {
    display: block;
  }
  @media ${device.desktopWide} {
    left: 200px;
  }
`

export const Group = styled(Girl)`
  height: 400px;
  width: 370px;
  top: 300px;
  left: auto;
  right: 15px;
  display: none;
  @media ${device.tabletPortrait} {
    height: 400px;
    width: 370px;
    top: 300px;
    left: auto;
    right: 15px;
    display: block;
  }
  @media ${device.tabletLandscape} {
    height: 500px;
    width: 500px;
    top: 280px;
    left: auto;
    right: 15px;
  }
  @media ${device.desktop} {
    height: 600px;
    width: 600px;
    top: 190px;
    left: auto;
    right: 15px;
    display: block;
  }
  @media (min-width: 1600px) {
    right: calc((100% - 1600px) / 2);
  }
`

export const Boy = styled(Girl)`
  height: 190px;
  width: 108px;
  top: 435px;
  left: auto;
  right: 50px;
  @media ${device.tabletLandscape} {
    left: auto;
  }
  @media ${device.desktopWide} {
    right: 200px;
  }
`

export const Background = styled.div`
  position: absolute;
  display: ${ifProp('hidden', 'none', 'block')};
  background-color: #fff;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  // background-size: 100% calc(705px + ${(props) => (props.isMessage ? '104px' : '0px')});
  background-size: 100% calc(705px + ${(props) => (props.isBluePartBigger ? '280px' : '0px')});
  background-position: 0 ${prop('offsetMobile')}px;
  @media ${device.mobPortrait} {
    background-size: 100% calc(705px + ${(props) => (props.isBluePartBigger ? '120px' : '0px')});
  }
  @media ${device.mobLandscape} {
    // background-size: 100% calc(730px + ${(props) => (props.isMessage ? '104px' : '0px')});
    background-size: 100% calc(705px + ${(props) => (props.isBluePartBigger ? '200px' : '0px')});
    background-position: 0 ${prop('offsetmobLandscape')}px;
  }
  @media ${device.tabletPortrait} {
    // background-size: 100% calc(760px + ${(props) => (props.isMessage ? '150px' : '50px')});
    background-size: 100% calc(760px + ${(props) => (props.isBluePartBigger ? '250px' : '50px')});
    background-position: 0 ${prop('offsetTabletPortrait')}px;
  }
  width: 100%;
  background-image: url(${prop('filename')});
  @media ${device.tabletLandscape} {
    background-image: url(${prop('filenameDesktop')});
    // background-size: 100% calc(2010px + ${(props) => (props.isMessage ? '300px' : '50px')});
    background-size: 100% calc(2010px + ${(props) => (props.isBluePartBigger ? '400px' : '50px')});
    background-position: 0 ${prop('offset')}px;
  }
  background-repeat: no-repeat;
`
