import React from 'react'
import theme from '../../styles/theme'

interface Props {
  isActive?: boolean
  width?: number
  height?: number
}

const Profile = ({ isActive, width = 24, height = 24 }: Props) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        d="M5 19.1115C5 16.6984 6.69732 14.643 9.00404 14.2627L9.21182 14.2284C11.0589 13.9239 12.9411 13.9239 14.7882 14.2284L14.996 14.2627C17.3027 14.643 19 16.6984 19 19.1115C19 20.1545 18.1815 21 17.1719 21H6.82813C5.81848 21 5 20.1545 5 19.1115Z"
        fill={isActive ? theme.colors.primary : '#000000'}
        fillOpacity="0.15"
      />
      <path
        d="M16.0834 6.9375C16.0834 9.11212 14.2552 10.875 12 10.875C9.74486 10.875 7.91669 9.11212 7.91669 6.9375C7.91669 4.76288 9.74486 3 12 3C14.2552 3 16.0834 4.76288 16.0834 6.9375Z"
        fill={isActive ? theme.colors.primary : '#000000'}
        fillOpacity="0.15"
      />
      <path
        d="M5 19.1115C5 16.6984 6.69732 14.643 9.00404 14.2627L9.21182 14.2284C11.0589 13.9239 12.9411 13.9239 14.7882 14.2284L14.996 14.2627C17.3027 14.643 19 16.6984 19 19.1115C19 20.1545 18.1815 21 17.1719 21H6.82813C5.81848 21 5 20.1545 5 19.1115Z"
        stroke={isActive ? theme.colors.primary : theme.colors.midDarkGrey}
        strokeWidth="2"
        fillOpacity={isActive ? '1' : '0.15'}
      />
      <path
        d="M16.0834 6.9375C16.0834 9.11212 14.2552 10.875 12 10.875C9.74486 10.875 7.91669 9.11212 7.91669 6.9375C7.91669 4.76288 9.74486 3 12 3C14.2552 3 16.0834 4.76288 16.0834 6.9375Z"
        stroke={isActive ? theme.colors.primary : theme.colors.midDarkGrey}
        strokeWidth="2"
        fillOpacity={isActive ? '1' : '0.15'}
      />
    </svg>
  )
}

export default Profile
