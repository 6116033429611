import React, { useEffect } from 'react'
import localCache from './cacheUser'
import fetchPlayer from './fetchPlayer'
import { Action } from './type'

const apziEnv = process.env.GATSBY_ENV

const useInitialize = (dispatch: React.Dispatch<Action>) => {
  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        // initialize from cache
        const prevUser = localCache.getUser()
        const parent = localCache.getParent()
        if (prevUser) {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: prevUser,
              masterUser: parent,
              family: [],
            },
          })
        }
        const { user, family, masterUser } = await fetchPlayer()

        localCache.setUser(user)
        localCache.setParent(masterUser)

        if (typeof window !== 'undefined') {
          window.appziSettings = {
            data: {
              playerId: masterUser?.id,
              env: apziEnv,
            },
          }
          window.dataLayer.push({
            user_id: masterUser?.id,
          })
        }

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: user,
            masterUser: masterUser,
            family,
          },
        })
      } catch (error) {
        console.error(error)
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: undefined,
            family: [],
          },
        })
      }
    }

    initialize()
  }, [])
}

export default useInitialize
