import styled from 'styled-components'
import device from '../../styles/device'

export const MainContainer = styled.div`
  margin: 0 auto;

  @media ${device.tabletLandscape} {
    margin: -80px auto 0 auto;
    ${(props) =>
      props.positiveMargin &&
      `
        margin: 0 auto;
       `};
  }
`

export const OuterContainer = styled.main`
  position: relative;
  height: 100vh;
`
