import React, { FC } from 'react'
import { StyledToggle, ToggleButton } from './Toggle.styled'

type ToggleButtonProps = {
  value: string
  text?: string
}

interface ToggleProps {
  left: ToggleButtonProps
  right: ToggleButtonProps
  onChange: (value: string) => void
  value: string
}

const Toggle: FC<ToggleProps> = ({ left, right, onChange, value }) => {
  return (
    <StyledToggle>
      <ToggleButton toggle={value === left.value} onClick={() => onChange(left.value)}>
        {left.text || left.value}
      </ToggleButton>
      <ToggleButton toggle={value === right.value} onClick={() => onChange(right.value)}>
        {right.text || left.value}
      </ToggleButton>
    </StyledToggle>
  )
}

export default Toggle
