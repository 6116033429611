import React from 'react'
import { ErrorMessage, Formik } from 'formik'
import Input from '../../../../components/Input/Input'
import { Error, FormItem } from '../../../../components/FormElements/FormElements.styled'
import Button from '../../../../components/Button/Button'
import useAuth from '../../../../contexts/AuthContext/useAuth'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { getChangePasswordSchema } from './validation'

const ChangePasswordForm = ({ toastmessage }) => {
  const { changePassword } = useAuth()
  const { t } = useTranslation()
  const changePasswordSchema = getChangePasswordSchema(t)

  return (
    <Formik
      initialValues={{
        currentPassword: '',
        password: '',
        confirmPassword: '',
        submit: null,
      }}
      validationSchema={changePasswordSchema}
      onSubmit={async (
        { currentPassword, password },
        { setErrors, setStatus, setSubmitting, resetForm },
      ): Promise<void> => {
        try {
          await changePassword(currentPassword, password)
          resetForm()
          setStatus({ success: true })
          setSubmitting(false)
          toastmessage()
        } catch (error) {
          console.error(error)
          setStatus({ success: false })
          if (error.code === 'NotAuthorizedException') {
            setErrors({ submit: t('errors.cognito.changePassword.currentPassword') || undefined })
          } else {
            setErrors({ submit: t('errors.generic') || undefined })
          }
          setSubmitting(false)
        }
      }}
    >
      {({ values, errors, touched, handleChange, handleSubmit, isSubmitting, handleBlur }) => (
        <>
          <form onSubmit={handleSubmit} noValidate>
            <FormItem>
              <Input
                id="currentPassword"
                type="password"
                autoComplete="current-password"
                text={t('manageAccount.passwordChange.inputs.currentPassword') || ''}
                maxlength={50}
                value={values.currentPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                hasError={Boolean(touched.currentPassword && errors.currentPassword)}
                error={touched.currentPassword ? errors.currentPassword : undefined}
              />
            </FormItem>
            <FormItem>
              <Input
                id="password"
                type="password"
                autoComplete="new-password"
                text={t('common.password') || ''}
                maxlength={50}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                hasPassToggle
                hasError={Boolean(touched.password && errors.password)}
                error={touched.password ? errors.password : undefined}
              />
            </FormItem>
            <FormItem>
              <Input
                id="confirmPassword"
                type="password"
                autoComplete="new-password"
                text={t('manageAccount.passwordChange.inputs.confirmPassword') || ''}
                maxlength={50}
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                hasError={Boolean(touched.confirmPassword && errors.confirmPassword)}
                error={touched.confirmPassword ? errors.confirmPassword : undefined}
              />
            </FormItem>
            <ErrorMessage name="submit" component={(props) => <Error className="checkboxError" {...props} />} />
            <Button
              isSubmitting={isSubmitting}
              disabled={isSubmitting}
              text={t('manageAccount.passwordChange.buttonText')}
              secondary
              type="submit"
            />
          </form>
        </>
      )}
    </Formik>
  )
}

export default ChangePasswordForm
