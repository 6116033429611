import styled from 'styled-components'
import device from '../../styles/device'
import { withProp } from 'styled-tools'

export const StyledRowItem = styled.div`
  border-bottom: 1px dashed ${(props) => props.theme.colors.lightGrey};
  position: relative;
  &:before {
    content: '';
    width: 130%;
    height: 100%;
    background: ${withProp(['isOpen', 'isUser', 'theme.gradients'], (isOpen, isUser, gradients) => {
      if (isOpen) {
        return gradients.grey
      }
      if (isUser) {
        return gradients.yellow
      }
      return 'none'
    })};
    position: absolute;
    left: -15%;
    top: 0;
    z-index: -1;
  }
  ${(props) => props.theme.hover} {
    &:before {
      background: ${withProp(['isOpen', 'isUser', 'theme.gradients'], (isOpen, isUser, gradients) => {
        return gradients.grey
      })};
    }
  }
`

export const StyledRowHeader = styled.div`
  user-select: none;
  cursor: ${(props) => (props.body ? 'pointer' : 'inherit')};
  padding: 1rem;
  display: flex;
  align-items: center;
  background: transparent;
  color: ${(props) => props.theme.colors.body};
  font-family: ${(props) => (props.isUser ? props.theme.fonts.secondaryFont : props.theme.fonts.primaryFont)};
  width: 100%;
  text-align: left;
  font-size: 1.3rem;

  @media ${device.tabletPortrait} {
    padding: 1rem;
    font-size: ${(props) => props.theme.fonts.m};
  }

  ${(props) => props.theme.hover} {
    background: none;
  }
`

export const StyledRowRank = styled.span`
  padding-right: 2rem;
  flex: 0 0 3rem;
  @media ${device.tabletPortrait} {
    flex: 0 0 6rem;
  }
`

export const StyledRowName = styled.span`
  padding-right: 1rem;
  width: 340px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyledRowCount = styled.span`
  margin-left: auto;
  padding: 0 0.5rem 0 1rem;
  display: inline-block;
  white-space: nowrap;
  color: ${(props) => props.theme.colors.primary};
  font-family: ${(props) => props.theme.fonts.primaryFont};
  font-size: 12px;
  strong {
    font-size: 18px;
  }
  @media ${device.tabletPortrait} {
    padding-right: 2rem;
    font-size: 14px;
  }
`

export const StyledRowPoints = styled.span`
  margin-left: auto;
  padding-right: 1rem;
  display: inline-block;
  min-width: 113px;
  text-align: right;
  white-space: nowrap;
  color: ${(props) => props.theme.colors.primary};
  font-family: ${(props) => props.theme.fonts.primaryFont};
  font-size: 12px;
  strong {
    font-size: 18px;
  }
  @media ${device.tabletPortrait} {
    font-size: 14px;
  }
`

export const StyledRowHidden = styled.div`
  display: flex;
  border-top: 1px dashed ${(props) => props.theme.colors.white};
  border-color: white;
  cursor: auto;
  padding: 1rem;
`

export const StyledRowLogo = styled.img`
  margin-left: auto;
  width: 80px;
  height: 80px;

  @media ${device.tabletPortrait} {
    width: 180px;
    height: auto;
  }
`

export const StyledRowBody = styled.div`
  font-size: ${(props) => props.theme.fonts.xxs};
  color: ${(props) => props.theme.colors.darkGrey};
  margin-right: 1rem;

  @media ${device.mobLandscape} {
    font-size: ${(props) => props.theme.fonts.s};
  }
`
