import React, { useState } from 'react'
import SEO from '../../components/seo'
import Wrapper from '../../components/Wrapper/Wrapper'
import ForgottenPassword from './components/forgottenPassword'
import CompletedPassword from './components/completedPassword'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const ForgottenPasswordPage = () => {
  const [completed, setCompleted] = useState(false)
  const { t } = useTranslation()

  return (
    <>
      <SEO title={t('forgotPassword.seoTitle')} />
      <Wrapper>
        {completed ? (
          <CompletedPassword setCompleted={setCompleted} />
        ) : (
          <ForgottenPassword setCompleted={setCompleted} />
        )}
      </Wrapper>
    </>
  )
}

export const query = graphql`
  query ForgottenPasswordPage($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
  }
`

export default ForgottenPasswordPage
