import useGame from '../../../../contexts/GameContext/useGame'
import { Maybe, useFindLocationsQuery } from '../../../../generated/api'

export type Properties = {
  id: string
  type: string
  name?: Maybe<string>
  description?: Maybe<string>
  beatBoxNumber?: Maybe<number>
  accessible?: Maybe<boolean>
}

export type CollectionFeature = GeoJSON.FeatureCollection<GeoJSON.Point, Properties>['features'][0]

const useLocations = () => {
  const { game } = useGame()
  const { data, isLoading } = useFindLocationsQuery(
    {
      gameId: game?.id,
    },
    {
      enabled: !!game?.id,
    },
  )

  const geoJsonData: GeoJSON.FeatureCollection<GeoJSON.Point, Properties> = {
    type: 'FeatureCollection',
    features:
      data?.findLocations.map((location) => {
        return {
          type: 'Feature' as const,
          geometry: {
            coordinates: [Number(location.lng), Number(location.lat)],
            type: 'Point' as const,
          },
          properties: {
            id: location.id,
            type: location.type,
            name: location.details.name || location.slug,
            beatBoxNumber: location.beatBoxNumber,
            description: location.details.description,
            accessible: location.details.accessible,
          },
        }
      }) || [],
  }

  return {
    isLoading,
    geoJsonData,
  }
}

export default useLocations
