import LinkButton, { LinkButtonProps } from '../LinkButton/LinkButton'
import { darken } from 'polished'
import styled from 'styled-components'
import { FC } from 'react'

export const CTAButton: FC<LinkButtonProps> = styled(LinkButton)`
  width: auto;
  padding: 11px 32px 13px;
  font-size: 2rem;
  display: inline-block;
  background: ${(props) => props.theme.colors.orange};
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.white};
  border: none;

  ${(props) => props.theme.hover} {
    background-color: ${(props) => darken(0.1, props.theme.colors.orange)};
    color: ${(props) => props.theme.colors.white};
    border: none;
  }
  margin-bottom: 33px;
`
