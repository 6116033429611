import { Auth } from 'aws-amplify'
import env from '../env'
import localAuth from '../utils/localAuth'

export const fetchData = async <TData, TVariables = unknown>(
  query: string,
  variables?: TVariables,
  options?: RequestInit['headers'],
): Promise<TData> => {
  const headers: Record<string, any> = {
    Authorization: 'none',
  }
  if (typeof window !== 'undefined') {
    try {
      const session = await Auth.currentSession()
      headers.Authorization = session.getAccessToken().getJwtToken()
    } catch (e) {
      if (env.local) {
        const username = localAuth.getUsername()
        if (username) {
          headers.Authorization = username
        }
      }
    }
  }
  const res = await fetch(`${env.apiUrl}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
      ...options,
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  })

  const json = await res.json()

  if (json.errors) {
    const { message } = json.errors[0] || {}
    console.error(message)
    throw new Error(message || 'Error…')
  }

  return json.data
}

export const fetchGenerated = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  options?: RequestInit['headers'],
): (() => Promise<TData>) => {
  return () => fetchData(query, variables, options)
}
