import { UiReducerActionTypes } from '../reducers/ui'

export const setMessage = (data) => {
  return {
    type: UiReducerActionTypes.SET_MESSAGE,
    message: {
      ...data,
    },
  }
}
