import React, { FC, useEffect } from 'react'
import Input from '../Input/Input'
import { useFormik } from 'formik'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { getDateInputSchema } from './validation'
import { Maybe } from '../../generated/api'

interface DateInputProps {
  id?: number | string
  maxYear?: number
  onChange?: (date: Date) => void
  value: string | null
  setFieldError?: (field: string, message: string) => void
  name?: string
  disabled?: boolean
  setIsTouched?: React.Dispatch<React.SetStateAction<boolean>>
}

interface IProps {
  day: number
  month: Maybe<number>
  year: Maybe<number>
}

const isValid = ({ day, month, year }: IProps) => {
  if (day && month && year) {
    const date = new Date(year, month - 1, day)
    const dateYear = date.getFullYear()
    const dateMonth = date.getMonth()
    const dateDay = date.getDate()
    return day === dateDay && month - 1 === dateMonth && year === dateYear
  } else {
    return false
  }
}

const DateInput: FC<DateInputProps> = ({
  maxYear,
  value = null,
  onChange,
  setFieldError,
  name,
  disabled,
  setIsTouched,
}) => {
  const { t } = useTranslation()
  const dateInputSchema = getDateInputSchema(t, maxYear)
  const { values, handleBlur, handleChange, touched, errors } = useFormik<IProps>({
    enableReinitialize: true,
    initialValues: {
      day: 15,
      month: value ? new Date(value).getMonth() + 1 : null,
      year: value ? new Date(value).getFullYear() : null,
    },
    validationSchema: dateInputSchema,
    onSubmit: async () => ({}),
  })

  useEffect(() => {
    if ((touched.year || touched.month) && setIsTouched) setIsTouched(true)
    const monthError = touched.month ? errors.month : undefined
    const yearError = touched.year ? errors.year : undefined
    if (setFieldError && name) {
      if (monthError) {
        setFieldError(name, monthError)
      } else if (yearError) {
        setFieldError(name, yearError)
      }
    }
  }, [errors.month, errors.year, touched.month, touched.year, setFieldError, name])

  return (
    <>
      <Input
        text={t('components.dateInput.month') || ''}
        id="month"
        disabled={disabled}
        placeholder="MM"
        type="number"
        value={values.month || ''}
        onChange={(e) => {
          const value = Number(e.target.value)
          if (
            values.year &&
            onChange &&
            isValid({
              day: values.day,
              month: value,
              year: values.year,
            })
          ) {
            onChange(new Date(values.year, value - 1, values.day))
          } else if (setFieldError && name) {
            setFieldError(name, t('components.dateInput.error.invalidDate'))
          }
          handleChange(e)
        }}
        onBlur={handleBlur}
        hasError={Boolean(touched.month && errors.month)}
        required
      />

      <Input
        text={t('components.dateInput.year') || ''}
        id="year"
        disabled={disabled}
        placeholder="YYYY"
        type="number"
        value={values.year || ''}
        onChange={(e) => {
          const value = Number(e.target.value)
          if (
            values.month &&
            onChange &&
            isValid({
              day: values.day,
              month: values.month,
              year: value,
            }) &&
            value > 1899 &&
            value < 2300
          ) {
            onChange(new Date(value, values.month - 1, values.day))
          } else if (setFieldError && name) {
            setFieldError(name, t('components.dateInput.error.invalidDate'))
          }
          handleChange(e)
        }}
        onBlur={handleBlur}
        hasError={Boolean(touched.year && errors.year)}
        required
      />
    </>
  )
}

export default DateInput
