import React, { FC, useState } from 'react'
import Logo from '../Logo/Logo'
import HeaderNav from '../HeaderNav/HeaderNav'
import { StyledHeader } from './Header.styled'
import usePageContext from '../../hooks/usePageContext'
import { useLocation } from '@gatsbyjs/reach-router'
import FocusTrap from 'focus-trap-react'

interface IProps {
  force?: boolean
}

const Header: FC<IProps> = ({ force }) => {
  const { headerLogoShown: _headerLogoShown, headerNavigationShown } = usePageContext()
  const headerLogoShown = force || _headerLogoShown
  const [isNavOpen, setIsNavOpen] = useState(false)

  const { pathname } = useLocation()
  const isAppTrialPage = pathname.includes('app-trial')

  if (!headerLogoShown && !headerNavigationShown) {
    return null
  }

  return (
    <FocusTrap
      active={isNavOpen}
      focusTrapOptions={{
        escapeDeactivates: false,
      }}
    >
      <StyledHeader logoShown={headerLogoShown}>
        {headerLogoShown && <Logo linkTo="/landing" isNavOpen={isNavOpen || isAppTrialPage} />}
        {headerNavigationShown && <HeaderNav isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen} />}
      </StyledHeader>
    </FocusTrap>
  )
}

export default Header
