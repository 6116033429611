import React, { FC } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import RichText from '../components/RichText'
import { ContextProps } from '../components/CallToAction/CallToActionPanel'

interface TermsAndConditionsProps {
  context?: ContextProps
}

const TermsAndConditions: FC<TermsAndConditionsProps> = ({ context }) => {
  const {
    sanityHomepage: { termsAndConditions },
  } = useStaticQuery(graphql`
    query ($language: String) {
      sanityHomepage(_id: { regex: "/(drafts.|)homepage/" }, i18n_lang: { eq: $language }) {
        termsAndConditions {
          id
          body: _rawBody
        }
      }
    }
  `)

  if (!termsAndConditions) {
    return null
  }

  return <RichText blocks={termsAndConditions.body} context={context} />
}

export default TermsAndConditions
