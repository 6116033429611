import React, { FC } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Background, Boy, Girl, Group } from './BackgroundArc.styled'

interface BackgroundArcProps {
  loggedIn?: boolean
  offset?: number
  offsetMobile?: number
  offsetMobLandscape?: number
  offsetTabletPortrait?: number
  hidden?: boolean
  isGetStarted?: boolean
  isBluePartBigger?: boolean
}

// interface StateProps {
//   ui: {
//     message: {
//       show: unknown
//     }
//   }
// }

const BackgroundArc: FC<BackgroundArcProps> = ({
  loggedIn,
  offset = 0,
  offsetMobile = 0,
  offsetMobLandscape = 0,
  offsetTabletPortrait = 0,
  hidden,
  isGetStarted,
  isBluePartBigger,
}) => {
  // const isMessage = useSelector((state: StateProps) => state.ui.message.show) || false
  const data = useStaticQuery(graphql`
    query {
      images: allFile {
        edges {
          node {
            relativePath
            publicURL
            name
          }
        }
      }
    }
  `)

  const filenameDesktop = (loggedIn) =>
    data.images.edges.find((n) => {
      if (loggedIn) {
        return n.node.name.includes('white-arc-bg-desktop')
      }
      return n.node.name.includes('blue-arc-bg2')
    }).node.publicURL

  const filenameMobile = (loggedIn) =>
    data.images.edges.find((n) => {
      if (loggedIn) {
        return n.node.name.includes('white-arc-bg-mobile')
      }
      return n.node.name.includes('blue-arc-bg-mobile')
    }).node.publicURL

  const girl = data.images.edges.find((n) => n.node.name.includes('young_lady'))

  const boy = data.images.edges.find((n) => n.node.name.includes('boy-scooter'))

  const group = data.images.edges.find((n) => n.node.name.includes('group-of-people'))

  return (
    <>
      {!isGetStarted && <Girl filename={girl.node.publicURL} />}
      <Background
        hidden={hidden}
        offset={offset}
        //we don't display message at all at the moment
        isMessage={false}
        offsetMobile={offsetMobile}
        offsetmobLandscape={offsetMobLandscape}
        offsetTabletPortrait={offsetTabletPortrait}
        filename={filenameMobile(loggedIn)}
        filenameDesktop={filenameDesktop(loggedIn)}
        isBluePartBigger={isBluePartBigger}
      />
      {!isGetStarted && <Boy filename={boy.node.publicURL} />}
      {isGetStarted && <Group filename={group.node.publicURL} />}
    </>
  )
}

export default BackgroundArc
