import device from '../../styles/device'
import { CloseButton } from '../Modal/Modal.styled'
import { AnchorBold } from '../Anchor/Anchor.styled'
import styled, { css } from 'styled-components'

export const hide = css`
  opacity: 0;
  height: 0;
`

export const Container = styled('div')`
  max-width: 100%;
  margin: 0 auto 40px;
  @media ${device.tabletPortrait} {
    max-width: ${(props) => props.theme.contentWidth};
  }
`
export const Background = styled('div')`
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 10%) 0 0 8px 0;
  padding: 1rem 1.5rem;
  max-width: 100%;
  position: relative;
  opacity: 1;
  height: auto;
  transition: ${(props) => props.theme.transitions.quickTransition};
  display: flex;
  background: white;

  @media ${device.tabletPortrait} {
    max-width: 550px;
    margin: auto;
  }
  @media ${device.tabletLandscape} {
    margin: ${(props) => (props.center ? 'auto' : 0)};
  }

  ${(props) => !props.show && hide};
`

export const Bar = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 4px;
  background: ${(props) => props.theme.colors[props.type]};
  border-radius: 5px 0 0 5px;
`

export const Text = styled('p')`
  color: ${(props) => props.theme.colors.darkGrey};
  font-family: ${(props) => props.theme.fonts.primaryFont};
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  padding: 0;
  margin: 0;
`

export const SubText = styled('p')`
  color: ${(props) => props.theme.colors.darkGrey};
  font-family: ${(props) => props.theme.fonts.primaryFont};
  font-size: 14px;
  font-weight: normal;
  text-align: left;
  padding: 0;
  margin: 0;
`

export const Close = styled(CloseButton)`
  z-index: 0;
  background: transparent;
  position: initial;
  margin: 0;
`

export const IconContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Icon = styled('img')`
  width: 38px;
  height: 38px;
  padding: 0 15px 0 4px;
`

export const MessageContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex: 1;
`
export const ResendLink = styled(AnchorBold)`
  font-size: 14px;
`
