import styled from 'styled-components'
import device from '../../styles/device'

export const StyledCardGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media ${device.tabletLandscape} {
    justify-content: flex-start;
  }
`
