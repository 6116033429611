import { CollectionFeature } from '../../useLocations'
import { Maybe } from '../../../../../../generated/api'

type Props = {
  reportEmail: string
  feature?: Maybe<CollectionFeature>
  type?: Maybe<string>
}

export const getReportHref = ({ reportEmail, feature, type }: Props) => {
  if (!reportEmail) {
    return
  }
  let params = `subject=${encodeURIComponent('Map problem report')}&`
  params += `body=${encodeURIComponent(
    `Map: ${window.location.href}\nName: ${feature?.properties.name}\nType: ${type}\nCoordinates: ${feature?.geometry.coordinates}\nMessage:\n###### Type your message here. ######`,
  )}`
  return `mailto:${reportEmail}?${params}`
}
