import React from 'react'
import styled from 'styled-components'

const StyledBlockWrapper = styled.div`
  margin-bottom: 3rem;
`

interface BlockWrapperProps {
  children?: JSX.Element[] | JSX.Element
}

const BlockWrapper = ({ children }: BlockWrapperProps) => <StyledBlockWrapper>{children}</StyledBlockWrapper>

export default BlockWrapper
