import React from 'react'
import ChangeGame from '../changeGame'
import Wrapper from '../../../../components/Wrapper/Wrapper'
import useGame from '../../../../contexts/GameContext/useGame'
import TeamChange from './teamChange'
import useAuth from '../../../../contexts/AuthContext/useAuth'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import useGameRestricted from '../../../../hooks/useGameRestricted'

const GameSection = () => {
  const { activeGame: game } = useGame()
  const { user } = useAuth()
  const { t } = useTranslation()
  const { isGameRestricted } = useGameRestricted()

  return (
    <>
      <Wrapper>
        <h2>
          {game?.info.name
            ? t('manageAccount.gameSection.titleWithGame', { game: game.info.name })
            : t('manageAccount.gameSection.noGameTitle')}
        </h2>
      </Wrapper>
      <TeamChange />
      {!user?.role.isChild && !isGameRestricted && <ChangeGame />}
    </>
  )
}

export default GameSection
