import React, { FC, useState } from 'react'
import Wrapper from '../../../components/Wrapper/Wrapper'
import SEO from '../../../components/seo'
import { HeadingWrapper } from '../../../components/HeadingWrapper/HeadingWrapper.styled'
import { Heading } from '../../../components/Heading/Heading.styled'
import { SubHeading } from '../../../components/SubHeading/SubHeading.styled'
import LinkButton from '../../../components/LinkButton/LinkButton'
import Input from '../../../components/Input/Input'
import Button from '../../../components/Button/Button'
import useAuth from '../../../contexts/AuthContext/useAuth'
import theme from '../../../styles/theme'
import { useLocation } from '@gatsbyjs/reach-router'
import Tooltip from '../../../components/Tooltip/Tooltip'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { IPageProps } from '../../../contexts/PageContext'
import { toUrlId } from '../../../utils/toUrlId'
import useGameRestricted from '../../../hooks/useGameRestricted'
import Loading from '../../../components/Loading'
import RestrictedPage from '../../../components/RestrictedPage'
const InviteFamily: FC<IPageProps<Queries.InviteFamilyQuery>> = () => {
  const { masterUser } = useAuth()
  const [copied, setCopied] = useState(false)
  const location = useLocation()
  const { t } = useTranslation()
  const { isGameRestricted, isLoading, game } = useGameRestricted()

  const familyInviteLink = `${location.origin}/join-family/?id=${toUrlId(masterUser?.id || '')}`

  if (isLoading) {
    return <Loading />
  }

  if (isGameRestricted) {
    return (
      <RestrictedPage
        title={t('addFamily.title')}
        text={t('signUp.selectAge.restrictedPage', { days: game?.info.restrictAtDays })}
      />
    )
  }

  return (
    <>
      <SEO title={t('addFamily.title')} />
      <HeadingWrapper>
        <Heading tall marginTop={'1rem'}>
          {t('addFamily.invite.title')}
        </Heading>
        <SubHeading as="h5" veryTall secondaryForm>
          {t('addFamily.invite.subTitle')}
          <Tooltip
            text={t('addFamily.invite.linkTooltip') || ''}
            labelStyle={{
              margin: '0 7px',
              display: 'flex',
            }}
          />
        </SubHeading>
      </HeadingWrapper>
      <Wrapper width="400px" textAlign="center" marginTop={'1rem'}>
        <Input
          type="text"
          id="inviteLink"
          text="Invite link"
          autoComplete="off"
          value={familyInviteLink}
          disabled
          inputProps={{
            title: 'inviteLink',
          }}
        />
      </Wrapper>
      <Wrapper>
        <Button
          text={t('addFamily.invite.copyLink')}
          style={{ marginTop: 20 }}
          onClick={() => {
            navigator.clipboard.writeText(familyInviteLink)
            setCopied(true)
            setTimeout(() => {
              setCopied(false)
            }, 5000)
          }}
          secondary
        />
      </Wrapper>

      {copied && (
        <Wrapper marginTop={'10px'} style={{ color: theme.colors.primary }}>
          <p>{t('addFamily.invite.linkCopied')}</p>
          <p>{t('addFamily.invite.shareLink')}</p>
        </Wrapper>
      )}
      <Wrapper marginTop={'150px'}>
        <div style={{ marginBottom: 100 }}>
          <LinkButton to={`/dashboard`} $tertiary>
            {t('common.cancel') || ''}
          </LinkButton>
        </div>
      </Wrapper>
    </>
  )
}

export const query = graphql`
  query InviteFamily($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
  }
`

export default InviteFamily
