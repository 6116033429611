import styled from 'styled-components'
import device from '../../styles/device'

export const StyledHeaderLanding = styled.header`
  position: relative;
  display: flex;
  justify-content: center;

  ${(props) =>
    props.home &&
    `
        margin: 0 auto 20px 0;
        width: 100%;
        max-width: 190px;

        @media ${device.tabletLandscape} {
            max-width: 463px;
            margin-left: 73px;
            margin-bottom: 30px;
        }

        @media ${device.desktop} {
            max-width: 563px;
            margin-left: 73px;
            margin-bottom: 50px;
        }
    `};
  ${(props) =>
    props.register &&
    `
        margin: 18px auto 10px auto;
        width: 100%;
        max-width: 100px;

        @media ${device.mobLandscape} {
            max-width: 160px;
            margin-bottom: 12px;
        }
        @media ${device.tabletLandscape} {
            max-width: 240px;
            margin-left: 20px;
            margin-bottom: 14px;

        }
        @media ${device.desktop} {
            max-width: 260px;
            margin-left: 22px;
            margin-bottom: 16px;
        }
    `};
  ${(props) =>
    props.centered &&
    `   
        @media ${device.mobLandscape} {
            max-width:100%;
            margin-top: 50px;
            margin-left: 0;
        }
            
           
        `}
`
