import theme from '../../../../../styles/theme'
import styled from 'styled-components'
import device from '../../../../../styles/device'
import { withProp } from 'styled-tools'

export const Themes = {
  POI: theme.colors.lightOrange,
  distributionPoint: theme.colors.green,
  beatBox: theme.colors.primary,
  school: theme.colors.body,
  gem: theme.colors.lightPink,
}

export const FilterBoxWrapper = styled.div`
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  align-items: flex-start;
  > div.inner {
    margin-top: 100px;
    width: 100%;
    display: flex;
    max-height: ${withProp('height', (height) => `${height - 100}px`)};
    @media ${device.tabletLandscape} {
      margin-top: 180px;
      max-height: ${withProp('height', (height) => `${height - 180}px`)};
    }
    @media ${device.mobOnly} {
      max-height: ${withProp('height', (height) => `${height - 180}px`)};
    }
    //@media (max-width: 500px) {
    //  max-height: 70%;
    //}
    position: relative;
    box-shadow: 0 5px 15px hsla(0, 0%, 0%, 0.2);
    .mask {
      width: 100%;
      pointer-events: all;
      border-radius: 5px;
      max-height: ${withProp('height', (height) => `${height - 100}px`)};
      @media ${device.tabletLandscape} {
        max-height: ${withProp('height', (height) => `${height - 180}px`)};
      }
      @media ${device.mobOnly} {
        max-height: ${withProp('height', (height) => `${height - 180}px`)};
      }
      /* max-width: ${withProp('isExpanded', (isExpanded) => (isExpanded ? 'auto' : '5px'))}; */
      > div {
        padding: 20px;
        width: 360px;
        @media (max-width: 500px) {
          width: 100%;
        }
        @media (max-width: 360px) {
          width: 360px;
        }
      }
      box-sizing: border-box;
      overflow: scroll;
      scrollbar-width: none;
      background: ${(props) => props.theme.colors.white};
      position: relative;
      z-index: 2;
    }
  }
  label {
    margin-bottom: 10px;
    height: 28px;
    align-items: center;
    align-content: center;
    line-height: 26px;
    cursor: pointer;
  }
  input[type='checkbox'].unstyled {
    opacity: 1;
    position: relative;
    padding: 0;
    display: block;
    float: left;
    height: 15px;
    margin: 7px 1px 0 10px;
  }
  label.unstyled {
    &::before,
    &::after {
      display: none;
    }

    padding: 0;
  }
  @media ${device.mobOnly} {
    align-items: flex-end;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`

export const Toggle = styled.div`
  position: absolute;
  right: -25px;
  top: 10px;
  padding: 10px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 0 5px 5px 0;
  box-shadow: 0 5px 15px hsla(0, 0%, 0%, 0.2);
  z-index: 1;
  pointer-events: all;
  cursor: pointer;
  svg {
    width: 8px;
    transform: ${withProp('isExpanded', (isExpanded) => (isExpanded ? 'rotate(180deg)' : 'none'))};
    @media (max-width: 500px) {
      transform: ${withProp('isExpanded', (isExpanded) => (isExpanded ? 'rotate(90deg)' : 'rotate(270deg)'))};
    }
  }
  @media (max-width: 500px) {
    top: -27px;
    right: unset;
    left: 10px;
    bottom: unset;
    border-radius: 5px 5px 0 0;
    padding-left: 15px;
    padding-right: 15px;
    z-index: 1;
  }
`

export const CheckboxContainer = styled.div`
  flex-wrap: nowrap;
  @media (max-width: 500px) {
    display: flex;
    flex-wrap: wrap;
    column-gap: 3%;
    justify-content: flex-start;
    width: 100%;
  }

  > div {
    width: 100%;
    margin: 0;
    @media (max-width: 500px) {
      width: 47%;
    }
    @media (max-width: 320px) {
      width: 100%;
    }
  }
`
