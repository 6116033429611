import React, { useState, useEffect, useRef, FC } from 'react'
import FacebookSocialFeed from '../FacebookSocialFeed'
import TwitterSocialFeed from '../TwitterSocialFeed'
import { IS_IE11 } from '../../styles/device'
import { ResponsiveGridWrapper, SocialBlockWrap, SocialItemWrap } from './SocialBlock.styled'
import { Trans } from 'gatsby-plugin-react-i18next'
import { GameWithContent } from '../../contexts/PageContext'

interface IProps {
  game?: GameWithContent
}

const SocialBlock: FC<IProps> = ({ game }) => {
  const showTwitter = !!game?.content.twitterUsername && !IS_IE11
  /* eslint-disable react-hooks/rules-of-hooks */
  if (!game?.content.facebookUsername && !showTwitter) {
    return null
  }
  const targetRef1 = useRef<HTMLDivElement>(null)
  const targetRef2 = useRef()

  const [blockWidth, setBlockWidth] = useState(350)

  const updateWidth = () => {
    // @ts-ignore
    const { width } = targetRef1.current?.childNodes[0].getBoundingClientRect() ||
      // @ts-ignore
      targetRef2.current?.childNodes[0].getBoundingClientRect() || { width: 0 }
    setBlockWidth(Math.min(width, 350))
  }
  useEffect(() => {
    updateWidth()
    window.addEventListener('resize', updateWidth)
    return () => {
      window.removeEventListener('resize', updateWidth)
    }
  }, [])
  const columns = game.content.facebookUsername && showTwitter ? 2 : 1
  return (
    <SocialBlockWrap columns={columns}>
      <ResponsiveGridWrapper columns={columns}>
        {game.content.facebookUsername && (
          <div className="facebook" ref={targetRef1}>
            <h2>
              <Trans i18nKey={'components.social.facebook'} components={{ strong: <strong /> }} />
            </h2>
            <SocialItemWrap columns={columns}>
              <FacebookSocialFeed username={game.content.facebookUsername} width={Math.round(blockWidth)} />
            </SocialItemWrap>
          </div>
        )}

        {showTwitter && (
          <div className="twitter">
            <h2>
              <Trans i18nKey={'components.social.twitter'} components={{ strong: <strong /> }} />
            </h2>
            <SocialItemWrap ref={targetRef2} columns={columns}>
              <TwitterSocialFeed username={game.content.twitterUsername || ''} width={Math.round(blockWidth)} />
            </SocialItemWrap>
          </div>
        )}
      </ResponsiveGridWrapper>
    </SocialBlockWrap>
  )
}
export default SocialBlock
