import React, { FC } from 'react'
import ThemeImage from '../../../../../components/ThemeImage/ThemeImage'
import ShallowArc from '../../../../../components/ShallowArc/ShallowArc'
import theme from '../../../../../styles/theme'
import {
  ButtonWrapper,
  ContentWrapper,
  EventsButton,
  HeadingWrapper,
  SubHeading,
  WeekHeading,
  WeeklySectionWrapper,
} from './DashboardWeeklySecton.styled'
import useNavigate from '../../../../../hooks/useNavigate'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { GameWithContent } from '../../../../../contexts/PageContext'

export interface WeeklyThemeProps {
  name: string
  title: string
  subtitle: string
  themeColours: string
  logo: null | {
    asset: {
      url: string
    }
  }
}

interface DashboardWeeklySectionProps {
  weeklyThemes: WeeklyThemeProps[]
  currentWeek: number
  game?: GameWithContent
  children: React.ReactNode
}

const DashboardWeeklySection: FC<DashboardWeeklySectionProps> = ({ children, weeklyThemes, currentWeek }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  if (!weeklyThemes?.length || currentWeek >= weeklyThemes.length || currentWeek < 0) {
    return null
  }
  const currentTheme = weeklyThemes[currentWeek]
  if (!currentTheme) return null

  const { name, title, subtitle, themeColours, logo } = currentTheme
  const { buttonBgColor, buttonTextColor, bgColor, textColor } = theme.game_themes[themeColours]

  return (
    <WeeklySectionWrapper backgroundColor={bgColor}>
      <HeadingWrapper>
        <ShallowArc backgroundColor={bgColor} />
        <WeekHeading textColor={textColor}>
          <div>
            <ThemeImage
              gameTheme={themeColours}
              name={name}
              currentWeek={currentWeek + 1}
              logo={logo?.asset?.url || ''}
            />
          </div>
        </WeekHeading>
      </HeadingWrapper>
      <ContentWrapper backgroundColor={bgColor}>
        <div>
          <SubHeading textColor={textColor}>
            <h3>{title}</h3>
            <p>{subtitle}</p>
          </SubHeading>
          {children}
          <ButtonWrapper>
            <EventsButton
              text={t('game.dashboard.weeklySection.buttonText', { schemeName: name })}
              backgroundColor={buttonBgColor}
              textColor={buttonTextColor}
              onClick={() => navigate('/events')}
            />
          </ButtonWrapper>
        </div>
      </ContentWrapper>
    </WeeklySectionWrapper>
  )
}

export default DashboardWeeklySection
