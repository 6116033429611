import React from 'react'
import useAuth from '../../contexts/AuthContext/useAuth'
import { Link } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { TagTypes } from '../../generated/api'

const ConnectCardLink = () => {
  const { isAuthenticated, user } = useAuth()
  const { t } = useTranslation()

  const isAppPlayer = user?.tags?.find((tag) => tag.tagType === TagTypes.App)
  const hasTag = user?.tags?.find((tag) => tag.tagType === TagTypes.Card || tag.tagType === TagTypes.Fob)

  if (!isAuthenticated) return null

  if (isAppPlayer && !hasTag) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '-5%',
          marginBottom: '8%',
        }}
      >
        <Link to={'/add-card'}>
          {hasTag ? t('manageAccount.details.addMoreCards') : t('manageAccount.details.connectCard')}
        </Link>
      </div>
    )
  }

  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '-5%', marginBottom: '8%' }}
    >
      <Link to={'/add-card'}>
        {hasTag ? t('manageAccount.details.addMoreCards') : t('manageAccount.details.connectCard')}
      </Link>
    </div>
  )
}

export default ConnectCardLink
