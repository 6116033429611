import styled from 'styled-components'

const Section = styled.hr`
  margin: 50px auto;
  max-width: 600px;
  border: 0;
  border-top: 1px solid rgb(240 240 240);
`

export default Section
