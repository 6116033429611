import useAuth from '../../../../contexts/AuthContext/useAuth'
import { format, isBefore } from 'date-fns'
import Wrapper from '../../../../components/Wrapper/Wrapper'
import { ErrorMessage, Formik } from 'formik'
import { Error, FormItem } from '../../../../components/FormElements/FormElements.styled'
import SelectDropdown from '../../../../components/SelectDropdown/SelectDropdown'
import Button from '../../../../components/Button/Button'
import React from 'react'
import Tooltip from '../../../../components/Tooltip/Tooltip'
import useNavigate from '../../../../hooks/useNavigate'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import useGame from '../../../../contexts/GameContext/useGame'
import { TeamStatus, useFindAllTeamsQuery } from '../../../../generated/api'
import { useManagePlayers } from '../../../signup/hooks/useManagePlayers'

export const GameSetupJoinTeam = ({ step, setStep }) => {
  const { user } = useAuth()
  const { activeGame: game } = useGame()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { onJoinTeam } = useManagePlayers()

  const isJoinToTeamDisabled = game?.info.teamsStartsAt && isBefore(new Date(), new Date(game?.info.teamsStartsAt))

  const { data: teamsRes } = useFindAllTeamsQuery({ input: { gameId: game?.id || '' } }, { enabled: Boolean(game?.id) })

  const teams = teamsRes?.findTeams

  const approvedTeams = teams
    ?.map((team) => {
      if (team.status !== TeamStatus.Approved) return
      return {
        value: team.id,
        display: team.name,
      }
    })
    ?.filter((team) => team)

  return (
    <Wrapper width="400px" textAlign="left" style={{ marginTop: 50, marginBottom: 100 }}>
      <Formik
        enableReinitialize
        initialValues={{
          teamId: '',
          submit: null,
        }}
        onSubmit={async (values, { setErrors }) => {
          try {
            if (isJoinToTeamDisabled) {
              return navigate('/')
            }
            if (values.teamId && user?.id) {
              await onJoinTeam({
                teamId: values.teamId,
                playerId: user?.id,
              })
            }
            setStep(step + 1)
          } catch (e) {
            setErrors({ submit: t('errors.joinTeam') || undefined })
          }
        }}
      >
        {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              {isJoinToTeamDisabled ? (
                <p>
                  {t('gameSetup.joinTeam.teamDisabled', {
                    date: format(new Date(game?.info.teamsStartsAt), 'eeee d MMMM h:mm aaa'),
                  })}
                </p>
              ) : (
                <>
                  <fieldset>
                    <legend>
                      {t('gameSetup.joinTeam.select.title')}
                      <Tooltip text={t('gameSetup.joinTeam.select.toolTip') || ''} />
                    </legend>
                    <FormItem>
                      <SelectDropdown
                        id={'teamId'}
                        ariaLabel="Select Team"
                        // @ts-ignore
                        ariaLabelledby="Select Team"
                        options={approvedTeams}
                        placeholder={t('gameSetup.joinTeam.select.placeholder') || ''}
                        value={values.teamId}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        secondary
                      />
                    </FormItem>
                  </fieldset>

                  <ErrorMessage name="teamId" component={(props) => <Error className="checkboxError" {...props} />} />
                  <ErrorMessage name="submit" component={(props) => <Error className="checkboxError" {...props} />} />
                </>
              )}
              <Button
                type="submit"
                isSubmitting={isSubmitting}
                disabled={isSubmitting}
                text={isJoinToTeamDisabled ? t('common.finish') : t('common.next')}
                secondary
                style={{ marginTop: 10 }}
              />
            </form>
          )
        }}
      </Formik>
    </Wrapper>
  )
}
