type DataLayerEvent = {
  event: string
}

const useGoogleTagManager = () => {
  const pushEvent = ({ event }: DataLayerEvent) => {
    if (typeof window === undefined) return
    window.dataLayer = window.dataLayer || []

    const eventExists = window.dataLayer.find((element) => element.event === event)

    if (!eventExists) {
      window.dataLayer.push({
        event,
      })
    }
  }

  const removeEvent = (event: string) => {
    if (typeof window === undefined) return

    const eventExists = window.dataLayer.find((element) => element.event === event)

    if (eventExists) {
      window.dataLayer = window.dataLayer.filter((element) => element.event !== event)
      window.dataLayer.push({
        [event]: undefined,
      })
    }
  }

  return { pushEvent, removeEvent }
}

export default useGoogleTagManager
