import device from '../../styles/device'
import { ifProp } from 'styled-tools'
import { media } from '../../utils/media'
import GridWrapper from '../Wrapper/GridWrapper'
import styled from 'styled-components'

export const SocialBlockWrap = styled.div`
  margin: 40px auto 0;
  max-width: 850px;
  text-align: center;
  > div > div {
    @media ${device.tabletPortrait} {
      padding: 0 36px;
    }
    margin: 0 auto;
  }

  h2 {
    font-family: ${(props) => props.theme.fonts.primaryFont};
    font-size: 24px;
    text-align: center;
    strong {
      display: block;
      font-family: ${(props) => props.theme.fonts.secondaryFont};
    }
  }

  iframe {
    border-radius: 6px;
    margin: 0 auto;
  }
  @media ${device.tabletPortrait} {
    iframe {
      margin: inherit;
    }
  }
  .twitter iframe {
    //forced into using !important here as the react-twitter-embed package isn't respecting the borderColor option
    border: 1px solid #e2e2e2 !important;
  }
  .facebook h2 {
    color: ${(props) => props.theme.colors.darkBlue};
  }

  .twitter h2 {
    color: ${(props) => props.theme.colors.darkBlue};
  }
  ${ifProp(
    { columns: 1 },
    `

    margin: 40px auto auto;
    h2 {
        text-align: center;
        &.facebook{
            text-align: center;
        }
    }
    `,
  )};
`

export const SocialItemWrap = styled.div`
  box-sizing: content-box;
  padding: 10px 0;
  ${media.phablet`padding: 5px 5px 20px;`}
  display:flex;
  justify-content: center;
  @media ${device.tabletPortrait} {
    justify-content: left;
  }
  > * {
    width: 100%;
  }
  @media ${device.tabletPortrait} {
    justify-content: flex-end;
  }

  ${ifProp(
    { columns: 1 },
    `
        justify-content:center;
    `,
  )};
`

export const ResponsiveGridWrapper = styled(GridWrapper)`
  ${media.phablet`display:block`}
`
