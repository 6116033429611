import React, { FC } from 'react'
import styled from 'styled-components'
import { withProp } from 'styled-tools'
import device from '../../styles/device'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const ThemeImageWrap = styled.div`
  width: ${withProp('events', (events) => (events ? '145px' : '316px'))};

  margin: ${withProp('events', (events) => (events ? '-30px auto 5px' : '0 auto'))};
  @media ${device.tabletPortrait} {
    margin: ${withProp('events', (events) => (events ? '-50px auto 10px' : '0 auto'))};
  }
  @media ${device.tabletLandscape} {
    margin: ${withProp('events', (events) => (events ? '-130px auto 10px' : '0 auto'))};
  }
  position: relative;
  h2 {
    font-family: ${(props) => props.theme.fonts.secondaryFont};
    font-size: 24px;
    transform: rotate(20deg);
    position: absolute;
    bottom: 20px;
    right: 50px;
    color: ${(props) => props.theme.game_themes[props.gameTheme || 'default'].textColor};
  }
`

interface ThemeImageProps {
  name: string
  gameTheme: string
  logo: string
  currentWeek?: number
  events?: boolean
}

const ThemeImage: FC<ThemeImageProps> = ({ name, gameTheme, currentWeek, events, logo }) => {
  const { t } = useTranslation()

  return (
    <ThemeImageWrap events={events} gameTheme={gameTheme}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: events ? '145px' : '316px',
          height: events ? '121px' : '264px',
        }}
      >
        <img src={logo} alt={name} />
      </div>

      {!events && (
        <h2>
          {t('components.themeImage.week')} {currentWeek}
        </h2>
      )}
    </ThemeImageWrap>
  )
}

export default ThemeImage
