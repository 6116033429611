import React, { FC } from 'react'
import { format } from 'date-fns'
import ShowJourney from '../ShowJourney'
import Pin from '../../components/Pin/Pin'
import { Wrapper } from './LastBeatBox.styled'
import useAuth from '../../contexts/AuthContext/useAuth'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { usePlayerRoutesQuery } from '../../generated/api'

const LastBeatBox: FC = () => {
  const { user } = useAuth()
  const { t } = useTranslation()

  const { data: routesRes } = usePlayerRoutesQuery({ playerId: user?.id || '' }, { enabled: Boolean(user?.id) })
  const routes = routesRes?.playerRoutes

  const route = routes?.routes?.[0]

  const displayDate =
    route && route?.swipeTimestamp
      ? format(new Date(route?.swipeTimestamp), "EEE d MMM h:mmaaaaa'm'")
      : t('game.dashboard.stats.activityElement.noActivity')

  const match = route?.locationSlug?.match(/\d+/g)

  const markerNumber = match && match.length > 0 ? match[0] : t('game.dashboard.stats.activityElement.numberReplace')

  return (
    <Wrapper>
      <ShowJourney>
        <Pin fill="#329F2F">{markerNumber}</Pin>
        <p>{t('game.dashboard.stats.activityElement.lastBeatBox')}</p>
        <p className="sub">{displayDate}</p>
      </ShowJourney>
    </Wrapper>
  )
}

export default LastBeatBox
