import styled from 'styled-components'
import device from '../../styles/device'

export const StyledToggle = styled.div`
  margin: 0 auto 1rem;
  text-align: center;
  font-size: 1.4rem;
  padding: 0 1rem;
  @media ${device.mobLandscape} {
    font-size: 1.6rem;
  }

  @media ${device.tabletLandscape} {
    margin: 0 auto 2rem;
    font-size: 1.7rem;
  }
`

export const ToggleButton = styled.button`
  min-width: 8rem;
  padding: 0.3rem 1.2rem 0.5rem 1.2rem;
  font-family: ${(props) => props.theme.fonts.primaryFont};
  border: 1px solid ${(props) => props.theme.colors.primary};
  color: ${(props) => (props.toggle ? props.theme.colors.white : props.theme.colors.primary)};
  background-color: ${(props) => (props.toggle ? props.theme.colors.primary : props.theme.colors.white)};

  ${(props) => props.theme.hover} {
    outline: none;
    background-color: ${(props) => (props.toggle ? props.theme.colors.primary : props.theme.colors.white)};
  }

  &:focus {
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};
  }

  &:first-child {
    margin: 0;
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:last-child {
    margin: 0;
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`
