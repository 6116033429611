import React, { FC, useState } from 'react'
import { graphql } from 'gatsby'
import { endOfDay, startOfDay } from 'date-fns'
import SEO from '../../../components/seo'
import { Post } from '../../../components/CardGrid/CardGrid'
import ShallowArc from '../../../components/ShallowArc/ShallowArc'
import ThemeImage from '../../../components/ThemeImage/ThemeImage'
import Toggle from '../../../components/Toggle/Toggle'
import { Heading } from '../../../components/Heading/Heading.styled'
import themes from '../../../styles/theme'
import { EventsHeading, EventsWrapper, FilterWrapper } from './components/events.styled'
import differenceInCalendarWeeks from 'date-fns/differenceInCalendarWeeks'
import { StyledCardGrid } from './components/new-events.styled'
import Card from './components/Card'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { IPageProps } from '../../../contexts/PageContext'
import useGame from '../../../contexts/GameContext/useGame'

const EventsPage: FC<IPageProps<Queries.EventsPageQuery>> = ({ data, pageContext }) => {
  const scheme = data.sanityGame
  const { gameEvents } = pageContext
  const { game } = useGame()
  const { t } = useTranslation()
  const weeklyThemes = scheme?.weeklyThemes || []
  const [filterEvents, setFilterEvents] = useState<string>(t('game.events.upcoming') || '')
  const currentWeekNumber = differenceInCalendarWeeks(new Date(), new Date(game?.info.startsAt || ''))

  const posts = gameEvents
    ?.filter((node) => {
      if (!node.endDate) {
        if (filterEvents === t('game.events.past')) {
          return startOfDay(new Date()) > new Date(node.startDate || '')
        }
        return startOfDay(new Date()) <= new Date(node.startDate || '')
      }
      const endDate = endOfDay(new Date(node.endDate))
      if (filterEvents === t('game.events.past')) {
        return startOfDay(new Date()) > new Date(endDate)
      }
      return startOfDay(new Date()) <= new Date(endDate)
    })
    .sort((a, b) => new Date(a.startDate || '').getTime() - new Date(b.startDate || '').getTime()) as unknown as Post[]

  const theme =
    Number.isSafeInteger(currentWeekNumber) && weeklyThemes.length > currentWeekNumber
      ? weeklyThemes[currentWeekNumber]
      : null

  const { bgColor, textColor } =
    (theme && theme.logo && themes.game_themes[theme.themeColours || '']) || themes.game_themes.default

  return (
    <>
      <SEO title={t('game.events.seoTitle', { schemeName: scheme?.name })} />
      {theme && theme.logo?.asset?.url && theme.name && (
        <ThemeImage gameTheme={theme.themeColours || ''} logo={theme.logo?.asset?.url} name={theme.name} events />
      )}
      <EventsHeading>Events in {scheme?.name}</EventsHeading>
      <FilterWrapper>
        <Toggle
          value={filterEvents}
          onChange={(v) => setFilterEvents(v)}
          left={{
            value: 'Upcoming',
            text: t('game.events.upcoming') || '',
          }}
          right={{
            value: 'Past',
            text: t('game.events.past') || '',
          }}
        />
      </FilterWrapper>
      <EventsWrapper backgroundColor={bgColor}>
        <ShallowArc backgroundColor={bgColor} />
        {posts && posts.length > 0 ? (
          <StyledCardGrid>
            {posts?.map((post) => {
              return <Card context={{ game }} post={post} key={post.id} />
            })}
          </StyledCardGrid>
        ) : (
          <Heading as="h2" style={{ color: textColor }}>
            No events happening...
          </Heading>
        )}
      </EventsWrapper>
    </>
  )
}

export default EventsPage

export const query = graphql`
  query EventsPage($gameSlug: String, $language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
    sanityGame(slug: { eq: $gameSlug }, i18n_lang: { eq: $language }) {
      ...gameDetails
      ...weeklyThemes
    }
  }
`
