import Wrapper from '../../../components/Wrapper/Wrapper'
import {
  ButtonsWraper,
  ColumnWrapper,
  ContentWrapper,
  CTAButtonExtra,
  HeroWrapper,
  TertiaryButton,
  TextWrapper,
} from '../get-started.styled'
import { Heading } from '../../../components/Heading/Heading.styled'
import theme from '../../../styles/theme'
import React from 'react'

const BeginSection = ({ scroll, link }) => {
  return (
    <ContentWrapper>
      <Wrapper marginBottom="10rem" marginTop="5rem">
        <HeroWrapper>
          <Heading style={{ marginLeft: 0 }} textAlign="left">
            Let the games begin
          </Heading>
          <ColumnWrapper>
            <TextWrapper>
              <p style={{ color: theme.colors.body, overflow: 's' }} className="big">
                Beat the Street is a free, fun walking and cycling game which gets children, families and individuals
                out and about exploring in their local area, having fun and keeping healthy.
              </p>
            </TextWrapper>

            <ButtonsWraper>
              <CTAButtonExtra to={link}>Play Now</CTAButtonExtra>
              <TertiaryButton onClick={scroll} style={{ margin: 0 }}>
                How to play
              </TertiaryButton>
            </ButtonsWraper>
          </ColumnWrapper>
        </HeroWrapper>
      </Wrapper>
    </ContentWrapper>
  )
}

export default BeginSection
