import React, { FC } from 'react'
import { Figure, FigureContainer } from './ImageWithCaption.styled'
import SanityImage from '../RichText/SanityImage'

export interface ImageWithCaptionNodeProps {
  value: {
    placement: unknown
    image: string
    caption: string
    alt: string
  }
}

interface ImageWithCaptionProps {
  props: ImageWithCaptionNodeProps
}

const ImageWithCaption: FC<ImageWithCaptionProps> = ({ props: { value } }) => (
  <FigureContainer placement={value.placement}>
    <Figure>
      <SanityImage image={value.image} caption={value.caption} alt={value.alt} />
      {value.caption && <figcaption>{value.caption}</figcaption>}
    </Figure>
  </FigureContainer>
)

export default ImageWithCaption
