export enum PointsType {
  average = 'Average pts',
  total = 'Total pts',
  beatBoxes = 'Beat Boxes',
  gems = 'Gems',
}

export enum LeaderboardType {
  team = 'team',
  player = 'player',
  follow = 'follow',
}

export enum TEAMS_TYPES {
  workplace = 'workplace',
  community = 'community',
  school = 'school',
}

// interface SortOptions {
//   value: 'All' | TEAMS_TYPES
// }

export enum IndexType {
  top = 'top',
  bottom = 'bottom',
}

export interface LeaderboardProps {
  mini?: boolean
  pageSize?: number
  limitRows?: boolean
}
