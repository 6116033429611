import React, { FC, useCallback, useState } from 'react'
import Wrapper from '../../../../components/Wrapper/Wrapper'
import Input from '../../../../components/Input/Input'
import Button from '../../../../components/Button/Button'
import SEO from '../../../../components/seo'
import { Error, FormItem, FormItemGrid } from '../../../../components/FormElements/FormElements.styled'
import { HeadingWrapper } from '../../../../components/HeadingWrapper/HeadingWrapper.styled'
import { Heading } from '../../../../components/Heading/Heading.styled'
import { SubHeading } from '../../../../components/SubHeading/SubHeading.styled'
import omit from 'lodash/omit'
import { ErrorMessage, Formik, FormikErrors } from 'formik'
import DateInput from '../../../../components/DateInput'
import GenderInput from '../../../../components/GenderInput'
import useAuth from '../../../../contexts/AuthContext/useAuth'
import useNavigate from '../../../../hooks/useNavigate'
import { graphql } from 'gatsby'
import { flatten, join as lodashJoin } from 'lodash'
import Tooltip from '../../../../components/Tooltip/Tooltip'
import debounce from 'lodash/debounce'
import theme from '../../../../styles/theme'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { ChildProfileProps, getCreateFamilyMemberSchema } from './validation'
import useGame from '../../../../contexts/GameContext/useGame'
import { IPageProps } from '../../../../contexts/PageContext'
import { fetchData } from '../../../../client'
import {
  CheckPublicNameDocument,
  CheckPublicNameQuery,
  CheckPublicNameQueryVariables,
  PostCodeInput,
} from '../../../../generated/api'
import { useManagePlayers } from '../../hooks/useManagePlayers'
import useJoinGame from '../../../../hooks/useJoinGame/useJoinGame'
import useChild from '../../../../hooks/useChild/useChild'
import { validatePostcode } from '../../helpers/validatePostcode'
import useGameRestricted from '../../../../hooks/useGameRestricted'
import Loading from '../../../../components/Loading'
import RestrictedPage from '../../../../components/RestrictedPage'

const CreateFamilyMember: FC<IPageProps<Queries.CreateFamilyMemberQuery>> = ({ data }) => {
  const { masterUser, refetch } = useAuth()
  const [publicNameError, setPublicNameError] = useState<string | null>(null)
  // TODO child context
  const { setChildId } = useChild()
  const { activeGame: game } = useGame()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const createFamilyMemberSchema = getCreateFamilyMemberSchema(t)
  const { onCreateChild, onCreatePostCodeRecord } = useManagePlayers()
  const join = useJoinGame()
  const [postCodeData, setPostCodeData] = useState<PostCodeInput>()
  const { isGameRestricted, isLoading } = useGameRestricted()
  const [isBirthTouched, setIsBirthTouched] = useState(false)

  const validatePublicName = useCallback(
    debounce(async (value: string) => {
      try {
        const res = await fetchData<CheckPublicNameQuery, CheckPublicNameQueryVariables>(CheckPublicNameDocument, {
          name: value,
        })
        const isFree = res?.checkPublicName
        setPublicNameError(null)
        if (!isFree) {
          setPublicNameError(t('errors.api.signUp.displayNameTaken'))
        }
      } catch (e) {
        setPublicNameError(t('errors.api.signUp.error'))
        console.log(e)
      }
    }, 400),
    [],
  )

  const tAndCsConsentBlocks = data?.sanityRegistrationSettings?.termsAndConditionsConsentText as unknown as any[]

  const formattedConsentSanity = flatten(tAndCsConsentBlocks?.map((el) => el?.children))
  const signupConsentText = lodashJoin(
    formattedConsentSanity.map((el: { text: string }) => el?.text),
    '',
  )

  const gameConsentText = (gameName: string): string | null => {
    const gameProperties = data?.allSanityGame?.edges?.find(({ node }) => node.name === gameName)?.node
    return gameProperties?.gamePartnerConsentText as unknown as string
  }

  const getPlainText = (richText) => {
    const children = richText?.[0].children
    const text = children?.map((content) => content?.text)

    return text ? text.join('') : ''
  }

  const initialValues: ChildProfileProps = {
    firstName: '',
    birthDate: '',
    publicName: '',
    postCode: '',
    gender: '',
    submit: null,
  }

  const postCodeValidation = async (values: ChildProfileProps) => {
    const { postCode } = values
    const errors: FormikErrors<ChildProfileProps> = {}
    try {
      const result = await validatePostcode(postCode, setPostCodeData)
      if (result?.statusCode === 404) {
        errors.postCode = result.error
      }
    } catch (e) {}
    return errors
  }

  if (isLoading) {
    return <Loading />
  }

  if (isGameRestricted) {
    return (
      <RestrictedPage
        title={t('addFamily.create.title')}
        text={t('signUp.selectAge.restrictedPage', { days: game?.info.restrictAtDays })}
      />
    )
  }

  return (
    <>
      <SEO title={t('signUp.title')} />
      <HeadingWrapper>
        <Heading>{t('addFamily.create.title')}</Heading>
        <SubHeading as="h2" secondaryForm veryTall>
          {t('addFamily.create.subTitle')}
        </SubHeading>
      </HeadingWrapper>
      <Wrapper width="400px" textAlign="left">
        <Formik<ChildProfileProps>
          initialValues={initialValues}
          validationSchema={createFamilyMemberSchema}
          validate={postCodeValidation}
          onSubmit={async (values, { setErrors }) => {
            try {
              const newProfileId = await onCreateChild({
                profile: {
                  ...omit(values, ['submit', 'publicName', 'postCode']),
                  postCode: postCodeData?.postcode || values.postCode,
                  consentText: signupConsentText,
                  isRegistered: true,
                  isVerified: true,
                },
                info: {
                  publicName: values.publicName,
                },
              })

              setChildId(newProfileId?.createChild?.id)

              if (masterUser?.info.gameId && game?.info.name) {
                await join({
                  gameId: masterUser?.info.gameId,
                  consentText: getPlainText(gameConsentText(game?.info.name)) || '',
                })
              }

              try {
                if (postCodeData) {
                  await onCreatePostCodeRecord(postCodeData)
                }
              } catch (e) {
                console.log('failed to create postcode record')
              }

              await refetch()

              await navigate(`/signup/family/add-tag`, { replace: true })
            } catch (e) {
              setErrors({ submit: t('errors.api.createFamily.fail') || undefined })
            }
          }}
        >
          {({
            values,
            errors,
            handleChange,
            setFieldValue,
            handleBlur,
            handleSubmit,
            isSubmitting,
            touched,
            setFieldError,
            isValid,
          }) => {
            return (
              <form onSubmit={handleSubmit} noValidate>
                <FormItem>
                  <Input
                    type="text"
                    id="firstName"
                    text={t('addFamily.create.inputs.childFirstName') || ''}
                    maxlength={20}
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.firstName ? errors.firstName : undefined}
                  />
                </FormItem>
                <FormItem>
                  <Input
                    type="text"
                    id="publicName"
                    text={t('addFamily.create.inputs.childDisplayName') || ''}
                    tooltip={<Tooltip text={t('addFamily.create.inputs.tooltipDN') || ''} />}
                    maxlength={20}
                    value={values.publicName}
                    autoComplete="username"
                    onChange={async (e) => {
                      await handleChange(e)
                      await validatePublicName(e.target.value)
                    }}
                    onBlur={handleBlur}
                    error={touched.publicName ? errors.publicName || publicNameError || undefined : undefined}
                  />
                </FormItem>
                <fieldset style={{ margin: 0 }}>
                  <legend>Date of birth</legend>
                </fieldset>
                <FormItemGrid>
                  <DateInput
                    name="birthDate"
                    onChange={(value) => {
                      setFieldValue('birthDate', value)
                    }}
                    setFieldError={setFieldError}
                    value={values.birthDate}
                    maxYear={new Date().getFullYear()}
                    setIsTouched={setIsBirthTouched}
                  />
                </FormItemGrid>
                {(isBirthTouched || touched.birthDate) && errors?.birthDate && (
                  <>
                    <p style={{ color: theme.colors.error, display: 'flex', justifyContent: 'center' }}>
                      {errors?.birthDate}
                    </p>
                    <br />
                  </>
                )}
                <FormItem>
                  <Input
                    type="text"
                    id="postCode"
                    text={t('addFamily.create.inputs.postcode') || ''}
                    maxlength={20}
                    value={values.postCode}
                    autoComplete="postal-code"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.postCode ? errors.postCode : undefined}
                  />
                </FormItem>
                <FormItem>
                  <GenderInput
                    id="gender"
                    value={values.gender}
                    onChange={(v) => setFieldValue('gender', v)}
                    error={touched.gender && errors.gender}
                  />
                  <ErrorMessage name="gender" component={(props) => <Error className="checkboxError" {...props} />} />
                </FormItem>
                <br />
                <Button
                  isSubmitting={isSubmitting}
                  disabled={!isValid || !!publicNameError}
                  onClick={handleSubmit}
                  text={t('addFamily.create.buttonText')}
                  secondary
                  type="submit"
                />
              </form>
            )
          }}
        </Formik>
      </Wrapper>
    </>
  )
}

export const query = graphql`
  query CreateFamilyMember($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
    allSanityGame(
      filter: { status: { in: ["live", "anticipation"] }, i18n_lang: { eq: $language } }
      sort: { name: ASC }
    ) {
      edges {
        node {
          name
          slug
          gamePartnerConsentText: _rawGamePartnerConsentText
          ...gameDetails
        }
      }
    }
    sanityRegistrationSettings(_id: { regex: "/(drafts.|)registrationSettings/" }, i18n_lang: { eq: $language }) {
      contactConsentText
      termsAndConditionsConsentText: _rawTermsAndConditionsConsentText
    }
  }
`

export default CreateFamilyMember
