import React, { FC, useState } from 'react'
import { useSwipeable } from 'react-swipeable'
import { useSpring, animated } from 'react-spring'
import { ControlDisc, Controls, ScrollableArea, Wrapper } from './SwipeableContent.styled'

interface SwipeableContentProps {
  pages: string[]
  mobileOnly: boolean
  isScrollable: boolean
}

const SwipeableContent: FC<SwipeableContentProps> = ({ pages = [], mobileOnly = false, isScrollable = true }) => {
  const [currentPage, setCurrentPage] = useState(0)
  const props = useSpring({
    transform: `translateX(${100 * (0 - currentPage)}%)`,
  })
  const handleSwipe = (increment) => {
    setCurrentPage(Math.min(Math.max(0, currentPage + increment), pages.length - 1))
  }
  const handlers = useSwipeable({
    onSwipedLeft: () => handleSwipe(1),
    onSwipedRight: () => handleSwipe(-1),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  })
  return (
    <Wrapper {...handlers}>
      <ScrollableArea isScrollable={isScrollable} mobileOnly={mobileOnly} totalPages={pages.length}>
        <animated.div style={props}>
          {pages.map((page) => {
            return <div className="content">{page}</div>
          })}
        </animated.div>
      </ScrollableArea>
      <Controls totalPages={pages.length} mobileOnly={mobileOnly}>
        {pages.map((page, idx) => {
          return (
            <div key={`key${idx}`}>
              <ControlDisc isCurrent={idx === currentPage} type="button" onClick={() => setCurrentPage(idx)}>
                <span>{idx}</span>
              </ControlDisc>
            </div>
          )
        })}
      </Controls>
    </Wrapper>
  )
}

export default SwipeableContent
