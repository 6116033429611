import { HeadingWrapper } from '../../components/HeadingWrapper/HeadingWrapper.styled'
import { Heading } from '../../components/Heading/Heading.styled'
import { SubHeading } from '../../components/SubHeading/SubHeading.styled'
import SEO from '../../components/seo'
import LinkButton from '../../components/LinkButton/LinkButton'
import React, { FC, useEffect, useState } from 'react'
import useAuth from '../../contexts/AuthContext/useAuth'
import Loading from '../../components/Loading'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import { useConfirmEmailMutation } from '../../generated/api'

export type ConfirmEmailFeedbackProps = {
  location?: {
    search?: string
  }
}

const ConfirmEmailFeedback: FC<ConfirmEmailFeedbackProps> = ({ location }) => {
  const { user, isInitialized } = useAuth()
  const [isVerified, setIsVerified] = useState(false)
  const { t } = useTranslation()

  const { mutateAsync: confirmEmail } = useConfirmEmailMutation()

  useEffect(() => {
    ;(async () => {
      const parameters = new URLSearchParams(location?.search)
      const code = parameters?.get('code')
      const playerId = parameters?.get('playerId')?.replace('_', '#')
      if (code) {
        try {
          await confirmEmail({ input: { code: Number(code), id: playerId } })
          setIsVerified(true)
        } catch {
          setIsVerified(false)
        }
        return
      }
      return
    })()
  }, [location])

  if (!isInitialized) return <Loading />

  return (
    <>
      <SEO title={t('confirmEmailFeedback.seoTitle')} />
      <HeadingWrapper tall style={{ marginTop: '10rem' }}>
        {user?.profile.isVerified || isVerified ? (
          <Heading>{t('confirmEmailFeedback.verified')}</Heading>
        ) : (
          <Heading>{t('confirmEmailFeedback.verifying')}</Heading>
        )}
        {!user?.profile.isRegistered && (
          <SubHeading as="h2" secondaryForm veryTall>
            {t('confirmEmailFeedback.subtitle')}
          </SubHeading>
        )}
        <LinkButton to={'/'}>{t('common.continue') || ''}</LinkButton>
      </HeadingWrapper>
    </>
  )
}

export const query = graphql`
  query ConfirmEmailFeedback($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
  }
`

export default ConfirmEmailFeedback
