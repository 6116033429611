import React, { FC } from 'react'
import numeral from 'numeral'

import { StyledResource, Meta } from './Resources.styled'
import SanityImage from '../RichText/SanityImage'

export interface ItemsProps {
  _key: string | number
  alt?: string
}

interface ResourcesProps {
  title: string
  items: ItemsProps[]
}

const Resources: FC<ResourcesProps> = ({ title = '', items }) => (
  <>
    {title && <h2>{title}</h2>}
    {items && items.map((v) => <Resource key={v._key} {...v} />)}
  </>
)

interface ResourceProps {
  thumbnail?: string
  title?: string
  alt?: string
  asset?: {
    url: string
    size: number | string
    extension: string
  }
}

const Resource: FC<ResourceProps> = ({ thumbnail = undefined, title = 'File', asset: file = undefined, alt }) => (
  <StyledResource>
    <Meta>
      {file && (
        <a href={file.url} target="_blank" rel="noopener noreferrer">
          {title}
        </a>
      )}
      {file && file.extension && (
        <span>
          {/* KB/MB in Base 10 IEC definition: https://en.wikipedia.org/wiki/Kilobyte */}
          {numeral(file.size).format('0.0 b')} - {file.extension.toUpperCase()}
        </span>
      )}
    </Meta>
    {thumbnail && (
      <a href={file?.url} target="_blank" rel="noopener noreferrer" aria-label={file?.url}>
        <SanityImage image={thumbnail} width={60} alt={alt} />
      </a>
    )}
  </StyledResource>
)

export default Resources
