import Wrapper from '../../../../components/Wrapper/Wrapper'
import { SubHeading } from '../../../../components/SubHeading/SubHeading.styled'
import RichText from '../../../../components/RichText'
import Spacer from '../../../../components/Spacer'
import VideoCta from '../../../../components/VideoCta'
import React, { FC } from 'react'
//import { IGame } from '../../../interfaces/game.interface'

interface IProps {
  game: any //IGame
}

const Introduction: FC<IProps> = ({ game }) => {
  const { introductionHeading, introductionContent, introductionVideoCta } = game.content

  return (
    <>
      <Wrapper>
        {introductionHeading && (
          <SubHeading tertiary medium>
            {introductionHeading}
          </SubHeading>
        )}
        {introductionContent && <RichText blocks={introductionContent} context={{ game }} />}
      </Wrapper>
      <Spacer />
      <Wrapper noMobileMargins>{introductionVideoCta && <VideoCta videoCta={introductionVideoCta} />}</Wrapper>
      <Spacer />
    </>
  )
}

export default Introduction
