import styled from 'styled-components'
import device from '../../../styles/device'
import LinkButton, { LinkButtonProps } from '../../../components/LinkButton/LinkButton'
import { Heading } from '../../../components/Heading/Heading.styled'
import { FC } from 'react'

export const Error = styled.p`
  color: ${(props) => props.theme.colors.error};
  margin-bottom: 24px;
`

export const LeaderboardWrap = styled.div`
  width: 100%;
  @media ${device.tabletPortrait} {
    width: 608px;
  }
  margin: 20px auto;
`

export const LeaderboardLink: FC<LinkButtonProps> = styled(LinkButton)`
  margin: 20px auto;
  width: 190px;
`

export const InlineHeading = styled(Heading)`
  display: block;
  span {
    color: ${(props) => props.theme.colors.primary};
    display: inline;
  }
  @media ${device.mobOnly} {
    margin-top: 2rem;
    font-size: 4rem;
    span {
      display: block;
      font-size: 2.8rem;
    }
  }
`

export const HeadingWrap = styled.div`
  text-align: center;
  @media ${device.tabletPortrait} {
    margin-bottom: 3rem;
  }
`

export const ConfettiMask = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
  overflow: hidden;
  svg,
  svg > image {
    width: 100%;
    height: auto;
  }
  &:before {
    content: '';
    height: 100px;
    position: absolute;
    width: 100%;
    bottom: 0;
    background: linear-gradient(rgba(255, 255, 255, 0.001), #fff) left repeat;
  }
`

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ButtonLink: FC<LinkButtonProps> = styled(LinkButton)`
  margin: 20px auto;
  width: 190px;
`

export const SubTitle = styled('span')`
  max-width: ${(props) => props.theme.siteWidth};
  margin-top: 5px;
  text-align: center;
  font-size: 16px;
  color: ${(props) => props.theme.colors.body};
`

export const MainSubTitle = styled(SubTitle)`
  min-height: 60px;
  max-width: 140px;
`

export const AwardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  min-width: 120px;
  margin-bottom: 15px;
`
