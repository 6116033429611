import React, { FC } from 'react'
import Modal from 'react-modal'
import Cross from '../../assets/images/svg/cross.svg'
import { CloseButton, ModalHeading } from './Modal.styled'
import { useTranslation } from 'gatsby-plugin-react-i18next'

interface SiteModalProps {
  modalOpen?: boolean
  closeModal?: () => void
  ariaLabel?: string
  children?: JSX.Element | JSX.Element[]
  modalType?: {
    content?: {
      [key: string]: string
    }
  }
  overlayClassName?: string
  heading?: string
  customStyle?: {
    [key: string]: string
  }
  modalRef?: unknown
}

const SiteModal: FC<SiteModalProps> = ({
  modalOpen,
  closeModal,
  ariaLabel,
  children,
  modalType,
  overlayClassName,
  heading,
  customStyle = {},
}) => {
  const { t } = useTranslation()
  if (modalType?.content) {
    modalType.content = { ...modalType.content, ...customStyle }
  }

  return (
    <Modal
      overlayClassName={overlayClassName || ''}
      isOpen={modalOpen}
      ariaHideApp={false}
      style={{ ...modalType, ...customStyle }}
      onRequestClose={() => closeModal && closeModal()}
      closeTimeoutMS={250}
      aria={{
        label: ariaLabel,
        expanded: !!modalOpen,
        hidden: !modalOpen,
      }}
      htmlOpenClassName="ReactModal__Html--open"
      parentSelector={() => document.getElementById('layout-outer-container')}
    >
      {heading && <ModalHeading>{heading}</ModalHeading>}
      <CloseButton onClick={() => closeModal && closeModal()} aria-label={t('aria.label.close')}>
        <Cross />
      </CloseButton>
      {children}
    </Modal>
  )
}

export default SiteModal
