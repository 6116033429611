export enum AwardType {
  points = 'points',
  swipes = 'swipes',
}

export interface IAward {
  description: string
  icon: {
    asset: {
      url: string
    }
  }
  iconBackground: {
    rgb: {
      r: number
      g: number
      b: number
      a: number
    }
  }
  motivator: string
  name: string
  range: number
  type: AwardType
  alt?: string
}
