import styled from 'styled-components'
import device from '../../styles/device'
import Pin from '../Pin/Pin'

export const TapList = styled.div`
  height: 530px;
  overflow-y: auto;
  margin: 2rem -2rem 2rem -2rem;
  padding-bottom: 10rem;
`

export const StyledTapListItem = styled.li`
  color: ${(props) => props.theme.colors.body};
  ${(props) => props.theme.borders.dottedBottom};
  display: flex;
  align-items: center;
  position: relative;
  list-style-type: none;
  padding: 1rem 0;
  font-size: ${(props) => props.theme.fonts.s};
  margin: 0 1.7rem;

  @media ${device.mobLandscape} {
    font-size: ${(props) => props.theme.fonts.m};
    margin: 0 2rem;
  }
  :last-child {
    border: none;
  }
`

export const StyledIconContainer = styled.span`
  position: relative;
  display: flex;
  justify-content: center;

  svg {
    width: 35px;
    height: 50px;

    @media ${device.mobLandscape} {
      width: 45px;
      height: 65px;
    }
  }
`

export const StyledTapListItemRank = styled.p`
  position: absolute;
  color: ${(props) => props.theme.colors.white};
  font-weight: bold;
  margin: 0;
  font-size: 12px;
  top: 9px;
  letter-spacing: -1px;

  @media ${device.mobLandscape} {
    font-size: 16px;
    top: 11px;
  }
`

export const StyledTapListItemCode = styled.span`
  font-weight: bold;
`

export const StyledTapListItemPoints = styled.span`
  color: ${(props) => props.theme.colors.primary};
  margin-left: auto;
  margin-right: 1rem;
  @media ${device.mobPortrait} {
    margin-right: 1.4rem;
  }
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.4rem;

  span {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
`
export const SmallPin = styled(Pin)`
  font-size: 10px;
  padding-top: 3px;
  margin-right: 15px;
`
