import React from 'react'

const MoreHorizontalIcon = ({ width = 32, height = 32, ...restProps }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="MoreHorizIcon"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...restProps}
    >
      <path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
    </svg>
  )
}

export default MoreHorizontalIcon
