import styled from 'styled-components'
import { prop } from 'styled-tools'
import device from '../../styles/device'

export const Heading = styled.h1`
  margin: 0 1rem 0 1rem;
  word-wrap: break-word;
  text-align: ${(props) => props.textAlign || 'center'};
  color: ${(props) => (props.secondary ? props.theme.colors.primary : props.theme.colors.body)};
  margin-bottom: ${prop('marginBottom', '3rem')};
  font-size: ${(props) => props.fontSize || '2.8rem'};

  @media ${device.mobLandscape} {
    margin-bottom: ${prop('marginBottom', '3.5rem')};
    font-size: 4rem;
  }
  @media ${device.tabletLandscape} {
    margin-bottom: ${prop('marginBottom', '4rem')};
    font-size: 5rem;
    line-height: 55px;
  }

  ${(props) =>
    props.tertiary &&
    `
        font-family: ${props.theme.fonts.primaryFont};
        font-weight: normal;
        font-size: 2.4rem;

        @media ${device.mobLandscape} {
            font-size: 3.3rem;
        }

        @media ${device.tabletLandscape} {
            font-size: 5rem;
        }
    `};

  ${(props) =>
    props.landing &&
    `
        margin-top: 4rem;
        font-size: 2.4rem;

        @media ${device.mobLandscape} {
            font-size: 3.8rem;
        }

        @media ${device.tabletLandscape} {
            font-size: 5rem;
        }

    `};
  ${(props) =>
    props.verySmall &&
    `
        margin-bottom: 0.6rem;

        @media ${device.mobLandscape} {
            margin-bottom: 0.8rem;
        }

        @media ${device.tabletLandscape} {
            margin-bottom: 1rem;
        }
    `}
  ${(props) =>
    props.small &&
    `
        margin-bottom: 1.7rem;

        @media ${device.mobLandscape} {
            margin-bottom: 2rem;
        }

        @media ${device.tabletLandscape} {
            margin-bottom: 2.3rem;
        }
    `}
    ${(props) =>
    props.medium &&
    `
        margin-bottom: 3rem;

        @media ${device.mobLandscape} {
            margin-bottom: 3.5rem;
        }

        @media ${device.tabletLandscape} {
            margin-bottom: 4rem;
        }
    `}
    ${(props) =>
    props.tall &&
    `
        margin-bottom: 3.5rem;
        @media ${device.mobLandscape} {
            margin-bottom: 4rem;
        }

        @media ${device.tabletLandscape} {
            margin-bottom: 4.5rem;
        }
    `}

    ${(props) =>
    props.contentPage &&
    `
        text-align: left;
        margin-left: 3rem;
        margin-bottom: 3rem;

        @media ${device.mobLandscape} {
            margin-left: 0;
            text-align: center;
            margin-bottom: 5rem;
        }

        @media ${device.tabletLandscape} {
            margin-bottom: 7.5rem;
        }
    `}
    ${(props) =>
    props.smallFont &&
    `
        font-size: 2.5rem

        @media ${device.mobLandscape} {
            font-size: 3rem; 
        }

        @media ${device.tabletLandscape} {
            font-size: 3.5rem; 
        }
    `}
`
