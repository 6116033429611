import React, { FC } from 'react'
import { format, isToday, isTomorrow } from 'date-fns'
import {
  StyledCard,
  StyledCardContainer,
  StyledCardTitle,
  StyledCardDate,
  StyledCardBody,
  StyledButton,
  SizeText,
} from './Card.styled'
import { BodyEllipsis, TitleEllipsis } from '../../utils/ellipsis'
import RichText from '../RichText'
import { getImage, ImageDataLike, GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { Block } from '../CardGrid/CardGrid'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { ContextProps } from '../CallToAction/CallToActionPanel'
import { Maybe } from '../../generated/api'

export interface CardProps {
  context?: ContextProps
  title?: string
  image?: ImageDataLike
  startDate?: Date | string
  startTime?: Date | string
  endDate?: Date | string
  endTime?: Date | string
  body?: Maybe<Block>
  externalUrl?: string
  gridSize?: number
  file?: any
  alt?: string
}

const Card: FC<CardProps> = ({
  context,
  title,
  image,
  startDate,
  startTime,
  endDate,
  endTime,
  body,
  externalUrl,
  gridSize,
  file,
  alt,
}) => {
  const { t } = useTranslation()
  const getStartDateDisplay = (): string => {
    if (!startDate) return ''

    const today: boolean = isToday(new Date(startDate))
    const tomorrow: boolean = isTomorrow(new Date(startDate))

    let dateString = `${startDate}`
    let formatString = ''
    let prefix = ''

    if (today) {
      prefix = t('common.today')
    } else if (tomorrow) {
      prefix = t('common.tomorrow')
    }

    if (!today && !tomorrow) {
      formatString = 'EEE d MMM'
    }

    if (startTime) {
      dateString += `T${startTime}Z`
      formatString += " h:mmaaaaa'm'"
    }
    return `${prefix}${formatString && format(new Date(dateString), formatString)}`
  }

  const getEndDateDisplay = (): string => {
    if (!endDate) return ''
    let dateString = ''
    let formatString = ''
    if (endDate && endDate !== startDate) {
      formatString = 'EEE d MMM'
      dateString = `${endDate}`
    } else {
      dateString = `${startDate}`
    }

    if (endTime) {
      if (endDate && endDate !== startDate) {
        formatString += " h:mmaaaaa'm'"
      } else {
        formatString = "h:mmaaaaa'm'"
      }

      dateString += `T${endTime}Z`
    }

    if (!dateString || !formatString) {
      return ''
    }

    return format(new Date(dateString), formatString)
  }

  const getDateDisplay = (): string => {
    let displayString = getStartDateDisplay()
    const endDateDisplay = getEndDateDisplay()
    if (endDateDisplay) {
      displayString += ` - ${endDateDisplay}`
    }
    return displayString
  }

  return (
    <StyledCard gridSize={gridSize}>
      <div>
        {image && <GatsbyImage image={getImage(image) as IGatsbyImageData} alt={alt || title || ''} />}
        <StyledCardContainer>
          {title && <StyledCardTitle>{TitleEllipsis(title)}</StyledCardTitle>}
          <StyledCardDate>{getDateDisplay()}</StyledCardDate>
          {body && <StyledCardBody>{BodyEllipsis(<RichText blocks={body} context={context} />)}</StyledCardBody>}
          {externalUrl && (
            <a href={externalUrl} target="_blank" rel="noopener noreferrer">
              {t('game.activities.card.details')}
            </a>
          )}
          {file && file.url && (
            <StyledButton
              link
              text={t('game.activities.card.download')}
              onClick={() => {
                window.open(file.url, '_blank')
              }}
            />
          )}
          {file && file.size && <SizeText>{`${Math.round(Number(file.size) / 1000)} KB - ${file.extension}`}</SizeText>}
        </StyledCardContainer>
      </div>
    </StyledCard>
  )
}

export default Card
