import styled from 'styled-components'
import device from '../../styles/device'

export const SubHeading = styled.h2`
  text-align: ${(props) => props.textAlign || 'center'};
  color: ${(props) => (props.secondary ? props.theme.colors.primary : props.theme.colors.body)};

  font-size: ${(props) => props.fontSize || '2.8rem'};
  margin-bottom: 0;

  ${(props) =>
    props.tertiary &&
    `
        font-family: ${props.theme.fonts.primaryFont};
        font-weight: normal;
        font-size: 2rem;

        @media ${device.mobLandscape} {
            font-size: 2.5rem;
        }
    
        @media ${device.tabletLandscape} {
            font-size: 3rem;
        }
    `};
  ${(props) =>
    props.tertiarySmall &&
    `
        font-family: ${props.theme.fonts.primaryFont};
        font-weight: normal;
        font-size: 1.6rem;
        
        @media ${device.mobLandscape} {
          font-size: 1.8rem;
        }
    
        @media ${device.tabletLandscape} {
          font-size: 2.2rem;
        }
    `};
  ${(props) =>
    props.secondaryForm &&
    `
        font-family: ${props.theme.fonts.primaryFont};
        font-weight: normal;
        font-size: 2rem;
        color: ${props.theme.colors.primary};
        margin-bottom: 1.7rem;
        
        @media ${device.mobLandscape} {
          font-size: 2.2rem;
            margin-bottom: 2rem;
        }
    
        @media ${device.tabletLandscape} {
          font-size: 2.4rem;
            margin-bottom: 2.3rem;
        }
    `};

  ${(props) =>
    props.tertiaryForm &&
    `
        font-size: 1.6rem;
        
        @media ${device.mobLandscape} {
          font-size: 1.8rem;
        }
    
        @media ${device.tabletLandscape} {
          font-size: 2.2rem;
        }
    `};
  ${(props) =>
    props.tertiaryFormHeader &&
    `
        font-size: 1.5rem;
        margin-bottom: 1.8rem;
    `};

  ${(props) =>
    props.verySmall &&
    `
        margin-bottom: 0.6rem;

        @media ${device.mobLandscape} {
            margin-bottom: 0.8rem;
        }

        @media ${device.tabletLandscape} {
            margin-bottom: 1rem;
        }
    `}

  ${(props) =>
    props.small &&
    `
        margin-bottom: 1.7rem;

        @media ${device.mobLandscape} {
            margin-bottom: 2rem;
        }

        @media ${device.tabletLandscape} {
            margin-bottom: 2.3rem;
        }
    `}
    ${(props) =>
    props.medium &&
    `
        margin-bottom: 2rem;

        @media ${device.mobLandscape} {
            margin-bottom: 2.3rem;
        }

        @media ${device.tabletLandscape} {
            margin-bottom: 2.5rem;
        }
    `}
    ${(props) =>
    props.tall &&
    `
        margin-bottom: 2.3rem;

        @media ${device.mobLandscape} {
            margin-bottom: 2.5rem;
        }

        @media ${device.tabletLandscape} {
            margin-bottom: 3rem;
        }
    `}

    ${(props) =>
    props.veryTall &&
    `
        margin-bottom: 6rem;

        @media ${device.mobLandscape} {
            margin-bottom: 7rem;
        }

        @media ${device.tabletLandscape} {
            margin-bottom: 8rem;
        }
    `}
`
