import { object, string, date } from 'yup'

export const getNameDetailsSchema = (t2, minValidDate) => {
  const t = (...args: any) => t2(...args) || undefined
  return object({
    firstName: string().max(50, t('player.errors.maxCharFN')).required(t('player.errors.firstName')),
    publicName: string().max(16, t('player.errors.maxCharDN')).required(t('player.errors.displayName')),
    birthDate: date()
      .max(minValidDate, t('player.errors.minValidBirthDate'))
      .nullable()
      .required(t('player.errors.birthDate')),
  })
}
