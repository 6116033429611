import React, { useEffect, useMemo } from 'react'
import { GameProvider } from '../GameContext/gameContext'
import ReactDOM from 'react-dom'
import PageContext, { IPageContext } from '../PageContext'
import { GatsbyBrowser, graphql, useStaticQuery } from 'gatsby'
import LayoutContent from './layout'
type LayoutProps = GatsbyBrowser<Record<string, unknown>, IPageContext>['wrapPageElement']

const Layout: LayoutProps = ({ props: { pageContext, data, path }, element }) => {
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      import('@axe-core/react').then((axe) => {
        axe.default(React, ReactDOM, 1000)
      })
    }
  }, [path])

  const generatedPages = useStaticQuery(graphql`
    query {
      pages: allSitePage {
        nodes {
          path
        }
      }
    }
  `)

  const pageList = useMemo(() => generatedPages?.pages?.nodes?.map((page) => page?.path), [generatedPages])

  return (
    <>
      <PageContext.Provider value={{ ...pageContext, pageList, sanityData: data }}>
        <GameProvider game={pageContext.game}>
          <LayoutContent>{element}</LayoutContent>
        </GameProvider>
      </PageContext.Provider>
    </>
  )
}

export default Layout
