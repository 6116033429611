import styled from 'styled-components'
import device from '../../styles/device'

export const Overlay = styled.div`
  cursor: pointer;
  position: relative;
  max-width: 800px;
  margin: 0 auto;

  img {
    width: 100%;
  }
`
export const ContentGradient = styled.div`
  background: rgba(1, 1, 1, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 1) 100%) repeat scroll 0 0;
  position: relative;
  margin-top: -140px;
  height: 140px;
  @media ${device.tabletPortrait} {
    margin-top: -170px;
    height: 170px;
  }
`
export const Content = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  transition: margin ${(props) => props.theme.transitions.quickTransition};

  ${Overlay}:hover & {
    margin-left: 4px;
  }

  @media ${device.tabletPortrait} {
    bottom: 4rem;
    left: 4rem;
  }
`

export const Title = styled.p`
  margin: 0 0 0 1rem;
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fonts.secondaryFont};
  font-size: ${(props) => props.theme.fonts.l};
  @media ${device.tabletPortrait} {
    font-size: ${(props) => props.theme.fonts.xl};
  }
`

export const PlayButton = styled.div`
  background-color: ${(props) => props.theme.colors.white70};
  padding: 0;
  border-radius: 0.2rem;
  width: 46px;
  height: 46px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  @media ${device.tabletPortrait} {
    width: 60px;
    height: 60px;
  }
  div {
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 18px solid green;
    @media ${device.tabletPortrait} {
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
      border-left: 22px solid green;
    }
  }
`

export const VideoWrapper = styled.div`
  position: relative;
  text-align: center;
  margin: 0 auto;
  .player {
    margin: 0 auto;

    width: 100%;
    height: 100%;
  }
`

export const StyledReferenceDiv = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  ${(props) => (props.modalOpen ? `display: block;` : `display: none;`)}
`
