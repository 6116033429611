import styled from 'styled-components'

export const StyledRadio = styled.div`
  position: relative;
  display: flex;
  margin: 0 1.5rem 7px 0;
  min-height: ${(props) => props.theme.radioCheckboxWidth};
  padding-top: 0px;
  label {
    padding-top: 6px;
    font-size: 14px;
    line-height: 14px;
    cursor: pointer;
  }
  input {
    cursor: pointer;
  }
`
