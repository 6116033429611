import React from 'react'
import { ErrorMessage, Formik } from 'formik'
import { Error, FormItem } from '../../../../components/FormElements/FormElements.styled'
import Input from '../../../../components/Input/Input'
import startCase from 'lodash/startCase'
import Button from '../../../../components/Button/Button'
import Wrapper from '../../../../components/Wrapper/Wrapper'
import useAuth from '../../../../contexts/AuthContext/useAuth'
import Tooltip from '../../../../components/Tooltip/Tooltip'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { TagTypes, useAssignTagMutation } from '../../../../generated/api'
import { getConnectCardSchema } from './validation'

export const ConnectCard = ({ step, setStep }) => {
  const { user } = useAuth()
  const { t } = useTranslation()
  const connectCardSchema = getConnectCardSchema(t)

  const { mutateAsync: createTag } = useAssignTagMutation()

  return (
    <>
      <Wrapper width="400px" textAlign="left">
        <Formik
          enableReinitialize
          initialValues={{
            tagNumber: undefined,
            tagType: TagTypes.Card,
            code: '',
            submit: null,
          }}
          onSubmit={async (values, { setErrors }) => {
            try {
              if (user?.id) {
                await createTag({
                  input: {
                    tagNumber: values.tagNumber,
                    tagType: values.tagType,
                    playerId: user?.id,
                    code: values?.code,
                  },
                })
              }

              if (user?.info.teamId) {
                setStep(step + 2)
              } else {
                setStep(step + 1)
              }
            } catch (e) {
              if (e?.errors?.[0].message.includes('This card is connected to different game')) {
                setErrors({ submit: t('errors.api.addCard.differentGame') || undefined })
              } else if (e?.errors?.[0].message.includes('There is already used tag with this number')) {
                setErrors({ submit: t('errors.api.addCard.usedTag') || undefined })
              } else if (e?.errors?.[0].message.includes('Activation code is required for a card tag.')) {
                setErrors({ submit: t('errors.api.addCard.codeRequired') || undefined })
              } else if (e?.errors?.[0].message.includes('Activation code is not valid.')) {
                setErrors({ submit: t('errors.api.addCard.codeNotValid') || undefined })
              } else {
                setErrors({ submit: t('addCard.errorMessage') || undefined })
              }
            }
          }}
          validationSchema={connectCardSchema}
        >
          {({ values, handleChange, handleBlur, handleSubmit, isSubmitting, touched, errors }) => {
            return (
              <form onSubmit={handleSubmit} noValidate>
                <>
                  <FormItem>
                    <Input
                      type="number"
                      id="tagNumber"
                      text={t('gameSetup.connectCard.inputs.tagNumber', { tagType: startCase(values.tagType) }) || ''}
                      value={values.tagNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.tagNumber && errors?.tagNumber
                          ? typeof errors.tagNumber === 'string'
                            ? errors.tagNumber
                            : errors.tagNumber[0]
                          : undefined
                      }
                      inputProps={{ min: 0 }}
                      tooltip={<Tooltip text={t('gameSetup.connectCard.inputs.cardTooltip') || ''} />}
                    />
                  </FormItem>
                  <FormItem>
                    <Input
                      type="text"
                      id="code"
                      text={t('gameSetup.connectCard.inputs.code') || ''}
                      value={values.code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.code ? errors.code : undefined}
                    />
                  </FormItem>
                </>
                <ErrorMessage name="submit" component={(props) => <Error className="checkboxError" {...props} />} />
                <Button
                  type="submit"
                  isSubmitting={isSubmitting}
                  disabled={
                    isSubmitting ||
                    !values.tagNumber ||
                    (values.tagType === TagTypes.Card && (!values.tagNumber || !values.code))
                  }
                  text={t('common.next')}
                  secondary
                />
              </form>
            )
          }}
        </Formik>
      </Wrapper>
    </>
  )
}
