import React, { FC } from 'react'
import CTA from '../CallToAction'
// import { Cta } from '../../interfaces/cta.interface'
import { ContextProps } from '../../components/CallToAction/CallToActionPanel'

export interface Cta {
  icon?: string
  link?: any
  size?: string
  style?: string
  text?: string
  title?: string
  context?: ContextProps
  _id?: string | number
  internalLinkHash?: string
}

const CtaWrapper: FC<Cta> = ({ context, title, text, style, size, link, icon, _id: id, internalLinkHash }) => {
  const extraProps = id ? {} : { key: id }
  return (
    <CTA
      {...{
        context: {
          ...context,
          hash: internalLinkHash,
        },
        title,
        text,
        style,
        size,
        icon,
        link,
      }}
      {...extraProps}
    />
  )
}

export default CtaWrapper
