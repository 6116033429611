import { useCallback, useState } from 'react'
import throttle from 'lodash/throttle'
import useGame from '../../../../contexts/GameContext/useGame'

const useSuggestions = () => {
  const { game } = useGame()
  const [suggestions, setSuggestions] = useState([])
  const getPoints = async (query: string) => {
    try {
      const res = await fetch(
        `https://geocoder.cycle.travel/api?q=${query.trim()}${
          game?.info.boundingBox ? `&bbox=${game?.info.boundingBox.trim()}` : ''
        }`,
      )
      const data = await res.json()
      if (data?.features) {
        setSuggestions(data.features)
      }
    } catch (e) {
      console.error('Issue with geocoder query')
    }
  }

  const getSuggestion = useCallback(
    throttle((query) => getPoints(query), 2000),
    [],
  )

  return {
    suggestions,
    getSuggestion,
  }
}

export default useSuggestions
