import React, { FC, useState } from 'react'
import Loading from '../../../components/Loading'
import { LeaderboardProps, PointsType } from '../interfaces'
import { Container, Loader, ToggleWrap } from '../Leaderboard.styled'
import useAuth from '../../../contexts/AuthContext/useAuth'
import Toggle from '../../../components/Toggle/Toggle'
import Tooltip from '../../../components/Tooltip/Tooltip'
import Button from '../../../components/Button/Button'
import { orderBy } from 'lodash'
import RowItem from '../../../components/RowItem/RowItem'
import { LeaderboardWrapper } from '../../../components/LeaderboardTable/LeaderboardTable.styled'
import OptionsButton from './OptionsButton'
import OptionsModal from './OptionsModal'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import useFollowLeaderboards from './useFollowLeaderboards'
import { LeaderboardPointType, TagTypes } from '../../../generated/api'
import { LeaderboardElement } from '../../../components/LeaderboardTable/LeaderboardTable'
import AddFollowPlayerModal from './AddFollowPlayerModal'

const FollowLeaderboard: FC<LeaderboardProps> = ({ mini }) => {
  const { masterUser } = useAuth()

  const { t } = useTranslation()

  const [pointsType, setPointsType] = useState<LeaderboardPointType>(LeaderboardPointType.Swipe)
  const [activeModal, setActiveModal] = useState(false)
  const [selectedPlayerModal, setSelectedPlayerModal] = useState<undefined | string>()

  const { data, isLoading: isDataLoading } = useFollowLeaderboards(pointsType)

  const isUserHasApp = !!masterUser?.tags.find((tag) => tag.tagType === TagTypes.App)

  const followedPlayers = orderBy(data, pointsType === LeaderboardPointType.Swipe ? 'all' : 'gems', 'desc')

  if (isDataLoading) return <Loading />
  return (
    <Container mini={mini}>
      {isDataLoading && !mini && <Loader />}
      {!mini && (
        <>
          <Button
            text={t('game.leaderboards.followButton')}
            style={{ marginBottom: 16 }}
            onClick={() => setActiveModal(true)}
          />
          {isUserHasApp && (
            <ToggleWrap>
              <Toggle
                value={pointsType}
                onChange={(v: LeaderboardPointType) => setPointsType(v)}
                left={{
                  value: LeaderboardPointType.Swipe,
                  text: t(`game.leaderboards.pointsType.${PointsType.beatBoxes}`) || '',
                }}
                right={{
                  value: LeaderboardPointType.Gem,
                  text: t(`game.leaderboards.pointsType.${PointsType.gems}`) || '',
                }}
              />
              <Tooltip overlayPosition="left" text={t('game.leaderboards.typeTooltip') || ''} />
            </ToggleWrap>
          )}
        </>
      )}
      {activeModal && <AddFollowPlayerModal setActiveModal={setActiveModal} />}

      {selectedPlayerModal && (
        <OptionsModal selectedPlayerModal={selectedPlayerModal} setSelectedPlayerModal={setSelectedPlayerModal} />
      )}

      <LeaderboardWrapper>
        {followedPlayers?.slice(0, mini ? 3 : undefined)?.map((filtered: LeaderboardElement) => {
          return (
            <RowItem
              mini={mini}
              key={filtered?.id}
              highlight={false}
              rank={filtered?.position}
              name={filtered?.name || ''}
              points={filtered?.amount}
              options={
                <OptionsButton
                  aria-label={`${t('game.leaderboards.unfollowModal.unfollow')} ${filtered?.name}`}
                  onClick={() => setSelectedPlayerModal(filtered.id)}
                />
              }
            />
          )
        })}
      </LeaderboardWrapper>
    </Container>
  )
}

export default FollowLeaderboard
