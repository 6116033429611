import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const FacebookSocialFeed = ({ username, width = 500, height = 500 }) => {
  const { t } = useTranslation()
  const style = {
    overflow: 'hidden',
    maxWidth: '400px',
    height,
  }
  const iframeSrc = `https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2F${username}&show_posts=true&width=${width}&height=${height}&small_header=true&adrpt_container_width=true&hide_cover=false&show_facepile=true&appId`

  return (
    <iframe
      src={iframeSrc}
      style={style}
      title={t('components.social.facebookFeed', { name: username }) || ''}
      scrolling="no"
      frameBorder={0}
      allowTransparency={true}
      allow="encrypted-media"
    />
  )
}

export default FacebookSocialFeed
