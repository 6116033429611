import React, { FC } from 'react'
import { Error, FormItem } from '../../../components/FormElements/FormElements.styled'
import Input from '../../../components/Input/Input'
import Button from '../../../components/Button/Button'
import { ErrorMessage, Formik } from 'formik'
import useAuth from '../../../contexts/AuthContext/useAuth'
import { HeadingWrapper } from '../../../components/HeadingWrapper/HeadingWrapper.styled'
import { SubHeading } from '../../../components/SubHeading/SubHeading.styled'
import { CustomLink } from '../../../components/Link/Link.styled'
import { Heading } from '../../../components/Heading/Heading.styled'
import Wrapper from '../../../components/Wrapper/Wrapper'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { getForgottenPasswordSchema } from './validate'

interface ForgottenPasswordProps {
  setCompleted: (completed: boolean) => void
}

const ForgottenPassword: FC<ForgottenPasswordProps> = ({ setCompleted }) => {
  const { passwordRecovery } = useAuth()
  const { t } = useTranslation()
  const forgottenPasswordSchema = getForgottenPasswordSchema(t)

  return (
    <>
      <HeadingWrapper>
        <Heading secondary small>
          {t('forgotPassword.title')}
        </Heading>
        <SubHeading as="h2" tertiarySmall tall>
          {t('forgotPassword.subtitle')}
        </SubHeading>
      </HeadingWrapper>
      <Wrapper width="400px" marginTop="1rem" small>
        <Formik
          initialValues={{
            email: '',
            submit: null,
          }}
          validationSchema={forgottenPasswordSchema}
          onSubmit={async ({ email }, { setErrors, setStatus, setSubmitting, resetForm }): Promise<void> => {
            try {
              await passwordRecovery(email)
              setCompleted(true)
              resetForm()
              setStatus({ success: true })
              setSubmitting(false)
            } catch (error) {
              console.error(error)
              setStatus({ success: false })
              if (error.code === 'UserNotFoundException') {
                setErrors({ submit: t('errors.cognito.passwordRecovery.noUser') || undefined })
              } else {
                setErrors({ submit: t('error.generic') || undefined })
              }
              setSubmitting(false)
            }
          }}
        >
          {({ values, errors, handleChange, handleSubmit, handleBlur }) => (
            <form onSubmit={handleSubmit}>
              <FormItem>
                <Input
                  id="email"
                  type="text"
                  text={t('common.email') || ''}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={Boolean(errors.email)}
                  error={errors.email}
                />
              </FormItem>
              <ErrorMessage name="submit" component={(props) => <Error className="checkboxError" {...props} />} />
              <Button text={t('forgotPassword.resetButton')} secondary />
            </form>
          )}
        </Formik>
      </Wrapper>
      <Wrapper veryTall>
        <CustomLink to="/login/">{t('forgotPassword.return')}</CustomLink>
      </Wrapper>
    </>
  )
}

export default ForgottenPassword
