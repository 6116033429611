import { createContext, FC, useEffect, useReducer } from 'react'
import { reducer } from './reducer'
import { AuthContextValue, ICognitoUser, State, User } from './type'
import { Auth } from 'aws-amplify'
import React from 'react'
import env from '../../env'
import localAuth from '../../utils/localAuth'
import fetchPlayer from './fetchPlayer'
import useInitialize from './useInitialize'
import { navigate } from 'gatsby-link'
import localCache from './cacheUser'
import { useSignupPlayerMutation, useGetPlayerByIdQuery } from '../../generated/api'

const initialState: State = {
  isAuthenticated: !!localCache.getUser(),
  isInitialized: false,
  user: localCache.getUser(),
  masterUser: localCache.getParent(),
  family: [],
}

const apziEnv = process.env.GATSBY_ENV

export const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve({} as State['user']),
  changePassword: () => Promise.resolve(),
  passwordRecovery: () => Promise.resolve(),
  passwordReset: () => Promise.resolve(),
  refetch: () => Promise.resolve(),
  setUser: () => Promise.resolve(),
})

const AuthProvider: FC<any> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { mutateAsync: signup } = useSignupPlayerMutation()
  const { data: player } = useGetPlayerByIdQuery(
    {
      id: state.user?.id as string,
    },
    { enabled: !!state.user?.id, placeholderData: { getPlayer: state.user as User } },
  )

  useEffect(() => {
    const masterUser = state.masterUser?.id === player?.getPlayer?.id ? player?.getPlayer : undefined
    dispatch({
      type: 'REFETCH',
      payload: {
        user: player?.getPlayer,
        masterUser: masterUser,
        family: state.family,
      },
    })
    if (player?.getPlayer?.id) {
      localCache.setUser(player?.getPlayer as User)
    }
  }, [player])

  useInitialize(dispatch)

  const login = async (email: string, password: string): Promise<void | ICognitoUser> => {
    if (env.local) return localLogin(email, password)
    const cognitoUser = (await Auth.signIn(email, password)) as ICognitoUser

    if (cognitoUser.challengeName) {
      console.error(
        `Unable to login, because challenge '${cognitoUser.challengeName}' is mandated and the case is not handled.`,
      )
      return
    }

    const { user, family, masterUser } = await fetchPlayer(cognitoUser.getUsername())

    localCache.setUser(user)
    localCache.setParent(masterUser)

    if (typeof window !== 'undefined') {
      window.appziSettings = {
        data: {
          playerId: masterUser?.id,
          env: apziEnv,
        },
      }
      window.dataLayer.push({
        user_id: masterUser?.id,
      })
    }

    dispatch({
      type: 'LOGIN',
      payload: {
        user: user,
        family,
      },
    })
  }

  const localLogin = async (email: string, authID: string) => {
    localAuth.setUsername(authID)
    const { user, family } = await fetchPlayer(authID)
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        family,
      },
    })
  }
  const localLogout = () => {
    localAuth.removeUsername()
    dispatch({
      type: 'LOGOUT',
    })
  }

  const logout = async (): Promise<void> => {
    if (env.local) return localLogout()
    await Auth.signOut()
    dispatch({
      type: 'LOGOUT',
    })
    localCache.cleanCache()
    await navigate('/')
  }

  const register = async (email: string, password: string) => {
    await signup({ input: { email, password } })
    await Auth.signIn({ username: email, password })

    const { user } = await fetchPlayer()

    localCache.setUser(user)
    localCache.setParent(user)

    dispatch({
      type: 'REGISTER',
      payload: {
        user: user,
      },
    })
    return user
  }

  const changePassword = async (password: string, newPassword: string): Promise<void> => {
    const cognitoUser = await Auth.currentAuthenticatedUser()
    await Auth.changePassword(cognitoUser, password, newPassword)
  }

  const passwordRecovery = async (username: string): Promise<void> => {
    await Auth.forgotPassword(username)
  }

  const passwordReset = async (username: string, code: string, newPassword: string): Promise<void> => {
    await Auth.forgotPasswordSubmit(username, code, newPassword)
  }

  const refetch = async () => {
    if (state.masterUser?.id) {
      const { user, family, masterUser } = await fetchPlayer()

      localCache.setUser(user)
      localCache.setParent(masterUser)

      const res = {
        user: user,
        masterUser: masterUser,
        family,
      }
      dispatch({
        type: 'REFETCH',
        payload: res,
      })
      return res
    }
    return
  }

  const setUser = async (id: string) => {
    const localFamily = state.family
    const localUser = localFamily.find((u) => u.id === id)
    if (localUser) {
      dispatch({
        type: 'SET_USER',
        payload: {
          user: localUser,
        },
      })
    }
    try {
      const res = await refetch()
      const user = res?.family.find((u) => u.id === id)
      if (user) {
        dispatch({
          type: 'SET_USER',
          payload: {
            user,
          },
        })
        localCache.setUser(user)
      } else {
        throw new Error('no family')
      }
    } catch {
      const prevUser = localCache.getUser()
      dispatch({
        type: 'SET_USER',
        payload: {
          user: prevUser,
        },
      })
      return
    }
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        changePassword,
        passwordRecovery,
        passwordReset,
        refetch,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
