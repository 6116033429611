import React, { FC, useEffect, useMemo } from 'react'
import { graphql } from 'gatsby'
import urlSlug from 'url-slug'
import SEO from '../../components/seo'
import RichText from '../../components/RichText'
import Wrapper from '../../components/Wrapper/Wrapper'
import { Heading } from '../../components/Heading/Heading.styled'
import QuestionsBlock from '../../components/QuestionsBlock/QuestionsBlock'
import QuestionsBlockItem from '../../components/QuestionsBlockItem/QuestionsBlockItem'
import { AnchorLinks } from './faqs.styled'
import { IPageProps } from '../../contexts/PageContext'
import useGame from '../../contexts/GameContext/useGame'
import { SanityFaqs } from 'src/generated/api'

const FAQsPage: FC<IPageProps<Queries.FAQsPageQuery>> = ({ location, data }) => {
  const { game } = useGame()

  const { title, _rawIntro: intro, _rawQuestions } = (data.sanityGame?.faqs || {}) as SanityFaqs

  useEffect(() => {
    // Go to the right heading after page loaded.
    const { hash } = location
    if (hash) {
      const domElement = document.querySelector(hash)
      if (domElement) {
        domElement.scrollIntoView()
      }
    }
  }, [])

  const questions = useMemo(
    () =>
      _rawQuestions?.reduce((acc, v) => {
        let counter: number | null = null
        let slugifiedQuestion

        do {
          slugifiedQuestion = urlSlug(v.question)
          if (counter === null) {
            counter = 0
          } else {
            slugifiedQuestion = `${slugifiedQuestion}-${counter}`
          }
          counter += 1
        } while (acc.map((v2) => v2.slug).includes(slugifiedQuestion))

        acc.push({
          ...v,
          slug: slugifiedQuestion,
        })
        return acc
      }, []),
    [],
  )

  const chevron = data.images.edges.find((n) => {
    return n.node.relativePath.includes('down-chevron')
  })

  return (
    <>
      <SEO title={`${title} | ${game?.info.name}`} />
      <Wrapper width="850px" textAlign="left">
        <Heading contentPage>{title}</Heading>
        <RichText blocks={intro} context={{ game }} />
        <AnchorLinks background={chevron?.node.publicURL}>
          {questions?.map((v, idx) => (
            <li key={idx}>
              <a href={`#q-${v.slug}`}>{v.question}</a>
            </li>
          ))}
        </AnchorLinks>

        <QuestionsBlock>
          {questions?.map((v) => (
            <QuestionsBlockItem
              key={v._key}
              question={v.question}
              linkName={v.slug}
              answer={<RichText context={{ game }} blocks={v.answer} />}
            />
          ))}
        </QuestionsBlock>
      </Wrapper>
    </>
  )
}

export default FAQsPage

export const query = graphql`
  query FAQsPage($gameSlug: String, $language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
    sanityGame(slug: { eq: $gameSlug }, i18n_lang: { eq: $language }) {
      faqs {
        ... on SanityFaqs {
          id
          title
          _rawQuestions(resolveReferences: { maxDepth: 15 })
          _rawIntro(resolveReferences: { maxDepth: 15 })
        }
      }
    }
    images: allFile {
      edges {
        node {
          relativePath
          publicURL
          name
        }
      }
    }
  }
`
