import React, { FC } from 'react'
import { ThreeDots } from 'react-loader-spinner'
import { StyledButton } from './Button.styled'
import SVGImage from '../DynamicImg/SVGImage'

interface ButtonProps {
  id?: string
  style?: any
  disabled?: boolean
  icon?: {
    alt?: string
    filename?: string
    width?: number
    height?: number
  }
  iconComponent?: any
  text?: string | React.ReactNode
  secondary?: boolean
  tertiary?: boolean
  onClick?: (e: any) => void
  width?: number | string
  border?: boolean | string
  isSubmitting?: boolean
  className?: string
  iconOnly?: boolean
  showArrow?: boolean
  link?: boolean
  smallFont?: boolean
  type?: string
  tabIndex?: number
  testID?: string
}

const Button: FC<ButtonProps> = ({
  id,
  iconComponent,
  disabled,
  icon,
  text,
  secondary,
  tertiary,
  onClick,
  width,
  border,
  isSubmitting,
  className,
  iconOnly,
  showArrow,
  link,
  smallFont,
  tabIndex,
  style,
  type,
  testID,
}) => (
  <StyledButton
    id={id}
    className={className}
    secondary={secondary}
    tertiary={tertiary}
    onClick={onClick}
    disabled={disabled}
    isSubmitting={isSubmitting}
    width={width}
    border={border}
    iconOnly={iconOnly}
    showArrow={showArrow}
    link={link}
    smallFont={smallFont}
    tabIndex={tabIndex || 0}
    style={style}
    type={type}
    data-testid={testID}
  >
    {iconComponent}
    {icon && (
      <SVGImage
        alt={icon.alt}
        filename={icon.filename}
        svgProperties={{
          width: icon.width || 50,
          height: icon.height || 50,
        }}
      />
    )}
    {text}
    {showArrow && (
      <svg
        height="1em"
        width="1em"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
        className="arrow"
      >
        <g>
          <polyline points="12 5 19 12 12 19" />
        </g>
      </svg>
    )}
    {isSubmitting && (
      <ThreeDots
        color="#ffffff"
        height={15}
        width={50}
        wrapperStyle={{ justifyContent: 'center' }}
        wrapperClass="loader"
      />
    )}
  </StyledButton>
)

export default Button
