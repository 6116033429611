import React, { FC, useState } from 'react'
import Modal from '../../components/Modal/Modal'
import styled from 'styled-components'
import { modalSmall } from '../../components/Modal/Modal.styled'
import Button from '../../components/Button/Button'
import TapHistoryContainer from '../TapHistoryContainer'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const Wrapper = styled.div`
  &:hover {
    cursor: pointer;
    button {
      text-decoration: underline;
      background-color: transparent !important;
    }
  }
  button {
    background-color: transparent !important;
  }
`

interface ShowJourneyProps {
  children?: JSX.Element | JSX.Element[]
}

const ShowJourney: FC<ShowJourneyProps> = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const { t } = useTranslation()

  return (
    <>
      <Modal
        modalOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
        ariaLabel="Journey list"
        modalType={modalSmall}
        overlayClassName="is-narrow is-tap-list"
        heading={t('game.dashboard.stats.journeyModal.title') || ''}
      >
        <TapHistoryContainer />
      </Modal>
      <Wrapper onClick={() => setModalOpen(true)}>
        {children} <Button link text={t('game.dashboard.stats.activityElement.journey')} />
      </Wrapper>
    </>
  )
}

export default ShowJourney
