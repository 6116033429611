import styled from 'styled-components'

export const StyledMenu = styled.div`
  position: relative;
`

export const StyledMenuList = styled.div`
  position: absolute;
  top: 4rem;
  right: 0;
  min-width: 200px;
  max-width: 320px;
  padding: 0.5rem 0;
  list-style: none;
  border-radius: 4px;
  z-index: 1000;
  background-color: ${(props) => props.theme.colors.primary};
`

export const StyledMenuItem = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 1rem 0 0;
  padding: 0.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  color: white;

  &:hover {
    background-color: rgba(0, 0, 0, 0.25);
  }
`

export const StyledMenuButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: transparent;

  &:hover,
  &:active,
  &:focus {
    background-color: rgba(0, 0, 0, 0.1);
  }
`
