import styled from 'styled-components'

export const StyledResource = styled.div`
  ${(props) => props.theme.borders.dotted};
  display: flex;
  padding: 1.5rem 0;
  h2 {
    font-size: 1.6rem;
  }

  a:nth-child(2) {
    margin-left: auto;
  }
`

export const Meta = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  a {
    color: ${(props) => props.theme.colors.midDarkBlue};
  }

  span {
    color: ${(props) => props.theme.colors.midDarkGrey};
    font-size: 1rem;
  }
`
