import React, { FC } from 'react'
import { ErrorMessage, Formik } from 'formik'
import useAuth from '../../../../contexts/AuthContext/useAuth'
import useNavigate from '../../../../hooks/useNavigate'
import Wrapper from '../../../../components/Wrapper/Wrapper'
import Input from '../../../../components/Input/Input'
import Button from '../../../../components/Button/Button'
import SEO from '../../../../components/seo'
import { Error, FormItem } from '../../../../components/FormElements/FormElements.styled'
import { HeadingWrapper } from '../../../../components/HeadingWrapper/HeadingWrapper.styled'
import { Heading } from '../../../../components/Heading/Heading.styled'
import Radio from '../../../../components/Radio/Radio'
import TextArea from '../../../../components/TextArea'
import theme from '../../../../styles/theme'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import useGame from '../../../../contexts/GameContext/useGame'
import { TeamTypes, useCreateTeamMutation, useFindTeamNamesQuery } from '../../../../generated/api'
import { getCreteTeamSchema } from './validation'
import { useManagePlayers } from '../../hooks/useManagePlayers'
import { IPageProps } from '../../../../contexts/PageContext'
import { useQueryClient } from '@tanstack/react-query'
import useGameRestricted from '../../../../hooks/useGameRestricted'
import Loading from '../../../../components/Loading'
import RestrictedPage from '../../../../components/RestrictedPage'

const CreateTeam: FC<IPageProps<Queries.CreateTeamQuery>> = () => {
  const { masterUser, user } = useAuth()
  const { activeGame: game } = useGame()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { onJoinTeam } = useManagePlayers()
  const queryClient = useQueryClient()
  const { isGameRestricted, isLoading } = useGameRestricted()

  const { data: teamsRes } = useFindTeamNamesQuery(
    { input: { gameId: game?.id || '' } },
    { enabled: Boolean(game?.id) },
  )
  const teams = teamsRes?.findTeamNames.teamNames || []

  const createTeamSchema = getCreteTeamSchema(t, teams)

  const { mutateAsync: createTeam } = useCreateTeamMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['GetPlayerById'] }),
  })

  if (isLoading) {
    return <Loading />
  }

  if (isGameRestricted) {
    return (
      <RestrictedPage
        title={t('createTeam.title')}
        text={t('createTeam.restrictedPage', { days: game?.info.restrictAtDays })}
      />
    )
  }

  return (
    <>
      <SEO title={t('createTeam.title')} />
      <HeadingWrapper>
        <Heading>{t('createTeam.title')}</Heading>
      </HeadingWrapper>
      <Wrapper width="400px" textAlign="left">
        <Formik
          enableReinitialize
          initialValues={{
            name: '',
            type: TeamTypes.Workplace,
            description: '',
            leader: masterUser?.profile.firstName,
            leaderId: masterUser?.id,
            gameId: game?.id,
            submit: null,
          }}
          validationSchema={createTeamSchema}
          onSubmit={async (values, { setErrors }) => {
            try {
              if (values.gameId && values.leaderId) {
                const newTeam = await createTeam({
                  input: {
                    gameId: values.gameId,
                    type: values.type,
                    name: values.name,
                    details: {
                      gameId: values.gameId,
                      description: values.description,
                    },
                  },
                })
                await onJoinTeam({ teamId: newTeam.createTeam.id, playerId: values.leaderId })
                await navigate(`/signup/create-team/success`, { replace: true })
              }
            } catch (e) {
              console.log('e==', e)
              if (e?.errors?.[0].message.includes('Could not Create a team')) {
                setErrors({ submit: t('errors.api.createTeam.fail') || undefined })
              } else if (e?.errors?.[0].message.includes('Could not update a player')) {
                setErrors({ submit: t('errors.joinTeam') || undefined })
              } else {
                setErrors({ submit: t('errors.generic') || undefined })
              }
            }
          }}
        >
          {({ values, errors, handleChange, setFieldValue, handleBlur, handleSubmit, isSubmitting, touched }) => {
            return (
              <>
                <div style={{ marginBottom: 20 }}>
                  <p style={{ color: theme.colors.body, fontWeight: 'bolder' }}>{t('createTeam.rules.title')}</p>
                  <p style={{ fontSize: '1.6rem' }}>{t('createTeam.rules.ruleOne')}</p>
                  <p style={{ fontSize: '1.6rem' }}>{t('createTeam.rules.ruleTwo')}</p>
                </div>
                <form onSubmit={handleSubmit} noValidate>
                  <FormItem>
                    <Input
                      type="text"
                      id="name"
                      text={t('createTeam.inputs.teamName') || ''}
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.name ? errors.name : undefined}
                    />
                  </FormItem>
                  <FormItem>
                    <Input
                      disabled
                      type="text"
                      id="leader"
                      text={t('createTeam.inputs.teamLeader') || ''}
                      value={values.leader}
                      error={touched.leader ? errors.leader : undefined}
                    />
                  </FormItem>
                  <p style={{ fontSize: '1.6rem', marginTop: 10, marginBottom: 20 }}>
                    {t('createTeam.inputs.teamBioTitle')}
                  </p>
                  <FormItem>
                    <TextArea
                      id={'teamBio'}
                      maxlength={300}
                      name={'description'}
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      rows={5}
                      cols={5}
                      text={t('createTeam.inputs.description') || ''}
                      characterCounter={
                        <p
                          style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '1.3rem' }}
                        >{`${values.description.length}/300`}</p>
                      }
                      error={touched.description ? errors.description : undefined}
                    />
                  </FormItem>
                  <FormItem>
                    <fieldset>
                      <legend>{t(`createTeam.teamTypes.title`)}</legend>
                      {Object.keys(TeamTypes).map((type: string) => {
                        if ([TeamTypes.School, TeamTypes.Charity, TeamTypes.SenSchool].includes(TeamTypes[type])) return
                        return (
                          <Radio
                            id={type}
                            key={type}
                            checked={values.type === TeamTypes[type]}
                            name={type}
                            value={TeamTypes[type]}
                            text={t(`createTeam.teamTypes.${type}`) || ''}
                            onChange={(e) => setFieldValue('type', e.target.value)}
                            onBlur={handleBlur}
                          />
                        )
                      })}
                    </fieldset>
                    <ErrorMessage name="type" component={(props) => <Error className="checkboxError" {...props} />} />
                  </FormItem>
                  <br />
                  <Button
                    isSubmitting={isSubmitting}
                    disabled={
                      isSubmitting ||
                      user?.id !== masterUser?.id ||
                      !user?.info.gameId ||
                      !!errors.description ||
                      !!errors.name
                    }
                    text={t('createTeam.buttonText')}
                    secondary
                    type="submit"
                    style={{ marginBottom: 30 }}
                  />
                  <br />
                  <Wrapper>
                    <ErrorMessage name="gameId" component={(props) => <Error className="checkboxError" {...props} />} />
                  </Wrapper>
                </form>
              </>
            )
          }}
        </Formik>
      </Wrapper>
    </>
  )
}

export const query = graphql`
  query CreateTeam($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
  }
`

export default CreateTeam
