import React, { FC, useEffect, useState } from 'react'
import Wrapper from '../../components/Wrapper/Wrapper'
import SEO from '../../components/seo'
import { HeadingWrapper } from '../../components/HeadingWrapper/HeadingWrapper.styled'
import { Heading } from '../../components/Heading/Heading.styled'
import { SubHeading } from '../../components/SubHeading/SubHeading.styled'
import theme from '../../styles/theme'
import Stepper from '../../components/Stepper'
import Input from '../../components/Input/Input'
import useAuth from '../../contexts/AuthContext/useAuth'
import Loading from '../../components/Loading'
import Button from '../../components/Button/Button'
import useNavigate from '../../hooks/useNavigate'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { ConnectCard } from './components/connectCard/ConnectCard'
import { GameSetupJoinTeam } from './components/joinGame'
import JoinTeamSuccess from './components/gameSetupSuccess'
import useGameRestricted from '../../hooks/useGameRestricted'
import RestrictedPage from '../../components/RestrictedPage'

const switchState = (step: number, setStep: React.Dispatch<React.SetStateAction<number>>) => {
  switch (step) {
    case 1: {
      return <ConnectCard step={step} setStep={setStep} />
    }
    case 2: {
      return <GameSetupJoinTeam step={step} setStep={setStep} />
    }
    case 3: {
      return <JoinTeamSuccess setStep={setStep} />
    }
    default: {
      return null
    }
  }
}

const GameSetup: FC = () => {
  const { user, family, setUser, masterUser } = useAuth()
  const [step, setStep] = useState(1)
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { isGameRestricted, isLoading: gameLoading, game } = useGameRestricted()

  const isParent = masterUser?.id === masterUser?.role.parentId || !masterUser?.role.parentId

  const filteredFamily = family?.filter((member) => member.role.isChild === true)

  const familyMemberToSetup = filteredFamily?.find((familyMember) => !familyMember?.tags.length)

  useEffect(() => {
    ;(async () => {
      if (!masterUser?.tags.length && masterUser?.id) {
        await setUser(masterUser?.id)
        return setIsLoading(false)
      }

      if (familyMemberToSetup && isParent) {
        await setUser(familyMemberToSetup?.id)
        return setIsLoading(false)
      }
      return setIsLoading(false)
    })()
  }, [familyMemberToSetup?.id, masterUser?.tags.length])

  if (isLoading || gameLoading || !family.length) {
    return <Loading />
  }

  if (isGameRestricted) {
    return (
      <RestrictedPage
        title={t('gameSetup.title')}
        text={t('gameSetup.restrictedPage', { days: game?.info.restrictAtDays })}
      />
    )
  }

  if (!isLoading && masterUser?.tags.length && !isParent) {
    return (
      <HeadingWrapper>
        <Heading>{t('gameSetup.accountConfigured')}</Heading>
        <Wrapper>
          <Button text={t('gameSetup.backButton')} onClick={() => navigate('/')} />
        </Wrapper>
      </HeadingWrapper>
    )
  }

  if (!familyMemberToSetup && !isLoading && masterUser?.tags.length) {
    return (
      <HeadingWrapper>
        <Heading>{t('gameSetup.setupComplete')}</Heading>
        <Wrapper>
          <Button text={t('gameSetup.backButton')} onClick={() => navigate(`/`)} />
        </Wrapper>
      </HeadingWrapper>
    )
  }

  return (
    <>
      <SEO title={t('gameSetup.seoTitle')} />
      <HeadingWrapper>
        <Heading>{t('gameSetup.title')}</Heading>
        <SubHeading as="h2" secondaryForm veryTall>
          {t('gameSetup.subTitle')}
        </SubHeading>
      </HeadingWrapper>
      <Wrapper width="450px">
        <div
          style={{
            width: '100%',
            height: '50px',
            backgroundColor: theme.colors.lightGrey,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Stepper currentStep={step} />
        </div>
      </Wrapper>
      <Wrapper style={{ marginTop: 30, marginBottom: 10 }} width={'400px'}>
        <Input
          type="text"
          id="player"
          autoComplete="player"
          text={t('common.player') || ''}
          value={`${user?.profile.firstName}`}
          disabled
          inputProps={{
            title: 'player',
          }}
        />
      </Wrapper>
      {switchState(step, setStep)}
    </>
  )
}
export default GameSetup

export const query = graphql`
  query GameSetup($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
