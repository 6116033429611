import React, { FC, useState } from 'react'
import { graphql } from 'gatsby'
import Wrapper from '../../components/Wrapper/Wrapper'
import Button from '../../components/Button/Button'
import SEO from '../../components/seo'
import { Error, FormItem } from '../../components/FormElements/FormElements.styled'
import { HeadingWrapper } from '../../components/HeadingWrapper/HeadingWrapper.styled'
import { Heading } from '../../components/Heading/Heading.styled'
import { SubHeading } from '../../components/SubHeading/SubHeading.styled'
import { ErrorMessage, Formik } from 'formik'
import SelectDropdown from '../../components/SelectDropdown/SelectDropdown'
import GameConsentLines from '../../components/GameConsentLines/GameConsentLines'
import useJoinGame from '../../hooks/useJoinGame/useJoinGame'
import RichText from '../../components/RichText'
import { isBefore, subDays } from 'date-fns'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import usePageContext from '../../hooks/usePageContext'
import { GameStatus } from '../../generated/api'
import { IPageProps } from '../../contexts/PageContext'
import { getGameDetailsSchema } from '../signup/details/gameDetails/validation'
import { useLocation } from '@gatsbyjs/reach-router'
import JoinGameMessage from '../../components/JoinGameMessage'

const JoinGame: FC<IPageProps<Queries.JoinGameQuery>> = ({ data }, context) => {
  const join = useJoinGame()
  const location = useLocation()
  const { t } = useTranslation()
  const { allGames } = usePageContext()
  const joinGameSchema = getGameDetailsSchema(t)
  const [isLoading, setIsLoading] = useState(false)

  const parameters = new URLSearchParams(location?.search)

  const selectedGameId = parameters?.get('gameId') || ''

  const games = allGames
    ? allGames
        .filter((game) => [GameStatus.Anticipation, GameStatus.Live].includes(game.info.status))
        .filter((game) => {
          const isGameLocked =
            game.info.restrictAtDays && game.info.restrictAtDays > 0
              ? isBefore(subDays(new Date(game.info.endsAt), game.info.restrictAtDays), new Date())
              : false

          return !isGameLocked
        })
        .map((game) => ({
          id: game.id,
          name: game.info.name,
        }))
    : []

  const gameConsentText = (gameName: string): string | null => {
    const gameProperties = data?.allSanityGame?.edges?.find(({ node }) => node.name === gameName)?.node
    if (gameProperties?.gamePartnerConsentText) {
      return gameProperties?.gamePartnerConsentText as unknown as string
    } else {
      return null
    }
  }

  const getPlainText = (richText) => {
    const children = richText?.[0].children
    const text = children?.map((content) => content?.text)

    return text ? text.join('') : ''
  }

  return (
    <>
      <SEO title={t('joinGame.seoTitle')} />
      <HeadingWrapper>
        <Heading>{t('joinGame.title')}</Heading>
        <SubHeading as="h2" secondaryForm veryTall>
          {t('joinGame.subTitle')}
        </SubHeading>
      </HeadingWrapper>
      <JoinGameMessage />
      <Wrapper width="400px" textAlign="left">
        <Formik
          enableReinitialize
          initialValues={{
            gameId: selectedGameId,
            gameName: games.find((game) => game?.id === selectedGameId)?.name || '',
            consent: false,
            code: '',
            submit: null,
          }}
          validationSchema={joinGameSchema}
          onSubmit={async (values, { setErrors }) => {
            setIsLoading(true)
            try {
              await join({
                gameId: values.gameId,
                consentText: values?.consent ? getPlainText(gameConsentText(values.gameName)) : '',
              })
            } catch (e) {
              if (e?.errors?.[0].message.includes('Can not join the game. Leave current game before.')) {
                setErrors({ submit: t('errors.api.joinGame.haveGame') || undefined })
              } else {
                setErrors({ submit: t('joinGame.errors.fail') || undefined })
              }
              setIsLoading(false)
            }
          }}
        >
          {({ values, handleBlur, handleSubmit, setFieldValue }) => {
            return (
              <form onSubmit={handleSubmit} noValidate>
                <fieldset>
                  <legend>{t('joinGame.select.title')}</legend>
                  <FormItem>
                    <SelectDropdown
                      id="gameId"
                      title="Select game Id"
                      aria-label={t('aria.label.selectGameId')}
                      options={games.map((game) => ({
                        value: game.id,
                        display: game.name,
                      }))}
                      placeholder={t('joinGame.select.placeholder') || ''}
                      value={values.gameId}
                      onBlur={handleBlur}
                      onChange={(event) => {
                        const value = event.target.value === t('joinGame.select.placeholder') ? '' : event.target.value
                        setFieldValue('gameId', value)
                        setFieldValue('gameName', games.find((game) => game.id === value)?.name)
                      }}
                      secondary={true}
                    />
                  </FormItem>
                  {getPlainText(gameConsentText(values.gameName)) && (
                    <GameConsentLines
                      name="consent"
                      text={<RichText blocks={gameConsentText(values.gameName)} context={context} />}
                      onChange={(e) => {
                        const value = e.target.checked
                        setFieldValue('consent', value)
                      }}
                      value={!!values.consent}
                    />
                  )}
                </fieldset>
                <ErrorMessage name="gameId" component={(props) => <Error className="checkboxError" {...props} />} />
                <ErrorMessage name="consent" component={(props) => <Error className="checkboxError" {...props} />} />
                <ErrorMessage name="submit" component={(props) => <Error className="checkboxError" {...props} />} />
                <Button
                  type="submit"
                  isSubmitting={isLoading}
                  disabled={isLoading || !values.gameId}
                  text={t('joinGame.buttonText')}
                  secondary
                />
              </form>
            )
          }}
        </Formik>
      </Wrapper>
    </>
  )
}

export const query = graphql`
  query JoinGame($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
    allSanityGame(filter: { status: { in: ["live", "anticipation"] } }, sort: { name: ASC }) {
      edges {
        node {
          name
          slug
          gamePartnerConsentText: _rawGamePartnerConsentText
          ...gameDetails
        }
      }
    }
  }
`

export default JoinGame
