import React, { FC, useState } from 'react'
import Wrapper from '../../../../components/Wrapper/Wrapper'
import Input from '../../../../components/Input/Input'
import Button from '../../../../components/Button/Button'
import SEO from '../../../../components/seo'
import { Error, FormItem } from '../../../../components/FormElements/FormElements.styled'
import { HeadingWrapper } from '../../../../components/HeadingWrapper/HeadingWrapper.styled'
import { Heading } from '../../../../components/Heading/Heading.styled'
import { SubHeading } from '../../../../components/SubHeading/SubHeading.styled'
import { ErrorMessage, Formik, FormikErrors } from 'formik'
import GenderInput from '../../../../components/GenderInput'
//import useSubmit from './useSubmit'
import useAuth from '../../../../contexts/AuthContext/useAuth'
import useNavigate from '../../../../hooks/useNavigate'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { getProfileDetailsSchema, ProfileDetailsProps } from './validation'
import { IPageProps } from '../../../../contexts/PageContext'
import { useManagePlayers } from '../../hooks/useManagePlayers'
import { validatePostcode } from '../../helpers/validatePostcode'
import { PostCodeInput } from '../../../../generated/api'
//import { SUPPORTED_LANGUAGES } from '../../../../utils/constants'

const ProfileDetails: FC<IPageProps<Queries.ProfileDetailsQuery>> = () => {
  const { user, refetch } = useAuth()
  //const onSubmit = useSubmit()
  const { id, profile } = user || {}
  const navigate = useNavigate()
  const { t } = useTranslation()
  const profileDetailsSchema = getProfileDetailsSchema(t)
  const { onUpdatePlayer, onCreatePostCodeRecord } = useManagePlayers()
  const [postCodeData, setPostCodeData] = useState<PostCodeInput>()
  // TODO translations
  //const { language: currentLanguage } = useI18next()

  const initialValues: ProfileDetailsProps = {
    postCode: profile?.postCode || '',
    gender: profile?.gender || '',
    submit: null,
    // preferredLanguage: preferredLanguage || currentLanguage,
  }

  const postCodeValidation = async (values: ProfileDetailsProps) => {
    const { postCode } = values
    const errors: FormikErrors<ProfileDetailsProps> = {}
    try {
      const result = await validatePostcode(postCode, setPostCodeData)
      if (result?.statusCode === 404) {
        errors.postCode = result.error
      }
    } catch (e) {}
    return errors
  }

  return (
    <>
      <SEO title={t('signUp.title')} />
      <HeadingWrapper tall>
        <Heading>{t('signUp.profileDetails.title')}</Heading>
        <SubHeading as="h2" secondaryForm veryTall>
          {t('signUp.profileDetails.subTitle', { name: profile?.firstName || '...' })}
        </SubHeading>
      </HeadingWrapper>
      <Wrapper width="400px" textAlign="left" style={{ marginBottom: 50 }}>
        <Formik<ProfileDetailsProps>
          initialValues={initialValues}
          validationSchema={profileDetailsSchema}
          validate={postCodeValidation}
          onSubmit={async (values, { setErrors }) => {
            try {
              if (id) {
                await onUpdatePlayer({
                  id,
                  profile: {
                    postCode: postCodeData?.postcode || values.postCode,
                    gender: values.gender,
                    isRegistered: true,
                  },
                })
                await refetch()
                try {
                  if (postCodeData) {
                    await onCreatePostCodeRecord(postCodeData)
                  }
                } catch (e) {
                  console.log('failed to create postcode record')
                }
                await navigate(`/signup/success`, { replace: true })
              }
            } catch (e) {
              setErrors({ submit: t('errors.generic') || undefined })
            }
          }}
        >
          {({
            values,
            errors,
            handleChange,
            setFieldValue,
            handleBlur,
            handleSubmit,
            isSubmitting,
            touched,
            dirty,
            isValid,
          }) => {
            return (
              <form onSubmit={handleSubmit} noValidate>
                <FormItem>
                  <Input
                    type="text"
                    id="postCode"
                    text={t('signUp.profileDetails.inputs.postcode') || ''}
                    maxlength={20}
                    value={values.postCode}
                    autoComplete="postal-code"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.postCode ? errors.postCode : undefined}
                  />
                </FormItem>
                {/*<FormItem>*/}
                {/*  <SelectDropdown*/}
                {/*    id="preferredLanguage"*/}
                {/*    options={SUPPORTED_LANGUAGES.map(({ name, localeCode }) => ({ display: name, value: localeCode }))}*/}
                {/*    label={t('player.selectPreferredLanguage')}*/}
                {/*    labelStyle={{ margin: '0 0 1rem 0' }}*/}
                {/*    ariaLabel={t('player.selectPreferredLanguage')}*/}
                {/*    placeholder={t('player.selectPreferredLanguage')}*/}
                {/*    value={values.preferredLanguage}*/}
                {/*    onBlur={handleBlur}*/}
                {/*    onChange={handleChange}*/}
                {/*    secondary*/}
                {/*    testID="signup_preferredLanguageSelect"*/}
                {/*  />*/}

                {/*  <ErrorMessage*/}
                {/*    name="preferredLanguage"*/}
                {/*    component={(props) => <Error className="checkboxError" {...props} />}*/}
                {/*  />*/}
                {/*</FormItem>*/}
                <FormItem>
                  <GenderInput
                    id="gender"
                    value={values.gender}
                    onChange={(v) => setFieldValue('gender', v)}
                    error={touched.gender && errors.gender}
                  />
                  <ErrorMessage name="gender" component={(props) => <Error className="checkboxError" {...props} />} />
                </FormItem>
                <br />
                <Button
                  isSubmitting={isSubmitting}
                  disabled={!dirty || !isValid || isSubmitting}
                  text={t('signUp.profileDetails.buttonText')}
                  secondary
                  type="submit"
                />
              </form>
            )
          }}
        </Formik>
      </Wrapper>
    </>
  )
}

export const query = graphql`
  query ProfileDetails($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
  }
`

export default ProfileDetails
