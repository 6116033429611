import React, { FC } from 'react'
import { format } from 'date-fns'
import TapListItem from '../TapListItem/TapListItem'
import { StyledTapListDay, StyledTapListDayText, TapListContainer } from './TapListDay.styled'
import { Route } from '../../generated/api'

interface TapListDayProps {
  date: Date | string
  items: Route[]
}

const TapListDay: FC<TapListDayProps> = ({ date, items }) => {
  const dateDisplay = format(new Date(date), 'EEEE do MMMM')

  return (
    <TapListContainer>
      <StyledTapListDay>
        <StyledTapListDayText>{dateDisplay}</StyledTapListDayText>
      </StyledTapListDay>
      {items.map((v) => (
        <TapListItem code={v.locationSlug || ''} time={v.swipeTimestamp} points={v.points} />
      ))}
    </TapListContainer>
  )
}

export default TapListDay
