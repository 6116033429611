import React, { CSSProperties, FC } from 'react'

import { SelectContainer } from './SelectDropdown.styled'

interface SelectDropdownProps {
  id?: string
  ariaLabel?: string
  title?: string
  label?: string
  placeholder?: string
  tooltip?: string
  value?: string | number
  onChange?: (e) => void
  options?: unknown[]
  required?: boolean
  disabled?: boolean
  secondary?: boolean
  onBlur?: (e) => void
  style?: CSSProperties
  labelStyle?: CSSProperties
  testID?: string
}

const SelectDropdown: FC<SelectDropdownProps> = ({
  id,
  ariaLabel,
  label,
  placeholder,
  tooltip,
  value,
  onChange,
  options,
  required = false,
  disabled = false,
  secondary,
  onBlur,
  style,
  labelStyle,
  testID,
}) => {
  const renderOption = (option) => {
    let display
    let optionValue
    if (typeof option !== 'object') {
      display = option
      optionValue = option
    } else {
      display = option.display || option.value
      optionValue = option.value || option.display
    }

    return (
      <option key={optionValue} value={optionValue} data-testid="select-option">
        {display}
      </option>
    )
  }

  return (
    <SelectContainer secondary={secondary} style={style}>
      {label && (
        <label htmlFor={id} style={labelStyle}>
          {`${label} ${tooltip || ''}`}
        </label>
      )}
      <select
        id={id}
        name={id}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        aria-label={ariaLabel || id}
        required={required}
        disabled={disabled}
        data-testid={testID}
      >
        {placeholder && (
          <option
            id={placeholder}
            title={placeholder}
            key={placeholder + id}
            aria-label={placeholder}
            data-testid="select-option"
          >
            {placeholder}
          </option>
        )}
        {options?.sort().map((option) => renderOption(option))}
      </select>
    </SelectContainer>
  )
}

export default SelectDropdown
