import CTAGrid from '../../../../components/CTAGrid'
import React from 'react'
import useAuth from '../../../../contexts/AuthContext/useAuth'
import CallToActionPanel from '../../../../components/CallToAction'
import { useSurvey } from '../../../../hooks/useSurvey/useSurvey'
import useGame from '../../../../contexts/GameContext/useGame'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { GameStatus } from '../../../../generated/api'

const Surveys = () => {
  const { user } = useAuth()
  const { activeGame: game } = useGame()
  const { closingSurveyEnabled, surveyLink } = useSurvey()
  const { t } = useTranslation()
  const isGameFinished = game?.info.status === GameStatus.Sustain

  if (!user || !game) return null

  return (
    <CTAGrid>
      <CallToActionPanel
        icon="young_lady"
        link={{
          externalUrlToggle: true,
          linkText: isGameFinished ? t('components.surveys.linkTextFinished') : t('components.surveys.linkTextLive'),
          externalUrl: surveyLink || '#',
        }}
        target="_self"
        title={
          isGameFinished
            ? t('components.surveys.titleFinished')
            : closingSurveyEnabled
              ? t('components.surveys.titleClosing')
              : t('components.surveys.titleHealth', { name: user?.profile.firstName })
        }
        text={isGameFinished ? t('components.surveys.textFinished') : t('components.surveys.textLive')}
        style="white"
      />
    </CTAGrid>
  )
}

export default Surveys
