import React, { FC, useEffect } from 'react'
import Embedo from 'embedo'
import { EmbedWrapper, EmbedCaption } from './Embed.styled'

interface EmbedProps {
  url: string
  caption?: string
}

const Embed: FC<EmbedProps> = ({ url, caption }) => {
  const isSSR = typeof window === 'undefined'
  const embedo = !isSSR ? new Embedo() : null
  const embedRef = React.createRef()
  const embedContainer = <EmbedWrapper ref={embedRef} />

  useEffect(() => {
    if (embedo) embedo.load(embedRef.current, url)
    return () => embedo.destroy(embedRef.current)
  }, [url])

  return (
    <>
      {embedContainer}
      {caption && <EmbedCaption>{caption}</EmbedCaption>}
    </>
  )
}

export default Embed
