import React, { FC } from 'react'
import { Container, Item, Button, Heading, Panel } from './Accordion.styled'
import { ContextProps } from '../CallToAction/CallToActionPanel'
import RichText from '../RichText'

export interface IItem {
  _key: string
  heading: string
  text?: string
  body?: React.ReactElement
}

interface IProps {
  items: IItem[]
  context: ContextProps
  useText?: boolean
  expandFirst?: boolean
}

const Accordion: FC<IProps> = ({ items, context, useText, expandFirst }) => {
  const expandID = expandFirst ? items[0]._key : ''

  if (typeof window !== 'undefined') {
    document?.querySelector('div[data-accordion-component="AccordionItemPanel"]')?.removeAttribute('aria-labelledby')
  }

  return (
    <Container allowMultipleExpanded allowZeroExpanded preExpanded={[expandID]}>
      {items.map((v: IItem) => (
        <Item uuid={v._key} key={v._key}>
          <Heading>
            <Button>{v.heading}</Button>
          </Heading>
          <Panel id="accordion-item-panel">
            <RichText context={context} blocks={useText ? v.text : v.body} />
          </Panel>
        </Item>
      ))}
    </Container>
  )
}

export default Accordion
