import React, { FC } from 'react'
import SEO from '../../../../components/seo'
import { EventsHeading } from '../components/events.styled'
import styled from 'styled-components'
import theme from '../../../../styles/theme'
import RichText from '../../../../components/RichText'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import Button from '../../../../components/Button/Button'
import useNavigate from '../../../../hooks/useNavigate'
import { endOfDay, startOfDay } from 'date-fns'
import { Post } from '../../../../components/CardGrid/CardGrid'
import { graphql } from 'gatsby'
import device from '../../../../styles/device'
import { getDateDisplay } from '../components/Card'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { IPageProps } from '../../../../contexts/PageContext'
import usePageContext from '../../../../hooks/usePageContext'
import useGame from '../../../../contexts/GameContext/useGame'

export const StyledCardDate = styled.time`
  color: ${(props) => props.theme.colors.orange};
  font-size: ${theme.fonts.m};
  font-weight: bold;
`

export const StyledWrapper = styled.div`
  width: 400px;
  padding: 30px;

  @media ${device.tabletPortrait} {
    width: 700px;
    padding: 0;
  }
`

export const StyledButton = styled(Button)`
  margin-top: 25px;
  width: 40%;
  font-size: 1.6rem;

  @media ${device.tabletPortrait} {
    width: 30%;
    font-size: 1.8rem;
  }
`

const EventDetails: FC<IPageProps<Queries.EventDetailsQuery>> = () => {
  const { game } = useGame()
  const { event, gameEvents } = usePageContext()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const posts = gameEvents
    ?.filter((node) => {
      if (!node.endDate && node.startDate) {
        return startOfDay(new Date()) <= new Date(node?.startDate)
      }
      const endDate = node?.endDate && endOfDay(new Date(node?.endDate))

      if (endDate) {
        return startOfDay(new Date()) <= new Date(endDate)
      }

      return false
    })
    .sort(
      (a, b) => new Date(a?.startDate || '').getTime() - new Date(b?.startDate || '').getTime(),
    ) as unknown as Post[]

  const nextEvents = posts?.map((event) => ({ eventSlug: event?.eventSlug }))
  const currentEventIndex = nextEvents.findIndex((e) => e.eventSlug === event?.eventSlug)
  const isNext = currentEventIndex >= 0 && currentEventIndex + 1 !== nextEvents?.length

  const nextEvent = nextEvents[currentEventIndex + 1]

  return (
    <>
      <SEO title={t('game.events.details.seoTitle', { gameName: game?.info.name })} />
      <EventsHeading>{event?.title}</EventsHeading>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <StyledWrapper>
          {event?.image?.asset && (
            <GatsbyImage
              style={{ width: '100%', height: 300, overflow: 'hidden', objectFit: 'cover', borderRadius: 10 }}
              image={getImage(event.image.asset) as IGatsbyImageData}
              alt={event?.title || ''}
            />
          )}
          <div style={{ display: 'flex', width: '100%', marginTop: 20, marginBottom: 10 }}>
            <StyledCardDate>
              {getDateDisplay({
                startDate: event?.startDate,
                startTime: event?.startTime,
                endDate: event?.endDate,
                endTime: event?.endTime,
              })}
            </StyledCardDate>
          </div>
          <div style={{ width: '100%' }}>
            <RichText blocks={event?.description} context={{ game }} />
          </div>

          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: 15,
            }}
          >
            <StyledButton
              style={{ padding: 12 }}
              text={t('game.events.details.allEvents')}
              onClick={() => navigate('/events')}
              tertiary
            />
            {isNext && (
              <StyledButton
                style={{ padding: 13 }}
                text={t('game.events.details.nextEvent')}
                // @ts-ignore
                onClick={() => navigate(`/events/${nextEvent.eventSlug}`)}
                secondary
              />
            )}
          </div>
        </StyledWrapper>
      </div>
    </>
  )
}

export const query = graphql`
  query EventDetails($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
  }
`

export default EventDetails
