import styled from 'styled-components'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import device from '../../styles/device'

export const StyledTabs = styled(Tabs)``

export const StyledTabList = styled(TabList)`
  list-style: none;
  padding: 0;
  display: flex;
  margin: 0 -1rem 2rem -1rem;

  @media ${device.tabletLandscape} {
    margin: 0 0 3rem;
  }
`

export const StyledTab = styled(Tab)`
  cursor: pointer;
  margin: 0 0.5rem 0 0;
  padding: 1rem;
  font-family: ${(props) => props.theme.fonts.secondaryFont};
  background-color: ${(props) => props.theme.colors.lighterGrey};
  border-top: 4px solid ${(props) => props.theme.colors.lighterGrey};
  flex: 1;
  text-align: center;
  transition: background-color, border, ${(props) => props.theme.transitions.quickTransition};

  ${(props) => props.theme.hover} {
    border-color: ${(props) => props.theme.colors.body};
  }

  &:last-child {
    margin-right: 0;
  }

  &[aria-selected='true'] {
    background-color: ${(props) => props.theme.colors.white};
    border-color: ${(props) => props.theme.colors.body};
  }
`

export const StyledTabPanel = styled(TabPanel)`
  select {
    margin: 0 auto 2rem;
    display: block;
  }
`
