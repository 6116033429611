import React, { useEffect, useState } from 'react'
import { useSpring, animated } from 'react-spring'

import Checkbox from '../../../../../components/Checkbox/Checkbox'
import ArrowIcon from '../../../../../assets/images/svg/ic_play_arrow.svg'
import { CheckboxContainer, FilterBoxWrapper, Themes, Toggle } from './FilterBox.styled'
import useWindowSize from '../../../../../hooks/useWindowSize'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { isAppleMapsSupported } from '../isAppleMapsSupported'
import useSsrLocalStorage from '../../../../../utils/useSsrLocalStorage'
import { LocationTypes } from '../../../../../generated/api'
import { useMapState } from '../Context'
import useLocations from '../useLocations'
import useGame from '../../../../../contexts/GameContext/useGame'
import CallToActionPanel from '../../../../../components/CallToAction/CallToActionPanel'
import OverlayModal from '../OverlayModal/overlayModal'
import round from 'lodash/round'

const FilterBox = ({ ctaData }) => {
  const { width, height: windowHeight } = useWindowSize()
  const { game } = useGame()
  const { t } = useTranslation()
  const { isExpanded, setIsExpanded, setFilter, filter, userHasApp } = useMapState()
  const { geoJsonData } = useLocations()
  const [useGoogle, setUseGoogle] = useSsrLocalStorage(
    'useGoogleMapsForDirections',
    isAppleMapsSupported() ? 'false' : 'true',
  )
  const [modalOpen, setModalOpen] = useState(false)
  const [boxWidth, setBoxWidth] = useState('360px')
  const [height, setHeight] = useState(170)

  useEffect(() => {
    if (width < 500) {
      setBoxWidth(`${width}px`)
    } else {
      setBoxWidth('360px')
    }
  }, [width])

  const filterBoxVisibility = {
    poi: geoJsonData?.features?.find(({ properties }) => properties.type === LocationTypes.Poi),
    schools: geoJsonData?.features?.find(({ properties }) => properties.type === LocationTypes.School),
    distributionPoints: geoJsonData?.features?.find(
      ({ properties }) => properties.type === LocationTypes.DistributionPoint,
    ),
    beatBoxes: geoJsonData?.features?.find(({ properties }) => properties.type === LocationTypes.BeatBox),
    gems: geoJsonData?.features?.find(({ properties }) => properties.type === LocationTypes.Gem) && userHasApp,
  }

  useEffect(() => {
    let checkboxes = 0
    const cta = ctaData ? 1 : 0
    if (filterBoxVisibility.poi) {
      checkboxes++
    }
    if (filterBoxVisibility.schools) {
      checkboxes++
    }
    if (filterBoxVisibility.distributionPoints) {
      checkboxes++
    }
    if (filterBoxVisibility.beatBoxes) {
      checkboxes++
    }
    if (filterBoxVisibility.gems) {
      checkboxes++
    }

    if (checkboxes > 0) {
      if (width > 320) {
        setHeight(170 + cta * 230 + round(checkboxes / 2) * 40)
      } else {
        setHeight(170 + cta * 230 + checkboxes * 40)
      }
    }
  }, [width])

  const propsW = useSpring({
    width: isExpanded ? boxWidth : '5px',
  })

  const propsH = useSpring({
    height: isExpanded ? `${height}px` : '5px',
  })

  const handleClick = (event) => setFilter((prev) => ({ ...prev, [event.target.name]: event.target.checked }))

  return (
    <FilterBoxWrapper isExpanded={isExpanded} modalOpen={modalOpen} height={windowHeight}>
      <OverlayModal isOpen={modalOpen} setIsOpen={setModalOpen} data={ctaData?.link?.overlayLink} />
      <div className="inner">
        <animated.div className="mask" style={width <= 500 ? propsH : propsW}>
          <div>
            <fieldset>
              <legend>{t('game.map.filterBox.title')}</legend>
              <CheckboxContainer>
                {filterBoxVisibility.beatBoxes && (
                  <Checkbox
                    text={t('game.map.filterBox.beatBoxes') || ''}
                    smallFont
                    customFill={Themes.beatBox}
                    value={filter.beatBoxes}
                    id="beat-boxes"
                    onChange={handleClick}
                    name={'beatBoxes'}
                    className="filterBox"
                  />
                )}
                {filterBoxVisibility.distributionPoints && (
                  <Checkbox
                    text={t('game.map.filterBox.distributionPoints') || ''}
                    smallFont
                    customFill={Themes.distributionPoint}
                    value={filter.distributionPoints}
                    id="distribution-points"
                    onChange={handleClick}
                    name={'distributionPoints'}
                    className="filterBox"
                  />
                )}
                {filterBoxVisibility.schools && (
                  <Checkbox
                    text={t('game.map.filterBox.schools') || ''}
                    smallFont
                    customFill={Themes.school}
                    id="schools"
                    value={filter.schools}
                    onChange={handleClick}
                    name={'schools'}
                    className="filterBox"
                  />
                )}
                {filterBoxVisibility.gems && (
                  <Checkbox
                    text={t('game.map.filterBox.gems') || ''}
                    smallFont
                    customFill={Themes.gem}
                    value={filter.gems}
                    id="gems"
                    onChange={handleClick}
                    name="gems"
                    className="filterBox"
                  />
                )}
                {filterBoxVisibility.poi && (
                  <Checkbox
                    text={game?.info.settings.POIName || 'POI'}
                    smallFont
                    customFill={Themes.POI}
                    id="POI"
                    value={filter.POI}
                    onChange={handleClick}
                    name={'POI'}
                    className="filterBox"
                  />
                )}
              </CheckboxContainer>
            </fieldset>
            {ctaData && (
              <div style={{ marginTop: '25px', marginBottom: '20px' }}>
                <CallToActionPanel context={{ game }} {...ctaData} openModal={setModalOpen} />
              </div>
            )}

            {isAppleMapsSupported() && (
              <Checkbox
                className="unstyled"
                text={t('game.map.filterBox.googleDirections') || ''}
                id="google"
                value={useGoogle === 'true'}
                isStyled={false}
                onChange={(ev) => setUseGoogle(ev.target.checked.toString())}
                name={'google'}
              />
            )}
          </div>
        </animated.div>

        <Toggle onClick={() => setIsExpanded(!isExpanded)} isExpanded={isExpanded} width={width}>
          <ArrowIcon />
        </Toggle>
      </div>
    </FilterBoxWrapper>
  )
}

export default FilterBox
