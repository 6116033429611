import React, { useState, useRef, FC } from 'react'
import ReactPlayer from 'react-player'
import Modal from '../Modal/Modal'
import { modalVideo } from '../Modal/Modal.styled'
import {
  Overlay,
  PlayButton,
  Content,
  ContentGradient,
  Title,
  VideoWrapper,
  StyledReferenceDiv,
} from './VideoCta.styled'
import { getImage, ImageDataLike, GatsbyImage } from 'gatsby-plugin-image'
// import { IVideoCta } from '../../interfaces/videoCta.interface'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { IVideoCta } from '../RichText/VideoCtaBlock'

interface VideoCtaProps {
  videoCta: IVideoCta
}

interface ReferenceProps {
  parentNode: {
    childNodes: {
      getBoundingClientRect: () => { width: number; height: number }
    }[]
  }
}

const VideoCta: FC<VideoCtaProps> = ({ videoCta }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [videoPlaying, setVideoPlaying] = useState<boolean>(false)
  const thisRef = useRef<ReferenceProps>(null)
  const { t } = useTranslation()

  const { videoUrl, placeholderImage, text }: IVideoCta = videoCta

  const handleClick = () => {
    setModalOpen(true)
    setVideoPlaying(true)
  }

  const image = getImage(placeholderImage?.asset as ImageDataLike)

  return (
    <>
      <StyledReferenceDiv ref={thisRef} />
      <Overlay onClick={handleClick}>
        {image && <GatsbyImage image={image} alt={t('aria.alt.videoPlaceholder')} />}
        <ContentGradient />
        <Content>
          <PlayButton aria-hidden="true">
            <div />
          </PlayButton>
          {text && <Title>{text}</Title>}
        </Content>
      </Overlay>
      <Modal
        modalOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
        ariaLabel={text}
        modalType={modalVideo}
        overlayClassName="is-video"
        modalRef
      >
        <VideoWrapper
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <ReactPlayer width="100%" height="100%" className="player" controls url={videoUrl} playing={videoPlaying} />
        </VideoWrapper>
      </Modal>
    </>
  )
}

export default VideoCta
