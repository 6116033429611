import { toast } from 'react-toastify'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import useAuth from '../contexts/AuthContext/useAuth'
import useGame from '../contexts/GameContext/useGame'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const useSendFreshdeskReport = () => {
  const { masterUser } = useAuth()
  const { t } = useTranslation()
  const { game } = useGame()

  //TODO
  //const { mutateAsync: createReportTicket } = useMutation('freshdesk', 'reportUser', { query: reportUser })

  const createReportTicket = async (...args: any[]) => undefined

  return async (reportedName: string) => {
    confirmAlert({
      title: t('player.confirmToSubmitReport') || '',
      message: t('player.reportUser', { reportedName }) || '',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              await createReportTicket({
                input: {
                  reportedName,
                  email: masterUser?.profile.email,
                  name: masterUser?.info.publicName,
                  tags: [`${game?.info.name}`],
                },
              })

              toast.success(t('player.reportSentSuccess'), {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              })
            } catch (error) {
              console.error('Create Freshdesk ticket error === ', error)

              toast.error(t('player.reportSentFailure'), {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              })
            }
          },
        },
        {
          label: 'No',
          onClick: undefined, // Unused property - field required by library
        },
      ],
    })
  }
}

export default useSendFreshdeskReport
