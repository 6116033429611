import React, { FC, useEffect, useState } from 'react'
import useAuth from '../../contexts/AuthContext/useAuth'
import { GatsbyLinkProps } from 'gatsby-link'
import { Link } from 'gatsby-plugin-react-i18next'
import useGame from '../../contexts/GameContext/useGame'

export type Links =
  | '/'
  | '/signup'
  | '/signup/create-account'
  | '/signup/game-details'
  | '/signup/add-card'
  | '/signup/profile-details'
  | '/signup/join-team'
  | '/signup/success'
  | '/signup/family/details'
  | '/signup/family/success'
  | '/signup/family/add-tag'
  | '/signup/family/join-team'
  | '/signup/family/select-age'
  | '/signup/family/invite-family'
  | '/signup/create-team/success'
  | '/login'
  | '/account'
  | '/forgot-password'
  | '/join-game'
  | '/privacy'
  | '/terms-and-conditions'
  | '/verify-account-feedback'
  | '/survey-completed'
  | '/landing'
  | '/dashboard'
  | '/leaderboards'
  | '/faqs'
  | '/contact'
  | '/add-card'
  | '/how-to-play'
  | '/awards'
  | '/partners'
  | '/map'
  | '/events'
  | '/activities'
  | '/faqs#q-where-can-i-pick-up-a-card'
  | '/signup/name-details'
  | '/shop'
  | '/accessibility'
  | '/game-setup'
  | '/cookies'
  | '/newsletter'
  | '/newsletter/success'
  | string

export const gameUrls = [
  'landing',
  'dashboard',
  'leaderboards',
  'faqs',
  'contact',
  'how-to-play',
  'awards',
  'partners',
  'map',
  'events',
  'activities',
  'newsletter',
]

interface ContextLinkProps extends Omit<GatsbyLinkProps<unknown>, 'ref'> {
  to: Links
}

const ContextLink: FC<ContextLinkProps> = ({ to, children, ...props }) => {
  const { user } = useAuth()
  const { activeGame, game: currentGame } = useGame()
  const [localTo, setLocalTo] = useState<string>(to)

  const game = currentGame || activeGame

  useEffect(() => {
    setLocalTo(to)

    if (gameUrls.some((url) => to?.includes(url))) {
      const toWithJoiner = to[0] !== '/' ? '/' + to : to
      setLocalTo(`/${game?.info.slug}${toWithJoiner}`)
    }

    if (user?.info.gameId && to === '/') {
      if (game) {
        setLocalTo(`/${game?.info.slug}/dashboard`)
      } else {
        setLocalTo(`/`)
      }
    }

    if (to === '/landing') {
      setLocalTo(game ? `/${game?.info.slug}/` : '/')
    }

    if (to?.includes('app-trial')) {
      setLocalTo(game ? `/${game?.info.slug}/app-trial` : '/')
    }
  }, [to, game, user?.info.gameId])

  return (
    <Link {...props} to={localTo}>
      {children}
    </Link>
  )
}

export default ContextLink
