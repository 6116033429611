import AnimateNumber from '../../components/AnimateNumber/AnimateNumber'
import SVGImg from '../../components/DynamicImg/SVGImage'
import SwipeableContent from '../../components/SwipeableContent'
import React, { FC } from 'react'
import { Map, Miles, Players, TABLETWIDTH } from './GameStats.styled'
import numeral from 'numeral'
import useGame from '../../contexts/GameContext/useGame'
import { StatsProps } from './index'
import ContextLink from '../../components/ContextLink'
import theme from '../../styles/theme'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const DetailedGameStats: FC<StatsProps> = ({ carousel, carouselColumns = 2, pageWidth }) => {
  const { game } = useGame()
  const { t } = useTranslation()

  // fetch fresh game
  // const { data } = useGetQuery<'game'>(
  //   'game',
  //   {
  //     query: getPublicGame,
  //     variables: {
  //       id: game?.id,
  //     },
  //     authMode: GRAPHQL_AUTH_MODE.API_KEY,
  //   },
  //   {
  //     enabled: !!game?.id,
  //   },
  // )

  const mobileUserPlayersDisplay = (players) => {
    // abbreviated user count displayed for mobile view
    const mobileUserCount = numeral(players).format('0[.]0a')
    // actual value of abbreviated user count
    const userCountValue = numeral(mobileUserCount).value()

    // intro text for  mobile user count
    let countIntro = ''
    if (players >= 1000 && players % 100 !== 0) {
      countIntro =
        userCountValue > players
          ? t('game.landing.gameStats.countIntro.almost')
          : t('game.landing.gameStats.countIntro.moreThan')
    }

    return {
      countIntro,
      mobileUserCount,
    }
  }

  const mobilePlayersAmount = mobileUserPlayersDisplay(game?.counter?.totalPlayers)

  const content = [
    <li key="map">
      <Map>
        <ContextLink
          className="mapLink"
          style={{ cursor: 'pointer' }}
          to="/map"
          id="Map"
          aria-hidden="true"
          role="button"
        >
          <SVGImg
            filename="map-bb-icon"
            alt={t('aria.alt.map') || ''}
            svgProperties={{
              width: '175px',
              height: '100px',
            }}
          />
        </ContextLink>
        <p>
          <ContextLink style={{ color: theme.colors.darkGrey }} to="/map">
            {t('game.landing.gameStats.map')}
          </ContextLink>
        </p>
      </Map>
    </li>,
  ]

  if (!game?.content?.hideLeaderboards) {
    content.unshift(
      <li key="pcount">
        <Players>
          <div className="desktopStat">
            <AnimateNumber number={game?.counter?.totalPlayers || 0} format="0,0" />
          </div>
          <div className="mobileStat" aria-hidden="true">
            <p className="intro">
              {mobilePlayersAmount?.countIntro}
              <strong>{mobilePlayersAmount?.mobileUserCount}</strong>
            </p>
          </div>
          <p>{t('game.landing.gameStats.players', { gameName: game?.info.name })}</p>
        </Players>
      </li>,
    )
    content.push(
      <li key="miles">
        <Miles>
          <AnimateNumber number={game?.counter?.totalMiles || 0} format="0,0" />
          <p>{t('game.landing.gameStats.miles', { gameName: game?.info.name })}</p>
        </Miles>
      </li>,
    )
  }

  return (
    <ul>
      {carousel && pageWidth && pageWidth < TABLETWIDTH ? (
        <SwipeableContent
          mobileOnly
          isScrollable={false}
          pages={content
            .reduce((resultArray: any[], item, index) => {
              const chunkIndex = Math.floor(index / carouselColumns)

              if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = [] // start a new chunk
              }

              resultArray[chunkIndex].push(item)

              return resultArray
            }, [])
            .map((c) => c.flat())}
        />
      ) : (
        content
      )}
    </ul>
  )
}
