import React, { FC } from 'react'
import styled from 'styled-components'

const QuestionWrapper = styled.div`
  text-align: left;
  ul {
    list-style-position: outside;
    padding-left: 22px;
    text-indent: 0;
  }
  li:before {
    width: 0;
  }
`

interface QuestionsBlockItemProps {
  question?: string
  linkName?: string
  answer?: string | JSX.Element
  showTitle?: boolean
}

const QuestionsBlockItem: FC<QuestionsBlockItemProps> = ({ question, linkName, answer, showTitle = true }) => {
  return (
    <QuestionWrapper>
      {showTitle && (
        <a href={`#q-${linkName}`}>
          <h1 id={`q-${linkName}`}>{question}</h1>
        </a>
      )}
      {answer}
    </QuestionWrapper>
  )
}

export default QuestionsBlockItem
