import { ContentWrapper, HiddenWrapper, SectionWrapper, TextWrapper } from '../get-started.styled'
import { Heading } from '../../../components/Heading/Heading.styled'
import React, { forwardRef } from 'react'
import Carousel from '../components/carousel'

const HowToPlaySection = forwardRef((props, ref) => {
  return (
    <HiddenWrapper>
      <SectionWrapper style={{ flexDirection: 'column' }}>
        <ContentWrapper>
          <TextWrapper style={{ overflow: 'hidden', width: '100%' }}>
            <Heading ref={ref} as="h2" style={{ marginLeft: 0 }} textAlign="center">
              Get Started. Pick up a Beat the Street card and swipe Beat Boxes across your community.
            </Heading>

            <Heading as="h3" style={{ marginLeft: 0 }}>
              How to play
            </Heading>
          </TextWrapper>
        </ContentWrapper>
        <Carousel />
      </SectionWrapper>
    </HiddenWrapper>
  )
})

export default HowToPlaySection
