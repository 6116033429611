const getLimitRowsIndexes = (rowPosition: number, rowsCount: number) => {
  if (rowPosition <= 0) {
    return {
      topIndex: 0,
      bottomIndex: rowPosition + 3,
    }
  }

  if (rowPosition === 1) {
    return {
      topIndex: rowPosition - 1,
      bottomIndex: rowPosition + 2,
    }
  }

  if (rowPosition + 1 >= rowsCount) {
    return {
      topIndex: rowPosition - 2,
      bottomIndex: rowPosition + 2,
    }
  }

  return {
    topIndex: rowPosition - 1,
    bottomIndex: rowPosition + 2,
  }
}

export default getLimitRowsIndexes
