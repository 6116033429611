import React, { useEffect, useState } from 'react'
import { MenuLink } from '../HeaderNav/HeaderNav.styled'
import ProfileNavBar from '../../assets/icons/ProfileNavBar'
import useAuth from '../../contexts/AuthContext/useAuth'
import device from '../../styles/device'
import styled from 'styled-components'
import { useTranslation } from 'gatsby-plugin-react-i18next'

interface IProps {
  isNavOpen: boolean
  setIsNavOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const Users = ({ isNavOpen, setIsNavOpen }: IProps) => {
  const { user } = useAuth()
  const { t } = useTranslation()
  const [isUser, setIsUser] = useState(false)

  useEffect(() => {
    if (user) {
      setIsUser(true)
    } else {
      setIsUser(false)
    }
  }, [user?.id])

  return (
    <div style={{ position: 'relative' }}>
      {isUser ? (
        <MenuLink to={'/'} onClick={() => setIsNavOpen(false)}>
          <MenuButton as={'div'} isNavOpen={isNavOpen}>
            <ProfileNavBar isNavBarOpen={isNavOpen} width={35} height={35} />
            <p>{user?.profile.firstName}</p>
          </MenuButton>
        </MenuLink>
      ) : (
        <MenuLink
          to="/login"
          aria-label={t('auth.login')}
          data-testid="users-login-link"
          onClick={() => setIsNavOpen(false)}
        >
          <MenuButton aria-label={t('auth.login')} as={'div'} isNavOpen={isNavOpen}>
            <ProfileNavBar isNavBarOpen={isNavOpen} width={35} height={35} />
            <p>{t('auth.login')}</p>
          </MenuButton>
        </MenuLink>
      )}
    </div>
  )
}

export default Users

export const MenuButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  font-family: ${(props) => props.theme.fonts.primaryFont};
  color: ${(props) => (props.isNavOpen ? props.theme.colors.white : props.theme.colors.body)};
  padding: 0.2rem;
  margin: 0 1.5rem;
  max-width: 120px;

  @media ${device.tabletLandscape} {
    padding: 0.4rem;
  }

  ${(props) => props.theme.hover} {
    background-color: transparent;
  }

  svg {
    padding: 0.3rem ${(props) => props.marker};
    height: 24px;
    width: 24px;
    margin-bottom: 0.2rem;
    transition: fill ${(props) => props.theme.transitions.transition};

    @media ${device.mobLandscape} {
      height: 30px;
      width: 30px;
      margin-bottom: 0.7rem;
    }
    @media ${device.tabletLandscape} {
      height: 40px;
      width: 40px;
      margin-bottom: 1.2rem;
    }
  }

  p,
  span {
    display: block;
    margin: 0;
    max-width: 60px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 11px;

    @media ${device.tabletLandscape} {
      max-width: 100%;
      font-size: 15px;
    }
  }
`
