import device from '../../styles/device'
import { prop, withProp } from 'styled-tools'
import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`

export const Controls = styled.div`
  display: flex;
  @media ${device.tabletLandscape} {
    ${withProp(['mobileOnly'], (mobileOnly) => (mobileOnly ? `display: none;` : ''))};
  }
  width: ${withProp('totalPages', (totalPages) => `${totalPages * 16}px`)};
  margin: 0 auto;
  > div {
    text-align: center;
    width: ${withProp('totalPages', (totalPages) => `${Math.round(100 / totalPages)}px`)};
  }
  margin-bottom: 10px;
`

export const ScrollableArea = styled.div`
  width: 100%;
  overflow-x: hidden;
  ${withProp('isScrollable', (isScrollable) => (isScrollable ? 'overflow-x: hidden' : 'overflow: hidden;'))};
  > div {
    display: flex;
    flex-wrap: nowrap;
    > div {
      min-width: 100%;

      display: flex;
    }
    @media ${device.tabletLandscape} {
      ${withProp(['mobileOnly'], (mobileOnly) => (mobileOnly ? `transform: none !important;` : ''))};
      > div {
        min-width: ${withProp(['totalPages', 'mobileOnly'], (totalPages, mobileOnly) =>
          mobileOnly ? `${100 / totalPages}%` : '100%',
        )};
        li {
          width: 100%;
        }
      }
    }
  }
`

export const ControlDisc = styled.button`
  width: 8px;
  height: 8px;
  padding: 0;
  border-radius: 4px;
  background: ${prop('theme.colors.body')};
  opacity: ${withProp('isCurrent', (isCurrent) => (isCurrent ? 1 : 0.7))};
  &:hover {
    background: ${prop('theme.colors.body')};
    opacity: 1;
  }
  span {
    ${(props) => props.theme.screenReaderOnly};
  }
`
