import * as React from 'react'
import { StyledAward, StyledDetailsButton, StyledImage, WrapperButton } from './Award.styles'
import { IAward } from './Award.interface'
import PlusIcon from '../../assets/images/svg/plus.svg'
import { FC } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

interface Props {
  award?: IAward
  onClick?: () => void
}

export const LockedAward: FC<Props> = ({ award, onClick }) => {
  const { t } = useTranslation()
  return (
    <WrapperButton onClick={onClick} aria-label={t('aria.label.awardButton')}>
      <StyledAward locked>
        <StyledImage src={award?.icon?.asset?.url} locked alt={award?.alt || award?.name} aria-label={award?.alt} />
      </StyledAward>
      <StyledDetailsButton>
        <PlusIcon />
      </StyledDetailsButton>
    </WrapperButton>
  )
}
