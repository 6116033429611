import { createContext } from 'react'
import { PageProps } from 'gatsby'
import { GetActiveGameQuery, GetAllGamesQuery, GetAllInitialGamesQuery } from '../generated/api'

export type DefaultGameWithContent = GetAllGamesQuery['findGames'][0] & {
  content: GetAllInitialGamesQuery['allSanityGame']['nodes'][0]
}

export type GameWithContent = DefaultGameWithContent & Partial<Omit<GetActiveGameQuery['getGame'], 'info'>>

export interface IPageContext {
  footerWithBackground?: boolean
  headerLogoShown?: boolean
  headerNavigationShown?: boolean
  homepageId?: string
  showGameLinksInHeader?: boolean
  headerBigLogoShown?: boolean
  shownInSiteMap?: boolean
  footerShown?: boolean
  positiveMargin?: boolean
  showPartnersLogos?: boolean
  isLanding?: boolean
  isPrivate?: boolean
  alwaysAllow?: boolean
  hideConsent?: boolean

  pageList?: string[]
  sanityData?: Record<string, any>

  gameSlug?: string
  game?: GameWithContent
  allGames?: GameWithContent[]
  awards?: any[]
  event?: GetAllInitialGamesQuery['allSanityEvent']['nodes'][0]
  gameEvents?: GetAllInitialGamesQuery['allSanityEvent']['nodes']
}

export type IPageProps<Data = object, LocationState = unknown> = PageProps<Data, IPageContext, LocationState>

const PageContext = createContext<IPageContext>({})

export default PageContext
