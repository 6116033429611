import 'whatwg-fetch'
import 'url-polyfill'
import contextWrapper from './src/contexts'
import LayoutWrapper from './src/contexts/Layout'
import * as React from 'react'
import { GatsbyBrowser } from 'gatsby'

export const wrapRootElement = contextWrapper

// issue with i18n https://andremonteiro.pt/gatsby-i18next-wrap-page-element/
export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, ...rest }) => {
  return React.cloneElement(
    element, // I18nextProvider
    element.props,
    React.cloneElement(
      element.props.children, // I18nextContext.Provider
      element.props.children.props,
      React.createElement(LayoutWrapper, { element, ...rest }, element.props.children.props.children),
    ),
  )
}
export function onServiceWorkerUpdateReady() {
  const answer = window.confirm(`Beat the Street website has been updated. ` + `Reload to display the latest version?`)
  if (answer === true) {
    window.location.reload()
  }
}
