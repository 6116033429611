import styled from 'styled-components'
import { StyledTooltip } from '../Tooltip/Tooltip.styled'

export const SelectContainer = styled.div`
  margin: 0 0 2rem 0;

  label {
    margin: 0 0 5px 10px;
    display: flex;
    justify-content: space-between;
    font-family: ${(props) => props.theme.fonts.secondaryFont};
    font-size: ${(props) => props.theme.fonts.s};
    color: ${(props) => props.theme.colors.body};
  }
  ${StyledTooltip} {
    margin-right: 10px;
    margin-top: 2px;
  }

  ${(props) =>
    props.secondary &&
    `
        select {
            color: ${props.theme.colors.body};
            background: ${props.theme.colors.lighterGrey} url("data:image/svg+xml;utf8,<svg width='12' height='7' xmlns='http://www.w3.org/2000/svg'><path d='M10.6 0L6 4.351 1.4 0 0 1.324 6 7l6-5.676z' fill='%23044567' fill-rule='evenodd'/></svg>") no-repeat;
            ${props.theme.hover} {
                background-color: ${props.theme.colors.lightGrey};
            }
            background-position: calc(100% - 1rem) center;
            &:hover {
                border: 1px solid  ${props.theme.colors.midGrey};
                padding-left: 0.9rem;
                background-position: calc(100% - 0.9rem) center;
            }
            &:focus {
                border: 2px solid ${props.theme.colors.secondary};
                padding-left: 0.8rem
                background-position: calc(100% - 0.8rem) center;
            }
        }
    `};
`
