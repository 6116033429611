import { modalBig } from '../../../../../components/Modal/Modal.styled'
import { ScrollableArea } from '../../../leaderboards/components/leaderboards.styled'
import { Heading } from '../../../../../components/Heading/Heading.styled'
import Modal from '../../../../../components/Modal/Modal'
import React from 'react'
import { SanityContent } from '../../../../../generated/api'
import useGame from '../../../../../contexts/GameContext/useGame'
import RichText from '../../../../../components/RichText'
import styled from 'styled-components'

interface Props {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  data: SanityContent
}

const Container = styled.div`
  > h2 {
    font-size: 4rem;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 8px 5px -5px rgba(0, 0, 0, 0.05);
    @media (max-width: 500px) {
      font-size: 2rem;
    }
  }
  padding: 20px;
`

const OverlayModal = ({ isOpen, setIsOpen, data }: Props) => {
  const { game } = useGame()

  return (
    <Modal
      modalOpen={isOpen}
      modalType={modalBig}
      closeModal={() => setIsOpen(false)}
      ariaLabel="Map extra info"
      customStyle={{
        height: 'auto',
        maxHeight: '80%',
      }}
    >
      <Container>
        <Heading as="h2">{data?.title}</Heading>
        <ScrollableArea>
          <RichText blocks={data?.body} context={{ game }} />
        </ScrollableArea>
      </Container>
    </Modal>
  )
}

export default OverlayModal
