import React from 'react'
import Nav from '../Nav/Nav'
import Marker from '../../assets/images/svg/marker.svg'
import Hamburger from '../../assets/images/svg/hamburger.svg'
import Cross from '../../assets/images/svg/cross.svg'
import { MenuButton, MenuLink, MenuContainer } from './HeaderNav.styled'
import { useLocation } from '@gatsbyjs/reach-router'
import Users from '../Users'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import useGame from '../../contexts/GameContext/useGame'

interface IProps {
  isNavOpen: boolean
  setIsNavOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const HeaderNav = ({ isNavOpen, setIsNavOpen }: IProps) => {
  const { pathname } = useLocation()
  // const { allGames } = usePageContext()
  const { game } = useGame()
  const { t } = useTranslation()

  // const isGamePageOpen = allGames?.find((game) => pathname.includes(game.info.slug))
  const isAppTrialPage = pathname.includes('app-trial')

  return (
    <>
      <MenuContainer style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'baseline' }}>
        <Users isNavOpen={isNavOpen || isAppTrialPage} setIsNavOpen={setIsNavOpen} />

        {game && (
          <MenuLink to="/map" aria-label={t('aria.label.openMap')} onClick={() => setIsNavOpen(false)}>
            <MenuButton isNavOpen={isNavOpen || isAppTrialPage} marker aria-haspopup="true" as={'div'}>
              <Marker />
              <p>{t('components.headerNav.map')}</p>
            </MenuButton>
          </MenuLink>
        )}

        <MenuButton
          isNavOpen={isNavOpen || isAppTrialPage}
          aria-haspopup="true"
          onClick={() => setIsNavOpen((prevState) => !prevState)}
          aria-label={isNavOpen ? t('aria.label.closeMenu') : t('aria.label.openMenu')}
        >
          {isNavOpen ? <Cross aria-hidden="true" /> : <Hamburger aria-hidden="true" />}
          <p>{t('components.headerNav.menu')}</p>
        </MenuButton>
        <Nav isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen} />
      </MenuContainer>
    </>
  )
}

export default HeaderNav
