import * as React from 'react'
import { CSSProperties, FC } from 'react'
import { StyledAward, StyledDetailsButton, StyledImage, WrapperButton } from './Award.styles'
import { IAward } from './Award.interface'
import TickIcon from '../../assets/images/svg/tick.svg'
import { useTranslation } from 'gatsby-plugin-react-i18next'

interface Props {
  customStyle?: CSSProperties
  award?: IAward
  onClick?: () => void
  icon?: boolean
}

const Award: FC<Props> = ({ customStyle, award, onClick, icon = true }) => {
  const { t } = useTranslation()
  return (
    <WrapperButton onClick={onClick} aria-label={t('aria.label.awardButton')}>
      <StyledAward style={customStyle} award={award}>
        <StyledImage src={award?.icon?.asset?.url} alt={award?.alt || award?.name} aria-label={award?.alt} />
      </StyledAward>
      {icon && (
        <StyledDetailsButton style={{ backgroundColor: '#044567' }}>
          <TickIcon />
        </StyledDetailsButton>
      )}
    </WrapperButton>
  )
}

export default Award
