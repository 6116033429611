import React, { FC, useState } from 'react'
import Button from '../../../components/Button/Button'
import Modal from '../../../components/Modal/Modal'
import { modalSmall } from '../../../components/Modal/Modal.styled'
import Input from '../../../components/Input/Input'
import { AddFollowLeaderboardWrapper } from '../../../components/LeaderboardTable/LeaderboardTable.styled'
import RowItem from '../../../components/RowItem/RowItem'
import { indexOf } from 'lodash'
import useAuth from '../../../contexts/AuthContext/useAuth'
import styled from 'styled-components'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { LeaderboardElement } from '../../../components/LeaderboardTable/LeaderboardTable'
import {
  LeaderboardPointType,
  useCreateFollowMutation,
  useFindFollowedQuery,
  useGetLeaderboardPlayersQuery,
} from '../../../generated/api'
import { useQueryClient } from '@tanstack/react-query'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

interface AddFollowPlayerModalProps {
  setActiveModal: (value: boolean) => void
}

const AddFollowPlayerModal: FC<AddFollowPlayerModalProps> = ({ setActiveModal }) => {
  const { user } = useAuth()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const [filter, setFilter] = useState('')

  const { mutateAsync: createFollowMutation, isLoading } = useCreateFollowMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['GetLeaderboardFollow'] }),
  })
  const { data: followingRes } = useFindFollowedQuery(
    {
      input: {
        gameId: user?.info.gameId as string,
        playerId: user?.id as string,
      },
    },
    {
      enabled: !!(user?.info.gameId && user?.id),
    },
  )
  const { data: playersRes } = useGetLeaderboardPlayersQuery(
    {
      input: {
        gameId: user?.info.gameId as string,
        type: LeaderboardPointType.Swipe,
        playerId: user?.id as string,
      },
    },
    {
      enabled: !!(user?.info.gameId && user?.id),
      onSuccess: () => queryClient.invalidateQueries({ queryKey: ['FindFollowed'] }),
    },
  )

  const followed = followingRes?.findFollowed || []
  const players = playersRes?.getPlayerLeaderboards.data.filter((player) => player.name) || []
  const filteredPlayers = players.filter(
    (player) => !followed.includes(player.id) && player.id !== user?.id && player.name.includes(filter),
  )

  const [selectedPlayers, setSelectedPlayers] = useState<string[]>(followed)

  // const otherFilteredPlayers = orderBy(
  //   otherPlayers.filter((el: LeaderboardElement) => el.player.publicName?.includes(filter)),
  //   'all',
  //   'desc',
  // ).filter(
  //   (element: LeaderboardElement) =>
  //     !followedPlayers.find((followedPlayer: LeaderboardElement) => followedPlayer.player.id === element.player.id),
  // )

  const handleSelectedPlayers = (id) => {
    if (selectedPlayers.includes(id)) {
      return setSelectedPlayers(selectedPlayers.filter((playerId) => playerId !== id))
    }
    setSelectedPlayers([...selectedPlayers, id])
  }

  const onFollowPlayer = async () => {
    if (!user?.info.gameId || !user?.id) return
    try {
      await Promise.all(
        selectedPlayers.map((playerId) =>
          createFollowMutation({
            input: {
              gameId: user?.info.gameId || '',
              playerId: user?.id,
              followId: playerId,
            },
          }),
        ),
      )
      await setActiveModal(false)
    } catch (e) {
      console.log('e === ', e)
    }
  }

  return (
    <Modal
      modalOpen={true}
      closeModal={() => setActiveModal(false)}
      ariaLabel="Follow users"
      modalType={modalSmall}
      overlayClassName="is-narrow is-tap-list"
      heading={t('game.leaderboards.followModal.title') || ''}
    >
      <Wrapper>
        <Input
          style={{ marginLeft: 10, marginBottom: 10 }}
          placeholder={t('game.leaderboards.followModal.placeholder') || ''}
          type="search"
          id="Find User Input"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      </Wrapper>
      <AddFollowLeaderboardWrapper>
        {filteredPlayers.map((filtered: LeaderboardElement) => {
          return (
            <RowItem
              mini={false}
              key={filtered.id}
              highlight={selectedPlayers.includes(filtered.id)}
              rank={indexOf(filteredPlayers, filtered) + 1}
              name={filtered?.name || ''}
              points={filtered.amount}
              onClick={() => handleSelectedPlayers(filtered.id)}
            />
          )
        })}
      </AddFollowLeaderboardWrapper>
      {selectedPlayers && (
        <Wrapper>
          <Button
            text={t('common.submit')}
            style={{ marginTop: 20 }}
            onClick={onFollowPlayer}
            isSubmitting={isLoading}
          />
        </Wrapper>
      )}
    </Modal>
  )
}

export default AddFollowPlayerModal
