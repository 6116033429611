import React, { FC } from 'react'
import TooltipIcon from '../../assets/images/svg/tooltip.svg'
import { StyledTooltip, StyledLabel, StyledOverlay } from './Tooltip.styled'

// const Label = (props) => (
//   <StyledLabel {...props}>
//     <TooltipIcon />
//   </StyledLabel>
// )

interface OverlayProps {
  tabIndex?: string
  role?: string
  children: string | JSX.Element | JSX.Element[]
  overlayPosition?: string
  props?: any
}

const Overlay: FC<OverlayProps> = ({ props, children, overlayPosition }) => (
  <StyledOverlay overlayPosition={overlayPosition} {...props}>
    {children}
  </StyledOverlay>
)

interface TooltipProps {
  text?: string
  className?: string
  overlayPosition?: string
  labelStyle?: React.CSSProperties
  children?: React.ReactElement
}

const Tooltip: FC<TooltipProps> = ({ text, className, overlayPosition, labelStyle, children }) => {
  return (
    <StyledTooltip className={className} tabIndex="-1">
      <StyledLabel style={labelStyle}>{children || <TooltipIcon />}</StyledLabel>

      <Overlay overlayPosition={overlayPosition} role="tooltip" tabIndex="-1">
        {text || ''}
      </Overlay>
    </StyledTooltip>
  )
}

export default Tooltip
