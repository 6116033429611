import React, { FC } from 'react'
import { animated, useSpring } from 'react-spring'
import { SwipeableHandlers } from 'react-swipeable'
import styled from 'styled-components'
import device from '../../../styles/device'
import { withProp } from 'styled-tools'

interface SwipeableContentProps {
  pages: string[] | JSX.Element[]
  mobileOnly: boolean
  isScrollable: boolean
  currentPage: number
  handlers: SwipeableHandlers
}

const ScrollableArea = styled.div`
  width: 100%;
  overflow-x: hidden;
  position: relative;
  ${withProp('isScrollable', (isScrollable) => (isScrollable ? 'overflow-x: hidden' : 'overflow: hidden;'))};
  > div {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    left: 40%;
    > div {
      width: 100%;
      display: flex;
    }
  }
  @media ${device.tabletPortrait} {
  }
`
const Wrapper = styled.div`
  width: fit-content;
  position: relative;
  margin-top: 2rem;
  margin-bottom: 5rem;
`

const SwipeableHowToPlay: FC<SwipeableContentProps> = ({
  pages = [],
  mobileOnly = false,
  isScrollable = true,
  currentPage,
  handlers,
}) => {
  const props = useSpring({
    transform: `translateX(${(100 / pages.length) * (0 - currentPage)}%)`,
  })

  return (
    <Wrapper {...handlers}>
      <ScrollableArea isScrollable={isScrollable} mobileOnly={mobileOnly} totalPages={pages.length}>
        <animated.div style={props}>
          {pages.map((page) => {
            return <div className="content">{page}</div>
          })}
        </animated.div>
      </ScrollableArea>
    </Wrapper>
  )
}

export default SwipeableHowToPlay
