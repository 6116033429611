import React from 'react'
import { useGetScore } from '../../../../hooks/useGetScore'
//import { AwardType } from '../../../../../components/Award/Award.interface'
import Loading from '../../../../components/Loading'
import Award from '../../../../components/Award/Award'
import { AwardWrapper, ButtonLink, MainSubTitle } from '../dashboard.styled'
import { Heading } from '../../../../components/Heading/Heading.styled'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { AwardType } from '../../../../components/Award/Award.interface'

const Awards = ({ awards }) => {
  const { points, pointsDuringSpecifiedTime, isLoading } = useGetScore()
  const { t } = useTranslation()

  const usersAwards = awards
    // eslint-disable-next-line array-callback-return
    .map((award) => {
      if (award.node.type === AwardType.points && points >= award.node.range) return award
      if (award.node.type === AwardType.swipes && pointsDuringSpecifiedTime >= award.node.range) return award
    })
    .filter((award) => award)

  return (
    <section
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 40,
      }}
    >
      <Heading>{t('game.dashboard.awards.title')}</Heading>
      {isLoading && <Loading />}
      {!!usersAwards.length && !isLoading && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            maxWidth: 800,
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '2%',
          }}
        >
          {usersAwards.map((award) => {
            return (
              <AwardWrapper key={`${award.node.name}${award.node.range}`}>
                <Award award={award.node} />
                <MainSubTitle>{award.node.name}</MainSubTitle>
              </AwardWrapper>
            )
          })}
        </div>
      )}
      {!isLoading && !usersAwards.length && (
        <p
          style={{
            fontSize: 16,
            marginTop: 5,
            marginBottom: 15,
          }}
        >
          {t('game.dashboard.awards.noAwards')}
        </p>
      )}
      <ButtonLink to="/awards" $primary replace>
        {t('game.dashboard.awards.buttonText') || ''}
      </ButtonLink>
    </section>
  )
}

export default Awards
