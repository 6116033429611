import styled from 'styled-components'

interface AnchorBoldProps {
  theme: {
    colors: {
      [key: string]: string
    }
    fonts: {
      [key: string]: string
    }
  }
}

export const AnchorBold = styled.a(({ theme: { colors, fonts } }: AnchorBoldProps) => ({
  color: colors.secondary,
  textDecoration: 'none',
  fontFamily: fonts.secondaryFont,
  fontSize: '1.5rem',
}))
