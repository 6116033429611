import { HeadingWrapper } from '../../components/HeadingWrapper/HeadingWrapper.styled'
import { Heading } from '../../components/Heading/Heading.styled'
import SEO from '../../components/seo'
import LinkButton from '../../components/LinkButton/LinkButton'
import React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const NewsletterResultPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <SEO title={t('newsletter.successPage.seoTitle')} />
      <HeadingWrapper tall style={{ marginTop: '10rem' }}>
        <Heading>{t('newsletter.successPage.title')}</Heading>
        <LinkButton to={'/'}>{t('common.continue') || ''}</LinkButton>
      </HeadingWrapper>
    </>
  )
}

export const query = graphql`
  query NewsletterResultPage($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
  }
`

export default NewsletterResultPage
