import { ButtonsWraper, ColumnWrapper, ContentWrapper, SpeakButton, TextWrapper } from '../get-started.styled'
import { Heading } from '../../../components/Heading/Heading.styled'
import Wrapper from '../../../components/Wrapper/Wrapper'
import React from 'react'

const BottonHelpSection = ({ handleOpenHelpDesk }) => {
  return (
    <ContentWrapper>
      <Wrapper textAlign="left" marginBottom="15rem" marginTop="8rem">
        <ColumnWrapper>
          <TextWrapper>
            <Heading as="h2" style={{ marginLeft: 0 }} textAlign="left">
              Need help?
            </Heading>
            <p className="big">Can’t find what you are looking for? We would like to chat with you.</p>
          </TextWrapper>

          <ButtonsWraper>
            <SpeakButton onClick={handleOpenHelpDesk}>Speak with us</SpeakButton>
          </ButtonsWraper>
        </ColumnWrapper>
      </Wrapper>
    </ContentWrapper>
  )
}

export default BottonHelpSection
