import React, { useEffect, useState } from 'react'
import Wrapper from '../../../../components/Wrapper/Wrapper'
import { format, isBefore } from 'date-fns'
import { FormItem } from '../../../../components/FormElements/FormElements.styled'
import SelectDropdown from '../../../../components/SelectDropdown/SelectDropdown'
import { CustomLink } from '../../../../components/Link/Link.styled'
import { TeamStatus, useFindTeamsQuery } from '../../../../generated/api'
import useGame from '../../../../contexts/GameContext/useGame'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import useAuth from '../../../../contexts/AuthContext/useAuth'
import Button from '../../../../components/Button/Button'
import { Text } from '../../../../components/Message/Message.styled'
import theme from '../../../../styles/theme'
import { useManagePlayers } from '../../../signup/hooks/useManagePlayers'
import { toast } from 'react-toastify'
import useGameRestricted from '../../../../hooks/useGameRestricted'

const TeamChange = () => {
  const { activeGame: game } = useGame()
  const { t } = useTranslation()
  const { user, masterUser, family } = useAuth()
  const { onJoinTeam, onLeaveTeam } = useManagePlayers()
  const { isGameRestricted } = useGameRestricted()

  const { data: teamsRes } = useFindTeamsQuery({ input: { gameId: game?.id || '' } }, { enabled: Boolean(game?.id) })
  const teams = teamsRes?.findTeams

  const [team, setTeam] = useState(user?.info.teamId || '')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    setTeam(user?.info.teamId || '')
  }, [user?.info.teamId])

  const parentId = family?.length && family?.[0].role.parentId
  const isParent = parentId === masterUser?.id
  const isJoinToTeamDisabled = isBefore(new Date(), new Date(game?.info.teamsStartsAt || ''))

  const approvedTeams = teams
    ?.map((team) => {
      if (user?.id !== masterUser?.id && team?.status !== TeamStatus.Approved) return
      return {
        value: team.id,
        display: team.name,
      }
    })
    ?.filter((team) => team)

  const toastMessage = () =>
    toast.success(t('manageAccount.gameSection.team.toastMessage'), {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })

  const handleChangeTeam = async () => {
    if (user?.id) {
      setIsSubmitting(true)
      try {
        if (team && team !== user?.info.teamId && user?.id && team !== t('gameSetup.joinTeam.select.placeholder')) {
          await onJoinTeam({
            playerId: user?.id,
            teamId: team,
          })
        }
        if (user?.info.teamId && team === t('gameSetup.joinTeam.select.placeholder')) {
          await onLeaveTeam(user?.id)
        }
        setError(false)
        toastMessage()
      } catch (e) {
        console.log('err', e)
        setError(true)
      } finally {
        setIsSubmitting(false)
      }
    }
  }

  return (
    <Wrapper marginBottom="4rem" marginTop="2rem" width="400px" textAlign="left">
      {game?.id && isJoinToTeamDisabled && (
        <p>
          {t('player.teamDisabled', {
            date: format(new Date(game?.info.teamsStartsAt || ''), 'eeee d MMMM'),
          })}
        </p>
      )}

      {isGameRestricted && (
        <p>{t('manageAccount.gameSection.team.teamRestricted', { days: game?.info.restrictAtDays })}</p>
      )}
      <Text style={{ fontSize: 17, fontWeight: 'normal', marginBottom: '5px', color: theme.colors.body }}>
        {user?.role.isChild
          ? t('manageAccount.gameSection.team.titleChild')
          : t('manageAccount.gameSection.team.titleParent')}
      </Text>
      <FormItem marginBottom={isParent && user?.id === masterUser?.id && user?.info.gameId ? '0px' : '3rem'}>
        <SelectDropdown
          disabled={isJoinToTeamDisabled || isGameRestricted || !user?.info.gameId}
          id="teamId"
          title="Select team Id"
          ariaLabel="Select Team"
          options={approvedTeams}
          placeholder={t('gameSetup.joinTeam.select.placeholder') || ''}
          value={team}
          onChange={(event) => setTeam(event.target.value)}
          style={{ marginBottom: 0 }}
          secondary={true}
        />
      </FormItem>
      {isParent && user?.id === masterUser?.id && user?.info.gameId && !isGameRestricted && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CustomLink style={{ fontSize: '1.8rem' }} to="/signup/create-team">
            {t('player.createTeam')}
          </CustomLink>
        </div>
      )}
      {error && (
        <Text style={{ color: theme.colors.error, textAlign: 'center', fontSize: 16, marginBottom: '3px' }}>
          {t('errors.generic')}
        </Text>
      )}
      <Button
        isSubmitting={isSubmitting}
        disabled={isSubmitting || !Boolean(team) || isGameRestricted}
        text={t('manageAccount.gameSection.team.updateButton')}
        secondary
        onClick={handleChangeTeam}
        style={{ marginTop: 18 }}
      />
    </Wrapper>
  )
}

export default TeamChange
