import React, { FC, useEffect, useState } from 'react'
import LeaderboardTable from '../../../components/LeaderboardTable/LeaderboardTable'
import useLeaderboard from './useYouLeaderboard'
import useAuth from '../../../contexts/AuthContext/useAuth'
import { IndexType, LeaderboardProps, LeaderboardType, PointsType } from '../interfaces'
import { Container, Loader, PaginationButton, ToggleWrap } from '../Leaderboard.styled'
import Toggle from '../../../components/Toggle/Toggle'
import Tooltip from '../../../components/Tooltip/Tooltip'
import { differenceInDays } from 'date-fns'
import { useSurvey } from '../../../hooks/useSurvey/useSurvey'
import { Puff } from 'react-loader-spinner'
import Button from '../../../components/Button/Button'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import getLimitRowsIndexes from '../utils/getLimitRowsIndexes'
import { LeaderboardPointType, Survey, TagTypes } from '../../../generated/api'

const addPoints = (survey: Survey) => {
  if (!survey || survey?.isConfirmed) return 0
  const diff = differenceInDays(new Date(), new Date(survey?.createdAt))
  if (Math.abs(diff) > 1) return 0
  return 100
}

const YouLeaderboard: FC<LeaderboardProps> = ({ mini, limitRows = false, pageSize = 10 }) => {
  const { surveys } = useSurvey()
  const { user, masterUser } = useAuth()
  const { t } = useTranslation()
  const [selectedButton, setSelectedButton] = useState<IndexType | null>(null)
  const [pointsType, setPointsType] = useState<LeaderboardPointType>(LeaderboardPointType.Swipe)
  const [topIndex, setTopIndex] = useState(0)
  const [bottomIndex, setBottomIndex] = useState(pageSize)
  const { data, isLoading } = useLeaderboard(pointsType)

  const isUserHasApp = !!masterUser?.tags?.find((tag) => tag.tagType === TagTypes.App)

  const playersData = data?.map((player) => {
    return {
      ...player,
      all: (player?.amount || 0) + addPoints(surveys?.tellUsAboutYou) + addPoints(surveys?.closing),
    }
  })

  const userIndex = playersData.findIndex(({ id }) => id === user?.id)

  const { topIndex: limitTopIndex, bottomIndex: limitBottomIndex } = getLimitRowsIndexes(userIndex, playersData.length)

  const setOnUserPosition = () => {
    const base = Math.floor(userIndex / pageSize) * pageSize

    setTopIndex(base)

    if (base && base === userIndex) {
      setTopIndex(base - 1)
    }

    setBottomIndex(base + (pageSize - 1))
  }

  useEffect(() => {
    setOnUserPosition()
  }, [pageSize, userIndex])

  const incrementIndex = (index?: IndexType) => {
    if (index === 'top') {
      const newTopIndex = topIndex - pageSize < 0 ? 0 : topIndex - pageSize
      return setTopIndex(newTopIndex)
    }
    const newBottomIndex = bottomIndex + pageSize > playersData.length ? playersData.length : bottomIndex + pageSize
    return setBottomIndex(newBottomIndex)
  }

  if (isLoading) return <Puff color="#16771f" width={50} height={50} />

  return (
    <Container mini={mini}>
      {isLoading && mini && <Loader />}
      {!mini && isUserHasApp && (
        <ToggleWrap>
          <Toggle
            value={pointsType}
            onChange={(v: LeaderboardPointType) => {
              setPointsType(v)
            }}
            left={{
              value: LeaderboardPointType.Swipe,
              text: t(`game.leaderboards.pointsType.${PointsType.beatBoxes}`) || '',
            }}
            right={{
              value: LeaderboardPointType.Gem,
              text: t(`game.leaderboards.pointsType.${PointsType.gems}`) || '',
            }}
          />
          <Tooltip overlayPosition="left" text={t('game.leaderboards.typeTooltip') || ''} />
        </ToggleWrap>
      )}
      {topIndex !== 0 && !mini && !isLoading && (
        <PaginationButton
          text={t('game.leaderboards.paginationPrevious', {
            number:
              topIndex >= pageSize ? pageSize : Math.min(pageSize, data.length - (userIndex / pageSize) * pageSize) - 1,
          })}
          disabled={selectedButton === IndexType.top && isLoading}
          isSubmitting={selectedButton === IndexType.top && isLoading}
          onClick={() => {
            setSelectedButton(IndexType.top)
            incrementIndex(IndexType.top)
          }}
        />
      )}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {!mini && (
          <>
            {topIndex !== 0 && (
              <Button
                style={{ fontSize: '1.7rem' }}
                link
                onClick={() => {
                  setTopIndex(0)
                  setBottomIndex(10)
                }}
                text={t(`game.leaderboards.jumpToTop`)}
              />
            )}
            <Button
              style={{ fontSize: '1.7rem' }}
              link
              onClick={() => setOnUserPosition()}
              text={t(`game.leaderboards.yourPosition`, {
                number: `(${userIndex + 1}) ${String.fromCodePoint(8595)}`,
              })}
            />
          </>
        )}
      </div>
      <LeaderboardTable
        pointsType={pointsType}
        type={LeaderboardType.player}
        mini={true}
        data={playersData}
        total={playersData.length}
        yourPosition={userIndex}
        topIndex={limitRows ? limitTopIndex : topIndex}
        bottomIndex={limitRows ? limitBottomIndex : bottomIndex}
      />
      {bottomIndex < playersData.length && !mini && !isLoading && (
        <PaginationButton
          text={t(`game.leaderboards.paginationNext`, {
            number: Math.min(pageSize, playersData.length - bottomIndex),
          })}
          disabled={selectedButton === IndexType.bottom && isLoading}
          isSubmitting={selectedButton === IndexType.bottom && isLoading}
          onClick={() => {
            setSelectedButton(IndexType.bottom)
            incrementIndex(IndexType.bottom)
          }}
        />
      )}
    </Container>
  )
}

export default YouLeaderboard
