import React, { FC, useRef, useState } from 'react'
import EmailValidator from 'email-validator'
import numeral from 'numeral'
import {
  StyledRowItem,
  StyledRowHeader,
  StyledRowRank,
  StyledRowName,
  StyledRowCount,
  StyledRowPoints,
  StyledRowHidden,
  StyledRowLogo,
  StyledRowBody,
} from './RowItem.styled'
import Menu from '../Menu/Menu'
import MenuItem from '../Menu/MenuItem'
import ReportIcon from '../../assets/icons/Report'
import useSendFreshdeskReport from '../../hooks/useSendFreshdeskReport'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { useTranslation } from 'gatsby-plugin-react-i18next'

interface RowItemProps {
  rank: number
  name: string
  truncateEmailAddressesInNames?: boolean
  points?: number
  teamMemberCount?: number
  logo?: string
  body?: string
  highlight?: boolean
  mini?: boolean
  onClick?: unknown
  options?: JSX.Element | JSX.Element[]
  isCurrentUser?: boolean
}

interface RowHeaderProps {
  onClick?: () => void
}

const RowItem: FC<RowItemProps> = ({
  rank,
  name,
  teamMemberCount,
  truncateEmailAddressesInNames = true,
  points = 0,
  logo = null,
  body,
  highlight,
  mini,
  onClick,
  options,
  isCurrentUser,
}) => {
  const { t } = useTranslation()
  const reportUser = useSendFreshdeskReport()
  const [isOpen, setIsOpen] = useState(false)
  const rowHeaderProps = {} as RowHeaderProps

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const ref = useRef(null)

  useOnClickOutside(ref, () => setIsMenuOpen(false))

  const handleToggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState)
  }

  if (body) {
    rowHeaderProps.onClick = () => setIsOpen(!isOpen)
  }

  const getName = (): string => {
    if (truncateEmailAddressesInNames && EmailValidator.validate(name)) {
      return name.split('@')[0]
    }
    return name
  }

  return (
    <StyledRowItem isOpen={isOpen} isUser={highlight} isMini={mini} onClick={onClick}>
      <StyledRowHeader {...rowHeaderProps} isUser={highlight} isMini={mini}>
        <StyledRowRank>{rank}</StyledRowRank>
        <StyledRowName>{getName()}</StyledRowName>
        {Number.isSafeInteger(teamMemberCount) && (
          <StyledRowCount>
            <strong>{teamMemberCount}</strong>
            <br />
            player{teamMemberCount !== 1 && 's'}
          </StyledRowCount>
        )}
        <StyledRowPoints>
          <strong>{numeral(points).format('0,0')}</strong> {t('common.points')}
        </StyledRowPoints>

        {!isCurrentUser ? (
          <div ref={ref} style={{ width: '5%' }}>
            <Menu handleToggleMenu={handleToggleMenu} isMenuOpen={isMenuOpen}>
              <MenuItem
                icon={<ReportIcon width={23} height={30} fill={'white'} />}
                label={t('player.reportOffensiveName')}
                onClick={async () => {
                  await reportUser(getName())
                  setIsMenuOpen(false)
                }}
              />
            </Menu>
          </div>
        ) : (
          <div style={{ width: '5%' }} />
        )}
        {options}
      </StyledRowHeader>
      {isOpen && body && (
        <StyledRowHidden>
          <StyledRowBody>{body}</StyledRowBody>
          {logo && <StyledRowLogo src={logo} alt={name} />}
        </StyledRowHidden>
      )}
    </StyledRowItem>
  )
}

export default RowItem
