import styled from 'styled-components'
import device from '../../styles/device'

export const Figure = styled.figure`
  text-align: left;
  margin: 0 0 3rem 0;
  font-size: 1.4rem;
  figcaption {
    color: ${(props) => props.theme.colors.midDarkGrey};
    margin: 0.4rem 0 0;
  }
`
export const FigureContainer = styled.div`
  ${(props) =>
    props.placement === 'right-aligned' &&
    `
        width: 100%;
        padding: 0;
        
        @media ${device.tabletPortrait} {
            width: 40%;
            padding: 1rem;
            float: right;
            margin: 0 0 3rem 3rem;
        }
    `}
  ${(props) =>
    props.placement === 'left-aligned' &&
    `
        width: 100%;
        padding: 0;

        @media ${device.tabletPortrait} {
            width: 40%;
            padding: 1rem;
            float: right;
            margin: 0 0 3rem 3rem;
        }
    `}
`
