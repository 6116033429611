import { object, string } from 'yup'

export const getChangeEmailSchema = (t2) => {
  const t = (...args: any) => t2(...args) || undefined
  return object({
    email: string()
      .email(t('errors.formik.email.valid'))
      .max(50, t('errors.formik.email.max'))
      .required(t('errors.formik.email.req')),
  })
}
