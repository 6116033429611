import styled from 'styled-components'
import device from '../../styles/device'

export const StyledAward = styled.div`
  display: flex;
  height: 11vh;
  width: 11vh;

  border-radius: 50%;
  background-color: ${({ award }) =>
    `rgba(
        ${award?.iconBackground?.rgb.r},
        ${award?.iconBackground?.rgb.g},
        ${award?.iconBackground?.rgb.b},
        ${award?.iconBackground?.rgb.a}
        )`};
  justify-content: center;
  align-items: center;

  filter: ${(props) => (props.locked ? 'grayscale(100%)' : '')};
  border: ${(props) => (props.locked ? '2px solid #d4d4d4' : '')};
`

export const WrapperButton = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: transparent;

  @media ${device.tabletPortrait} {
    padding: 0.8rem 2rem 1rem;
  }

  ${(props) => props.theme.hover} {
    background-color: transparent;
  }
`

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  opacity: ${({ locked }) => (locked ? '0.5' : '1')};
`

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const AwardWrapper = styled(FlexWrapper)`
  min-width: 120px;
`

export const StyledDetailsButton = styled.div`
  display: flex;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  color: #ffffff;
  font-size: 20px;
  background-color: #02b6eb;

  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 0;

  ${(props) => props.theme.hover} {
    background-color: transparent;
  }
`
