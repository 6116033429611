import styled from 'styled-components'

export const EmbedWrapper = styled.div`
  > [data-embedo-source='youtube'],
  > [data-embedo-source='iframe'],
  > [data-embedo-source='vimeo'] {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    margin-bottom: 1rem;

    > iframe,
    > embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }

  > [data-embedo-source='instagram'] {
    margin-bottom: 1rem;

    > blockquote {
      min-width: auto !important;
    }
  }
`

export const EmbedCaption = styled.div`
  text-align: left;
  font-size: 1.4rem;
  color: ${(props) => props.theme.colors.midDarkGrey};
  margin: 0.4rem 0 0;
`
