import React from 'react'
import store from '../redux/store'
import { Provider as ReduxProvider } from 'react-redux'
import QueryClientProvider from './QueryClientProvider'
import { ThemeProvider } from 'styled-components'
import theme from '../styles/theme'
import { ChildProvider } from './ChildContext'
import AuthProvider from './AuthContext'
import { GatsbyBrowser } from 'gatsby'
import { Amplify } from 'aws-amplify'
import env from '../env'

Amplify.configure({
  aws_user_pools_web_client_id: env.cognitoClientId,
  aws_user_pools_id: env.cognitoPoolId,
  aws_project_region: 'eu-west-1',
  aws_cognito_region: 'eu-west-1',
})

const Providers: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <ReduxProvider store={store}>
        <QueryClientProvider>
          <AuthProvider>
            <ChildProvider>{element}</ChildProvider>
          </AuthProvider>
        </QueryClientProvider>
      </ReduxProvider>
    </ThemeProvider>
  )
}

export default Providers
