import * as Yup from 'yup'
import useAuth from '../../../contexts/AuthContext/useAuth'
import Input from '../../../components/Input/Input'
import { Error, FormItem } from '../../../components/FormElements/FormElements.styled'
import React from 'react'
import { ErrorMessage, Formik } from 'formik'
import Wrapper from '../../../components/Wrapper/Wrapper'
import Button from '../../../components/Button/Button'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { getResetPasswordSchema } from './validation'
import useNavigate from '../../../hooks/useNavigate'
// eslint-disable-next-line @typescript-eslint/no-var-requires
require('yup-password')(Yup) // extend yup

const ResetPassword = () => {
  const { passwordReset } = useAuth()
  const { t } = useTranslation()
  const resetPasswordSchema = getResetPasswordSchema(t)
  const navigate = useNavigate()

  return (
    <Wrapper width="400px" marginTop="1rem" small>
      <Formik
        initialValues={{ email: '', code: '', newPassword: '', confirmPassword: '', submit: null }}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }): Promise<void> => {
          try {
            await passwordReset(values.email, values.code, values.newPassword)
            resetForm()
            setStatus({ success: true })
            setSubmitting(false)
            navigate('/login')
          } catch (error) {
            console.error(error)
            setStatus({ success: false })
            if (error.code === 'ExpiredCodeException') {
              setErrors({ submit: t('errors.cognito.passwordRecovery.codeexpired') || undefined })
            } else if (error.code === 'CodeMismatchException') {
              setErrors({ submit: t('errors.cognito.passwordRecovery.invalidCode') || undefined })
            } else if (error.code === 'UserNotFoundException') {
              setErrors({ submit: t('errors.cognito.passwordRecovery.noUser') || undefined })
            } else if (error.code === 'LimitExceededException') {
              setErrors({ submit: t('errors.cognito.passwordRecovery.attemptLimit') || undefined })
            } else {
              setErrors({ submit: t('errors.generic') || undefined })
            }
            setSubmitting(false)
          }
        }}
        validationSchema={resetPasswordSchema}
        validate={(values) => {
          const errors = {} as { confirmPassword: string }

          if (values.confirmPassword && values.newPassword && values.confirmPassword !== values.newPassword) {
            errors.confirmPassword = t('forgotPassword.reset.errors.passwordEqual')
          }
          return errors
        }}
      >
        {({ values, errors, touched, isSubmitting, handleChange, handleSubmit, handleBlur }) => (
          <form onSubmit={handleSubmit} noValidate>
            <FormItem>
              <Input
                id="email"
                type="text"
                text={t('common.email') || ''}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                hasError={Boolean(errors.email)}
                error={errors.email}
              />
            </FormItem>
            <FormItem>
              <Input
                id="code"
                type="text"
                text={t('forgotPassword.reset.inputs.code') || ''}
                value={values.code}
                onChange={handleChange}
                onBlur={handleBlur}
                hasError={Boolean(touched.code && errors.code)}
                error={touched.code ? errors.code : undefined}
              />
            </FormItem>
            <FormItem>
              <Input
                id="newPassword"
                type="password"
                text={t('forgotPassword.reset.inputs.newPassword') || ''}
                hasError={Boolean(touched.newPassword && errors.newPassword)}
                maxlength={50}
                value={values.newPassword}
                hasPassToggle
                onChange={handleChange}
                onBlur={handleBlur}
                error={(touched.newPassword && errors.newPassword)?.toString()?.replace('newPassword', 'New password')}
                required
              />
            </FormItem>
            <FormItem>
              <Input
                id="confirmPassword"
                type="password"
                autoComplete="new-password"
                text={t('forgotPassword.reset.inputs.confirmPassword') || ''}
                maxlength={50}
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                hasError={Boolean(touched.confirmPassword && errors.confirmPassword)}
                error={touched.confirmPassword ? errors.confirmPassword : undefined}
              />
            </FormItem>
            <ErrorMessage name="submit" component={(props) => <Error className="checkboxError" {...props} />} />
            <Button
              isSubmitting={isSubmitting}
              disabled={isSubmitting}
              text={t('forgotPassword.resetButton')}
              secondary
              type="submit"
            />
          </form>
        )}
      </Formik>
    </Wrapper>
  )
}

export default ResetPassword
