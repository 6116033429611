import React, { FC } from 'react'
import { format, differenceInMilliseconds } from 'date-fns'
import { TapList } from '../../components/TapListItem/TapListItem.styled'
import TapListDay from '../../components/TapListDay/TapListDay'
import TapListEmpty from '../../components/TapListEmpty'
import useGame from '../../contexts/GameContext/useGame'
import useAuth from '../../contexts/AuthContext/useAuth'
import sortBy from 'lodash/sortBy'
import { Route, usePlayerRoutesQuery } from '../../generated/api'

const TapHistoryContainer: FC = () => {
  const { game } = useGame()
  const { user } = useAuth()

  const { data: routesRes } = usePlayerRoutesQuery({ playerId: user?.id || '' }, { enabled: Boolean(user?.id) })
  const routes = routesRes?.playerRoutes.routes

  if (!game) {
    return null
  }

  const sortedRoutes = sortBy(routes, 'swipeTimestamp', 'desc')

  const swipesByDate = sortedRoutes?.reduce((acc, route: Route) => {
    const date = format(new Date(route?.swipeTimestamp), 'yyyy-MM-dd')
    if (!(date in acc)) {
      acc[date] = []
    }
    acc[date].push(route)
    return acc
  }, {})

  if (swipesByDate && Object?.keys(swipesByDate)?.length === 0) {
    return <TapListEmpty game={game} />
  }

  return (
    <TapList>
      {swipesByDate &&
        Object?.keys(swipesByDate)
          ?.sort((a, b) => {
            return differenceInMilliseconds(new Date(b), new Date(a))
          })
          .map((date) => <TapListDay key={`swipes-${date}`} date={date} items={swipesByDate[date]} />)}
    </TapList>
  )
}
export default TapHistoryContainer
