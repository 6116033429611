import React, { FC } from 'react'
import { StyledWrapper, StyledWrapperChild, StyledWrapperContainer } from './Wrapper.styled'

interface Props {
  width?: string
  noMobileMargins?: boolean
  marginTop?: number | string
  marginBottom?: number | string
  small?: boolean
  medium?: boolean
  tall?: boolean
  veryTall?: boolean
  textAlign?: string
  className?: string
  style?: React.CSSProperties
  children?: React.ReactNode
}

const Wrapper: FC<Props> = ({
  style,
  width,
  noMobileMargins,
  marginTop,
  marginBottom,
  small,
  medium,
  tall,
  veryTall,
  textAlign,
  className,
  children,
}) => {
  return (
    <StyledWrapperContainer className={className}>
      <StyledWrapper
        style={style}
        noMobileMargins={noMobileMargins}
        marginTop={marginTop}
        marginBottom={marginBottom}
        small={small}
        medium={medium}
        tall={tall}
        veryTall={veryTall}
        textAlign={textAlign}
      >
        <StyledWrapperChild width={width}>{children}</StyledWrapperChild>
      </StyledWrapper>
    </StyledWrapperContainer>
  )
}

export default Wrapper
