import * as React from 'react'
import styled from 'styled-components'

const StyledDottedAward = styled.div`
  display: flex;
  height: 15vh;
  width: 15vh;
  border-radius: 50%;
  border: 3px dotted #044567;

  justify-content: center;
  align-items: center;
`

interface Props {
  children?: React.ReactNode
}

export const NewInTown = ({ children }: Props) => {
  return <StyledDottedAward>{children}</StyledDottedAward>
}
