import env from '../../env'
import localAuth from '../../utils/localAuth'
import { Auth } from 'aws-amplify'
import { fetchData } from '../../client'
import {
  AuthPlayerDocument,
  AuthPlayerQuery,
  AuthPlayerQueryVariables,
  FindFamilyPlayersDocument,
  FindFamilyPlayersQuery,
  FindFamilyPlayersQueryVariables,
} from '../../generated/api'

const fetchPlayer = async (_username?: string) => {
  let username = _username || env.local ? localAuth.getUsername() : undefined
  if (!username) {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true })
    username = user.getUsername()
  }

  if (!username) throw new Error("Can't find user")
  const res = await fetchData<AuthPlayerQuery, AuthPlayerQueryVariables>(AuthPlayerDocument, {
    authID: username,
  })
  const user = res.getPlayerByAuth
  const familyRes = await fetchData<FindFamilyPlayersQuery, FindFamilyPlayersQueryVariables>(
    FindFamilyPlayersDocument,
    {
      input: {
        parentId: user.role.parentId || user.id,
      },
    },
  )
  const family = familyRes.findFamilyPlayers

  return {
    user: user,
    masterUser: user,
    family,
  }
}

export default fetchPlayer
