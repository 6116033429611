import React, { FC } from 'react'

interface RosetteIconProps {
  width?: number
  height?: number
}

const RosetteIcon: FC<RosetteIconProps> = ({ width = 23, height = 30 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 23 30" xmlns="http://www.w3.org/2000/svg">
      <title>{'Group'}</title>
      <g fill="#02B6EB" fillRule="nonzero">
        <path d="M10.154 20.11c-.54-.057-1.002-.382-1.449-.747-.09-.074-.182-.148-.27-.228l-.269-.218c-.268-.218-.535-.416-.82-.542-.72-.317-1.669-.288-2.43-.52L.7 26.08s2.818-.514 4.681-1.797c-.01 2.22 1.283 4.818 1.283 4.818l2.785-5.435.138-.265v-.003l.136-.269 1.689-3.294c-.412.191-.83.321-1.259.276zM14.185 19.113c-.265.06-.53.177-.797.316l-.024.05-2.359 4.762 1.413 5.518s1.75-2.286 2.194-4.468c1.49 1.64 4.04 2.72 4.04 2.72l-2.279-8.897c-.715.022-1.514-.153-2.188 0zM22.183 7.564c-.21-.943-1.414-1.53-1.91-2.346-.5-.826-.438-2.113-1.177-2.754-.73-.635-2.055-.442-2.97-.836-.888-.381-1.62-1.47-2.634-1.576-1.015-.107-1.957.806-2.902.995-.98.194-2.232-.27-3.082.199-.857.473-1.062 1.745-1.721 2.45-.655.695-1.954 1.022-2.356 1.897-.39.846.219 1.993.116 2.966-.102.973-.935 1.969-.73 2.877.212.94 1.414 1.529 1.91 2.346.492.816.434 2.084 1.15 2.73a1.28 1.28 0 00.28.199c.099.056.202.101.312.138.734.265 1.69.215 2.406.522.3.129.581.34.861.567h.004c.063.051.123.1.186.155h.003c.063.05.127.1.19.152.428.342.87.648 1.387.703.484.05.951-.132 1.412-.356.124-.059.245-.121.369-.184.124-.065.249-.127.372-.186.251-.118.5-.22.75-.269.691-.137 1.524.054 2.25.011.07-.002.139-.007.205-.019.07-.009.136-.02.2-.038.15-.028.293-.08.425-.153.857-.474 1.062-1.746 1.724-2.45.655-.695 1.954-1.022 2.356-1.897.39-.846-.219-1.993-.116-2.966.102-.973.935-1.969.73-2.877zm-10.34 8.18c-3.597-.378-6.218-3.481-5.856-6.929.362-3.447 3.571-5.938 7.168-5.56 3.596.378 6.22 3.482 5.858 6.93-.362 3.447-3.574 5.937-7.17 5.56z" />
        <path d="M13.073 4.03A5.506 5.506 0 007.03 8.925a5.506 5.506 0 004.894 6.045 5.507 5.507 0 006.046-4.895 5.507 5.507 0 00-4.897-6.045zm-1.057 10.056a4.613 4.613 0 01-4.105-5.068 4.613 4.613 0 015.07-4.104 4.614 4.614 0 014.108 5.068 4.614 4.614 0 01-5.073 4.104z" />
      </g>
    </svg>
  )
}

export default RosetteIcon
