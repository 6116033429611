import React, { ChangeEvent, FC } from 'react'
import Radio from '../Radio/Radio'
import { useTranslation } from 'gatsby-plugin-react-i18next'

enum Genders {
  M = 'Male',
  F = 'Female',
  O = 'Non-binary',
  A = 'Prefer not to say',
}

interface GenderInputProps {
  id?: string
  onBlur?: (e) => void
  onChange?: (e: string | ChangeEvent) => void
  value?: string
  text?: string
  error?: any
  disabled?: boolean
}

const GenderInput: FC<GenderInputProps> = ({ onChange, value, text = 'Gender', onBlur, disabled }) => {
  const { t } = useTranslation()
  return (
    <>
      <fieldset>
        <legend data-testid="gender-heading">{text}</legend>
        {Object.keys(Genders).map((gender: string) => (
          <Radio
            disabled={disabled}
            id={gender}
            key={gender}
            checked={value === gender}
            name={gender}
            value={gender}
            text={t(`components.genderInput.${Genders[gender]}`) || ''}
            onChange={(e) => onChange && onChange(e.target.value)}
            onBlur={onBlur}
          />
        ))}
      </fieldset>
    </>
  )
}

export default GenderInput
