import React, { createContext, FC, PropsWithChildren, useMemo } from 'react'
import useAuth from '../AuthContext/useAuth'
import { GetActiveGameQuery, useGetActiveGameQuery } from '../../generated/api'
import { GameWithContent } from '../PageContext'

export type GameContextType = {
  game?: GameWithContent
  activeGame?: GetActiveGameQuery['getGame'] | GameWithContent
}

const GameContext = createContext<GameContextType>({})

export const GameProvider: FC<PropsWithChildren<{ game?: GameWithContent }>> = ({ game, children }) => {
  const { user } = useAuth()
  const { data: freshGame } = useGetActiveGameQuery(
    {
      id: game?.id,
    },
    {
      enabled: !!game?.id,
    },
  )

  const { data: activeGameRes } = useGetActiveGameQuery(
    {
      id: user?.info.gameId,
    },
    {
      enabled: !!(user?.info.gameId && game?.id !== user?.info.gameId),
    },
  )

  const gameValue = useMemo(() => {
    if (!freshGame?.getGame || !game) return undefined
    return {
      ...game,
      ...freshGame.getGame,
    }
  }, [game, freshGame])

  const activeGame = useMemo(() => {
    if (game && user?.info.gameId === game?.id) return gameValue
    return activeGameRes?.getGame
  }, [gameValue, activeGameRes?.getGame])

  return <GameContext.Provider value={{ game: gameValue, activeGame }}>{children}</GameContext.Provider>
}

export default GameContext
