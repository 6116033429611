import styled from 'styled-components'

export const FormItem = styled.div`
  margin-bottom: ${(props) => props.marginBottom || '3rem'};
`

export const Error = styled.p`
  color: ${(props) => props.theme.colors.error};
  margin-bottom: 24px;
`

export const FormItemGrid = styled(FormItem)`
  display: flex;
  justify-items: center;
  > * {
    margin: 0 auto;
  }
  > *:first-child {
    margin: 0 10px 0 0;
  }
  > *:last-child {
    margin: 0 0 0 10px;
  }
`

export const GroupedRadios = styled.div`
  display: flex;
`
