import React, { FC, useCallback } from 'react'
import Cross from '../../assets/images/svg/cross.svg'
import { setMessage } from '../../redux/actions/ui'
import {
  Background,
  Bar,
  Container,
  IconContainer,
  MessageContainer,
  ResendLink,
  SubText,
  Text,
  Close,
} from './Message.styled'
import Warning from '../../assets/images/svg/warning.svg'
import Success from '../../assets/images/svg/success.svg'
import { useDispatch, useSelector } from '../../redux/store'
import useAuth from '../../contexts/AuthContext/useAuth'
import { useTranslation } from 'gatsby-plugin-react-i18next'

interface ConfirmEmailProps {
  resendEmail: () => void
  type: 'warning' | string
}

const ConfirmEmail: FC<ConfirmEmailProps> = ({ resendEmail, type }) => {
  const { t } = useTranslation()
  return (
    <>
      <Text>
        {type === 'warning'
          ? t('components.message.confirmEmail.confirm')
          : t('components.message.confirmEmail.success')}
      </Text>
      <SubText>
        {type === 'warning' ? (
          <span>
            {t('components.message.confirmEmail.seeEmail')}{' '}
            <ResendLink onClick={resendEmail}>{t('components.message.confirmEmail.resend')}</ResendLink>
          </span>
        ) : (
          t('components.message.confirmEmail.junkFolder')
        )}
      </SubText>
    </>
  )
}

interface MessageProps {
  type: 'warning' | string
  text: string
  show: boolean
  predefined: string
  closable: boolean
  page: string
}

const Message: FC = () => {
  const { user, updateEmail } = useAuth()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { type, text, show, predefined, closable, page }: MessageProps = useSelector((state) => state.ui.message)

  const setMessageCommonOptions = {
    page,
    show: true,
    closable: true,
  }

  const resendEmail = useCallback(async () => {
    try {
      dispatch(setMessage({ ...setMessageCommonOptions, text: t('components.message.sending'), type: 'info' }))
      const userEmail = user?.profile.isVerified ? user.emailChange.newEmail : user?.profile.email
      await updateEmail(userEmail)
      dispatch(setMessage({ ...setMessageCommonOptions, predefined: 'confirmEmail', type: 'success' }))

      const timer = setTimeout(() => {
        dispatch(
          setMessage({
            show: false,
          }),
        )
      }, 3000)

      return () => {
        clearTimeout(timer)
      }
    } catch (e) {
      dispatch(
        setMessage({
          ...setMessageCommonOptions,
          type: 'error',
          text: t('components.message.sending.error'),
        }),
      )
    }
  }, [page])

  return (
    <Container>
      <Background show={user && show} center={page === 'landing'}>
        <Bar type={type} />
        {predefined === 'confirmEmail' && (
          <IconContainer style={{ height: 53, width: 36, marginRight: '1rem' }}>
            {type === 'warning' ? <Warning id="warning-icon" /> : <Success id="success-icon" />}
          </IconContainer>
        )}
        <MessageContainer>
          <div>
            {!predefined && <Text>{text}</Text>}
            {predefined === 'confirmEmail' && <ConfirmEmail resendEmail={resendEmail} type={type} />}
          </div>
          {closable && (
            <Close
              id="Close Button"
              tabIndex="0"
              role="button"
              aria-pressed="false"
              aria-label={t('aria.label.close')}
              name="Close Button"
              type={type}
              onClick={() => {
                dispatch(setMessage({ show: false }))
              }}
            >
              <Cross />
            </Close>
          )}
        </MessageContainer>
      </Background>
    </Container>
  )
}

export default Message
