import styled from 'styled-components'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import device from '../../styles/device'

export const Container = styled(Accordion)``
export const Item = styled(AccordionItem)``
export const Heading = styled(AccordionItemHeading)`
  color: ${(props) => props.theme.colors.body};
`

export const Button = styled(AccordionItemButton)`
  display: flex;
  align-items: center;
  font-family: ${(props) => props.theme.fonts.secondaryFont};
  font-size: 1.5rem;
  cursor: pointer;
  padding: 2rem;
  line-height: 20px;
  word-break: break-word;
  background-color: ${(props) => props.theme.colors.lighterGrey};
  border-top: 4px solid ${(props) => props.theme.colors.white};
  transition: ${(props) => props.theme.transitions.quickTransition};

  ${(props) => props.theme.hover} {
    background: ${(props) => props.theme.colors.white};
    border-color: ${(props) => props.theme.colors.body};
    transition: ${(props) => props.theme.transitions.quickTransition};
    outline: none;
  }

  &::before {
    display: inline-block;
    content: '';
    min-height: 12px;
    min-width: 12px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(-45deg);
    transition: transform ${(props) => props.theme.transitions.quickTransition};
  }

  &[aria-expanded='true']::before,
  &[aria-selected='true']::before {
    transform: rotate(45deg);
    transition: transform ${(props) => props.theme.transitions.quickTransition};
  }
`

export const Panel = styled(AccordionItemPanel)`
  @keyframes fadein {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  padding: 1rem 2rem;
  @media ${device.mobLandscape} {
    padding: 1rem 4rem;
  }
  animation: fadein 0.35s ease-in;
  font-size: 1rem;
  word-wrap: break-word;
`
