import { Heading } from '../../../components/Heading/Heading.styled'
import { SubHeading } from '../../../components/SubHeading/SubHeading.styled'
import React, { FC } from 'react'
import { AnchorBold } from '../../../components/Anchor/Anchor.styled'
import ResetPassword from '../resetPassword/index'
import { useTranslation } from 'gatsby-plugin-react-i18next'

interface CompletedPasswordProps {
  setCompleted: (completed: boolean) => void
}

const CompletedPassword: FC<CompletedPasswordProps> = ({ setCompleted }) => {
  const { t } = useTranslation()
  return (
    <>
      <Heading verySmall marginTop="7rem">
        {t('forgotPassword.completed.title')}
      </Heading>
      <SubHeading secondaryForm veryTall>
        {t('forgotPassword.completed.subtitle')}
      </SubHeading>
      <ResetPassword />
      <SubHeading tertiaryForm verySmall>
        {t('forgotPassword.completed.noEmail')}
      </SubHeading>
      <AnchorBold
        onClick={() => {
          setCompleted(false)
        }}
      >
        {t('forgotPassword.completed.resend')}
      </AnchorBold>
    </>
  )
}

export default CompletedPassword
