import React, { FC } from 'react'
import styled from 'styled-components'
import { Puff } from 'react-loader-spinner'

const LoadingWrapper = styled.div`
  height: 100px;
  width: 100px;
  margin: 0 auto;
  padding: 30px 0 30px;
  svg {
    width: 100%;
    height: 100%;
  }
`

const Loading: FC = (props) => {
  return (
    <LoadingWrapper {...props}>
      <Puff color="#16771f" height={15} width={50} />
    </LoadingWrapper>
  )
}

export default Loading
