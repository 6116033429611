import styled from 'styled-components'
import device from '../../styles/device'

export const StyledHeader = styled.header`
  z-index: ${(props) => props.theme.zIndex.header};
  position: relative;
  display: flex;
  justify-content: ${(props) => (props.logoShown ? 'space-between' : 'flex-end')};
  align-items: flex-start;
  margin: 0 auto;
  padding: 1.4rem 0.8rem 3rem 0.8rem;
  max-width: ${(props) => props.theme.maxHeaderWidth};
  // Disable pointer events for the header, and re-enable it for content.
  pointer-events: none;

  > * {
    pointer-events: all;
  }

  @media ${device.mobLandscape} {
    padding: 2rem 2rem 3rem 2rem;
  }
  @media ${device.tabletPortrait} {
    padding: 3rem 3rem;
  }
`
