import Button from '../Button/Button'
import styled from 'styled-components'

export const PaginationButton = styled(Button)`
  width: 155px;
  margin: 10px auto;
`

export const LeaderboardWrapper = styled.div`
  position: relative;
  min-height: 215px;
`

export const AddFollowLeaderboardWrapper = styled.div`
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  height: 385px;
  min-height: 215px;
`

export const Mask = styled.div`
  height: 100px;
  position: absolute;
  width: 100%;
  bottom: 0;
  background: linear-gradient(rgba(255, 255, 255, 0.001), #fff) left repeat;
`

export const InvertedMask = styled(Mask)`
  top: 0px;
  z-index: 1;
  background: linear-gradient(#fff, rgba(255, 255, 255, 0.001)) left repeat;
`

export const JumpLink = styled.a`
  margin: 19px auto;
  display: block;
`
