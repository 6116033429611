import styled from 'styled-components'

export const PinWrapper = styled.div`
  ${({ options: { width, height, paddingTop } }) => `
        width: ${width};
        height: ${height};
        padding: ${paddingTop} 0 0 0;
    `}
  position: relative;
  text-align: center;
  box-sizing: border-box;
  margin: 0 auto;
  font-size: 16px;
  font-family: ${(props) => props.theme.fonts.primaryFont};
  svg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
  }
  span {
    color: white;
    position: relative;
    z-index: 1;
  }
`
