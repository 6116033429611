import React, { FC } from 'react'
import LinkButton from '../LinkButton/LinkButton'
import SVGImage from '../DynamicImg/SVGImage'
import { iconProperties, themes } from './constants'
import { CallToActionWrapper } from './CallToAction.styled'
import { InternalLinkButton } from '../LinkButton/LinkButton.styled'
import ContextLink, { Links } from '../ContextLink'
import { GameWithContent } from '../../contexts/PageContext'
import { Maybe } from '../../generated/api'
import Button from '../Button/Button'

interface OverlayLink {
  id?: Maybe<string>
  title?: Maybe<string>
  body?: Maybe<Record<string, unknown>>
}

export interface LinkProps {
  internalLinkToggle?: Maybe<boolean>
  internalLink?: Maybe<Links>
  externalUrlToggle?: Maybe<boolean>
  externalUrl?: Maybe<string>
  linkText?: Maybe<string>
  internalLinkHash?: Maybe<string>
  overlayToggle?: Maybe<boolean>
  overlayLink?: Maybe<OverlayLink>
}

export interface ContextProps {
  openLinksInNewTab?: boolean
  hash?: string
  game?: GameWithContent
}

export interface CallToActionPanelProps {
  title?: Maybe<string>
  text?: Maybe<string>
  style?: Maybe<string>
  size?: Maybe<string>
  link?: Maybe<LinkProps>
  linkTo?: LinkProps | string
  icon?: Maybe<string>
  context?: ContextProps
  isButton?: boolean
  target?: Maybe<string>
  onClick?: () => void
  openModal?: React.Dispatch<React.SetStateAction<boolean>>
}

const CallToActionPanel: FC<CallToActionPanelProps> = ({
  title,
  text,
  style = 'white',
  size = 'standard',
  link,
  linkTo,
  icon,
  context,
  isButton,
  target = '_blank',
  onClick,
  openModal,
}) => {
  const svgProperties = (icon && iconProperties[icon]) || {
    top: 0,
    left: 0,
    height: '100%',
  }

  let linkType

  if (link?.internalLinkToggle) {
    linkType = ContextLink
  } else if (link?.externalUrlToggle) {
    linkType = 'a'
  } else {
    linkType = Button
  }
  // const linkType = link?.internalLink ? ContextLink : 'a'
  const linkRef = {}
  linkRef[link?.internalLink ? 'to' : 'href'] = linkTo

  let internalLinkHash = ''
  if (link?.internalLinkHash) {
    const intLinkHash = link?.internalLinkHash
    internalLinkHash = intLinkHash[0] === '#' ? intLinkHash : `#${intLinkHash}`
  }

  return (
    <CallToActionWrapper
      isButton={isButton}
      as={isButton ? linkType : 'div'}
      {...themes[style || 'white']}
      svgprops={svgProperties}
      size={size}
      icon={icon}
      {...linkRef}
    >
      {icon && <SVGImage alt={icon || ''} filename={icon || ''} svgProperties={svgProperties} />}

      {/* eslint-disable-next-line */}
      <div onClick={onClick}>
        <div className="content">
          <h2 aria-label={title || ''}>{title}</h2>
          {text && <p>{text}</p>}
        </div>
        {!isButton && (
          <>
            {link?.externalUrlToggle && link?.externalUrl && link?.linkText && (
              <LinkButton external to={link.externalUrl} $primarySmall target={target || ''}>
                {link.linkText}
              </LinkButton>
            )}
            {link?.internalLinkToggle &&
              link?.internalLink &&
              link.linkText &&
              (context?.openLinksInNewTab ? (
                <LinkButton
                  to={`${link?.internalLink}${internalLinkHash}`}
                  $primarySmall
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link.linkText}
                </LinkButton>
              ) : (
                <InternalLinkButton to={`${link?.internalLink}${internalLinkHash}`} $primarySmall>
                  {link?.linkText}
                </InternalLinkButton>
              ))}
            {link?.overlayToggle && link?.overlayLink && link?.linkText && openModal && (
              <Button text={link.linkText} onClick={() => openModal(true)} />
            )}
          </>
        )}
      </div>
    </CallToActionWrapper>
  )
}

export default CallToActionPanel
