import React, { FC } from 'react'

interface BtsFoxProps {
  width?: number
  height?: number
}

const BtsFox: FC<BtsFoxProps> = ({ width = 311.773, height = 173.68 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 311.773 173.68">
      <defs>
        <clipPath clipPathUnits="userSpaceOnUse" id="prefix__a">
          <path d="M1852.33 852.43c-100.01 75.879-172.45 272.46-172.45 272.46l-62.07-362.128" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="prefix__c">
          <path d="M853.965 450.648c13.137-33.062 13.113-492.636 135.293-384.449-73.574 60.781-82.586 138.25-29.969 219 80.791 123.989 206.071 120.942 214.911 293.547" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="prefix__e">
          <path d="M650.41 725.563c-50.515 95.578 57.344 571.597-251.894 576.957C75.078 1308.11 0 1106.64 0 1106.64l127.063 83.94-46.204-145.49 121.282 95.13-23.098-173.486s242.578 285.396 346.535 145.496c59.832-80.5 50.223-187.578 52.977-281.328 1.074-36.757-2.344-84.578 15.996-118.308" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="prefix__g">
          <path d="M594.551 712.594c15.781-28.992 47.765-38.465 59.941-67.071 44.942-105.617-98.164-298.781-134.551-395.984-21.113-56.41-108.75-223.05 4.219-246.758 79.942-16.781 7.11 46.918 2.871 69.93-8.066 43.719 38.223 138.879 79.414 155.301 143.672 57.269 484.845 103.422 398.785 323.554-6.695 17.141-2.09 17.754-17.593 23.411L658.418 714.891c-3.035 2.703-5.691 6.289-8.008 10.672" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="prefix__i">
          <path d="M1564.39 727.535c-53.49-59.617-48.31-170.449-68.69-244.949-19.3-70.566-36.44-129.848-40.19-203.387-2.09-41.23-9.47-112.73 32.05-139.469 70.29-45.27 36.95 23.75 27.01 56.211-26.72 87.348 14.26 184.204 41.69 269.844 21.01 65.57 78.78 278.996 155.44 280.957" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="prefix__k">
          <path d="M1748.87 642.059c-57.97-60.852-69.15-221.543-62.08-303.516 3.41-39.672 99.36-155.691 96.57-168.992-13.8-65.52-123.97 79.84-135.2 106.199-49.68 116.648-23.17 211.973-19.22 333.047" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="prefix__m">
          <path d="M2333.44 826.551s-188.44-24.309-314.07 29.668a543.142 543.142 0 01-18.97-4.055c-177.59-41.434-234.79-236.055-242.45-265.281 128.51 48.555 341.23 47.422 478.93 116.637 112.43 56.503 96.56 123.031 96.56 123.031z" />
        </clipPath>
        <linearGradient
          x1={0}
          y1={0}
          x2={1}
          y2={0}
          gradientUnits="userSpaceOnUse"
          gradientTransform="scale(-208.2237 208.2237) rotate(63.435 -7.241 -4.904)"
          spreadMethod="pad"
          id="prefix__b"
        >
          <stop offset={0} stopColor="#231f20" />
          <stop offset={1} stopColor="#bc361b" />
        </linearGradient>
        <linearGradient
          x1={0}
          y1={0}
          x2={1}
          y2={0}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-76.8567 166.522 166.522 76.8567 1157.18 86.1)"
          spreadMethod="pad"
          id="prefix__d"
        >
          <stop offset={0} stopColor="#231f20" />
          <stop offset={1} stopColor="#bc361b" />
        </linearGradient>
        <linearGradient
          x1={0}
          y1={0}
          x2={1}
          y2={0}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(362.134 13.7956 13.7956 -362.134 71.49 991.934)"
          spreadMethod="pad"
          id="prefix__f"
        >
          <stop offset={0} stopColor="#231f20" />
          <stop offset={1} stopColor="#f14624" />
        </linearGradient>
        <linearGradient
          x1={0}
          y1={0}
          x2={1}
          y2={0}
          gradientUnits="userSpaceOnUse"
          gradientTransform="scale(-47.6151 47.6151) rotate(66.371 -10.703 -11.57)"
          spreadMethod="pad"
          id="prefix__h"
        >
          <stop offset={0} stopColor="#231f20" />
          <stop offset={1} stopColor="#f14624" />
        </linearGradient>
        <linearGradient
          x1={0}
          y1={0}
          x2={1}
          y2={0}
          gradientUnits="userSpaceOnUse"
          gradientTransform="scale(-140.4627 140.4627) rotate(65.772 -7.289 -8.478)"
          spreadMethod="pad"
          id="prefix__j"
        >
          <stop offset={0} stopColor="#231f20" />
          <stop offset={1} stopColor="#bc361b" />
        </linearGradient>
        <linearGradient
          x1={0}
          y1={0}
          x2={1}
          y2={0}
          gradientUnits="userSpaceOnUse"
          gradientTransform="scale(-107.0417 107.0417) rotate(72.646 -9.399 -10.609)"
          spreadMethod="pad"
          id="prefix__l"
        >
          <stop offset={0} stopColor="#231f20" />
          <stop offset={1} stopColor="#f14624" />
        </linearGradient>
        <radialGradient
          fx={0}
          fy={0}
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="scale(-309.274 309.274) rotate(52.253 -5.132 -6.083)"
          spreadMethod="pad"
          id="prefix__n"
        >
          <stop offset={0} stopColor="#fff" />
          <stop offset={1} stopColor="#f14624" />
        </radialGradient>
      </defs>
      <g clipPath="url(#prefix__a)" transform="matrix(.13333 0 0 -.13333 -4.458 175.166)">
        <path d="M1852.33 852.43c-100.01 75.879-172.45 272.46-172.45 272.46l-62.07-362.128" fill="url(#prefix__b)" />
      </g>
      <g clipPath="url(#prefix__c)" transform="matrix(.13333 0 0 -.13333 -4.458 175.166)">
        <path
          d="M853.965 450.648c13.137-33.062 13.113-492.636 135.293-384.449-73.574 60.781-82.586 138.25-29.969 219 80.791 123.989 206.071 120.942 214.911 293.547"
          fill="url(#prefix__d)"
        />
      </g>
      <g clipPath="url(#prefix__e)" transform="matrix(.13333 0 0 -.13333 -4.458 175.166)">
        <path
          d="M650.41 725.563c-50.515 95.578 57.344 571.597-251.894 576.957C75.078 1308.11 0 1106.64 0 1106.64l127.063 83.94-46.204-145.49 121.282 95.13-23.098-173.486s242.578 285.396 346.535 145.496c59.832-80.5 50.223-187.578 52.977-281.328 1.074-36.757-2.344-84.578 15.996-118.308"
          fill="url(#prefix__f)"
        />
      </g>
      <g clipPath="url(#prefix__g)" transform="matrix(.13333 0 0 -.13333 -4.458 175.166)">
        <path
          d="M594.551 712.594c15.781-28.992 47.765-38.465 59.941-67.071 44.942-105.617-98.164-298.781-134.551-395.984-21.113-56.41-108.75-223.05 4.219-246.758 79.942-16.781 7.11 46.918 2.871 69.93-8.066 43.719 38.223 138.879 79.414 155.301 143.672 57.269 484.845 103.422 398.785 323.554-6.695 17.141-2.09 17.754-17.593 23.411L658.418 714.891c-3.035 2.703-5.691 6.289-8.008 10.672"
          fill="url(#prefix__h)"
        />
      </g>
      <g clipPath="url(#prefix__i)" transform="matrix(.13333 0 0 -.13333 -4.458 175.166)">
        <path
          d="M1564.39 727.535c-53.49-59.617-48.31-170.449-68.69-244.949-19.3-70.566-36.44-129.848-40.19-203.387-2.09-41.23-9.47-112.73 32.05-139.469 70.29-45.27 36.95 23.75 27.01 56.211-26.72 87.348 14.26 184.204 41.69 269.844 21.01 65.57 78.78 278.996 155.44 280.957"
          fill="url(#prefix__j)"
        />
      </g>
      <g clipPath="url(#prefix__k)" transform="matrix(.13333 0 0 -.13333 -4.458 175.166)">
        <path
          d="M1748.87 642.059c-57.97-60.852-69.15-221.543-62.08-303.516 3.41-39.672 99.36-155.691 96.57-168.992-13.8-65.52-123.97 79.84-135.2 106.199-49.68 116.648-23.17 211.973-19.22 333.047"
          fill="url(#prefix__l)"
        />
      </g>
      <path
        d="M243.475 18.848s-6.832 27.325-23.91 45.257c0 0-23.057 6.832-64.9 11.102-37.324 3.808-76.858 1.707-76.858 1.707s14.876 40.693 53.8 48.676c56.618 11.61 79.418-16.225 88.811-23.91 1.879-1.54 5.23-3.145 9.518-4.766 17.134-6.474 49.237-13.168 62.214-16.584 16.226-4.27 14.518-15.371 14.518-15.371s-25.126 3.242-41.877-3.955c-7.564-3.248-11.979-8.925-14.484-17.395-2.747-9.284-6.832-24.761-6.832-24.761z"
        fill="#f14624"
      />
      <g clipPath="url(#prefix__m)" transform="matrix(.13333 0 0 -.13333 -4.458 175.166)">
        <path
          d="M2333.44 826.551s-188.44-24.309-314.07 29.668a543.142 543.142 0 01-18.97-4.055c-177.59-41.434-234.79-236.055-242.45-265.281 128.51 48.555 341.23 47.422 478.93 116.637 112.43 56.503 96.56 123.031 96.56 123.031"
          fill="url(#prefix__n)"
        />
      </g>
      <path
        d="M293.737 65.758s2.679 4.34 6.532 4.95c3.852.609 6.003-.924 6.003-.924l1.186-4.749-13.917.659"
        fill="#231f20"
      />
      <path
        d="M241.14 66.567s5.058 13.335 10.577 9.197c5.518-4.14 1.38-13.335 1.38-13.335"
        fill="none"
        stroke="#231f20"
        strokeWidth={1.839}
        strokeMiterlimit={10}
      />
    </svg>
  )
}

export default BtsFox
