import useAuth from '../../contexts/AuthContext/useAuth'
import { useEffect, useState } from 'react'
import {
  PlayerSurveysDocument,
  PlayerSurveysQuery,
  PlayerSurveysQueryVariables,
  SurveyTypes,
  useGetGameSurveyInfoQuery,
} from '../../generated/api'
import { fetchData } from '../../client'

export const useFamilySurveyActive = () => {
  const { family, isInitialized, masterUser } = useAuth()
  const [familySurveysActive, setFamilySurveysActive] = useState<string[]>([])
  const { data: gameSurveyRes } = useGetGameSurveyInfoQuery(
    { id: masterUser?.info.gameId },
    { enabled: Boolean(masterUser?.info.gameId) },
  )
  const game = gameSurveyRes?.getGame

  useEffect(() => {
    if (!game?.id && isInitialized) return
    ;(async function () {
      const getPlayerSurvey = async (userId, type) => {
        if (!userId || !game?.id) return null
        const res = await fetchData<PlayerSurveysQuery, PlayerSurveysQueryVariables>(PlayerSurveysDocument, {
          playerId: userId,
          gameId: game?.id,
        })
        const data = res?.findPlayerSurveys

        const isTellUsSurvey = data?.find((item) => item.surveyType === SurveyTypes.TellUsAboutYou)
        const isClosingSurvey = data?.find((item) => item.surveyType === SurveyTypes.Closing)

        if (type === SurveyTypes.TellUsAboutYou) {
          return (
            game?.info.surveys?.tellUsAboutYou?.link &&
            !isTellUsSurvey &&
            (!game?.info.surveys?.closing?.shownFromDate ||
              new Date(game?.info.surveys?.closing?.shownFromDate) >= new Date())
          )
        } else {
          return (
            !!game?.info.surveys?.closing &&
            game?.info.surveys?.closing?.link &&
            game?.info.surveys?.closing?.shownFromDate &&
            new Date(game?.info.surveys?.closing?.shownFromDate) <= new Date() &&
            !isClosingSurvey
          )
        }
      }

      const res: string[] = []
      await Promise.all(
        family.map(async (member) => {
          const tellUsEnabled = await getPlayerSurvey(member.id, SurveyTypes.TellUsAboutYou)
          const closingEnabled = await getPlayerSurvey(member.id, SurveyTypes.Closing)

          if (tellUsEnabled || closingEnabled) {
            res.push(member.id)
          }
        }),
      )
      setFamilySurveysActive(res)
    })()
  }, [game?.id, family])

  return familySurveysActive
}
