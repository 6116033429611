import styled from 'styled-components'
import device from '../../styles/device'

export const StyledTooltip = styled.span`
  display: inline-flex;
  align-items: center;
  position: relative;
`

export const StyledOverlay = styled.span`
  visibility: hidden;
  border-radius: 6px;
  padding: 0.8rem 1rem 1rem;
  width: 230px;
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -115px;
  background-color: ${(props) => props.theme.colors.body};
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fonts.primaryFont};
  font-size: 13px;
  font-weight: normal;
  text-align: left;
  margin-bottom: 1rem;
  z-index: ${(props) => props.theme.zIndex.tooltip};
  opacity: 0;
  transition: opacity 0.3s;

  ${(props) => props.theme.hover} {
    visibility: visible;
    opacity: 1;
  }

  white-space: normal;

  &::before {
    position: absolute;
    display: block;
    content: '';
    left: 50%;
    right: 0;
    top: 100%;
    border: 10px solid transparent;
    margin-left: -10px;
    border-top-color: ${(props) => props.theme.colors.body};
    width: 0;
    height: 0;
  }

  ${(props) =>
    props.overlayPosition === 'left' &&
    `
        @media ${device.mobPortraitOnly} {
            left: auto;
            margin-left: -170px;
            &::before {
                left: 78%;
                right: 0;
            }
        }
    `};
`

export const StyledLabel = styled.span`
  ${(props) => props.theme.hover} {
    + ${StyledOverlay} {
      visibility: visible;
      opacity: 1;
    }
  }
`
