import styled from 'styled-components'
import device from '../../styles/device'

export const LinkList = styled.ul`
  display: flex;
  margin: 1.6rem auto 2rem auto;
  flex-flow: row wrap;
  justify-content: center;
  list-style: none;
  padding: 0;
  max-width: 500px;

  @media ${device.tabletLandscape} {
    max-width: 770px;
    margin: 1.6rem auto 5rem auto;
  }

  li::before {
    content: none;
  }
`
export const LinkedListItem = styled.li`
  width: 100%;
  max-width: 80px;
  margin: 10px;

  @media ${device.tabletLandscape} {
    max-width: 120px;
    margin: 20px;
  }

  img {
    border-radius: 50%;
    margin-bottom: 4px;
    width: 80px;
    height: 80px;

    @media ${device.tabletLandscape} {
      width: 120px;
      height: 120px;
    }
  }
`

export const LinkedListAreaName = styled.div`
  text-align: center;
  font-family: ${(props) => props.theme.fonts.primaryFont};
  color: ${(props) => props.theme.colors.body};
  font-size: ${(props) => props.theme.fonts.xxs};

  @media ${device.tabletLandscape} {
    font-size: ${(props) => props.theme.fonts.s};
  }
`
