import React, { FC } from 'react'
import { TwitterTimelineEmbed } from 'react-twitter-embed'

interface TwitterSocialFeedProps {
  username: string
  height?: number | string
  width?: number | string
}

const TwitterSocialFeed: FC<TwitterSocialFeedProps> = ({ username, height = 500, width = 400 }) => {
  return (
    <TwitterTimelineEmbed
      borderColor="#E2E2E2"
      screenName={username}
      sourceType="profile"
      options={{ height, width }}
    />
  )
}

export default TwitterSocialFeed
