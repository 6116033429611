import { StyledTooltip } from '../Tooltip/Tooltip.styled'
import styled from 'styled-components'

export const StyledWrapper = styled.div`
  position: relative;

  textarea {
    border-radius: 6px;
    padding: 0.7rem 0.2rem 0.9rem 1.1rem;
    font-size: 1.6rem;
    vertical-align: middle;
    width: 100%;
    max-width: 400px;
    padding-left: 11px;
    color: ${(props) => props.theme.colors.body};
    &::placeholder {
      font-size: 1.5rem;
      color: ${(props) => props.theme.colors.midGrey};
    }
    &:focus {
      padding: 0.6rem 0.1rem 0.8rem 1rem;
    }
    &:focus::placeholder {
      color: transparent;
    }
  }
  ${(props) =>
    props.isSubmitting &&
    `
        input{
            color: ${props.theme.colors.transparent};

            transition: none;
            ${props.theme.hover} {
                color: ${props.theme.colors.transparent};
            }
        }
    `}
  ${(props) =>
    props.isError &&
    `
        input{
            border-color: ${props.theme.colors.error};
            &:${props.theme.hover}{
                border-color: ${props.theme.colors.error};
            }
            &:focus{
                border-color: ${props.theme.colors.error};
            }
        }
    `}

    span.passVisible {
    display: block;
    padding: 10px;
    cursor: pointer;
    position: absolute;
    top: 3px;
    right: 5px;
    svg.hidePass {
      display: none;
    }
  }

  label {
    position: absolute;
    top: -1.2rem;
    background: ${(props) => props.theme.colors.white};
    padding: 1px 3px 0;
    left: 0.7rem;
    white-space: nowrap;
    z-index: ${(props) => props.theme.zIndex.label};
  }

  ${StyledTooltip} {
    vertical-align: middle;
    top: -0.2rem;
    margin: 0.2rem 0.2rem 0.2rem 0.6rem;
  }
  position: relative;
  > .loader {
    width: 100%;
    position: absolute;
    top: 13px;
  }
`
