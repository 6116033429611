type ENVType = {
  apiUrl: string
  cognitoPoolId: string
  cognitoClientId: string
  env: string
  local: boolean
}

const getEnv = (): ENVType => {
  const isLocal = !!(process.env.NODE_ENV !== 'production' && process.env.GATSBY_LOCAL)
  const baseUrl = process.env.GATSBY_API_URL
  return {
    apiUrl: baseUrl + '/graphql',
    cognitoPoolId: process.env.GATSBY_COGNITO_POOL_ID || '',
    cognitoClientId: process.env.GATSBY_COGNITO_CLIENT_ID || '',
    env: process.env.GATSBY_ENV || '',
    local: isLocal,
  }
}

const env = getEnv()

export default env
