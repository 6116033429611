import * as React from 'react'
import { StyledRadio } from './Radio.styled'
import { FC } from 'react'

interface RadioProps {
  id?: string
  name?: string
  text?: string
  required?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: React.ChangeEventHandler<HTMLInputElement>
  error?: string
  checked?: boolean
  disabled?: boolean
  value?: string
}

const Radio: FC<RadioProps> = ({
  id,
  name,
  text,
  required,
  checked = false,
  onChange,
  error,
  value,
  onBlur,
  disabled,
}) => {
  return (
    <StyledRadio>
      <input
        disabled={disabled}
        type="radio"
        id={id}
        name={name}
        value={value}
        required={required}
        checked={checked}
        onChange={onChange}
        onBlur={onBlur}
      />
      <label htmlFor={id}>{text}</label>
      {error && <p>{error}</p>}
    </StyledRadio>
  )
}

export default Radio
