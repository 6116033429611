import React, { FC } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Heading } from '../components/Heading/Heading.styled'
import device from '../styles/device'
import { LinkedListAreaName, LinkedListItem, LinkList } from '../components/LinkList/LinkList.styled'
import Spacer from '../components/Spacer'
import { SubHeading } from '../components/SubHeading/SubHeading.styled'
import SEO from '../components/seo'
import Logo from '../components/Logo/Logo'
import VideoCta from '../components/VideoCta'
import Wrapper from '../components/Wrapper/Wrapper'
import RichText from '../components/RichText'
import useAuth from '../contexts/AuthContext/useAuth'
import Loading from '../components/Loading'
import usePageContext from '../hooks/usePageContext'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import { IPageProps } from '../contexts/PageContext'
import { GameStatus } from '../generated/api'

const HomeHeader = styled(Heading)`
  margin-top: -20px;
  @media ${device.tabletLandscape} {
    margin-top: -35px;
  }
`

const HomePage: FC<IPageProps<Queries.HomePageQuery>> = ({ data }) => {
  const { user } = useAuth()
  const { allGames } = usePageContext()
  const { t } = useTranslation()
  const games =
    allGames?.filter(
      (game) =>
        [GameStatus.Anticipation, GameStatus.Live, GameStatus.Sustain].includes(game.info.status) &&
        !game.info.hideOnLanding,
    ) || []
  const homePage = data?.sanityHomepage

  if (user && user.info.gameId) {
    return <Loading />
  }

  if (!homePage) return null
  return (
    <>
      <SEO title={t('home.seoTitle')} />
      <HomeHeader>{homePage.mainHeading}</HomeHeader>
      <Wrapper width="1000px" style={{ minHeight: '220px' }}>
        <Logo animated />
      </Wrapper>
      {games.length ? (
        <>
          <Wrapper width="800px">
            <SubHeading as="h2" tertiary>
              {homePage.gamesSectionHeading}
            </SubHeading>
          </Wrapper>
          <LinkList>
            {games.map((game) => {
              const extraContent = data.allSanityGame.nodes.find((sg) => sg.dynamoId === game.id)
              return (
                <LinkedListItem key={game.id}>
                  <Link to={`/${game.info.slug}/`}>
                    {extraContent?.gameIcon?.icon?.asset?.url && (
                      <img
                        src={extraContent?.gameIcon?.icon?.asset?.url}
                        alt={`${t('aria.alt.game')} ${game?.info.name}`}
                      />
                    )}
                    <LinkedListAreaName>{game?.info.name}</LinkedListAreaName>
                  </Link>
                </LinkedListItem>
              )
            })}
          </LinkList>
        </>
      ) : null}

      <Wrapper width="700px" tall>
        {homePage.introductionHeading && (
          <SubHeading as="h2" tertiary verySmall>
            {homePage.introductionHeading}
          </SubHeading>
        )}
        {homePage.introductionContent && <RichText blocks={homePage.introductionContent} />}
      </Wrapper>
      <Spacer space="30px" />
      <Wrapper noMobileMargins tall>
        {homePage.introductionVideoCta && <VideoCta videoCta={homePage.introductionVideoCta} />}
      </Wrapper>
    </>
  )
}

export const query = graphql`
  query HomePage($homePageId: String, $language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }

    sanityHomepage(id: { eq: $homePageId }, i18n_lang: { eq: $language }) {
      ...homepageDetails
      mainHeading
      gamesSectionHeading
      introductionHeading
      introductionContent: _rawIntroductionContent
      introductionVideoCta {
        placeholderImage {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          }
        }

        text
        videoUrl
      }
    }

    allSanityGame(filter: { status: { ne: "draft" }, i18n_lang: { eq: $language } }) {
      nodes {
        dynamoId
        gameIcon {
          icon {
            asset {
              url
            }
          }
        }
      }
    }
  }
`

export default HomePage
