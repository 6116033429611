import { object, string } from 'yup'

export const getCreteTeamSchema = (t2, teams: string[]) => {
  const t = (...args: any) => t2(...args) || undefined
  return object({
    name: string()
      .min(3, t('errors.formik.team.nameMin'))
      .max(30, t('errors.formik.team.nameMax'))
      .nullable()
      .required(t('validation.req'))
      .test({
        name: 'in array',
        params: { teams },
        message: t('createTeam.errors.nameTaken'),
        test: (value) => {
          if (value) {
            return !teams.includes(value.toLowerCase())
          } else {
            return false
          }
        },
      }),
    type: string().nullable().required(t('validation.req')),
    description: string()
      .min(30, t('errors.formik.team.bioMin'))
      .max(300, t('errors.formik.team.descriptionMax'))
      .nullable()
      .required(t('validation.req')),
    gameId: string().required(t('createTeam.errors.gameIdReq')),
  })
}
