import { Background, Bar, Close, Container, IconContainer, MessageContainer, Text } from './Message/Message.styled'
import Warning from '../assets/images/svg/warning.svg'
import Cross from '../assets/images/svg/cross.svg'
import React, { useEffect, useState } from 'react'
import useAuth from '../contexts/AuthContext/useAuth'
import { useSurvey } from '../hooks/useSurvey/useSurvey'
import useGame from '../contexts/GameContext/useGame'
import { AnchorBold } from './Anchor/Anchor.styled'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { GameStatus } from '../generated/api'

const SurveyBanner = () => {
  const { user } = useAuth()
  const { closingSurveyEnabled, surveyLink } = useSurvey()
  const [show, setShow] = useState(true)
  const { game } = useGame()
  const isFinished = game?.info.status === GameStatus.Sustain
  const { t } = useTranslation()

  useEffect(() => {
    setShow(true)
  }, [user?.id])

  const surveyToComplete = closingSurveyEnabled ? 'feedback' : 'health'

  return (
    <>
      {show && (
        <Container>
          <Background show={true}>
            <Bar type="warning" />
            <IconContainer style={{ height: 53, width: 36, marginRight: '1rem' }}>
              <Warning id="warning-icon" />
            </IconContainer>
            <MessageContainer>
              <div style={{ marginRight: '1rem' }}>
                <Text>{`Hey ${user?.profile.firstName}, ${
                  isFinished && closingSurveyEnabled
                    ? t('game.dashboard.surveyBanner.titleFinishedGame')
                    : t('game.dashboard.surveyBanner.titleLiveGame')
                }`}</Text>
                <AnchorBold href={surveyLink}>
                  {t('game.dashboard.surveyBanner.text', { survey: surveyToComplete })}
                </AnchorBold>
              </div>
              <Close
                id="Close Button"
                tabIndex="0"
                role="button"
                aria-pressed="false"
                aria-label={t('aria.label.close')}
                name="Close Button"
                onClick={() => {
                  setShow(false)
                }}
              >
                <Cross />
              </Close>
            </MessageContainer>
          </Background>
        </Container>
      )}
    </>
  )
}

export default SurveyBanner
