import styled from 'styled-components'
import { withProp } from 'styled-tools'
import device from '../../styles/device'

export const LogoContainer = styled.div`
  ${(props) => props.centered && `flex: 1;`}
  position: relative;

  ${withProp(['animated'], (animated) => {
    if (animated) {
      return `

                height: 57vw;
                max-height:350px;
                @media ${device.mobLandscape} {
                    height: 100%;
                }
                @media ${device.tabletLandscape} {
                    height: 440px;
                    max-height:440px;
                    margin:0px auto;
                    padding-bottom:50px;
                }
                margin:0 auto 0;
                width: 100%;
                display:block;
                > div > div {
                justify-content:center;
                    display: flex;
                    flex: 1;
                    flex-basis: 100%;
                    align-items: center;
                    align-items:center;
                    > div:first-child{
                        width: 100%;
                    }
                    > div:first-child > div {
                        width: 100%;
                        max-width:500px;
                        overflow:visible;
                        > svg{
                                overflow:visible;
                                margin:0% auto -10%;
                                @media ${device.mobLandscape} {

                                                margin:-10% auto 10%;

                                 }
                            }
                    }
                    > div:last-child {
                        display:none;
                        padding-top:00px;
                        width: 120px;
                    }

                    @media ${device.tabletLandscape} {

                        width:100%;
                        > div:first-child{
                            width: auto;
                        }
                        > div:first-child > div {
                            width: 550px;
                            max-width:550px;
                            > svg{
                                overflow:visible;
                                margin:-20px auto -30px 40px;
                            }
                        }
                        > div:last-child  {
                            display:flex;
                            > div {
                                overflow:visible;
                                margin:-280px 0 0 -60px
                            }
                        }
                    }
                }

            `
    }
    return ''
  })}
  svg {
    height: 48px;
    width: 56px;
    @media ${device.mobLandscape} {
      height: 58px;
      width: 64px;
    }

    @media ${device.tabletLandscape} {
      height: 138px;
      width: 151px;
    }
  }

  a {
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  ${(props) =>
    props.centered &&
    `   display:flex;
            justify-content:center;
            width:100%;
            a {
                justify-content:center;


            }
            svg {
                    width:100px;
                    height: 91px;
                    @media ${device.tabletLandscape} {
                        width:152px;
                        height: 138px;
                    }
                }
        `}

  h2 {
    hyphens: auto;
    margin: 0 0 0 0.6rem;
    color: ${(props) => props.theme.colors.midBlue};
    font-size: 1.3rem;
    line-height: 1.5rem;

    @media ${device.tabletLandscape} {
      font-size: 2.2rem;
      margin: 0 0 0 1.6rem;
    }
  }
`

export const IEWrapper = styled.div`
  width: 100%;
  height: 210px;
  text-align: center;
  @media ${device.mobLandscape} {
    height: 350px;
  }
  svg {
    margin-bottom: 0;
    width: 350px;
    height: 350px;
  }
  svg.animatedReplacement {
    width: 250px;
    height: 210px;
    margin-bottom: 20px;
    @media ${device.mobLandscape} {
      width: 350px;
      height: 320px;
    }
    @media ${device.tabletLandscape} {
      width: 350px;
      height: 350px;
    }
  }
`

export const PlayPauseButton = styled.button`
  background-color: transparent;
  padding: 0;
  position: absolute;
  bottom: 40px;
  right: 24%; 
  border-radius: 50%;
  ${(props) => props.theme.hover} {
    background-color: transparent;
  }
  @media ${device.tabletLandscape} {
  right: 27%;
}
  svg {
    height: 35px;
    width: 35px;
    
    transition: fill ${(props) => props.theme.transitions.transition};
    

    path {
      fill: ${(props) => props.theme.colors.primary};
    }

    rect {
      fill: ${(props) => props.theme.colors.primary};
    }

    polygon {
      fill: ${(props) => props.theme.colors.primary};
    }
  }
  }
`
