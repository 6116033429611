import styled from 'styled-components'
import device from '../../../../styles/device'

const HeadingWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -1rem 4rem 3em 4rem;
  height: 8rem;
  @media ${device.mobLandscape} {
    margin: -2rem 5rem 2rem 5rem;
    height: 10rem;
  }
  @media ${device.tabletPortrait} {
    margin: -1rem 18rem 4rem 18rem;
  }
  @media ${device.tabletLandscape} {
    margin: -5rem 20rem 8rem 20rem;
  }

  @media ${device.laptopNarrow} {
    margin: -7rem 20rem 8rem 20rem;
  }
  @media ${device.desktop} {
    margin: -5.5rem 20rem 8rem 20rem;
  }

  h1 {
    margin: 0;
    align-self: flex-end;
    line-height: 1.2;
  }
`

export default HeadingWrapper
