import useAuth from '../AuthContext/useAuth'
import { useEffect } from 'react'
import usePageContext from '../../hooks/usePageContext'
import { useLocation } from '@gatsbyjs/reach-router'
import useNavigate from '../../hooks/useNavigate'

const useRedirect = () => {
  const { user, isAuthenticated, isInitialized } = useAuth()
  const { isPrivate, alwaysAllow } = usePageContext()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isInitialized || alwaysAllow) return

    const inSignup = !!pathname.match('/signup/+.')
    const inTeamCreation = !!pathname.match('/signup/create-team')
    const teamCreationSuccess = !!pathname.match('/signup/create-team/success')
    const inSuccess = !!pathname.match('/signup/success')
    const inFamily = !!pathname.match('/signup/family/+.')
    // const inInviteFamily = !!pathname.match('/signup/family/invite-family')
    const inSelectAge = !!pathname.match('/signup')
    const inUnderRage = !!pathname.match('/signup/under-age')
    const inGameSetup = !!pathname.match('/game-setup')
    const inJoinGame = !!pathname.match('/join-game')
    const inJoinFamily = !!pathname.match('/join-family/+')

    // const isFamilyOwner = user?.id === user?.role.parentId

    const exceptions =
      !inTeamCreation && !inFamily && !inSuccess && !teamCreationSuccess && !inSelectAge && !inUnderRage

    ;(async () => {
      if (user) {
        if (!user?.profile.isRegistered && !inSignup) {
          if (!user.info?.publicName && !inJoinFamily) {
            await navigate(`/signup/name-details`)
            return
          }

          if (user?.info.publicName && !user?.info.gameId) {
            await navigate('/signup/game-details')
            return
          }

          if (user?.info.publicName && user.info.gameId && (!user.profile.birthDate || !user?.profile.postCode)) {
            await navigate('/signup/profile-details')
            return
          }
        }

        // TODO: Need to redirect every user without game
        // if (user?.publicName && !user?.gameId) {
        //   await navigate('/join-game')
        //   return
        // }

        // if (user?.profile.isRegistered && inFamily && user?.role.parentId && !isFamilyOwner && !inInviteFamily) {
        //   console.log('-------------HER1E-------------')
        //   console.log('isFamilyOwner', isFamilyOwner)
        //   await navigate('/landing')
        //   return
        // }
        if (user?.profile.isRegistered && inSignup && exceptions) {
          await navigate('/landing')
          return
        }

        if (isAuthenticated && inJoinGame && user.info.gameId) {
          await navigate('/landing')
          return
        }

        if (inTeamCreation && !user?.info.gameId) {
          await navigate('/')
          return
        }

        // TODO user.info.teamId must be replace with ownerOfTeam
        // if (inTeamCreation && !teamCreationSuccess && user?.info.gameId && !!user.info.teamId) {
        //   await navigate('/')
        //   return
        // }

        if (user?.info.gameId) {
          if (pathname === '/') {
            await navigate('/landing')
            return
          }
        }
      }

      if (!isAuthenticated && inGameSetup) {
        await navigate('/login')
        return
      }
      if (isPrivate && (!isAuthenticated || !user)) {
        await navigate(`/landing`, { replace: true })
        return
      }
    })()
  }, [user, isPrivate, alwaysAllow, pathname, isInitialized, isAuthenticated])
}

export default useRedirect
