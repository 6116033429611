import styled from 'styled-components'
import device from '../../styles/device'

export const TapListContainer = styled.ul`
  background: ${(props) => props.theme.colors.white};
  padding: 0 0 1rem;
  margin: 0;
  margin-bottom: 20px;

  li::before {
    content: none;
  }
`

export const StyledTapListDay = styled.li`
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.fonts.xs};
  font-family: ${(props) => props.theme.fonts.primaryFont};
  list-style-type: none;
  text-transform: uppercase;
  display: inline-block;
  width: 100%;
  padding: 1rem;
  margin-bottom: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${device.mobLandscape} {
    padding: 1rem 1.5rem;
  }
`

export const StyledTapListDayText = styled.span`
  margin-right: 1rem;
  font-size: ${(props) => props.theme.fonts.xxs};

  @media ${device.mobLandscape} {
    font-size: ${(props) => props.theme.fonts.mxs};
  }
`

// Note: this button currently isn’t used.
// See https://projects.torchbox.com/projects/beat-the-street/tickets/232.
export const StyledTapListDayButton = styled.button`
  background: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.fonts.xxxs};
  font-family: ${(props) => props.theme.fonts.primaryFont};
  color: ${(props) => props.theme.colors.midDarkBlue};
  text-transform: uppercase;
  padding: 0.2rem 0.4rem;

  @media ${device.mobLandscape} {
    font-size: ${(props) => props.theme.fonts.xs};
  }
  :hover {
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.midDarkBlue};
  }
  :active {
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.midDarkBlue};
  }
  :focus {
    border: none;
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.midDarkBlue};
  }
`
