import { useMemo } from 'react'
import { LeaderboardPointType, useGetLeaderboardFollowQuery } from '../../../generated/api'
import useAuth from '../../../contexts/AuthContext/useAuth'

const useFollowLeaderboards = (type: LeaderboardPointType) => {
  const { user } = useAuth()
  const { data, ...rest } = useGetLeaderboardFollowQuery(
    {
      input: {
        gameId: user?.info.gameId as string,
        type: type,
        playerId: user?.id,
      },
    },
    {
      enabled: !!user?.info.gameId && !!user?.id,
    },
  )

  const leaderboards = useMemo(() => data?.getFollowLeaderboards.data, [data]) || []

  return {
    ...rest,
    data: leaderboards.filter((player) => player.name),
  }
}

export default useFollowLeaderboards
