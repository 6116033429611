import React, { FC } from 'react'
import imageUrlBuilder from '@sanity/image-url'
import sanity from '../../contexts/SanityClient'

export interface SanityImageProps {
  alt?: string
  caption?: string
  image?: string
  width?: number
}

const SanityImage: FC<SanityImageProps> = ({ alt = '', caption = '', image = '' }) => {
  const builder = imageUrlBuilder(sanity)
  const src = builder.image(image).auto('format').toString()
  const imageAlt = alt || caption
  return <img alt={imageAlt} src={src} />
}

export default SanityImage
