import styled, { css } from 'styled-components'

const landingBackground = css`
  overflow: hidden;
  position: absolute;
  height: 1300px;
  width: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(${(props) => props.theme.colors.midAqua}, white);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: ${(props) => props.theme.zIndex.backgroundContainer};
`

export const BackgroundContainer = styled.div`
  background-color: white;
  ${(props) => props.isLanding && landingBackground};
`
