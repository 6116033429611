import device from '../../../styles/device'
import styled from 'styled-components'

export const MainHeading = styled.h1`
  text-align: center;
  font-size: 30px;
  margin: -20px 30px 15px 30px;
  @media ${device.tabletPortrait} {
    font-size: 50px;
  }
`

export const Title = styled.h3`
  max-width: ${(props) => props.theme.siteWidth};
  padding-top: 10px;
  margin: auto;
  text-align: center;
  font-weight: normal;
  font-size: 21px;
`

export const SubHeading = styled.h2`
  max-width: ${(props) => props.theme.siteWidth};
  padding: 0 0 20px 0;
  margin: auto;
  text-align: center;
  font-size: 25px;
  color: ${(props) => props.theme.colors.body};
`

export const SubTitle = styled('span')`
  max-width: ${(props) => props.theme.siteWidth};
  margin-top: 5px;
  text-align: center;
  font-size: 16px;
  color: ${(props) => props.theme.colors.body};
`

export const MainSubTitle = styled(SubTitle)`
  min-height: 70px;
  max-width: 120px;

  @media screen and (min-width: 1024px) {
    max-width: 130px;
  }
`

export const Wrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const AwardSubtitle = styled('h1')`
  max-width: ${(props) => props.theme.siteWidth};
  padding-top: 7px;
  margin: auto;
  text-align: center;
  font-size: 13px;
  color: ${(props) => props.theme.colors.body};
`
