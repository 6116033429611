import * as React from 'react'
import { FC, memo } from 'react'

interface PinProps {
  width?: string
  height?: string
  fill?: string
}

const PinSVG: FC<PinProps> = ({ width = '42px', height = '61px', fill = '#16771f' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 49"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-214.000000, -340.000000)" fill={fill}>
          <g transform="translate(0.000000, 219.000000)">
            <g transform="translate(19.000000, 10.000000)">
              <g transform="translate(187.000000, 111.000000)">
                <g>
                  <circle cx="24.8" cy="16.8" r="15.4" />
                  <path
                    d="M24.8,0 C15.512,0 8,7.6685 8,17.15 C8,30.0125 24.8,49 24.8,49 C24.8,49 41.6,30.0125 41.6,17.15 C41.6,7.6685 34.088,0 24.8,0 L24.8,0 Z M24.8,30.8 C17.8448,30.8 12.2,24.8416 12.2,17.5 C12.2,10.1584 17.8448,4.2 24.8,4.2 C31.7552,4.2 37.4,10.1584 37.4,17.5 C37.4,24.8416 31.7552,30.8 24.8,30.8 L24.8,30.8 Z"
                    opacity="0.800000012"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default memo(PinSVG)
