import React, { useState } from 'react'
import Button from '../../../../../components/Button/Button'
import theme from '../../../../../styles/theme'
import useAuth from '../../../../../contexts/AuthContext/useAuth'
import { wideSmallModal } from '../../../../../components/Modal/Modal.styled'
import { Text } from '../../../../../components/Message/Message.styled'
import Modal from '../../../../../components/Modal/Modal'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useDeleteChildMutation, useDeletePlayerMutation } from '../../../../../generated/api'
import Input from '../../../../../components/Input/Input'
import { FormItem } from '../../../../../components/FormElements/FormElements.styled'
import { User } from '../../../../../contexts/AuthContext/type'
import { useQueryClient } from '@tanstack/react-query'

const DeleteAccountButton = () => {
  const { user, masterUser, login, refetch, logout } = useAuth()
  const [isChildaModalOpen, setIsChildaModalOpen] = useState(false)
  const [isParentModalOpen, setIsParentModalOpen] = useState(false)
  const { t } = useTranslation()
  const [password, setPassword] = useState('')
  const [error, setError] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const queryClient = useQueryClient()

  const isParent = user?.id === user?.role.parentId
  const isChild = user?.role.isChild

  const { mutateAsync: deleteAccount } = useDeletePlayerMutation()
  const { mutateAsync: deleteChild } = useDeleteChildMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['FindFamilyPlayers'] }),
  })

  const onDeleteAccount = async (player?: User) => {
    if (!password) return setError(t('validation.req') || '')
    setLoading(true)
    if (masterUser?.profile.email) {
      try {
        await login(masterUser?.profile.email, password)
        setError(undefined)
        if (player?.role.isChild && player?.role.parentId === masterUser?.id) {
          await deleteChild({ id: player.id })
        } else if (player?.id === masterUser.id) {
          await deleteAccount({})
          await logout()
        }
        refetch()
        setLoading(false)
        resetModal()
      } catch (e) {
        console.log('err', e)
        if (e.message.includes('Incorrect username or password')) {
          setError(t('auth.errors.wrongPassword') || '')
        } else if (e.message.includes('limit exceeded', 'Timeout')) {
          setError(t('auth.errors.timeExceeded') || '')
        } else {
          setError(t('errors.generic') || '')
        }
        setLoading(false)
      }
    }
  }

  const resetModal = () => {
    setIsChildaModalOpen(false)
    setIsParentModalOpen(false)
    setError(undefined)
    setPassword('')
  }

  return (
    <>
      <Button
        text={isChild ? t('manageAccount.deleteAccount.buttonChild') : t('manageAccount.deleteAccount.buttonAdult')}
        tertiary
        style={{
          color: theme.colors.error,
          borderColor: theme.colors.error,
        }}
        onClick={() => (isParent ? setIsParentModalOpen(true) : setIsChildaModalOpen(true))}
      />
      <Modal
        modalOpen={isParentModalOpen}
        closeModal={resetModal}
        heading={t('manageAccount.deleteAccount.modalAdult.title') || ''}
        modalType={wideSmallModal}
        children={
          <div style={{ paddingRight: '1.5rem', paddingLeft: '1.5rem' }}>
            <Text style={{ marginBottom: '10px', fontSize: 16, fontWeight: 'normal' }}>
              {t('manageAccount.deleteAccount.modalAdult.text', { name: user?.info.publicName })}
            </Text>
            <Text style={{ marginBottom: '25px', fontSize: 16, fontWeight: 'normal' }}>
              {t('manageAccount.deleteAccount.modalAdult.warning')}
            </Text>
            <FormItem marginBottom={'3rem'}>
              <Input
                id="password"
                type="password"
                text={'Password' || ''}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{ width: '100%', maxWidth: '600px' }}
                error={error}
                hasError={Boolean(error)}
              />
            </FormItem>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                text={t('common.cancel')}
                onClick={resetModal}
                tertiary
                style={{ marginRight: '15px', width: '80px' }}
              />
              <Button
                text={t('common.delete')}
                secondary
                style={{
                  backgroundColor: theme.colors.error,
                  borderColor: theme.colors.error,
                  width: '100px',
                }}
                onClick={() => onDeleteAccount(user)}
                isSubmitting={loading}
              />
            </div>
          </div>
        }
      />
      <Modal
        modalOpen={isChildaModalOpen}
        closeModal={resetModal}
        heading={t('manageAccount.deleteAccount.modalChild.title') || ''}
        modalType={wideSmallModal}
        children={
          <div style={{ paddingRight: '1.5rem', paddingLeft: '1.5rem' }}>
            <Text style={{ marginBottom: '25px', fontSize: 16, fontWeight: 'normal' }}>
              {t('manageAccount.deleteAccount.modalChild.text', { name: user?.info.publicName })}
            </Text>
            <FormItem marginBottom={'3rem'}>
              <Input
                id="password"
                type="password"
                text={'Password' || ''}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{ width: '100%', maxWidth: '600px' }}
                error={error}
                hasError={Boolean(error)}
              />
            </FormItem>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                text={t('common.cancel')}
                onClick={resetModal}
                tertiary
                style={{ marginRight: '15px', width: '80px' }}
              />
              <Button
                text={t('common.delete')}
                secondary
                // isSubmitting={isLoading}
                style={{
                  backgroundColor: theme.colors.error,
                  borderColor: theme.colors.error,
                  width: '100px',
                }}
                onClick={() => onDeleteAccount(user)}
                isSubmitting={loading}
              />
            </div>
          </div>
        }
      />
    </>
  )
}

export default DeleteAccountButton
