import { object, string } from 'yup'

export const getLoginSchema = (t) => {
  const t2 = (...args: any) => t(...args) || undefined
  return object({
    email: string()
      .email(t2('validation.email.notValid'))
      .max(50, t2('errors.formik.email.max'))
      .required(t2('validation.email.req')),
    password: string().max(50, t('errors.formik.password.max')).required(t('validation.password.req')),
  })
}
