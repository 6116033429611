import React, { FC } from 'react'
import styled from 'styled-components'
import { withProp } from 'styled-tools'
import device from '../../styles/device'

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  > * {
    width: 100%;
    @media ${device.tabletPortrait} {
      width: auto;
      flex-basis: ${withProp(['columns'], (columns) => `${Math.round(100 / columns)}%`)};
    }
  }
`

interface GridWrapperProps {
  className?: string
  children?: string | JSX.Element | JSX.Element[]
  columns?: number
}

const GridWrapper: FC<GridWrapperProps> = ({ className, children, columns = 2 }) => {
  return (
    <Wrapper className={className} columns={columns}>
      {children}
    </Wrapper>
  )
}

export default GridWrapper
