import styled from 'styled-components'
import { prop, withProp } from 'styled-tools'
import device from '../../../../../styles/device'
import Button from '../../../../../components/Button/Button'
import { lighten } from 'polished'

export const WeeklySectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 170px;
  margin-bottom: 50px;
  width: 100%;
`

export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 120%;
  @media ${device.desktop} {
    width: 100%;
  }

  > svg {
    width: 100%;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: -150px;
  background-color: ${prop('backgroundColor')};
  > div {
    max-width: 900px;
    margin: 0 auto;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media ${device.desktop} {
      max-width: 1030px;
    }
  }
`

export const ButtonWrapper = styled.div`
  width: 100%;
  padding: 0 20px;
  display: flex;
  justify-content: center;
`

export const WeekHeading = styled.div`
  position: absolute;
  width: 100%;
  top: -140px;
  > div > div {
    width: 316px;
    margin: 0 auto;
    position: relative;
  }
  h3 {
    font-family: ${(props) => props.theme.fonts.secondaryFont};
    font-size: 24px;
    transform: rotate(20deg);
    position: absolute;
    bottom: 20px;
    right: 50px;
    color: ${prop('textColor')};
  }
`

export const SubHeading = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  h3,
  p {
    display: inline-block;
    font-size: 30px;
    margin: 10px;
    text-align: center;
    color: ${prop('textColor')};
  }
`

export const EventsButton = styled(Button)`
  margin: 10px auto;
  background-color: ${prop('backgroundColor')};
  color: ${prop('textColor')};
  width: 100%;
  margin-bottom: 50px;
  ${(props) => props.theme.hover} {
    background-color: ${withProp('backgroundColor', (bgc) => lighten(0.3, bgc))};
    color: ${prop('textColor')};
  }
`
