import {
  JoinFamilyInput,
  JoinTeamInput,
  UpdatePlayerInput,
  useJoinFamilyMutation,
  useUpdatePlayerMutation,
  useJoinTeamMutation,
  useCreateChildMutation,
  CreateChildPlayerInput,
  useLeaveTeamMutation,
  useCreatePostCodeRecordMutation,
  PostCodeInput,
  useRemoveFromFamilyMutation,
  RemoveFromFamilyInput,
} from '../../../generated/api'
import { useQueryClient } from '@tanstack/react-query'

export const useManagePlayers = () => {
  const queryClient = useQueryClient()

  const { mutateAsync: update } = useUpdatePlayerMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['GetPlayerById'] }),
  })
  const { mutateAsync: joinFamily } = useJoinFamilyMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['GetPlayerById'] }),
  })
  const { mutateAsync: joinTeam } = useJoinTeamMutation({
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({ queryKey: ['GetPlayerById'] }),
        queryClient.invalidateQueries({ queryKey: ['FindTeams'] }),
      ]),
  })
  const { mutateAsync: createChild } = useCreateChildMutation()
  const { mutateAsync: leaveTeam } = useLeaveTeamMutation({
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({ queryKey: ['GetPlayerById'] }),
        queryClient.invalidateQueries({ queryKey: ['FindTeams'] }),
      ]),
  })

  const { mutateAsync: createPostCOdeRecord } = useCreatePostCodeRecordMutation()

  const { mutateAsync: removeFromFamily } = useRemoveFromFamilyMutation()

  const onUpdatePlayer = async (input: UpdatePlayerInput) => {
    return await update({ input })
  }

  const onJoinFamily = async (input: JoinFamilyInput) => {
    return await joinFamily({ input })
  }

  const onJoinTeam = async (input: JoinTeamInput) => {
    return await joinTeam({ input })
  }

  const onCreateChild = async (input: CreateChildPlayerInput) => {
    return await createChild({ input })
  }

  const onLeaveTeam = async (playerId: string) => {
    return await leaveTeam({ playerId })
  }

  const onCreatePostCodeRecord = async (input: PostCodeInput) => {
    return await createPostCOdeRecord({ input })
  }

  const onRemoveFromFamily = async (input: RemoveFromFamilyInput) => {
    return await removeFromFamily({ input })
  }

  return {
    onUpdatePlayer,
    onJoinFamily,
    onJoinTeam,
    onCreateChild,
    onLeaveTeam,
    onCreatePostCodeRecord,
    onRemoveFromFamily,
  }
}
