import { createGlobalStyle } from 'styled-components'
import { darken } from 'polished'
import device from '../styles/device'

const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    font-family: ${(props) => props.theme.fonts.primaryFont};
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    font-size: ${(props) => props.theme.fonts.default};
    line-height: 1.4;
    max-width: 100vw;

    // Cookie consent banner variables
    --cookie-consent-banner-colors-primary: #167711;
    --cookie-consent-banner-colors-secondary: #167711;
    --cookie-consent-banner-colors-primary-border: #167711;
    --cookie-consent-banner-colors-secondary-border: #167711;
    --cookie-consent-banner-font-size-body: 15px;
    --cookie-consent-banner-font-size-headline: 15px;
    --cookie-consent-banner-spacings-body-padding-top: 10px;
    --cookie-consent-banner-border-radius-body: 6px;
    --cookie-consent-banner-z-index-container: 3147483647;


    #cookie-banner {
      #shadow-root {
        .cc_buttons {
          background-color: red !important;
        }
      }
    }

    &.mapView {
      overflow: hidden;

      body {
        overflow: hidden;
      }
    }

    &.ReactModal__Html--open {
      overflow: hidden;
    }
  }

  body {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.darkGrey};
    max-width: 100vw;

    &.ReactModal__Body--open {
      height: 100vh;
      overflow: hidden;
    }
  }

  html,
  body {
    padding: 0;
    margin: 0;
    min-height: 100vh;
    overflow-x: hidden;
  }

  /* IE11 */
  main {
    display: block;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${(props) => props.theme.fonts.secondaryFont};
    color: ${(props) => props.theme.colors.body};
    margin: 0 0 1rem;
  }

  h1 {
    font-size: 2rem;

    @media ${device.mobLandscape} {
      font-size: 2.3rem;
    }

    @media ${device.tabletLandscape} {
      font-size: 2.6rem;
    }
  }

  h2 {
    font-size: 1.6rem;

    @media ${device.mobLandscape} {
      font-size: 1.9rem;
    }

    @media ${device.tabletLandscape} {
      font-size: 2.2rem;
    }
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.2rem;
  }

  h5 {
    font-size: 1rem;
  }

  h6 {
    font-size: 0.8rem;
  }

  p {
    margin: 0 0 1rem;
    font-size: 1.5rem;
    line-height: 1.5;

    @media ${device.mobLandscape} {
      font-size: 1.6rem;
      line-height: 1.6;
    }

    @media ${device.tabletLandscape} {
      font-size: 1.7rem;
      line-height: 1.7;
    }

    &.small {
      font-size: 1.3rem;
    }

    &.tall {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }

  /* Prevent empty space below images appearing */
  img,
  svg {
    vertical-align: top;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  button, input, select, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  form {
    padding-top: 1.5rem;
  }

  fieldset {
    border: none;

    legend {
      text-align: left;
      color: ${(props) => props.theme.colors.body};
      font-size: 1.5rem;
      font-family: ${(props) => props.theme.fonts.secondaryFont};
      margin-bottom: 2rem;
    }
  }


  textarea,
  select,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"],
  input[type="search"],
  input[type="tel"],
  input[type="color"] {
    border: 1px solid ${(props) => props.theme.colors.grey};
    border-radius: 4px;
    padding: 0.7rem;
    width: 100%;
    max-width: ${(props) => props.theme.maxInputWidth};
    /* remove top shadow on iOS */
    appearance: none;

    &:hover {
      border: 1px solid ${(props) => props.theme.colors.midGrey};
    }

    &:focus {
      outline: none;
      border: 2px solid ${(props) => props.theme.colors.secondary};

      + label {
        color: ${(props) => props.theme.colors.secondary};
      }
    }

    &[disabled], &:hover:disabled {
      opacity: 0.6;
      border: 1px solid ${(props) => props.theme.colors.lighterGrey};
    }
  }

  input[type="submit"]:focus {
    outline: none;
    border: 1px solid ${(props) => props.theme.colors.secondary};
  }


  input[type="checkbox"],
  input[type="radio"] {
    height: ${(props) => props.theme.radioCheckboxWidth};
    left: 0;
    opacity: 0;
    top: 0;
    width: ${(props) => props.theme.radioCheckboxWidth};
    margin: 0;
    cursor: pointer;
    position: absolute;

    + label {
      padding-left: calc(${(props) => props.theme.radioCheckboxWidth} + 10px);

      &::before {
        border: 1px solid ${(props) => props.theme.colors.grey};
        content: '';
        height: ${(props) => props.theme.radioCheckboxWidth};
        left: 0;
        position: absolute;
        top: 0;
        width: ${(props) => props.theme.radioCheckboxWidth};
        border-radius: 50%;
      }

      &::after {
        content: '';
        opacity: 0;
        border: 10px solid ${(props) => props.theme.colors.primary};
        border-radius: 50%;
        position: absolute;
        left: 5px;
        top: 5px;
        transition: opacity 0.2s ease-in-out;
      }
    }

    &:checked {
      + label::after {
        opacity: 1;
      }
    }

    &:focus {
      + label::before {
        box-shadow: 0 0 0 3px ${(props) => props.theme.colors.secondary};
        outline: 3px solid transparent;
      }
    }
  }

  input[type="checkbox"] {
    + label {
      font-family: ${(props) => props.theme.fonts.primaryFont};
      font-size: 1.5rem;
      text-align: left;
      max-width: 350px;

      &::before {
        border-radius: 0.5rem;
        margin-top: 3px;
      }

      &::after {
        border: none;
        width: 15px;
        height: 7px;
        border-radius: 0;
        margin-top: 3px;
        color: white;
        border: solid;
        border-width: 0 0 3px 3px;
        background: transparent;
        border-top-color: transparent;
        position: absolute;
        top: 10px;
        left: 8px;
        transform: rotate(-45deg);
        zoom: 1;
        opacity: 0;
      }

      p {
        font-size: 1.5rem;
      }
    }

    &:checked {
      + label::before {
        background: ${(props) => props.theme.colors.primary};
      }
    }
  }

  label {
    font-family: ${(props) => props.theme.fonts.primaryFont};
    font-size: ${(props) => props.theme.fonts.mxs};
    color: ${(props) => props.theme.colors.midGrey};
  }

  button {
    font-family: ${(props) => props.theme.fonts.secondaryFont};
    border-radius: 6px;
    appearance: none;
    border: 0;
    padding: 0.8rem 0.5rem 1rem 0.5rem;
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.primary};
    cursor: pointer;
    transition: background-color, ${(props) => props.theme.transitions.quickTransition};

    &:disabled {
      opacity: 0.3;
      cursor: default;
    }

    ${(props) => props.theme.hover} {
      background-color: ${(props) => darken(0.1, props.theme.colors.primary)};
    }

    &:hover:disabled {
      background-color: ${(props) => props.theme.colors.primary};
    }
  }


  select {
    font-family: ${(props) => props.theme.fonts.primaryFont};
    font-size: 1.6rem;
    height: 40px;
    padding: 0 3rem 0 1rem;

    @media ${device.mobLandscape} {
      height: 44px;
    }

    @media ${device.tabletLandscape} {
      font-size: 1.7rem;
      height: 50px;
    }
    color: ${(props) => props.theme.colors.body};
    cursor: pointer;
    border: 0;
    appearance: none;
      background: ${(props) =>
        props.theme.colors
          .white} url("data:image/svg+xml;utf8,<svg width='12' height='7' xmlns='http://www.w3.org/2000/svg'><path d='M10.6 0L6 4.351 1.4 0 0 1.324 6 7l6-5.676z' fill='black' fill-rule='evenodd'/></svg>") no-repeat;
    background-size: 12px;
    background-position: calc(100% - 1rem) center;
    transition: background-color ${(props) => props.theme.transitions.quickTransition};
    border: 1px solid ${(props) => props.theme.colors.grey};
            ${(props) => props.theme.hover} {
                    background-color: ${(props) => darken(0.1, props.theme.colors.primary)};
            }

    &:hover {
      border: none;

    }

    &:focus {
      border: none;
    }

    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }

    option {
      background: white;
      color: ${(props) => props.theme.colors.black};
    }
  }

  a {
    font-size: 1.5rem;
    color: ${(props) => props.theme.colors.secondary};
    text-decoration: none;

    ${(props) => props.theme.hover} {
      cursor: pointer;
      text-decoration: underline;
    }

    @media ${device.mobLandscape} {
      font-size: 1.6rem;
    }

    @media ${device.tabletLandscape} {
      font-size: 1.7rem;
    }

  }

  ul,
  ol {
    margin: 0;
    padding: 0;
    list-style-position: inside;
    font-size: 1.5rem;
    margin-bottom: 3rem;

    @media ${device.mobLandscape} {
      font-size: 1.6rem;
    }
    @media ${device.tabletPortrait} {
      margin-bottom: 3.5rem;
    }
    @media ${device.tabletLandscape} {
      font-size: 1.7rem;
    }

    li {
      margin-bottom: 10px;
    }

    li::before {
      content: "";
      width: 1rem;
      display: inline-block;
    }
  }

  ol ol, ul ul {
    margin: 1rem 0 0 1rem;
  }

  /* ------ Vendor ------- */
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity ${(props) => props.theme.transitions.transition};
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }

  .ReactModal__Content {
    .is-narrow & {
      max-width: ${(props) => props.theme.narrowModalWidth};
      max-height: ${(props) => props.theme.narrowModalHeight};
    }

    .is-video & {
      background: transparent !important; /* vendor override */
    }

    .is-small & {
      max-width: ${(props) => props.theme.smallModalWidth};
    }

    .is-large & {
      max-width: ${(props) => props.theme.largeModalWidth};
    }
  }

  .grecaptcha-badge {
    visibility: hidden;
  }

  .mapboxgl-ctrl-group button:active,
  .mapboxgl-ctrl-group button:focus {
    background-color: transparent;
  }

  #snipcart .snipcart-modal__container {
    z-index: 10000;
  }

  #snipcart button:hover {
    background-color: lightgrey;
  }

  #snipcart button:active {
    background-color: lightgrey;
  }
  
  .react-confirm-alert-body {
      font-size: 16px;
  }



`

export default GlobalStyle
