import useAuth from '../../contexts/AuthContext/useAuth'
import differenceInYears from 'date-fns/differenceInYears'
import { useMemo } from 'react'
import { useI18next } from 'gatsby-plugin-react-i18next'
import {
  Survey,
  SurveyTypes,
  TagTypes,
  useFindPlayerSurveysQuery,
  useGetGameWithSurveysQuery,
} from '../../generated/api'

export const useSurvey = () => {
  const { user } = useAuth()
  const { language: currentLanguage } = useI18next()
  const tagNumber = user?.tags?.find((tag) => [TagTypes.Card, TagTypes.Fob].includes(tag.tagType))?.tagNumber

  const { data: gameRes } = useGetGameWithSurveysQuery(
    { id: user?.info.gameId || '' },
    { enabled: Boolean(user?.info.gameId) },
  )
  const game = gameRes?.getGame

  const { data: surveyRes } = useFindPlayerSurveysQuery(
    { playerId: user?.id || '', gameId: game?.id || '' },
    { enabled: Boolean(user?.id) && Boolean(game?.id) },
  )

  const surveys = surveyRes?.findPlayerSurveys

  const tellUsAboutYouSurveys = surveys?.find((survey) => survey.surveyType === SurveyTypes.TellUsAboutYou)
  const closingSurveys = surveys?.find((survey) => survey.surveyType === SurveyTypes.Closing)

  const closingSurveyEnabled =
    !!game?.info.surveys?.closing &&
    game?.info.surveys?.closing?.link &&
    game?.info.surveys?.closing?.shownFromDate &&
    new Date(game?.info.surveys?.closing?.shownFromDate) <= new Date() &&
    !closingSurveys

  const tellUsAboutYouSurveyEnabled =
    game?.info.surveys?.tellUsAboutYou?.link &&
    !tellUsAboutYouSurveys &&
    (!game?.info.surveys?.closing?.shownFromDate || new Date(game?.info.surveys?.closing?.shownFromDate) >= new Date())

  const getSurveyLink = useMemo(() => {
    const surveyUrl = closingSurveyEnabled
      ? game?.info.surveys?.closing?.link
      : game?.info.surveys?.tellUsAboutYou?.link

    if (!user || !surveyUrl) return '/'

    const age = user?.profile.birthDate ? differenceInYears(new Date(), new Date(user.profile.birthDate)) : 0

    try {
      const url = new URL(surveyUrl)

      url.searchParams.set('playerId', user.id)
      if (!url.searchParams.has('type')) {
        url.searchParams.set('type', closingSurveyEnabled ? SurveyTypes.Closing : SurveyTypes.TellUsAboutYou)
      }

      if (!url.searchParams.has('gameId') && user.info.gameId) {
        url.searchParams.set('gameId', user.info.gameId)
      }

      if (age && Number?.isSafeInteger(age)) {
        url.searchParams.set('a', age?.toString())
      }

      if (user?.profile.gender) {
        url.searchParams.set('g', user?.profile.gender)
      }
      if (tagNumber) {
        url.searchParams.set('cn', tagNumber.toString())
      }
      return `${url.toString()}&lang=${currentLanguage}`
    } catch (error) {
      console.log(error)
    }
  }, [game, user, closingSurveyEnabled])

  return {
    surveyLink: getSurveyLink,
    surveys: {
      tellUsAboutYou: tellUsAboutYouSurveys as Survey,
      closing: closingSurveys as Survey,
    },
    closingSurveyEnabled,
    tellUsAboutYouSurveyEnabled,
  }
}
