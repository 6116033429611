import React, { FC } from 'react'
import { format } from 'date-fns'
import {
  StyledTapListItem,
  StyledTapListItemCode,
  StyledTapListItemPoints,
  Container,
  SmallPin,
} from './TapListItem.styled'
import { useTranslation } from 'gatsby-plugin-react-i18next'

interface TapListItemProps {
  code: string
  time: Date | string
  points: number
}

const TapListItem: FC<TapListItemProps> = ({ code, time, points }) => {
  const { t } = useTranslation()

  const timeDisplay = format(new Date(time), "h:mmaaaaa'm'")

  let markerNumber = '-'

  if (code) {
    // Obtain beat box number from the code name of it.
    const match = code.match(/\d+/g)
    if (match && match.length > 0) {
      ;[markerNumber] = match
    }
  }

  return (
    <StyledTapListItem tabIndex="0">
      <Container>
        <SmallPin width="26px" height="36px" fill="#329F2F">
          {markerNumber}
        </SmallPin>

        <StyledTapListItemCode>{code}</StyledTapListItemCode>

        <span>{timeDisplay}</span>
      </Container>

      <StyledTapListItemPoints>
        {points.toLocaleString('en-GB')}
        {t('game.dashboard.stats.journeyModal.points')}
      </StyledTapListItemPoints>
    </StyledTapListItem>
  )
}

export default TapListItem
