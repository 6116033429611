import React, { FC } from 'react'
import CTAButtonCard from '../CTAButtonCard/CTAButtonCard'
import BlockWrapper from '../BlockWrapper/BlockWrapper'
import useGame from '../../contexts/GameContext/useGame'
import ContextLink from '../ContextLink'
import { useTranslation } from 'gatsby-plugin-react-i18next'

interface JoinGameCTAProps {
  hideCTA?: boolean
  showExplanation?: boolean
}

const JoinGameCTA: FC<JoinGameCTAProps> = ({ hideCTA, showExplanation = false }) => {
  const { game } = useGame()
  const { t } = useTranslation()

  if (hideCTA) {
    return null
  }

  return (
    <>
      <CTAButtonCard
        icon="hand"
        title={t('components.CTA.card.title')}
        detailText={t('components.CTA.card.text')}
        link={{
          internalLink: '/join-game',
        }}
        context={{ game }}
      />
      {showExplanation && game?.info.slug && (
        <BlockWrapper>
          <ContextLink to="/faqs#q-where-can-i-pick-up-a-card">{t('game.landing.cardPickUp')}</ContextLink>
        </BlockWrapper>
      )}
    </>
  )
}

export default JoinGameCTA
