import React from 'react'
import styled from 'styled-components'
import { Heading } from '../../../components/Heading/Heading.styled'
import device from '../../../styles/device'
import { SpeakButton } from '../get-started.styled'
import SVGImage from '../../../components/DynamicImg/SVGImage'
import PNGimage from '../../../components/PNGimage'

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100vw - 4rem);
  border: solid 2px ${(props) => props.theme.colors.body};
  border-radius: 8px;
  margin-right: 3rem;
  margin-left: 3rem;
  @media ${device.mobLandscape} {
    width: calc(100vw - 8rem);
  }
  @media ${device.tabletPortrait} {
    width: 700px;
  }
`

const Step = styled.button`
  width: 17px;
  height: 17px;
  border-radius: 50%;
  padding: 0;
  border: solid 3px ${(props) => props.theme.colors.body};
  margin-left: 0.7rem;
  margin-right: 0.7rem;
  background-color: #fff;

  &:hover {
    background-color: #fff;
  }
  &:focus {
    background-color: #fff;
  }
`

const StepEmpty = styled(Step)`
  border-color: rgba(0, 0, 0, 0.25);
`

const StepsBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`
const NavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 3rem;
  height: 150px;
`

const NavButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ButtonWrapper = styled.div`
  display: flex;
`
const ArrowButton = styled.button`
  background-color: transparent;
  &:hover {
    background-color: transparent;
  }
  &:focus {
    background-color: transparent;
  }
`

const CarouselCard = ({ title, image, content, steps, step, setCurrentPage, currentPage }) => {
  const stepArray = Array(steps).fill(1)
  return (
    <Card>
      <div>
        <div style={{ padding: '3rem 3rem 0 3rem' }}>
          <Heading textAlign="left" style={{ fontSize: '32px' }}>
            {title}
          </Heading>
        </div>
        <PNGimage alt={'jjj'} filename={image} />
        <p style={{ padding: '3rem 3rem 0 3rem', margin: 0 }}>{content}</p>
      </div>
      <NavWrapper>
        {currentPage === step && (
          <NavButtons>
            <ButtonWrapper style={{ justifyContent: 'flex-start' }}>
              {step !== 0 && (
                <ArrowButton onClick={() => setCurrentPage(step - 1)}>
                  <SVGImage
                    filename="arrowBlue"
                    alt={'Blue arrow left'}
                    svgProperties={{
                      width: '32px',
                      height: '32px',
                    }}
                  />
                </ArrowButton>
              )}
            </ButtonWrapper>
            <ButtonWrapper style={{ justifyContent: 'flex-end' }}>
              {step !== steps - 1 && (
                <SpeakButton onClick={() => setCurrentPage(step + 1)} style={{ display: 'flex', alignItems: 'center' }}>
                  <p
                    style={{
                      margin: '0 1.5rem 0 0',
                      fontFamily: 'inherit',
                      fontSize: 'inherit',
                      lineHeight: 'inherit',
                    }}
                  >
                    Next step
                  </p>

                  <SVGImage
                    filename="arrow-white"
                    alt={'White arrow left'}
                    svgProperties={{
                      width: '13px',
                      height: '13px',
                    }}
                  />
                </SpeakButton>
              )}
            </ButtonWrapper>
          </NavButtons>
        )}

        <StepsBox>
          {stepArray.map((slot, index) => {
            if (index === step) {
              return <Step onClick={() => setCurrentPage(index)} />
            } else {
              return <StepEmpty onClick={() => setCurrentPage(index)} />
            }
          })}
        </StepsBox>
      </NavWrapper>
    </Card>
  )
}

export default CarouselCard
