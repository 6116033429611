import { LeaderboardPointType, LeaderboardTeamPointType, useGetLeaderboardTeamsQuery } from '../../../generated/api'
import useGame from '../../../contexts/GameContext/useGame'

type Props = {
  type: LeaderboardPointType
  pointType: LeaderboardTeamPointType
  leaderboardId?: string
}

const useTeamLeaderboards = ({ leaderboardId, type, pointType }: Props) => {
  const { game } = useGame()

  const { data: res, ...rest } = useGetLeaderboardTeamsQuery(
    {
      input: {
        gameId: game?.id as string,
        type,
        pointType,
        leaderboardId,
      },
      gameId: game?.id as string,
    },
    {
      enabled: !!game?.id,
    },
  )

  return {
    ...rest,
    data: res?.getTeamLeaderboards.data || [],
    leaderboards: res?.findLeaderboards || [],
  }
}

export default useTeamLeaderboards
