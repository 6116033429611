import React, { FC } from 'react'

import { FormItem } from '../FormElements/FormElements.styled'
import Checkbox from '../Checkbox/Checkbox'
import RichText from '../RichText'
import { ContextProps } from '../CallToAction/CallToActionPanel'

interface GameConsentLinesProps {
  text: JSX.Element | string
  context?: ContextProps
  introBlocks?: unknown
  onChange?: (e) => void
  value?: boolean
  name?: string
  required?: boolean
}

const GameConsentLines: FC<GameConsentLinesProps> = ({
  context,
  introBlocks,
  text,
  onChange,
  value,
  name,
  required,
}) => {
  return (
    <>
      <h3>Game partner consent</h3>
      <RichText blocks={introBlocks} context={context} />
      <FormItem>
        <Checkbox id={name} name={name} text={text} value={value} onChange={onChange} required={required} />
      </FormItem>
    </>
  )
}

export default GameConsentLines
