import * as React from 'react'
import styled from 'styled-components'
import Input from '../Input/Input'
import { useAutoComplete } from '../AutoComplete/useAutoComplete'
import { useRef } from 'react'
import AutoComplete from '../AutoComplete/AutoComplete'
import SearchIconGrey from '../../assets/images/svg/search-icon-grey.svg'
import Cross from '../../assets/images/svg/cross.svg'
import useWindowSize from '../../hooks/useWindowSize'
import sortBy from 'lodash/sortBy'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import useLocations from '../../templates/game/map/Mapbox/useLocations'
import { LocationTypes } from '../../generated/api'
import useSuggestions from '../../templates/game/map/Mapbox/useSuggestions'
import { Points } from '../../templates/game/map/Mapbox/Mapbox.interface'
import { ViewState } from 'react-map-gl/src/types/external'
import { useMapState } from '../../templates/game/map/Mapbox/Context'

const Wrapper = styled.div`
  position: absolute;
  top: 160px;
  right: 25px;

  @media (max-width: 769px) {
    top: 80px;
    right: 15px;
  }
`

const MapSearchBox = () => {
  const searchInputEl = useRef<any>(null)
  const { width } = useWindowSize()
  const { t } = useTranslation()
  const { geoJsonData } = useLocations()
  const { popup, setPopup, setViewState, searchValue, setIsExpanded, setTempPointCoords, setSearchValue } =
    useMapState()
  const { ref: forwardRef, isAutoCompleteComponentVisible, setIsAutoCompleteComponentVisible } = useAutoComplete(true)
  const { suggestions, getSuggestion } = useSuggestions()

  const beatBoxes = geoJsonData?.features?.filter(({ properties }) => properties.type === LocationTypes.BeatBox)

  const filteredBeatBoxes =
    sortBy(beatBoxes, 'properties.beatBoxNumber')
      ?.filter(({ properties }) => properties.name?.toLowerCase().includes(searchValue.toString().toLowerCase()))
      ?.slice(0, 3) || []

  const suggestionsWithBeatBoxes = [...filteredBeatBoxes, ...suggestions]

  const isVisible = isAutoCompleteComponentVisible && searchValue.length > 0

  const onSearch = async () => {
    const number = parseInt(searchValue, 10)
    if (searchValue && Number.isSafeInteger(number)) {
      const feature = geoJsonData.features.find((beatBox) => {
        return beatBox.properties.name?.includes(number.toString())
      })

      if (feature) {
        setPopup({ isVisible: true, feature, type: Points.beatBox })
        setViewState((prev: ViewState) => ({
          ...(prev || {}),
          latitude: feature.geometry.coordinates[0],
          longitude: feature.geometry.coordinates[1],
          zoom: 15,
        }))
        setSearchValue('')
        setIsExpanded(false)
      } else {
        setPopup({ isVisible: false, feature: null, type: null })
        throw new Error('Beat box not found')
      }
    } else {
      setPopup({ isVisible: false, feature: null, type: null })
      throw new Error('Beat box not found')
    }
  }

  const handleGeocodes = (element) => {
    setViewState((prev: ViewState) => ({
      ...(prev || {}),
      longitude: element.geometry.coordinates[0],
      latitude: element.geometry.coordinates[1],
      zoom: 15,
    }))

    if (element && element.properties && element.properties.name && element.properties.type === LocationTypes.BeatBox) {
      return setPopup({
        feature: element,
        type: Points.beatBox,
        isVisible: true,
      })
    }
    setTempPointCoords(element.geometry.coordinates)
    setPopup({ ...popup, isVisible: false })
  }

  async function handleSearch(ev) {
    ev.preventDefault()
    searchInputEl.current?.blur()
    try {
      await onSearch()
    } catch (e) {}
  }

  return (
    <Wrapper>
      <div
        style={{
          height: 'auto',
          width: width < 360 ? 250 : 300,
          backgroundColor: 'transparent',
        }}
      >
        <form method="post" onSubmit={handleSearch} noValidate>
          <div
            style={{
              position: 'absolute',
              zIndex: 100,
              top: 25,
              left: 10,
            }}
          >
            <button
              type="submit"
              title="Search submit button"
              style={{ backgroundColor: 'transparent', padding: 0, margin: 0 }}
            >
              <SearchIconGrey style={{ width: 20, height: 20 }} />
            </button>
          </div>
          <Input
            style={{ paddingLeft: 40, paddingRight: 32 }}
            forwardedRef={searchInputEl}
            type="text"
            placeholder={t('game.map.searchBox.placeholder') as string}
            inputProps={{
              'aria-label': t('aria.label.findBeatBox'),
            }}
            id="search-box"
            value={searchValue}
            onChange={async (ev) => {
              const { target } = ev
              target.setCustomValidity('')
              setSearchValue(target.value)
              await getSuggestion(target.value)
              if (suggestionsWithBeatBoxes?.length) {
                setIsAutoCompleteComponentVisible(true)
              }
              if (searchValue === '') {
                setTempPointCoords(undefined)
              }
            }}
          />
        </form>
        {searchValue && (
          <button
            type="button"
            aria-label={t('aria.label.closeSearch') as string}
            onClick={() => {
              setSearchValue('')
              setTempPointCoords(undefined)
            }}
            style={{
              position: 'absolute',
              right: 12,
              top: 28,
              backgroundColor: 'transparent',
              padding: 0,
            }}
          >
            <Cross style={{ width: 15, height: 15 }} />
          </button>
        )}
        {isVisible ? (
          <AutoComplete
            searchValue={searchValue}
            forwardRef={forwardRef}
            data={suggestionsWithBeatBoxes}
            setSearchValue={setSearchValue}
            handleClick={(element) => {
              setIsAutoCompleteComponentVisible(false)
              handleGeocodes(element)
            }}
          />
        ) : null}
      </div>
    </Wrapper>
  )
}

export default MapSearchBox
