import styled from 'styled-components'
import device from '../../styles/device'

export const StyledWrapperContainer = styled.div`
  margin: 0 auto;
  height: 100%;
`
export const StyledWrapperChild = styled.div`
  margin: 0 auto;
  ${(props) => props.width && `max-width: ${props.width};`}
`

export const StyledWrapper = styled.div`
  text-align: ${(props) => props.textAlign || 'center'};
  margin-top: ${(props) => props.marginTop || '0'};
  margin-bottom: ${(props) => props.marginBottom || '0'};
  margin-left: 2rem;
  margin-right: 2rem;

  ${(props) =>
    props.noMobileMargins &&
    `
        margin-left: 0rem;
        margin-right: 0rem;
    `}
  @media ${device.mobLandscape} {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  @media ${device.tabletLandscape} {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  ${(props) =>
    props.small &&
    `
        margin-bottom: 1rem;

        @media ${device.mobLandscape} {
            margin-bottom: 1.5rem;
        }

        @media ${device.tabletLandscape} {
            margin-bottom: 2rem;
        }
    `}
  ${(props) =>
    props.medium &&
    `
        margin-bottom: 2rem;

        @media ${device.mobLandscape} {
            margin-bottom: 3rem;
        }

        @media ${device.tabletLandscape} {
            margin-bottom: 4rem;
        }
    `}

    ${(props) =>
    props.tall &&
    `
        margin-bottom: 4rem;

        @media ${device.mobLandscape} {
            margin-bottom: 5rem;
        }

        @media ${device.tabletLandscape} {
            margin-bottom: 6rem;
        }
    `}

    ${(props) =>
    props.veryTall &&
    `
        margin-bottom: 6rem;

        @media ${device.mobLandscape} {
            margin-bottom: 6rem;
        }

        @media ${device.tabletLandscape} {
            margin-bottom: 8rem;
        }
    `}
`
