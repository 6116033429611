import { object, number } from 'yup'

export const getDateInputSchema = (t2, maxYear) => {
  const t = (...args: any) => t2(...args) || undefined
  return object({
    month: number()
      .min(1, t('errors.formik.dateInput.month.min', { number: 1 }))
      .max(12, t('errors.formik.dateInput.month.max', { number: 12 }))
      .required(t('errors.formik.dateInput.month.req'))
      .typeError(t('components.dateInput.error.month'))
      .nullable(),
    year: number()
      .min(1900, t('errors.formik.dateInput.year.min', { number: 1900 }))
      .max(maxYear, `${t('components.dateInput.error.maxYear')} ${maxYear}`)
      .required(t('errors.formik.dateInput.year.req'))
      .typeError(t('components.dateInput.error.year'))
      .nullable(),
  })
}
