import Button from '../../../../components/Button/Button'
import LinkButton from '../../../../components/LinkButton/LinkButton'
import { Error } from '../../../game/dashboard/dashboard.styled'
import Wrapper from '../../../../components/Wrapper/Wrapper'
import React, { useState } from 'react'
import useAuth from '../../../../contexts/AuthContext/useAuth'
import theme from '../../../../styles/theme'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useManagePlayers } from '../../../signup/hooks/useManagePlayers'

const NewsletterButton = () => {
  const [newsletterResignLoading, setNewsletterResignLoading] = useState(false)
  const [newsletterError, setNewsletterError] = useState(false)
  const { user } = useAuth()
  const { t } = useTranslation()
  const { onUpdatePlayer } = useManagePlayers()

  const handleResignNewsletter = async () => {
    setNewsletterResignLoading(true)
    setNewsletterError(false)
    try {
      if (user?.id) {
        await onUpdatePlayer({
          id: user?.id,
          profile: {
            allowMarketingEmails: false,
            marketingEmailsText: '',
          },
        })
        await setNewsletterResignLoading(false)
      }
    } catch (error) {
      console.error(error)
      setNewsletterError(true)
      setNewsletterResignLoading(false)
    }
  }

  return (
    <Wrapper medium marginTop="1rem" width="400px" textAlign="left">
      {user?.profile.allowMarketingEmails ? (
        <Button
          text={t('manageAccount.newsletter.unsubscribe')}
          tertiary
          onClick={handleResignNewsletter}
          isSubmitting={newsletterResignLoading}
          disabled={newsletterResignLoading}
          style={{
            color: theme.colors.error,
            borderColor: theme.colors.error,
          }}
        />
      ) : (
        <LinkButton to={'/newsletter'} $primary>
          {t('manageAccount.newsletter.signup') || ''}
        </LinkButton>
      )}
      {/*TODO*/}
      {newsletterError && <Error>{t('errors.generic')}</Error>}
    </Wrapper>
  )
}

export default NewsletterButton
