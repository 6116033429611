import React, { FC } from 'react'
import { CTAButton } from './PrimaryActionButton.styled'
import useAuth from '../../contexts/AuthContext/useAuth'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { GameWithContent } from '../../contexts/PageContext'
import { isAfter, subDays } from 'date-fns'

interface IProps {
  game: GameWithContent
}

const PrimaryActionButton: FC<IProps> = ({ game }) => {
  const { user, isAuthenticated } = useAuth()
  const { t } = useTranslation()

  const restrictedDay = subDays(new Date(game.info.endsAt), game.info.restrictAtDays || 0)
  const isRestricted = isAfter(new Date(), restrictedDay)

  if (isAuthenticated && !user?.info.gameId && !isRestricted) {
    return <CTAButton to={'/join-game'}>{t('game.landing.primaryAction.join') || ''}</CTAButton>
  }

  return (
    <CTAButton to={isAuthenticated ? '/dashboard' : '/signup'}>
      {isAuthenticated ? t('game.landing.primaryAction.dashboard') || '' : t('game.landing.primaryAction.signUp') || ''}
    </CTAButton>
  )
}

export default PrimaryActionButton
