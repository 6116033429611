import { navigate } from 'gatsby-link'
import { NavigateOptions, useLocation } from '@gatsbyjs/reach-router'
import usePageContext from './usePageContext'
import { gameUrls, Links } from '../components/ContextLink'
import useAuth from '../contexts/AuthContext/useAuth'
import { GameWithContent } from '../contexts/PageContext'
import { Game } from '../generated/api'

const useNavigate = () => {
  const { user } = useAuth()
  const location = useLocation()
  const { allGames } = usePageContext()

  let game: GameWithContent | Game | undefined

  if (allGames) {
    game =
      allGames?.find((game: GameWithContent) => game?.id === user?.info.gameId) ||
      allGames?.find((game: GameWithContent) => location.pathname.includes(game.info.slug))
  }

  return (to: Links, options?: NavigateOptions<any>) => {
    let localTo: string = to

    if (gameUrls.some((url) => to?.includes(url))) {
      localTo = `/${game?.info.slug}${to}`
    }

    if (user?.info.gameId && to === '/') {
      localTo = `/${game?.info.slug}/dashboard`
    }

    if (to?.includes('landing')) {
      localTo = game ? `/${game?.info.slug}/` : '/'
    }

    return navigate(localTo, { ...options })
  }
}

export default useNavigate
