import React, { FC, useEffect, useState } from 'react'
import Wrapper from '../../../components/Wrapper/Wrapper'
import SEO from '../../../components/seo'
import { HeadingWrapper } from '../../../components/HeadingWrapper/HeadingWrapper.styled'
import { Heading } from '../../../components/Heading/Heading.styled'
import { SubHeading } from '../../../components/SubHeading/SubHeading.styled'
import Button from '../../../components/Button/Button'
import useAuth from '../../../contexts/AuthContext/useAuth'
import useNavigate from '../../../hooks/useNavigate'
import { useLocation } from '@gatsbyjs/reach-router'
import { graphql, Link } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Error } from '../../../components/FormElements/FormElements.styled'
import { useManagePlayers } from '../hooks/useManagePlayers'
import { fromUrlId } from '../../../utils/toUrlId'
import { IPageProps } from '../../../contexts/PageContext'
import { Puff } from 'react-loader-spinner'

const JoinFamily: FC<IPageProps<Queries.JoinFamilyQuery>> = () => {
  const { isAuthenticated, refetch, family, user, isInitialized } = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { onJoinFamily } = useManagePlayers()

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [joined, setJoined] = useState(false)

  const { search } = useLocation()
  let parentUrl: string | null = ''
  let inSignUp: string | null = ''

  if (typeof window !== 'undefined') {
    parentUrl = new URLSearchParams(search).get('id')
    inSignUp = new URLSearchParams(search).get('insignup')
  }

  useEffect(() => {
    if (!parentUrl) {
      navigate('/')
    }
  }, [])

  const parentId = parentUrl && fromUrlId(parentUrl)

  if (!isInitialized) {
    return (
      <>
        <SEO title={t('joinFamily.title')} />
        <HeadingWrapper>
          <Heading tall marginTop={'1rem'} secondary>
            {t('joinFamily.title')}
          </Heading>
        </HeadingWrapper>
        <Wrapper style={{ display: 'flex', justifyContent: 'center' }}>
          <Puff />
        </Wrapper>
      </>
    )
  }

  if (family?.length > 1 && !joined && user?.role.parentId !== parentId) {
    return (
      <>
        <SEO title={t('joinFamily.title')} />
        <HeadingWrapper>
          <Heading tall marginTop={'1rem'} secondary>
            {t('joinFamily.title')}
          </Heading>
          <SubHeading as="h5" tall tertiarySmall>
            {t('joinFamily.subTitle.haveFamily')}
          </SubHeading>
        </HeadingWrapper>
        {inSignUp ? (
          <Wrapper>
            <Button
              secondary
              isSubmitting={isLoading}
              text={t('common.continue')}
              onClick={async () => {
                await navigate(`/signup/name-details`, { replace: true })
              }}
            />
          </Wrapper>
        ) : (
          <Wrapper>
            <Button
              secondary
              isSubmitting={isLoading}
              text={t('joinFamily.goToDashboard')}
              onClick={async () => {
                await navigate('/')
              }}
            />
          </Wrapper>
        )}
      </>
    )
  }

  if (family?.length > 1 && user?.role.parentId === parentId && !joined) {
    return (
      <>
        <SEO title={t('joinFamily.title')} />
        <HeadingWrapper>
          <Heading tall marginTop={'1rem'} secondary>
            {t('joinFamily.title')}
          </Heading>
          <SubHeading as="h5" tall tertiarySmall>
            {t('joinFamily.subTitle.inFamily')}
          </SubHeading>
        </HeadingWrapper>
        {inSignUp ? (
          <Wrapper>
            <Button
              secondary
              isSubmitting={isLoading}
              text={t('common.continue')}
              onClick={async () => {
                await navigate(`/signup/name-details`, { replace: true })
              }}
            />
          </Wrapper>
        ) : (
          <Wrapper>
            <Button
              secondary
              isSubmitting={isLoading}
              text={t('joinFamily.goToDashboard')}
              onClick={async () => {
                await navigate('/')
              }}
            />
          </Wrapper>
        )}
      </>
    )
  }

  if (isAuthenticated && !joined) {
    return (
      <>
        <SEO title={t('joinFamily.title')} />
        <HeadingWrapper>
          <Heading tall marginTop={'1rem'}>
            {t('joinFamily.title')}
          </Heading>
          <SubHeading as="h5" tall secondaryForm>
            {t('joinFamily.subTitle.authenticated')}
          </SubHeading>
        </HeadingWrapper>
        <Wrapper>
          <Button
            disabled={!parentUrl}
            isSubmitting={isLoading}
            text={t('joinFamily.join')}
            onClick={async () => {
              try {
                setIsLoading(true)
                if (parentId) {
                  await onJoinFamily({ parentId: parentId })
                }
                setIsLoading(false)
                await refetch()
                setIsError(false)
                setJoined(true)
              } catch (e) {
                setIsLoading(false)
                setIsError(true)
                console.log('Error', e)
              }
            }}
            secondary
          />
        </Wrapper>
        {isError && <Error style={{ marginTop: '15px' }}>{t('errors.api.createFamily.noFamily')}</Error>}
        {inSignUp && (
          <Wrapper>
            <Button
              tertiary
              isSubmitting={isLoading}
              text={t('joinFamily.reject')}
              onClick={async () => {
                await navigate(`/signup/name-details`, { replace: true })
              }}
              style={{ marginTop: 20 }}
            />
          </Wrapper>
        )}
      </>
    )
  }

  if (joined) {
    return (
      <>
        <SEO title={t('joinFamily.title')} />
        <HeadingWrapper>
          <Heading tall marginTop={'1rem'} secondary>
            {t('joinFamily.title')}
          </Heading>
          <SubHeading as="h5" tall tertiarySmall>
            {t('joinFamily.subTitle.success')}
          </SubHeading>
        </HeadingWrapper>
        <Wrapper>
          {inSignUp ? (
            <Button
              secondary
              isSubmitting={isLoading}
              text={t('common.continue')}
              onClick={async () => {
                await navigate(`/signup/name-details`, { replace: true })
              }}
            />
          ) : (
            <Button
              secondary
              isSubmitting={isLoading}
              text={t('joinFamily.goToDashboard')}
              onClick={async () => {
                await navigate('/')
              }}
            />
          )}
        </Wrapper>
      </>
    )
  }

  return (
    <>
      <SEO title={t('joinFamily.title')} />
      <HeadingWrapper>
        <Heading tall marginTop={'1rem'}>
          {t('joinFamily.title')}
        </Heading>
        <SubHeading as="h5" veryTall secondaryForm>
          {t('joinFamily.subTitle.notAuthenticated')}
        </SubHeading>
      </HeadingWrapper>
      <Wrapper>
        <Button
          text={t('signUp.createButton')}
          style={{ marginTop: 20 }}
          onClick={() => {
            // @ts-ignore
            navigate(`/signup/?id=${parentUrl}`)
          }}
          tertiary
        />
      </Wrapper>
      <Wrapper>
        <p className="small tall">
          {t('signUp.haveAccount')}{' '}
          <Link style={{ fontSize: '1.3rem' }} to={`/login/?next=/join-family/?id=${parentUrl}`}>
            {t('auth.login')}
          </Link>
          .
        </p>
      </Wrapper>
    </>
  )
}

export const query = graphql`
  query JoinFamily($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
  }
`

export default JoinFamily
