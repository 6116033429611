import React, { useEffect } from 'react'
import useAuth from '../contexts/AuthContext/useAuth'
import useNavigate from '../hooks/useNavigate'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isAuthenticated) {
      // @ts-ignore
      navigate(`/`)
    }
  }, [isAuthenticated])

  if (!isAuthenticated) {
    return null
  }

  return <Component {...rest} />
}

export default PrivateRoute
