import Button from '../../components/Button/Button'
import Loading from '../../components/Loading'
import styled from 'styled-components'
import { StyledTabs } from '../../components/Tabs/Tabs.styled'

export const PaginationButton = styled(Button)`
  width: 185px;
  margin: 10px auto;
  display: block;
`

export const Container = styled('div')`
  position: relative;
  ${(props) =>
    props.mini &&
    `
    display: flex;
    align-items: center;
    justify-content: center;
    `}
`

export const Loader = styled(Loading)`
  position: absolute;
  top: -50px;
  margin: auto;
`

export const ToggleWrap = styled.div`
  display: inline-block;
  > * {
    float: left;
  }
  > span {
    margin-top: 8px;
  }
`

export const FixedHeightStyledTabs = styled(StyledTabs)`
  min-height: 347px;
  text-align: center;
`
