import * as React from 'react'
import Cross from '../../../assets/images/svg/cross.svg'
import { CloseButton, ModalHeading, modalSmall, StyledModal } from './Modal.styled'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const AchievementModal = ({ modalOpen, closeModal, ariaLabel, children, heading }) => {
  const { t } = useTranslation()
  return (
    <StyledModal
      isOpen={modalOpen}
      ariaHideApp={false}
      style={{ ...modalSmall }}
      onRequestClose={() => closeModal()}
      closeTimeoutMS={250}
      aria={{
        label: ariaLabel,
        expanded: !!modalOpen,
        hidden: !modalOpen,
      }}
      htmlOpenClassName="ReactModal__Html--open"
    >
      {heading && <ModalHeading>{heading}</ModalHeading>}
      <CloseButton onClick={() => closeModal()} aria-label={t('aria.label.close')}>
        <Cross />
      </CloseButton>
      {children}
    </StyledModal>
  )
}

export default AchievementModal
