import styled from 'styled-components'
import { animated } from 'react-spring'
import device from '../../styles/device'
import Button from '../Button/Button'

export const NavContainer = styled(animated.div).withConfig({
  shouldForwardProp: (prop) => !['isNavOpen'].includes(prop),
})`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${(props) => (props.isNavOpen ? props.theme.zIndex.navContainer : -1)};
  a,
  button {
    pointer-events: ${(props) => (props.isNavOpen ? 'all' : 'none')};
  }
`

export const NavBackground = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${(props) => props.theme.zIndex.navContainer};
`

export const NavCircleTop = styled(animated.div)`
  background: ${(props) => props.theme.colors.secondary};
  min-height: 400px;
  height: 100%;
  padding: 0;
  margin: 0;
  top: 0;
  width: 100%;
  transition: 0s;
  border: 2px solid ${(props) => props.theme.colors.secondary};
`

export const NavCircle = styled(animated.div)`
  background: ${(props) => props.theme.colors.secondary};
  top: 0;
  min-height: 200px;
  width: 100%;
  border-radius: 0 0 0% 90%;
`

export const NavIconWrapper = styled.div`
  max-width: ${(props) => props.theme.siteWidth};
  margin: 0 auto;
`

export const NavIcon = styled(animated.div)`
  position: absolute;
  max-width: ${(props) => props.theme.siteWidth};
  width: 100%;
  top: 0;

  svg {
    width: 100%;
    max-width: 80px;
    position: absolute;
    top: 30rem;
    right: 1rem;

    @media ${device.mobLandscape} {
      max-width: 100px;
    }

    @media ${device.tabletPortrait} {
      top: 20rem;
      max-width: 150px;
    }

    @media ${device.laptopNarrow} {
      top: 20rem;
    }
  }
`

export const StyledNav = styled.div`
  max-width: ${(props) => props.theme.siteWidth};
  margin: 0 auto;
  padding: 10rem 9rem 0 1.5rem;
  position: relative;

  @media ${device.mobPortrait} {
    padding: 10rem 9rem 0 4rem;
  }
  @media ${device.mobLandscape} {
    padding: 10rem 11rem 0 10rem;
  }
  @media ${device.tabletPortrait} {
    padding: 14rem 16rem 0 10rem;
  }
  @media ${device.tabletLandscape} {
    padding: 20rem 16rem 0 18rem;
  }
  @media ${device.desktop} {
    padding: 20rem 16rem 0 2rem;
  }
  @media ${device.laptopNarrow} {
    padding: 14rem 16rem 0 18rem;
  }

  ul {
    list-style: none;
    padding: 0;
    li::before {
      content: none;
    }
  }

  a {
    color: ${(props) => props.theme.colors.white};
    font-family: ${(props) => props.theme.fonts.secondaryFont};
    font-size: 2.2rem;

    @media ${device.tabletPortrait} {
      font-size: 2.2rem;
    }
    @media ${device.laptopNarrow} {
      font-size: 2.2rem;
    }
  }

  a:active {
    text-decoration: none;
  }
`

export const NavButton = styled(Button)`
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.orange};
  border: 2px solid ${(props) => props.theme.colors.orange};
  display: inline-block;
  width: auto;
  padding: 0.6rem 20px 0.9rem;
  pointer-events: all;
  margin-top: 30px;
  ${(props) => props.theme.hover} {
    background: ${(props) => props.theme.colors.darkOrange};
    border: 2px solid ${(props) => props.theme.colors.darkOrange};
  }
  ${(props) =>
    props.secondary &&
    `
        margin-top: 0;
        background: ${props.theme.colors.secondary};
        border: 2px solid ${props.theme.colors.secondary};
        color: ${props.theme.colors.white};
        ${props.theme.hover} {
            border: 2px solid ${props.theme.colors.secondary};
            background: ${props.theme.colors.secondary};
            text-decoration: underline;
            text-decoration-thickness: 2px;
        }
    `}
  ${(props) =>
    props.white &&
    `
        background: ${props.theme.colors.white};
        border: 2px solid ${props.theme.colors.white};
        color: ${props.theme.colors.secondary};
        ${props.theme.hover} {
           color: ${props.theme.colors.white};
           background: transparent;
           border: 2px solid ${props.theme.colors.white};
        }
    `}
`
