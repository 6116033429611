const device = {
  mobPortrait: '(min-width: 320px)',
  mobLandscape: '(min-width: 480px)',
  tabletPortrait: '(min-width: 768px)',
  tabletLandscape: '(min-width: 1024px)',
  laptopNarrow: '(min-width: 768px) and (max-height: 800px)',
  desktop: '(min-width: 1280px)',
  desktopWide: '(min-width: 1900px)',
  desktopUltraWide: '(min-width: 2556px)',
  mobPortraitOnly: '(max-width: 480px)',
  mobOnly: '(max-width: 767px)',
}

export const IS_IE11 = typeof window !== 'undefined' && !window.ActiveXObject && 'ActiveXObject' in window

export default device
