import styled from 'styled-components'
import device from '../../styles/device'
import ContextLink from '../ContextLink'

export const CustomLink = styled(ContextLink)`
  margin: 10px auto;
  font-size: 1.3rem;
  display: inline-block;
  user-select: none;

  ${(props) =>
    props.placement === 'right-aligned' &&
    `
        float: right;
    `}
  ${(props) =>
    props.placement === 'left-aligned' &&
    `
        float: left;
    `};
`

export const MenuLink = styled(ContextLink)`
  padding: 0.2rem;
  margin-left: 0.8rem;
  background-color: transparent;
  font-family: ${(props) => props.theme.fonts.primaryFont};
  color: ${(props) => props.theme.colors.body};
  font-size: 0.9rem;
  user-select: none;

  ${(props) => props.theme.hover} {
    background-color: transparent;
    text-decoration: none;
  }

  @media ${device.tabletLandscape} {
    padding: 0.4rem;
    margin-left: 1rem;
  }

  svg {
    height: 24px;
    width: 24px;
    margin-bottom: 0.2rem;
    transition: fill ${(props) => props.theme.transitions.transition};

    @media ${device.mobLandscape} {
      height: 30px;
      width: 30px;
      margin-bottom: 0.3rem;
    }
    @media ${device.tabletLandscape} {
      height: 35px;
      width: 35px;
      margin-bottom: 0.5rem;
    }

    path {
      fill: ${(props) => (props.isNavOpen ? props.theme.colors.body : props.theme.colors.primary)};
    }
  }

  p {
    margin: 0;
    font-size: 11px;
    max-width: 60px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @media ${device.tabletLandscape} {
      font-size: 15px;
      max-width: 100%;
    }
  }
`
